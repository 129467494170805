import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isNil from "lodash/isNil";
import Icon from "wonderbly-components/lib/Icon";
import ActiveLink from "../../../ActiveLink";

const ICON_MAP = {
  draw: "location",
  send: "group-6",
  deliveryvan: "card",
};

const NavItemText = (props) => {
  let linkText = props.linkText;
  if (props.fabletDown) {
    linkText = props.mobileLinkText || props.linkText;
  }
  return (
    <Fragment>
      <div
        className={cx("link-text", {
          "link-text--mobile": props.mobileLinkText && props.fabletDown,
        })}
      >
        {linkText}
      </div>
      {!isNil(props.icon) && <Icon icon={ICON_MAP[props.icon] || props.icon} />}
    </Fragment>
  );
};

const NavItem = (props) => (
  <li key={props.url}>
    <ActiveLink
      url={props.url}
      className="main-navigation-link"
      activeClassName="navigation-link--active"
      target={props.target}
      rel={props.rel}
    >
      <span className="main-navigation-link-text">
        {props.label && (
          <span className="main-navigation-link-label">{props.label}</span>
        )}
        <NavItemText
          linkText={props.linkText}
          mobileLinkText={props.mobileLinkText}
          icon={props.icon}
          fabletDown={props.fabletDown}
        />
      </span>
    </ActiveLink>
  </li>
);

NavItemText.propTypes = {
  linkText: PropTypes.string.isRequired,
  mobileLinkText: PropTypes.string,
  icon: PropTypes.string,
  fabletDown: PropTypes.bool,
};

NavItem.propTypes = {
  url: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleOnActive: PropTypes.func.isRequired,
  mobileLinkText: PropTypes.string,
  label: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  icon: PropTypes.string,
  fabletDown: PropTypes.bool,
};

export default NavItem;
