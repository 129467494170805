const identity = require("lodash/identity");
const PrismicLanguageModel = require("./LanguageModel");

const singleDocuments = ["country_list", "payment_methods", "sitemap"];

class MetadataModel {
  constructor() {
    this.id = null;
    this.uid = null;
    this.type = null;
    this.href = null;
    this.tags = [];
    this.firstPublicationDate = null;
    this.lastPublicationDate = null;
    this.slugs = null;
    this.lang = null;
    this.alternateLanguages = [];
    this.linkedDocuments = [];
    this.isSingleDocument = null;
  }
  deserialise(document) {
    this.id = document.id;
    this.uid = document.uid;
    this.type = document.type;
    this.href = document.href;
    this.firstPublicationDate = document.first_publication_date;
    this.lastPublicationDate = document.last_publication_date;
    this.lang = document.lang;

    this.tags = (document.tags || []).map(identity);
    this.slugs = (document.slugs || []).map(identity);
    this.linkedDocuments = (document.linked_documents || []).map(identity); // TODO: this is broken on Prismic
    this.alternateLanguages = (document.alternate_languages || []).map((data) =>
      new PrismicLanguageModel().deserialise(data)
    );

    this.isSingleDocument = singleDocuments.includes(document.type);

    return this;
  }
}

module.exports = MetadataModel;
