import React from "react";
import PropTypes from "prop-types";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { trackChildNavItem } from "../tracking";
import { useModel } from "src/client/js/view/hooks";
import "./SiteNavLinksList.scss";

const SiteNavLinksList = (props) => {
  const { countryModel } = useModel();

  return (
    <ul className="SiteNavLinksList">
      {props.categoryTitle && (
        <li key={props.categoryTitle} className="SiteNavLinksList__title-item">
          <h5 className="SiteNavLinksList__title">{props.categoryTitle}</h5>
        </li>
      )}
      <div className="SiteNavLinksList__links-container">
        {props.items.map(
          (item) =>
            item.navLinkUrl && (
              <li key={item.navLinkLabel} className="SiteNavLinksList__link">
                <a
                  className="SiteNavLinksList__link-text"
                  onClick={() =>
                    trackChildNavItem(props.parentItemLabel, item.navLinkLabel)
                  }
                  href={
                    item.navLinkUrl &&
                    localiseUrlII(countryModel, item.navLinkUrl)
                  }
                >
                  {item.navLinkLabel}
                </a>
              </li>
            )
        )}
      </div>
    </ul>
  );
};

SiteNavLinksList.propTypes = {
  categoryTitle: PropTypes.string,
  parentItemLabel: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      navLinkLabel: PropTypes.string,
      navLinkUrl: PropTypes.string,
    })
  ),
};

export default SiteNavLinksList;
