import omit from "lodash/omit";
import pick from "lodash/pick";
import { request } from "../../../../common/rest/index";
import { getSessionHeaders } from "src/common/facades/eagle/helpers/session-headers";
import { getCookies, isUserLoggedIn } from "./helpers/cookies";
const {
  transformCartOrderData,
} = require("./helpers/transform-cart-order-data");

/** TODO: Set baseUrl to squid url when all cart endpoints have been migrated */
let baseUrl = "";
export const setBaseUrl = (value) => {
  baseUrl = value;
};

// This can be set to empty in most environments but we can provide a value for help when testing
let relativeBaseUrl = "";
export const setRelativeBaseUrl = (value) => {
  relativeBaseUrl = value;
};

export const addToCart = (payload, countryModel) => {
  return request.post(
    `${relativeBaseUrl}/website-api/v1/cart/items`,
    JSON.stringify(payload),
    {
      headers: {
        ...getSessionHeaders(getCookies(), countryModel),
        "content-type": "application/json",
      },
    }
  );
};

export const editCartItem = (item, countryModel) => {
  const itemToSend = pick(item, ["id", "sku", "customisations"]);

  return request.patch(
    `${relativeBaseUrl}/website-api/v1/cart/items/${item.id}`,
    JSON.stringify({ item: itemToSend }),
    {
      headers: {
        ...getSessionHeaders(getCookies(), countryModel),
        "content-type": "application/json",
      },
    }
  );
};

export const removeCartItem = (itemId, countryModel) => {
  return request.del(
    `${relativeBaseUrl}/website-api/v1/cart/items/${itemId}`,
    null,
    {
      headers: {
        ...getSessionHeaders(getCookies(), countryModel),
        "content-type": "application/json",
      },
    }
  );
};

export const getCart = (guestToken, currency) => {
  const headers = getSessionHeaders(getCookies(), { currency, guestToken });
  return request.get(`${relativeBaseUrl}/website-api/v1/cart`, null, {
    headers,
  });
};

/**
 * @deprecated this method is a massive security hole and should be removed with the endpoint when checkout v2 is
 * deployed
 */
export const guestTokenLogin = (guestToken) => {
  // if the user is already signed-in DO NOTHING!
  if (isUserLoggedIn()) {
    return;
  }
  const sessionHeaders = getSessionHeaders(getCookies());
  /* eslint-disable camelcase */
  const guest_token = guestToken || sessionHeaders.LMN_GUEST_TOKEN;
  if (guest_token) {
    return request.post(
      `${baseUrl}/mermaid/api/v1/guest_token_login`,
      JSON.stringify({ guest_token }),
      {
        headers: {
          ...sessionHeaders,
          "content-type": "application/json",
        },
      }
    );
  }
  /* eslint-enable camelcase */
};

export const getStates = (country) =>
  request.get(`${baseUrl}/website/v1/states?country_iso=${country}`);

export const getEstimatedDelivery = (params) => {
  // TODO:
  // This should probably be an Eagle responsibility.
  // Technically many countries have 'states', but the
  // endpoint will throw an error with 'states' that it
  // doesn't recognise
  const countriesWithStates = ["CA", "US", "AU"];
  if (!countriesWithStates.includes(params.country_iso)) {
    params = omit(params, "state_abbr");
  }

  let requestUrl = `${baseUrl}/website/v2/shipping/estimate`;
  return request
    .get(requestUrl, params, {
      headers: getSessionHeaders(getCookies()),
    })
    .then((response) => {
      return {
        body: {
          ...response.body,
          cart: transformCartOrderData(response.body.cart),
        },
      };
    });
};

export const applyPromoCode = (code) => {
  return request.post(
    `${relativeBaseUrl}/website-api/v1/cart/promotion`,
    JSON.stringify({ code }),
    {
      headers: {
        ...getSessionHeaders(getCookies()),
        "content-type": "application/json",
      },
    }
  );
};

export const removePromoCode = () => {
  return request.del(`${relativeBaseUrl}/website-api/v1/cart/promotion`, null, {
    headers: getSessionHeaders(getCookies()),
  });
};
