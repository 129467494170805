import { useEffect, useState } from "react";
import _throttle from "lodash/throttle";

const isBrowser = typeof window !== "undefined";

const getBoundingClientRect = (ref) => ref.current.getBoundingClientRect();

export default (ref, opts = {}) => {
  const { disabled = false, throttle = 150, offset = 0 } = opts;

  if (!isBrowser || !ref) {
    return {};
  }

  const [scrolledPast, setScrolledPast] = useState(false);
  const [refTop, setRefTop] = useState(null);

  useEffect(() => {
    const handleScroll = _throttle(() => {
      // In case the ref has been moved relative to page, update the ref top
      const newRefTop =
        getBoundingClientRect(ref).top + window.pageYOffset + offset;

      setRefTop(newRefTop);

      setScrolledPast(window.pageYOffset >= newRefTop);
    }, throttle || 150);

    if (!disabled) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [ref.current]);

  return { scrolledPast, refTop };
};
