import get from "lodash/get";
import { useSelector } from "react-redux";
import useProducts from "./use-products";

import { findProductById, findProductVariant } from "src/common/utils/product";
import { getProductCustomisation } from "../../store/selectors/product";

export default (productId) => {
  const products = useProducts();
  const product = findProductById(products, productId);
  const productCustomisation = useSelector(getProductCustomisation);
  const productVariant = findProductVariant(
    get(product, "variants"),
    productCustomisation
  );

  return {
    product,
    productId,
    productVariant,
    productCustomisation,
  };
};
