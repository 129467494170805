import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Button from "wonderbly-components/lib/Button";
import { Row, Col } from "src/client/js/view/components/Grid";
import Tooltip from "wonderbly-components/lib/Tooltip";

import InlineFormField from "wonderbly-components/lib/InlineFormField";

import { prismicImage } from "src/common/utils/prismic-image";

import "./Input.scss";

const Input = (props) => {
  return (
    <form className="drawer-input" onSubmit={props.handleSubmit}>
      <Tooltip
        className="error-tooltip"
        visible={props.errors && !!props.errors.base}
        absolute={false}
        type="alert"
      >
        {props.errors && props.errors.base}
      </Tooltip>
      <Row colsToRowsOn="fablet-down drawer-input-cta-row">
        {props.drawer.inputImage && (
          <Col>
            <img
              src={prismicImage(props.drawer.inputImage)}
              alt={props.drawer.inputImage.alt || ""}
              draggable="false"
            />
          </Col>
        )}
        <Col className="col-1-3">
          <Tooltip
            className="error-tooltip"
            visible={props.errorMessage}
            absolute={false}
            type="alert"
          >
            {props.errorMessage}
          </Tooltip>
          <InlineFormField
            value={get(props.values, props.drawer.drawerKey)}
            onChange={props.handleOnChangeItem}
            type="text"
            fieldType="input"
            label={props.drawer.inputLabel}
          />
        </Col>
        <Col className="col-2-3 drawer-input-cta-button">
          <Button
            onClick={props.handleSubmit}
            type="submit"
            disabled={props.isCompleteButtonDisabled}
          >
            {props.drawer.cta}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

Input.propTypes = {
  isCompleteButtonDisabled: PropTypes.bool.isRequired,
  handleOnChangeItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    addCta: PropTypes.string.isRequired,
    drawerKey: PropTypes.string,
    inputLabel: PropTypes.string,
    inputImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
  values: PropTypes.shape({
    inputValue: PropTypes.array.isRequired,
  }).isRequired,
  errorMessage: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    base: PropTypes.string,
    names: PropTypes.string,
  }),
};

export default Input;
