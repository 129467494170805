const qs = require("qs");
const url = require("url");
const isEmpty = require("lodash/isEmpty");
const omit = require("lodash/omit");
const { track } = require("../../client/js/analytics/facade");

/**
 * navigate - Pass a URL and some tracking information and navigate to it.
 *
 * @param {String} href - A URL path to go to
 * @param {Object} queryParams - An object of parameters to be passed to the next url
 * @param {Object} tracking - Tracking information object
 * @param {String} tracking.action -
 * @param {String} tracking.[category] - An optional string to set the category of the event
 * @param {String} tracking.from - The path/country prefix the you are coming from.
 * @param {String} tracking.to - The path/country prefix the you are going to.
 */
const navigate = (
  href,
  queryParams = {},
  { action, category = "Navigation", from = "", to = "" }
) => {
  let { search } = url.parse(href);
  let query = qs.parse(search, { ignoreQueryPrefix: true });

  track(action, {
    category,
    label: `${from || "us"} -> ${to || "us"}`,
  }).then(() => {
    if (!isEmpty(queryParams)) {
      href = href.replace(search, "");
      // In the next route we no longer need return to uid so I remove here to avoid confusion later
      query = { ...queryParams, ...query };
      query = omit(query, ["return_to_uid", "return_to"]);
      query = qs.stringify(query, { addQueryPrefix: true });

      href += query;
    }
    window.location = href;
  });
};

module.exports = {
  navigate,
};
