import withProps from "recompose/withProps";
import compose from "recompose/compose";
import lifecycle from "recompose/lifecycle";
import withHandlers from "recompose/withHandlers";
import reduce from "lodash/reduce";
import { isPhototypesVisible } from "../helpers";
import Phototype from "./Phototype";

const enhance = compose(
  withProps((props) => ({
    isPhototypeVisible:
      isPhototypesVisible(props.availablePhototypes, props.gender) &&
      props.showTooltip,
    phototypes: reduce(
      props.availablePhototypes,
      (result, value) => result.concat(value),
      []
    ),
  })),
  withHandlers({
    onHandleChange: (props) => (event) => {
      props.onHandleChange(event);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      if (!!this.props.phototype && this.props.productId === "lmn") {
        this.props.setFieldValue("phototype", "type-iii");
      }
    },
  })
);

export default enhance(Phototype);
