import React from "react";
import PropTypes from "prop-types";
import Icon from "wonderbly-components/lib/Icon";
import { WonderblyLogo } from "../../SVGs";
// Please note that the 3 components
// below are taken from
// HeaderII and is legacy code.
// Basket also uses
// functions from the duck file.
import NavCountryToggle from "./NavCountryToggle";
import Basket from "./Basket";
import AccountAction from "./AccountAction";

import SitePrimaryNav from "../SitePrimaryNav";
import SiteAdditionalLinks from "../SiteAdditionalLinks";
import ChooseYourCountryModal from "../ChooseYourCountryModal/ChooseYourCountryModal";
import { useSiteNavContext } from "./SiteNavToggle/SiteNavContext";
import { localiseUrlII } from "../../../../../../common/utils/format-localised-url";
import { useModel } from "../../../hooks";
import { trackHamburgerMenu } from "../tracking";
import "./SiteNavHeader.scss";

const SiteNavHeader = (props) => {
  const { countryModel } = useModel();
  const { subMenuIndex, setSubMenuIndex, isMenuOpen, setIsMenuOpen } =
    useSiteNavContext();
  const homepageUrl = localiseUrlII(countryModel, "/");

  const handleOnClick = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
    trackHamburgerMenu();
  };

  return (
    <>
      <div className="SiteNavHeader">
        <header className="SiteNavHeader__links-header">
          <div className="SiteNavHeader__additional-links container-guttered">
            <SiteAdditionalLinks items={props.navigation.secondaryNav} />
          </div>
        </header>
        <header className="SiteNavHeader__navigation-header">
          <div className="SiteNavHeader__container-content container-guttered">
            <div className="SiteNavHeader__primary-content">
              {!isMenuOpen && (
                <button
                  className="SiteNavHeader__button"
                  aria-label="Open menu"
                  onClick={handleOnClick}
                >
                  <div className="SiteNavHeader__hamburger">
                    <Icon name="burger-menu" />
                  </div>
                </button>
              )}
            </div>
            <a className="SiteNavHeader__logo" href={homepageUrl}>
              <WonderblyLogo />
            </a>
            <div className="SiteNavHeader__desktop-menu">
              <SitePrimaryNav items={props.navigation.navMenuItems} />
            </div>
            <div className="SiteNavHeader__secondary-content">
              <div className="SiteNavHeader__content-action">
                <div className="SiteNavHeader__choose-country-modal">
                  <NavCountryToggle />
                  <ChooseYourCountryModal />
                </div>
              </div>
              <div className="SiteNavHeader__content-action">
                <AccountAction
                  country={countryModel}
                  linkText={props.navigation.accountLabel || "My Account"}
                />
              </div>
              <div className="SiteNavHeader__content-action">
                <Basket
                  country={countryModel}
                  isFlush
                  isSolo
                  linkText={props.navigation.cartLabel || "Cart"}
                />
              </div>
            </div>
          </div>
        </header>
      </div>
      {subMenuIndex !== false && (
        <div
          type="button"
          aria-label="Close menu"
          className="SiteNavHeader__desktop-overlay"
          onClick={() => setSubMenuIndex(false)}
        />
      )}
    </>
  );
};

SiteNavHeader.propTypes = {
  navigation: PropTypes.shape({
    secondaryNav: PropTypes.array,
    navMenuItems: PropTypes.array.isRequired,
    changeCountryLabel: PropTypes.string,
    accountLabel: PropTypes.string,
    cartLabel: PropTypes.string,
  }),
};

export default SiteNavHeader;
