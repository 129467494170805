import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "wonderbly-components/lib/Icon";
import "./Basket.scss";
import Contents from "./Contents";

const Basket = (props) => (
  <>
    <a
      href={props.href}
      onClick={props.handleClick}
      className={cx("basket-link", {
        "basket-link-solo": props.isSolo,
        "basket-link--flush": props.isFlush,
      })}
    >
      <div className="basket-link-icon">
        <Icon icon={props.icon} title={props.linkText} />
        <span className="basket-link-number">{props.count}</span>
      </div>
    </a>
    {props.isOpen && <Contents onClose={props.handleClick} href={props.href} />}
  </>
);

Basket.propTypes = {
  href: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  isSolo: PropTypes.bool,
  isFlush: PropTypes.bool,
  linkText: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default Basket;
