import { validateCreationForm } from "../helpers";
import isEmpty from "lodash/isEmpty";
import {
  getCompletedDrawersMap,
  mergeLocalisedDrawers,
} from "../../Canvas/helpers";
import get from "lodash/get";
import reduce from "lodash/reduce";

/**
 * buildIndividualBirthdayErrors find all the date of birth errors for each character and map it to an object using the characters index position
 * @param {Object[]} characterErrors
 * @returns {Object}
 */
const buildIndividualBirthdayErrors = (characterErrors) => {
  return characterErrors
    .filter(
      (error) => error.index !== undefined && error.field === "date_of_birth"
    )
    .reduce((result, error) => {
      result[error.index] = error.message;
      return result;
    }, {});
};

/**
 * buildBaseBirthdayError find the base error message for the date of births
 * @param {Object[]} characterErrors
 * @returns {string|undefined}
 */
const buildBaseBirthdayError = (characterErrors) => {
  const baseErrors = characterErrors.filter(
    (error) => error.index === undefined && error.field === "date_of_birth"
  );
  const baseError = baseErrors[0] || {};
  return baseError.message;
};

/**
 * validateBirthdays validate the array of characters and return only errors that relate to the date of birth
 * @param {Object[]} characters
 * @param {Object} props
 * @returns {Promise<never>}
 */
export const validateBirthdays = async (characters, props) => {
  const birthdayErrors = await validateCreationForm({ characters }, props, {
    raw: true,
  });
  if (birthdayErrors) {
    const characterErrors = birthdayErrors.characters;
    let errors = {};
    if (characterErrors) {
      const individualBirthdayErrors =
        buildIndividualBirthdayErrors(characterErrors);
      const baseBirthdayError = buildBaseBirthdayError(characterErrors);
      if (individualBirthdayErrors && !isEmpty(individualBirthdayErrors)) {
        errors.birthdays = individualBirthdayErrors;
      }
      if (baseBirthdayError) {
        errors.base = baseBirthdayError;
      }
    }
    if (!isEmpty(errors)) {
      return errors;
    }
  }
};

/**
 * getDrawerIdByName find the full id of a canvas drawer via the name or return the currently active drawer's id
 * @param {string} name
 * @param {Object} props
 * @returns {string}
 */
export const getDrawerIdByName = (name, props) => {
  const localisedDrawers = mergeLocalisedDrawers(props);
  const drawers = get(props, "primary.drawers", {});
  const propsWithDrawers = {
    ...props,
    drawers:
      localisedDrawers.length > 0
        ? { ...drawers, other: localisedDrawers }
        : drawers,
  };
  const drawersMap = getCompletedDrawersMap(propsWithDrawers);
  return reduce(
    drawersMap,
    (result, value) => {
      const multiBirthdayDrawerId = value.find((drawerId) =>
        drawerId.includes(name)
      );
      return multiBirthdayDrawerId || result;
    },
    props.activeDrawers
  );
};
