// Add to cart

export const ADD_TO_CART_PENDING = "ADD_TO_CART_PENDING";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";

export const addToCartPending = (payload) => ({
  type: ADD_TO_CART_PENDING,
  payload,
});

export const addToCartSuccess = (response, payload) => ({
  type: ADD_TO_CART_SUCCESS,
  response,
  payload,
});

export const addToCartFailure = (response, payload) => ({
  type: ADD_TO_CART_FAILURE,
  response,
  payload,
});

// Edit cart item

export const EDIT_CART_ITEM_PENDING = "EDIT_CART_ITEM_PENDING";
export const EDIT_CART_ITEM_SUCCESS = "EDIT_CART_ITEM_SUCCESS";
export const EDIT_CART_ITEM_FAILURE = "EDIT_CART_ITEM_FAILURE";

export const editCartItemPending = (item) => ({
  type: EDIT_CART_ITEM_PENDING,
  item,
});

export const editCartItemSuccess = (response, itemId) => ({
  type: EDIT_CART_ITEM_SUCCESS,
  response,
  itemId,
});

export const editCartItemFailure = (response, itemId) => ({
  type: EDIT_CART_ITEM_FAILURE,
  response,
  itemId,
});

// Remove cart item

export const REMOVE_CART_ITEM_PENDING = "REMOVE_CART_ITEM_PENDING";
export const REMOVE_CART_ITEM_SUCCESS = "REMOVE_CART_ITEM_SUCCESS";
export const REMOVE_CART_ITEM_FAILURE = "REMOVE_CART_ITEM_FAILURE";

export const removeCartItemPending = (itemId) => ({
  type: REMOVE_CART_ITEM_PENDING,
  itemId,
});

export const removeCartItemSuccess = (response, itemId) => ({
  type: REMOVE_CART_ITEM_SUCCESS,
  response,
  itemId,
});

export const removeCartItemFailure = (response, itemId) => ({
  type: REMOVE_CART_ITEM_FAILURE,
  response,
  itemId,
});

// Get cart

export const GET_CART_PENDING = "GET_CART_PENDING";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAILURE = "GET_CART_FAILURE";
export const GET_CART_EMPTY = "GET_CART_EMPTY";
export const GET_ESTIMATED_CART_SUCCESS = "GET_ESTIMATED_CART_SUCCESS";

export const getCartPending = (guestToken) => ({
  type: GET_CART_PENDING,
  guestToken,
});

export const getCartSuccess = (response) => ({
  type: GET_CART_SUCCESS,
  response,
});

export const getCartFailure = (response) => ({
  type: GET_CART_FAILURE,
  response,
});

export const getCartEmpty = () => ({
  type: GET_CART_EMPTY,
});

export const getEstimatedCartSuccess = (response) => ({
  type: GET_ESTIMATED_CART_SUCCESS,
  response,
});

// Apply promo code to cart

export const ADD_PROMO_CODE_SUCCESS = "ADD_PROMO_CODE_SUCCESS";
export const ADD_PROMO_CODE_FAILURE = "ADD_PROMO_CODE_FAILURE";

export const addPromoCodeSuccess = (response) => ({
  type: ADD_PROMO_CODE_SUCCESS,
  response,
});

export const addPromoCodeFailure = (response) => ({
  type: ADD_PROMO_CODE_FAILURE,
  response,
});

// Remove promo code from cart

export const REMOVE_PROMO_CODE_SUCCESS = "REMOVE_PROMO_CODE_SUCCESS";
export const REMOVE_PROMO_CODE_FAILURE = "REMOVE_PROMO_CODE_FAILURE";

export const removePromoCodeSuccess = (response) => ({
  type: REMOVE_PROMO_CODE_SUCCESS,
  response,
});

export const removePromoCodeFailure = (response) => ({
  type: REMOVE_PROMO_CODE_FAILURE,
  response,
});

// Get checkout shipping methods

export const GET_SHIPPING_METHODS_PENDING = "GET_SHIPPING_METHODS_PENDING";
export const GET_SHIPPING_METHODS_SUCCESS = "GET_SHIPPING_METHODS_SUCCESS";
export const GET_SHIPPING_METHODS_FAILURE = "GET_SHIPPING_METHODS_FAILURE";

export const getShippingMethodsPending = () => ({
  type: GET_SHIPPING_METHODS_PENDING,
});

export const getShippingMethodsSuccess = (response) => ({
  type: GET_SHIPPING_METHODS_SUCCESS,
  response,
});

export const getShippingMethodsFailure = (response) => ({
  type: GET_SHIPPING_METHODS_FAILURE,
  response,
});

// Update checkout shipping methods

export const UPDATE_SHIPPING_METHODS_SUCCESS =
  "UPDATE_SHIPPING_METHODS_SUCCESS";
export const UPDATE_SHIPPING_METHODS_FAILURE =
  "UPDATE_SHIPPING_METHODS_FAILURE";

export const updateShippingMethodsSuccess = () => ({
  type: UPDATE_SHIPPING_METHODS_SUCCESS,
});

export const updateShippingMethodsFailure = (response) => ({
  type: UPDATE_SHIPPING_METHODS_FAILURE,
  response,
});

// Get checkout payment methods

export const GET_PAYMENT_METHODS_PENDING = "GET_PAYMENT_METHODS_PENDING";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAILURE = "GET_PAYMENT_METHODS_FAILURE";

export const getPaymentMethodsPending = () => ({
  type: GET_PAYMENT_METHODS_PENDING,
});

export const getPaymentMethodsSuccess = (response) => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  response,
});

export const getPaymentMethodsFailure = (response) => ({
  type: GET_PAYMENT_METHODS_FAILURE,
  response,
});
