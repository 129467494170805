import React from "react";
import { Route, Switch } from "react-router-dom";
import AppPage from "../components/AppPage";
import DynamicLayout from "../components/DynamicLayout";
import ProductLayout from "../components/ProductLayout";
import CheckoutPage from "../components/CheckoutPage";
import CreateProduct from "../components/CreateProduct";

import SitemapPage from "../components/SitemapPage";

const dynamicLayoutFactory =
  (pageId, analyticsContext = "Misc", hideReferrer = false) =>
  () =>
    (
      <DynamicLayout
        pageId={pageId}
        analyticsContext={analyticsContext}
        hideReferrer={hideReferrer}
      />
    );

const productLayoutFactory =
  (pageId, analyticsContext = "Product") =>
  () =>
    <ProductLayout pageId={pageId} analyticsContext={analyticsContext} />;

const checkoutLayoutFactory =
  (pageId, analyticsContext = "Checkout", props) =>
  () =>
    (
      <CheckoutPage {...props}>
        {dynamicLayoutFactory(pageId, analyticsContext)()}
      </CheckoutPage>
    );

const createProductFactory = () => () => <CreateProduct />;

export default (
  <Switch>
    <Route
      exact
      path="/:country?/checkout-v2/address"
      render={checkoutLayoutFactory("shipping-details", "Shipping Details")}
    />
    <Route
      exact
      path="/:country?/checkout-v2/delivery"
      render={checkoutLayoutFactory("shipping-methods", "Shipping Methods")}
    />
    <Route
      exact
      path="/:country?/checkout-v2/payment"
      render={checkoutLayoutFactory("payments-methods", "Payment Methods")}
    />

    <Route
      exact
      path="/:country?/checkout"
      render={checkoutLayoutFactory("express-checkout", "Checkout", {
        showNavigation: false,
      })}
    />

    <Route
      exact
      path="/:country?/checkout/shipping"
      render={checkoutLayoutFactory(
        "express-checkout-shipping",
        "Checkout shipping",
        { showNavigation: false }
      )}
    />

    <Route
      exact
      path="/:country?/checkout-v2/paypal"
      render={checkoutLayoutFactory("paypal-checkout", "Paypal Checkout")}
    />

    <Route
      exact
      path="/:country?/personalized-products/:productSlug/create-product"
      render={createProductFactory()}
    />

    <AppPage>
      <Switch>
        <Route
          exact
          path="/:country?/checkout-v2/confirmation/:orderNumber"
          render={dynamicLayoutFactory("confirmation", "Confirmation")}
        />

        <Route
          exact
          path="/:country?/checkout-v2/confirmation/:orderNumber"
          render={dynamicLayoutFactory("confirmation", "Confirmation")}
        />

        <Route
          exact
          path="/:country?/landing-pages/personalized-products/product/:slug"
          render={dynamicLayoutFactory("product-variant", "Product Page")}
        />
        <Route
          exact
          path="/:country?/landing-pages/personalized-products/:slug"
          render={dynamicLayoutFactory("range-variant", "Range")}
        />
        <Route
          exact
          path="/:country?/landing-pages/:slug"
          render={dynamicLayoutFactory("homepage-variant", "Homepage")}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/options/:itemId"
          render={dynamicLayoutFactory(
            "cart-edit-options",
            "Cart Edit Options"
          )}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/options"
          render={dynamicLayoutFactory("options", "Options", true)}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/preview/:orderNumber/:itemId"
          render={dynamicLayoutFactory("order-edit-item", "Order Edit Item")}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/preview/:itemId"
          render={dynamicLayoutFactory("cart-edit-item", "Cart Edit Item")}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/preview"
          render={dynamicLayoutFactory("canvas", "Creation Canvas", true)}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug"
          render={dynamicLayoutFactory("product", "Product Page")}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/formats"
          render={dynamicLayoutFactory("formats", "Product Formats Page", true)}
        />
        <Route
          exact
          path="/:country?/personalized-products/:productSlug/formats/:itemId"
          render={dynamicLayoutFactory("formats", "Cart Edit Formats Page")}
        />
        <Route
          exact
          path="/:country?/personalized-products"
          render={dynamicLayoutFactory("range", "Range")}
        />
        <Route
          exact
          path="/:country?/personalized-downloads/:slug"
          render={productLayoutFactory("product-download", "Product Download")}
        />
        <Route
          exact
          path="/:country?/choose-your-country"
          render={dynamicLayoutFactory("choose-your-country", "Choose Country")}
        />
        <Route
          exact
          path="/:country?/login"
          render={dynamicLayoutFactory("login", "Login")}
        />
        <Route
          exact
          path="/:country?/signup/existing-user"
          render={dynamicLayoutFactory(
            "signup-existing-user",
            "Signup Existing User Error"
          )}
        />
        <Route
          exact
          path="/:country?/signup"
          render={dynamicLayoutFactory("signup", "Signup")}
        />
        <Route
          exact
          path="/:country?/resend-email/:user_id"
          render={dynamicLayoutFactory("resend-email", "Resend Email")}
        />
        <Route
          exact
          path="/:country?/account/profile"
          render={dynamicLayoutFactory("account-profile", "Account Profile")}
        />
        <Route
          exact
          path="/:country?/account/credit"
          render={dynamicLayoutFactory("account-credit", "Account Credit")}
        />
        <Route
          exact
          path="/:country?/account/offers"
          render={dynamicLayoutFactory("account-offers", "Account Offers")}
        />
        <Route
          exact
          path="/:country?/account/orders/:orderNumber"
          render={dynamicLayoutFactory("order-details", "Order Details")}
        />
        <Route
          exact
          path="/:country?/account/orders/:orderNumber/edit-shipping"
          render={dynamicLayoutFactory(
            "edit-shipping-details",
            "Edit Shipping Details"
          )}
        />
        <Route
          exact
          path="/:country?/account/orders"
          render={dynamicLayoutFactory("orders", "Orders")}
        />
        <Route
          exact
          path="/:country?/account"
          render={dynamicLayoutFactory(
            "account-dashboard",
            "Account Dashboard"
          )}
        />
        <Route
          exact
          path="/:country?/pages/:slug"
          render={dynamicLayoutFactory("pages", "Static Pages")}
        />
        <Route
          exact
          path="/:country?/offers/:slug"
          render={dynamicLayoutFactory("offers-variant", "Offers")}
        />
        <Route
          exact
          path="/:country?/offers"
          render={dynamicLayoutFactory("offers", "Offers")}
        />
        <Route
          exact
          path="/:country?/search-links"
          render={dynamicLayoutFactory("search-links", "Search Links")}
        />
        <Route
          exact
          path="/:country?/forgot-password"
          render={dynamicLayoutFactory("forgot-password", "Forgot Password")}
        />
        <Route
          exact
          path="/:country?/confirm"
          render={dynamicLayoutFactory("confirm", "Confirm")}
        />
        <Route
          exact
          path="/:country?/cart"
          render={dynamicLayoutFactory("cart", "Cart")}
        />
        <Route exact path="/:country?/sitemap" component={SitemapPage} />
        <Route
          exact
          path="/:country?/help"
          component={dynamicLayoutFactory("gladly-help", "Gladly Help")}
        />
        <Route
          exact
          path="/:country?"
          render={dynamicLayoutFactory("homepage", "Homepage")}
        />
        <Route
          exact
          path="/:country?/contact-us"
          render={dynamicLayoutFactory("contact-us", "Contact Us")}
        />
      </Switch>
    </AppPage>
  </Switch>
);
