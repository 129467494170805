import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withModel } from "../data-providers/model";

export const getCountryContent = (props) => {
  const currentCountry = props.model.countryModel;
  const prismicCountries = props.model.prismicCountryListModel.countries;

  // Remove locale from CMS, this was added on 01/04/2020 during a feature to allow users to change county,
  // and was renamed to language. This change was causing a clash between the country model and the content,
  // which when making requests to Eagle that required the locale caused errors as the locale was either null or
  // the name of the locale, i.e. English instead of en-GB.

  // eslint-disable-next-line no-unused-vars
  const { locale, ...country } = prismicCountries
    .filter((prismicCountry) => prismicCountry.iso === currentCountry.iso)
    .find((prismicCountry, index, array) => {
      let sameLocale = true;
      if (array.length > 1) {
        sameLocale =
          prismicCountry.localeCode &&
          currentCountry.contentLocales.includes(
            prismicCountry.localeCode.toLowerCase()
          );
      }
      return sameLocale;
    });

  return country || {};
};

const withCountry = compose(
  withModel,
  withProps((props) => ({
    country: {
      ...props.model.countryModel,
      ...getCountryContent(props),
    },
  }))
);

export default withCountry;
