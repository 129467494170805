/**
 * @fileOverview
 *
 * Don't render children if a slice throws an error
 *
 * CLIENT SIDE ONLY! You still need to handle
 * and report errors on the server as usual.
 */

import React from "react";
import PropTypes from "prop-types";
import logger from "src/logger";

export default class SliceErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    logger.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}
