const { request } = require("../../rest/index");
const endpoints = require("../../config/endpoints");

const InvalidCustomisationError = require("../../errors/InvalidCustomisationError");
const ProductRendererNotFoundError = require("../../errors/ProductRendererNotFoundError");

const formatBody = (response) => {
  let body = {
    images: [],
    navItems: [],
  };

  response.body.toc.map((page, index) => {
    body.images.push(page.url);

    const availableOptions = page?.info?.options?.available;
    const bookmark = page?.info?.bookmark;

    // This maps the character part of the info to the navItems props for char picker
    // we should put other mappings for dynamic content here
    if (availableOptions) {
      const selectedOption = availableOptions.find((option) => option.selected);

      body.navItems.push({
        bookmark: bookmark,
        index: index,
        letter: selectedOption.letter,
        defaultCharacter: selectedOption.label,
        id: selectedOption.id,
        availableOptions: availableOptions,
      });
    } else if (bookmark) {
      body.navItems.push({ bookmark });
    }
  });

  return body;
};

const fetchPreviewAssets = async (productId, customisations) => {
  const params = customisations;
  const endpoint = endpoints.buildPreviewUrl(productId, params);

  const response = await request.post(endpoint, params, {
    headers: {
      "content-type": "application/json",
    },
  });

  if (endpoints.isMu2(productId)) {
    const formattedBody = formatBody(response);
    return formattedBody;
  }

  return response.body;
};

const renderProduct = async (productId, customisations) => {
  try {
    const params = customisations;
    const endpoint = endpoints.buildPreviewUrl(productId, params);

    const response = await request.post(endpoint, params, {
      headers: {
        "content-type": "application/json",
      },
    });

    return response.body; // If not mu2 then it's lmn not on muse
  } catch (err) {
    switch (err.status) {
      case 400:
        throw new InvalidCustomisationError(err);
      case 404:
        throw new ProductRendererNotFoundError(err, productId);
      default:
        throw err;
    }
  }
};

module.exports = {
  fetchPreviewAssets,
  renderProduct,
};
