import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import PromoBanners from "../PromoBanners";
import SiteNavigationWrapper from "../SiteNavigation/SiteNavigationWrapper";
import Footer from "../Footer";
import CountryPrompt from "../CountryPrompt";
import OffersOverlay from "../OffersOverlay";
import SiteLoader from "../SiteLoader";
import "./AppPage.scss";
import { useIsHeadless } from "../../data-providers/model";

const AppPage = (props) => {
  const isHeadless = useIsHeadless();
  const wrapperAttrs = {
    className: cx("app-wrapper", {
      "app-nav-open": props.isNavOpen,
      "app-overlay-active": props.overlayActive,
    }),
    style: {
      top: props.overlayActive ? `-${props.currentScrollPos}px` : null,
    },
  };
  if (isHeadless) {
    return (
      <div {...wrapperAttrs}>
        <CountryPrompt disabled />
        {props.children}
        <OffersOverlay />
        <SiteLoader />
        <div id="modal-container" />
      </div>
    );
  }
  return (
    <div {...wrapperAttrs}>
      <CountryPrompt disabled />
      <PromoBanners location={props.location} />
      <SiteNavigationWrapper />
      {props.children}
      <Footer />
      <OffersOverlay />
      <SiteLoader />
      <div id="modal-container" />
    </div>
  );
};

AppPage.propTypes = {
  overlayActive: PropTypes.bool.isRequired,
  currentScrollPos: PropTypes.number.isRequired,
  isNavOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  location: PropTypes.object,
};

export default AppPage;
