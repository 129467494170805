import { combineReducers } from "redux";
import { cart } from "./cart";
import { checkoutDetails } from "./checkoutDetails";
import { product } from "./product";
import { responsive } from "./responsive";
import app from "./app";
import header from "src/client/js/view/components/SiteNavigation/duck";
import bookPreview from "src/client/js/view/components/BookPreview/duck";
import canvasDrawers from "src/client/js/view/components/CanvasDrawers/duck";
import cartShipping from "src/client/js/view/components/Shipping/duck";
import flashMessages from "src/client/js/view/components/FlashMessages/duck";
import products from "./prismic/products";
import cookies from "./cookies";
import geoLocation from "./geoLocation";

const prismic = combineReducers({
  products,
});

const components = combineReducers({
  app,
  header,
  bookPreview,
  canvasDrawers,
  cartShipping,
  flashMessages,
});

const session = combineReducers({
  cart,
  product,
  responsive,
  prismic,
  cookies,
  geoLocation,
  checkoutDetails,
});

export default combineReducers({
  components,
  session,
});
