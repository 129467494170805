const merge = require("lodash/merge");
const isArray = require("lodash/isArray");

/**
 *
 * @param {object} object The original object.
 * @param {...object} [sources] The source objects
 * @returns {object} Returns a merged object.
 */
const mergeObjects = (object, ...sources) => {
  // If there are more than one source then merge those objects first.
  const newObject =
    sources.length > 1 ? mergeObjects(...sources) : sources[0] || {};
  const value = merge({}, object, newObject);

  Object.keys(value).map((key) => {
    // Only overwrite the value if it existed in the new object
    if (newObject[key]) {
      const oldValue = object[key];
      const newValue = newObject[key];
      // If the values are arrays and have different length,
      // we want to overwrite the old array with the new one
      // instead of merging them
      if (
        isArray(oldValue) &&
        isArray(newValue) &&
        oldValue.length !== newValue.length
      ) {
        value[key] = newValue;
      }
    }
  });

  return value;
};

module.exports = mergeObjects;
