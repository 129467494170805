const fetch = require("cross-fetch");
const isString = require("lodash/isString");
const merge = require("lodash/merge");
const appendQueryParams = require("../utils/append-query-params");

const RestResponse = require("./RestResponse");
const RestResponseError = require("./RestResponseError");

const isJSON = (contentType) =>
  String(contentType).startsWith("application/json");
const isText = (contentType) => String(contentType).startsWith("text");

const defaultOptions = {
  compress: true,
  timeout: 10000,
  headers: {},
  credentials: "same-origin",
};

/**
 * @private
 * @param {string} url
 * @param {string} method
 * @param {Object} body
 * @param {Object} options
 * @returns {Promise}
 */
const send = async (url, method, body, options) => {
  options = {
    ...defaultOptions,
    ...options,
    method,
  };

  /**
   * isomorphically add csrf token header to all outgoing requests
   * this is used by the server to validate that requests originate from our domain
   */
  options = {
    ...options,
    headers: {
      ...options.headers,
      "x-csrf-token": typeof window !== "undefined" && window._csrf,
    },
  };

  if (body) {
    options.body = isString(body) ? body : JSON.stringify(body);
  }

  const response = await fetch(url, options);
  const contentType = response.headers.get("content-type");
  // success or error - we need to drain the response to prevent memory leaks
  const responseBody = isJSON(contentType)
    ? await response.json()
    : await response.text();
  if (!response.ok) {
    throw new RestResponseError(
      response.status,
      response.statusText,
      responseBody,
      response.url
    );
  }
  return new RestResponse(
    response.status,
    contentType,
    responseBody,
    response.headers,
    response.url
  );
};

const get = (url, query = {}, options = {}) => {
  return send(appendQueryParams(query, url), "GET", null, options);
};

const post = (url, body = {}, options = {}) => {
  return send(url, "POST", body, options);
};

const put = (url, body = {}, options = {}) => {
  return send(url, "PUT", body, options);
};

const patch = (url, body = {}, options = {}) => {
  return send(url, "PATCH", body, options);
};

const del = (url, body = {}, options = {}) => {
  return send(url, "DELETE", body, options);
};

const jsonRequest =
  (method) =>
  (url, queryOrBody = {}, options = {}) => {
    return method(
      url,
      queryOrBody,
      merge({}, { headers: { "content-type": "application/json" } }, options)
    );
  };

module.exports = {
  request: {
    get,
    post,
    put,
    patch,
    del,
  },
  jsonRequest: {
    get: jsonRequest(get),
    post: jsonRequest(post),
    put: jsonRequest(put),
    patch: jsonRequest(patch),
    del: jsonRequest(del),
  },
  contentType: {
    isJSON,
    isText,
  },
};
