import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import omit from "lodash/omit";
import qs from "qs";
import mergeObjects from "src/common/utils/merge-objects";

const withQueryParams = compose(
  withRouter,
  withProps((props) => ({
    queryParams: qs.parse(props.history.location.search, {
      ignoreQueryPrefix: true,
    }),
  })),
  withHandlers({
    replaceQueryParams: (props) => (params) => {
      const newParams = mergeObjects(
        qs.parse(props.history.location.search, {
          ignoreQueryPrefix: true,
        }),
        props.queryParams,
        params
      );
      const newQuery = qs.stringify(newParams, { addQueryPrefix: true });
      if (newQuery !== props.history.location.search) {
        props.history.replace({ search: newQuery });
      }
    },
    updateQueryParams: (props) => (params) => {
      const newParams = mergeObjects(
        qs.parse(props.history.location.search, {
          ignoreQueryPrefix: true,
        }),
        props.queryParams,
        params
      );
      const newQuery = qs.stringify(newParams, { addQueryPrefix: true });
      if (newQuery !== props.history.location.search) {
        props.history.push({ search: newQuery });
      }
    },
    // Needed for avatars 3.0
    // will not merge object params deeply.
    // this will replace all params in any set object therefore removing params
    // which do not exist as props on the replacement object.
    updateQueryParamsShallow: (props) => (params) => {
      const newParams = {
        ...qs.parse(props.history.location.search, {
          ignoreQueryPrefix: true,
        }),
        ...props.queryParams,
        ...params,
      };
      const newQuery = qs.stringify(newParams, { addQueryPrefix: true });
      if (newQuery !== props.history.location.search) {
        props.history.push({ search: newQuery });
      }
    },
    removeQueryParams: (props) => (keys) => {
      const newParams = omit(props.queryParams, keys);
      const newQuery = qs.stringify(newParams, { addQueryPrefix: true });
      if (newQuery !== props.history.location.search) {
        props.history.push({ search: newQuery });
      }
    },
  })
);

export default withQueryParams;
