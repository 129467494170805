const { request } = require("../../rest/index");
const appendQueryParams = require("../../utils/append-query-params");

const { getSessionHeaders } = require("./helpers/session-headers");

let baseUrl = "";
const setBaseUrl = (value) => {
  baseUrl = value;
};

const jsonHeaders = (cookies = {}) => ({
  ...getSessionHeaders(cookies),
  "content-type": "application/json",
});

const getCheckoutData = (cookies) => {
  return request.get(`${baseUrl}/website/v2/checkout/user`, null, {
    headers: jsonHeaders(cookies),
  });
};

const getOrderState = (order, cookies) => {
  return request.get(`${baseUrl}/website/v2/order/states/${order}`, null, {
    headers: jsonHeaders(cookies),
  });
};

const getKlarnaToken = (paymentMethodId, cookies) => {
  const url = appendQueryParams(
    {
      klarna_payment_method_id: paymentMethodId,
    },
    `${baseUrl}/api/klarna/sessions`
  );

  return request.post(url, null, { headers: jsonHeaders(cookies) });
};

const authorizeKlarnaPayment = (authorizationToken, cookies) => {
  const url = appendQueryParams(
    {
      authorization_token: authorizationToken,
      currency_code: cookies.currency,
    },
    `${baseUrl}/checkout/klarna/payments`
  );

  return request.post(url, null, { headers: jsonHeaders(cookies) });
};

// I know this isn't a checkout route but...
// TODO: Move this into the cart facade in common when it the cart facade is moved from client
const getOrder = async (cookies) => {
  const headers = getSessionHeaders(cookies);
  const response = await request.get(`${baseUrl}/website/v2/cart`, null, {
    headers,
  });
  return response.body.data;
};

const getCheckoutState = async (cookies) => {
  const response = await request.get(
    `${baseUrl}/website/v2/checkout/state`,
    null,
    { headers: jsonHeaders(cookies) }
  );
  return response.body.state;
};

const submitUserDetails = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/shipping`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const submitContactDetails = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/shipping/express_create`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const getShippingMethods = (cookies) => {
  return request.get(`${baseUrl}/website/v2/checkout/shipping/methods`, null, {
    headers: jsonHeaders(cookies),
  });
};

const submitShippingMethods = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/shipping/methods`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const createStripeShippingMethod = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/shipping/stripe_methods`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const getPaymentMethods = (cookies) =>
  request.get(`${baseUrl}/website/v2/checkout/payment/methods`, null, {
    headers: jsonHeaders(cookies),
  });

const submitBraintreePaypalPayment = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/payment/paypal/pay`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const submitPaidForPayment = (cookies) => {
  return request
    .post(`${baseUrl}/website/v2/checkout/payment/free`, null, {
      headers: jsonHeaders(cookies),
    })
    .then((res) => res.body);
};

const getPaymentMethodSession = (paymentMethod, cookies, body = null) => {
  return request
    .post(`${baseUrl}/website/v2/checkout/payment/${paymentMethod}`, body, {
      headers: jsonHeaders(cookies),
    })
    .then((res) => res.body);
};

const pay = (paymentMethod, cookies, body = null) => {
  return request
    .post(`${baseUrl}/website/v2/checkout/payment/${paymentMethod}/pay`, body, {
      headers: jsonHeaders(cookies),
    })
    .then((res) => res.body);
};

const updatePaypalAddress = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/payment/paypal_checkout/create_or_update_shipping_address`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const submitPaypalOrder = (payload, cookies) => {
  return request.post(
    `${baseUrl}/website/v2/checkout/payment/paypal_checkout/order_approved`,
    JSON.stringify(payload),
    { headers: jsonHeaders(cookies) }
  );
};

const payPaypalOrder = (cookies) => {
  return request
    .post(
      `${baseUrl}/website/v2/checkout/payment/paypal_checkout/pay_approved_order`,
      {},
      { headers: jsonHeaders(cookies) }
    )
    .then((res) => res.body);
};

module.exports = {
  getOrder,
  getCheckoutData,
  getCheckoutState,
  getOrderState,
  submitUserDetails,
  submitContactDetails,
  setBaseUrl,
  getShippingMethods,
  createStripeShippingMethod,
  submitBraintreePaypalPayment,
  submitPaidForPayment,
  submitShippingMethods,
  getPaymentMethods,
  getPaymentMethodSession,
  pay,
  getKlarnaToken,
  authorizeKlarnaPayment,
  updatePaypalAddress,
  submitPaypalOrder,
  payPaypalOrder,
};
