import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Form from "wonderbly-components/lib/Form";
import RichText from "../../Prismic/RichText";

import { replaceRichText } from "src/common/utils/text-replace";

const ActiveItem = forwardRef(
  ({ image, content, fields, customisations, onHandleSubmit }, ref) => {
    const isImageVisible =
      image?.url &&
      !fields.includes(({ type }) => type === "scrollable_card_radio");

    return (
      <div className="MultiLevelForm__active-form">
        {isImageVisible && (
          <div className="MultiLevelForm__active-form__image">
            <img src={image.url} alt={image.alt} />
          </div>
        )}
        <div className="MultiLevelForm__active-form__container">
          <RichText
            className="MultiLevelForm__active-form__content"
            fields={replaceRichText(content, customisations)}
          />
          <Form onSubmit={onHandleSubmit} fields={fields} ref={ref} />
        </div>
      </div>
    );
  }
);

ActiveItem.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  content: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  customisations: PropTypes.object.isRequired,
  onHandleSubmit: PropTypes.func.isRequired,
};

export default ActiveItem;
