import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import SiteNavItem from "../SiteNavItem";
import { useSiteNavContext } from "../SiteNavHeader/SiteNavToggle/SiteNavContext";
import { useMediaQueries } from "../../../hooks";
import "./SitePrimaryNav.scss";

const SitePrimaryNav = (props) => {
  const { setSubMenuIndex } = useSiteNavContext();
  const desktop = useMediaQueries("desktop");

  let mouseEnterTimeout = null;
  let mouseLeaveTimeout = null;

  const handleMouseEnter = (index, columnLength) => {
    if (desktop) {
      clearTimeout(mouseEnterTimeout);
      clearTimeout(mouseLeaveTimeout);
      mouseEnterTimeout = setTimeout(() => setSubMenuIndex(index), 270);
    }
  };

  const handleMouseLeave = () => {
    if (desktop) {
      clearTimeout(mouseEnterTimeout);
      clearTimeout(mouseLeaveTimeout);
      mouseLeaveTimeout = setTimeout(() => setSubMenuIndex(false), 270);
    }
  };

  const handleMouseLeaveContainer = () => {
    if (desktop) {
      clearTimeout(mouseLeaveTimeout);
      setSubMenuIndex(false);
    }
  };

  return (
    <ul
      onMouseLeave={handleMouseLeaveContainer}
      className={cx("SitePrimaryNav", {
        "SitePrimaryNav--small": props.items?.length > 5,
      })}
    >
      {props.items?.map((navMenuItem, index) => (
        <SiteNavItem
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          item={navMenuItem}
          key={index}
          idx={index}
        />
      ))}
    </ul>
  );
};

SitePrimaryNav.propTypes = {
  items: PropTypes.array,
};

export default SitePrimaryNav;
