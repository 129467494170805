import NavCountryOptions from "./NavCountryOptions";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import qs from "qs";
import { withModel } from "../../../../data-providers/model";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import withQueryParams from "../../../../hocs/withQueryParams";

const enhance = compose(
  withModel,
  withQueryParams,
  withProps((props) => {
    const countryDropdownList = props.model.prismicCountryListModel.slices.find(
      (slice) => slice.type === "CountryDropdownList"
    );

    // Get the metadata of the current page.
    const currentPageMetadata = props.model.prismicPageModel.metadata;

    const isSingleDocument = currentPageMetadata.isSingleDocument;

    // Get the current page Prismic UID
    let uid = currentPageMetadata.uid;

    if (isSingleDocument) {
      uid = uid || currentPageMetadata.type;
    }

    // Check if the uid is the homepage so that we dont need to add a return to uid query param
    const isHomepage = uid.includes("homepage");
    const search = props.queryParams;

    if (!isHomepage) {
      search.return_to_uid = uid;
    }

    const query = qs.stringify(search, { addQueryPrefix: true });

    const inputUrl = `/choose-your-country${query}`;

    return {
      changeCountry: {
        flag: countryDropdownList.primary.otherCountryImage,
        name: props.changeCountryLink,
        prefix: props.model.countryModel.prefix,
        iso: "",
        href: localiseUrlII(props.model.countryModel, inputUrl),
      },
      options: props.options.filter(
        (country) =>
          !!countryDropdownList.items.find(
            (item) =>
              item.iso === country.iso &&
              country.contentLocales.includes(item.localeCode.toLowerCase())
          )
      ),
    };
  })
);

export default enhance(NavCountryOptions);
