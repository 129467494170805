import cloneDeep from "lodash/cloneDeep";
import omit from "lodash/omit";
import formatName from "src/common/utils/format-adventurer-name";

const getPhototype = (adventurer, fields) => {
  const field = fields.find((field) =>
    field.primary.name.includes("phototype")
  );
  const value = field.items.find((input) => {
    if (input.type && input.type !== adventurer.gender) {
      return false;
    }
    return input.value === adventurer.phototype;
  });
  return value ? value.image : null;
};

export const trimNameValue = (formData, customisationKey) => ({
  [customisationKey]: formData[customisationKey].map((field) => ({
    ...field,
    ...(field.name ? { name: formatName(field.name) } : {}),
  })),
});

export const cleanAdventurers = (adventurers) => {
  return adventurers.map((adventurer) =>
    omit(adventurer, ["editIndex", "displayIndex", "image", "formFields"])
  );
};

export const getAdventurers = (advs = [], forms) => {
  let adventurers = cloneDeep(advs);

  for (let [idx, item] of forms.entries()) {
    if (adventurers[idx]) {
      adventurers[idx] = {
        editIndex: idx,
        displayIndex: item.displayIndex,
        image: getPhototype(adventurers[idx], item.form.body),
        formFields: item.form.body,
        ...adventurers[idx],
      };
    }
  }

  return adventurers;
};

export const remapErrors = (errors, customisationKey) => {
  let remapped = {};
  for (const [idx, error] of errors[customisationKey].entries()) {
    if (error) {
      const key = Object.keys(error)[0];
      remapped[`${customisationKey}[${idx}].${key}`] = {
        message: error[key],
        type: "invalid",
      };
    }
  }
  return remapped;
};
