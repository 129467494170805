import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import AvatarModalWrapper from "wonderbly-components/lib/AvatarModalWrapper";
import Modal from "../Modal";
import logger from "src/logger";
import "../AvatarCreationModal/AvatarCreationModal.scss";

const AvatarCreationModal = (props) => (
  <Modal
    default
    isOpen={props.isOpen}
    className={cx("AvatarCreationModal", props.className)}
  >
    <AvatarModalWrapper
      settings={{
        schemaEndpoint: props.schemaEndpoint,
        payloadBaseParams: props.payloadBaseParams,
        sku: props.sku,
        uiLabels: {
          uiGlobal: props.globalUiLabels.global,
          uiCtas: props.globalUiLabels.ctas,
          uiFieldErrors: props.globalUiLabels.errors,
          ...props.avatarUiLabels,
        },
      }}
      editData={props.editData}
      onHandleClose={props.onHandleClose}
      onHandleAllComplete={props.onHandleAllComplete}
      onHandleCriticalError={(error) => logger.error(error)}
      onHandleIndexTracking={props.onHandleIndexTracking}
      onHandleCharacterTracking={props.onHandleCharacterTracking}
    />
  </Modal>
);

AvatarCreationModal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  sku: PropTypes.string.isRequired,
  schemaEndpoint: PropTypes.string.isRequired,
  payloadBaseParams: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    product_slug: PropTypes.string.isRequired,
  }).isRequired,
  editData: PropTypes.object,
  globalUiLabels: PropTypes.object,
  avatarUiLabels: PropTypes.object,
  onHandleClose: PropTypes.func,
  onHandleAllComplete: PropTypes.func,
  onHandleIndexTracking: PropTypes.func,
  onHandleCharacterTracking: PropTypes.func,
};

export default AvatarCreationModal;
