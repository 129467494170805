let quickAdd = null;
let headerBasket = null;
let lodFetch = null;
let websiteCheckout = null;

const hasInitialised = (featureFlag) => {
  if (featureFlag === null) {
    throw new Error("Tried to get value for feature flag before initialising!");
  }
};

const setQuickAdd = (value) => {
  quickAdd = value === "true";
};

const setHeaderBasket = (value) => {
  headerBasket = value === "true";
};

const setLodFetch = (value) => {
  lodFetch = value === "true";
};

const setWebsiteCheckout = (value) => {
  websiteCheckout = value === "true";
};

const isQuickAddEnabled = () => {
  hasInitialised(quickAdd);
  return quickAdd;
};

const isHeaderBasketEnabled = () => {
  hasInitialised(headerBasket);
  return headerBasket;
};

const isLodFetchEnabled = () => {
  hasInitialised(lodFetch);
  return lodFetch;
};

const isWebsiteCheckoutEnabled = () => {
  hasInitialised(websiteCheckout);
  return websiteCheckout;
};

const init = (featureFlags) => {
  setQuickAdd(featureFlags.quickAdd);
  setHeaderBasket(featureFlags.headerBasket);
  setLodFetch(featureFlags.lodFetch);
  setWebsiteCheckout(featureFlags.websiteCheckout);
};
module.exports = {
  init,
  isQuickAddEnabled,
  isHeaderBasketEnabled,
  isLodFetchEnabled,
  isWebsiteCheckoutEnabled,
};
