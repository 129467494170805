import React from "react";
import PropTypes from "prop-types";
import PaymentIcon from "./PaymentIcon";

const PaymentIcons = (props) => (
  <div className="payment-icons">
    {props.paymentIcons.map((icon) => (
      <PaymentIcon key={icon.id} width={84} image={icon.image} />
    ))}
  </div>
);

PaymentIcons.propTypes = {
  paymentIcons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
};

export default PaymentIcons;
