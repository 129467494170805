import range from "lodash/range";

export const getRange = (rangeString) => {
  const startEnd = rangeString
    .split("-")
    .map((value, index) =>
      index === 0 ? parseInt(value, 10) : parseInt(value, 10) + 1
    );
  const rangeOptions = range(...startEnd).map((value) => ({
    value,
    label: value.toString(),
  }));
  return rangeOptions;
};
