import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import pick from "lodash/pick";
import { getMediaQueries } from "../../store/selectors/responsive";

const withMediaQueries = connect((state) => ({
  media: getMediaQueries(state),
}));

export const pickMediaQueries = (pickedQueries) =>
  compose(
    withMediaQueries,
    mapProps(({ media, ...props }) => ({
      ...props,
      ...pick(media, pickedQueries),
    }))
  );

export default withMediaQueries;
