import { withFormik } from "formik";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Formats from "./Formats";
import { getFormats } from "./helpers";
import withProductCustomisation from "../../../hocs/withProductCustomisation";

const enhance = compose(
  withProductCustomisation,
  withFormik({
    mapPropsToValues: (props) => ({
      format_identifier: props.productCustomisation.format_identifier,
    }),
  }),
  withProps((props) => ({
    formats: getFormats(props),
  }))
);

export default enhance(Formats);
