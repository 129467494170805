import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import InlineFormField from "wonderbly-components/lib/InlineFormField";
import Tooltip from "wonderbly-components/lib/Tooltip";

const Name = (props) => {
  const [value, setValue] = useState(props.value || "");

  const onHandleChange = useCallback(
    (e) => {
      const n = e.target.value;
      setValue(n);
      props.onHandleChange(e);
    },
    [setValue]
  );

  return (
    <div className="name">
      <Tooltip
        className="error-tooltip"
        visible={!!props.error}
        absolute={!props.tabletDown}
        type="alert"
      >
        {props.error}
      </Tooltip>
      <InlineFormField
        id={props.id}
        label={props.label}
        fieldType="input"
        value={value}
        onChange={onHandleChange}
      />
    </div>
  );
};

Name.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  tabletDown: PropTypes.bool,
};

export default Name;
