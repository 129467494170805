import React from "react";
import cx from "classnames";

import DefaultPromoBannerModel from "src/common/models/prismic/components/DefaultPromoBannerModel";
import TrustpilotWidget from "../TrustpilotWidget";
import Button from "wonderbly-components/lib/Button";
import Icon from "wonderbly-components/lib/Icon";

import "./PromoBanner.scss";
import RichText from "../Prismic/RichText";

class PromoBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { headerOffset: 0 };
  }

  getHeaderHeight = () => {
    const el = document.querySelector("header.header");
    if (!el) {
      return 0;
    }
    return el.getBoundingClientRect().bottom;
  };

  setHeaderHeight = () => {
    this.setState({
      headerOffset: this.getHeaderHeight(),
    });
  };

  componentDidMount() {
    setTimeout(this.setHeaderHeight, 500); // waiting for the onload logo animation (which is reducing the header height) to finish
    window.addEventListener("resize", this.setHeaderHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setHeaderHeight);
  }

  render() {
    const { props, state } = this;
    const isAddedProductBanner =
      props.promoBanner.slug === "product-added-banner";

    return (
      <div
        className={cx("promo-banner", {
          [`color-${props.promoBanner.textColour}`]:
            props.promoBanner.textColour,
          [props.promoBanner.slug]: props.promoBanner.slug,
        })}
        style={{
          backgroundColor: props.promoBanner.background,
          top: isAddedProductBanner ? state.headerOffset : "0",
        }}
      >
        <div className="container-guttered">
          <div className="promo-banner-inner">
            {isAddedProductBanner && <Icon icon="check" />}
            <RichText
              fields={[
                {
                  type: "paragraph",
                  text: props.promoBanner.text,
                },
              ]}
            />
            {props.promoBanner.supportingText && (
              <p className="promo-banner-supporting">
                {` ${props.promoBanner.supportingText}`}
              </p>
            )}
            {props.promoBanner.trustpilotWidgetVisible && (
              <TrustpilotWidget
                className="promo-banner-trustpilot-micro"
                templateId="micro"
                theme={props.promoBanner.trustpilotTheme}
                style={{
                  backgroundColor: props.promoBanner.trustpilotBackgroundColor,
                }}
                isComponent={false}
              />
            )}
            {props.promoBanner.ctaText && props.promoBanner.ctaLink && (
              <div className="promo-banner-cta">
                <Button
                  href={props.promoBanner.ctaLink}
                  className="promo-banner-button"
                >
                  {props.promoBanner.ctaText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PromoBanner.propTypes = {
  promoBanner: DefaultPromoBannerModel.propTypes.isRequired,
};

export default PromoBanner;
