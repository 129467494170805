import MultiInput from "./MultiInput";
import { withFormik } from "formik";
import withProps from "recompose/withProps";
import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import withState from "recompose/withState";
import get from "lodash/get";
import set from "lodash/set";
import withValidationData from "../../../hocs/withValidationData";
import formatAdventurerName from "src/common/utils/format-adventurer-name";

import {
  addItem,
  drawerKey,
  handleItemChange,
  mapCustomistaionToValues,
} from "./helpers";

/* This is a multi input drawer - it allows you to add individual fields in an input box
 * up to the max number.  It takes a drawerKey param which means that you can have two
 * drawers of the same type and the drawer key determines how the input values are stored
 * and passed to both muse and the backend. It is similar to the family members drawer but * functionaltiy is moved into helpers and tested and the drawerkey makes it more reusable*/
const enhance = compose(
  withValidationData,
  withProps((props) => ({ drawerKey: drawerKey(props) })),
  withState("additionalItem", "setAdditionalItem", ""),
  withFormik({
    mapPropsToValues: mapCustomistaionToValues,
    handleSubmit: async (values, { props, ...formikBag }) => {
      const drawer = props.drawer;

      if (props.additionalItem) {
        values = await addItem({ ...props, ...formikBag, values })();
      }

      if (values) {
        if (drawer.autocapitaliseInputs) {
          const reformatted = get(values, props.drawerKey).map(
            formatAdventurerName
          );
          set(values, props.drawerKey, reformatted);
        }
        props.onFormikSubmit(values);
      }
    },
  }),
  withHandlers({
    handleOnRemoveItem: (props) => handleItemChange(props),
    handleOnChangeItem: (props) => handleItemChange(props),
    handleOnAddItem: (props) => () => {
      if (props.additionalItem) {
        addItem(props)();
      }
    },
    handleOnChangeAdditionalItem: (props) => (e) => {
      props.setAdditionalItem(e.target.value);
      props.setErrors({});
    },
  })
);

export default enhance(MultiInput);
