import CustomSelection from "./CustomSelect";

import debounce from "lodash/debounce";

import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import withState from "recompose/withState";

import { withFormik } from "formik";

import withValidationData from "../../../hocs/withValidationData";

import {
  validateCreationForm,
  updateFieldValues,
  onFieldChange,
} from "../helpers";
import { OTHER_VALUE, onChangeSelect, onChangeInput } from "./helpers";

const debouncedValidation = debounce(
  (values, props, config) => validateCreationForm(values, props, config),
  500
);

// This component allows a user to select an option from a drop down.  If the value is OTHER_VALUE in the dropdown
// then show an input box where the user can input their custom entry.  Having two different input values means that
// it adds complexity to the component and we've had to add a temporary value to save what is entered in the input box
// so if the user types somehting, switches out and then back we still have it.  In addition having two inputs means we
// also need to force update the fields used by formik as you can't just rely on the input being updated.
//
// We need to send two values to the server - selection_state (non localised value of the dropdown) and the selection_value
// (the value is the localised label or the custom input by the user.)  In daycare for you only selection_value is used but
// other books the template might change dependings on what's in the value. (e.g. X loves Y books).
const enhance = compose(
  withValidationData,
  withProps({
    otherValue: OTHER_VALUE,
  }),
  withFormik({
    mapPropsToValues: ({
      selection_state: selectionState,
      selection_value: selectionValue,
      drawer,
    }) => ({
      selection_state: selectionState || drawer.items[0].value,
      selection_value: selectionValue || drawer.items[0].label,
    }),
    handleSubmit: (fields, { props }) => {
      const values = updateFieldValues(fields, props.country);
      props.onFormikSubmit(values);
    },
  }),
  withState(
    "selectionValue",
    "setSelectionValue",
    (props) =>
      props.productCustomisation.selection_value || props.drawer.items[0].label
  ),
  withState(
    "selectionState",
    "setSelectionState",
    (props) =>
      props.productCustomisation.selection_state || props.drawer.items[0].value
  ),
  // This holds what is in the other input box so we can switch between set values and custom ones.
  withState("tempValue", "setTempValue", (props) =>
    props.productCustomisation.selection_state === OTHER_VALUE
      ? props.selectionValue
      : ""
  ),
  withHandlers({
    onChange: (props) => onChangeSelect(props, props.drawer.items),
    onChangeInput: (props) => {
      const handleChange = onFieldChange(props);
      return (event) => {
        onChangeInput(props, event);

        handleChange(event);
        debouncedValidation({ selection_value: event.target.value }, props, {
          enableSetErrors: true,
        });
      };
    },
  })
);

export default enhance(CustomSelection);
