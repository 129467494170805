import React from "react";
import { useModel } from "../../../hooks";
import { SiteNavContextProvider } from "../SiteNavHeader/SiteNavToggle/SiteNavContext";
import SiteNavHeader from "../SiteNavHeader";
import SiteMobilePrimaryNav from "../SiteNavHeader/SiteMobilePrimaryNav";

const SiteNavigationWrapper = () => {
  const { prismicSiteNavigationModel } = useModel();

  if (!prismicSiteNavigationModel) {
    return null;
  }

  return (
    <SiteNavContextProvider>
      <SiteNavHeader navigation={prismicSiteNavigationModel} />
      <SiteMobilePrimaryNav mobileNavigation={prismicSiteNavigationModel} />
    </SiteNavContextProvider>
  );
};

export default SiteNavigationWrapper;
