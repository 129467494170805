import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import PaymentIcons from "./PaymentIcons";
import { withModel } from "../../data-providers/model";

const mapPaymentIcons = (paymentMethods, prismicIcons) =>
  paymentMethods.reduce((icons, method) => {
    const prismicIcon = prismicIcons.find(
      (icon) => icon.id === method.id && icon.type === "payment"
    );
    return prismicIcon ? icons.concat(prismicIcon) : icons;
  }, []);

const enhance = compose(
  withModel,
  mapProps(({ model, ...props }) => {
    const prismicIcons = model.prismicGlobalContentModel.icons.globalIcons;
    const paymentMethods = model.eagleModel.paymentMethods;
    const paymentIcons = mapPaymentIcons(paymentMethods, prismicIcons);
    return {
      paymentIcons: props.exclusionList
        ? paymentIcons.filter((icon) => !props.exclusionList.includes(icon.id))
        : paymentIcons,
    };
  })
);

export default enhance(PaymentIcons);
