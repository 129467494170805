import { geoip } from "src/client/js/facades/website";

import {
  fetchGeoLocationPending,
  fetchGeoLocationSuccess,
  fetchGeoLocationFailure,
} from "../../actions/geoLocation";

export const fetchGeoLocation = () => (dispatch) => {
  dispatch(fetchGeoLocationPending());

  return geoip()
    .then((response) => dispatch(fetchGeoLocationSuccess(response)))
    .catch((error) => dispatch(fetchGeoLocationFailure(error)));
};
