import once from "lodash/once";
import { track } from "../facade";

const dispatch = (action, countryIn, countryTo) =>
  track(action, {
    category: `Country Prompt`,
    label: `${countryIn.iso}:${countryTo.iso}`,
    value: null,
  });

export const show = once((countryIn, countryTo) =>
  dispatch("Show", countryIn, countryTo)
);
export const hide = once((countryIn, countryTo) =>
  dispatch("Hide", countryIn, countryTo)
);
export const navigate = once((countryIn, countryTo) =>
  dispatch("Navigate", countryIn, countryTo)
);
