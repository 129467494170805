import reduce from "lodash/reduce";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import isPlainObject from "lodash/isPlainObject";
import isArray from "lodash/isArray";
import isNil from "lodash/isNil";

import { request } from "src/common/rest/index";
import appendQueryParams from "src/common/utils/append-query-params";

let baseUrl = "";
export const setBaseUrl = (value) => {
  baseUrl = value;
};

const handleNullArrayItems = (object) => {
  return mapValues(object, (value) => {
    if (isPlainObject(value)) {
      return handleNullArrayItems(value);
    }

    if (isArray(value)) {
      const existingItem = value.find((i) => !isNil(i)) || {};
      // Creating new array to avoid mutating original values
      let array = [];
      for (let i = 0; i < value.length; i++) {
        const item = (array[i] = value[i]);
        if (isNil(item)) {
          array[i] = existingItem;
        }
      }

      return array;
    }

    return value;
  });
};

export const validate = (
  { item: { customisations, ...item } },
  locale = "en-US"
) => {
  const url = appendQueryParams(
    { user_locale: locale },
    `${baseUrl}/website/v2/validation`
  );

  return request.post(
    url,
    JSON.stringify({
      item: {
        ...item,
        customisations: handleNullArrayItems(customisations),
      },
    }),
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const formErrors = (values, errors) => {
  return reduce(
    errors,
    (acc, fieldErrors, key) => {
      for (const e of fieldErrors) {
        const { error } = e;
        const name = (error.path && error.path.join(".")) || key;
        const path = error.path || [key];

        if (has(values, path)) {
          let errorObj = {
            type: typeof error === "string" ? error : error.type,
            path,
          };
          if (error.context) {
            errorObj = { ...errorObj, value: error.context.value };
          }
          acc.push({
            name,
            error: errorObj,
          });
        }
      }
      return acc;
    },
    []
  );
};

export const isValid = (data, values) => {
  const hasErrors = data.status === "invalid";
  const errors = formErrors(values, data.errors);

  if (!hasErrors || isEmpty(errors)) {
    return true;
  }

  throw errors;
};
