import { updateResponsiveQueryValues } from "../actions/responsive";

export const defaultQueries = {
  mobile: "screen and (max-width: 479px)",
  fablet: "screen and (min-width: 480px) and (max-width: 777px)",
  fabletDown: "screen and (max-width: 777px)",
  fabletUp: "screen and (min-width: 480px)",
  tablet: "screen and (min-width: 778px) and (max-width: 1039px)",
  tabletDown: "screen and (max-width: 1039px)",
  tabletUp: "screen and (min-width: 778px)",
  desktop: "screen and (min-width: 1040px) and (max-width: 1199px)",
  desktopDown: "screen and (max-width: 1199px)",
  desktopUp: "screen and (min-width: 1040px)",
  wide: "screen and (min-width: 1200px)",
};

export const updateResponsiveQueries = (window) => (dispatch) => {
  const values = Object.keys(defaultQueries).reduce(
    (prev, query) => ({
      ...prev,
      [query]: window.matchMedia(defaultQueries[query]).matches,
    }),
    {}
  );
  dispatch(updateResponsiveQueryValues(values));
};
