import React from "react";
import PropTypes from "prop-types";
import Gender from "../Gender";
import Phototype from "../Phototype";

import "./Hero.scss";

const Hero = (props) => (
  <div className="adventurer">
    <Gender
      onHandleChange={props.onHandleChange}
      gender={props.gender}
      error={props.errors && props.errors.gender}
      tabletDown={props.tabletDown}
    />
    {props.hasPhototypes && (
      <Phototype
        title={props.title}
        gender={props.gender}
        phototype={props.phototype}
        availablePhototypes={props.availablePhototypes}
        onHandleChange={props.onHandleChange}
        error={props.errors && props.errors.phototype}
        tabletDown={props.tabletDown}
        setFieldValue={props.setFieldValue}
        productId={props.productId}
        showTooltip={props.phototypeVisible}
      />
    )}
  </div>
);

Hero.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  hasPhototypes: PropTypes.bool.isRequired,
  phototypeVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  availablePhototypes: PropTypes.object.isRequired,
  gender: PropTypes.string,
  phototype: PropTypes.string,
  errors: PropTypes.shape({
    base: PropTypes.string,
    gender: PropTypes.string,
    phototype: PropTypes.string,
  }),
  tabletDown: PropTypes.bool.isRequired,
};

export default Hero;
