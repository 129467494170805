const PropTypes = require("prop-types");

class ImageModel {
  constructor() {
    this.dimensions = null;
    this.alt = null;
    this.copyright = null;
    this.url = null;
  }
  deserialise(data) {
    this.dimensions = {
      width: data.dimensions.width,
      height: data.dimensions.height,
    };
    this.alt = data.alt;
    this.copyright = data.copyright;
    this.url = data.url;
    return this;
  }
}

ImageModel.propTypes = PropTypes.shape({
  dimensions: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  copyright: PropTypes.string,
});

module.exports = ImageModel;
