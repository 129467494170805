// Get Existing User Details

export const GET_CHECKOUT_DETAILS_PENDING = "GET_CHECKOUT_DETAILS_PENDING";
export const GET_CHECKOUT_DETAILS_SUCCESS = "GET_CHECKOUT_DETAILS_SUCCESS";
export const GET_CHECKOUT_DETAILS_FAILURE = "GET_CHECKOUT_DETAILS_FAILURE";

export const getCheckoutDetailsPending = () => ({
  type: GET_CHECKOUT_DETAILS_PENDING,
});

export const getCheckoutDetailsSuccess = (response) => ({
  type: GET_CHECKOUT_DETAILS_SUCCESS,
  payload: response.body,
});

export const getCheckoutDetailsFailure = (response) => ({
  type: GET_CHECKOUT_DETAILS_FAILURE,
  response,
});
