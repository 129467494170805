import React from "react";
import PropTypes from "prop-types";
import ButtonRadio from "wonderbly-components/lib/ButtonRadio";
import Panel from "wonderbly-components/lib/Panel";
import { Row, Col } from "src/client/js/view/components/Grid";
import Tooltip from "wonderbly-components/lib/Tooltip";
import cx from "classnames";
import { prismicImage } from "src/common/utils/prismic-image";

import "./Phototype.scss";

const Phototype = (props) => (
  <div
    className={cx("adventurer-phototypes", {
      "adventurer-phototypes--visible": props.isPhototypeVisible,
      [`adventurer-phototypes--${props.gender}`]: !!props.gender,
    })}
  >
    <Tooltip visible={props.showTooltip} huge noPadding>
      <Tooltip
        className="phototype-error-tooltip"
        visible={props.error}
        absolute={!props.tabletDown}
        type="alert"
      >
        {props.error}
      </Tooltip>
      <Panel highlight="grass-ceiling">
        <div className="adventurer-phototypes-title text-milli">
          {props.title}
        </div>
        <Row className="cols-spaced-small">
          {props.phototypes.map((type, index) => (
            <Col
              key={`${type.gender}-${type.phototype.toLowerCase()}`}
              className={cx(
                `col-1-3 adventurer-phototype-column adventurer-phototype-column--${type.gender.toLowerCase()}`
              )}
            >
              <ButtonRadio
                id={`${type.gender}-${type.phototype.toLowerCase()}`}
                value={type.phototype.toLowerCase()}
                name="phototype"
                className="adventurer-phototype-input"
                onChange={props.onHandleChange}
                checked={
                  props.gender === type.gender.toLowerCase() &&
                  props.phototype === type.phototype.toLowerCase()
                }
              >
                <img
                  src={prismicImage(type.image)}
                  alt={`${type.gender} ${type.phototype}`}
                />
              </ButtonRadio>
            </Col>
          ))}
        </Row>
      </Panel>
    </Tooltip>
  </div>
);

Phototype.propTypes = {
  title: PropTypes.string.isRequired,
  gender: PropTypes.string,
  phototype: PropTypes.string,
  onHandleChange: PropTypes.func.isRequired,
  isPhototypeVisible: PropTypes.bool.isRequired,
  showTooltip: PropTypes.bool.isRequired,
  phototypes: PropTypes.arrayOf(
    PropTypes.shape({
      phototype: PropTypes.string.isRequired,
      gender: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        dimensions: PropTypes.shape({
          width: PropTypes.number.isRequired,
        }),
      }),
    })
  ).isRequired,
  error: PropTypes.string,
  tabletDown: PropTypes.bool.isRequired,
};

export default Phototype;
