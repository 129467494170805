import compose from "recompose/compose";
import { withProps } from "recompose";
import { withModel } from "../../data-providers/model";
import Page from "./CheckoutPage";

const enhance = compose(
  withModel,
  withProps((props) => ({
    isCheckout: props.model.route.isCheckout,
  }))
);

export default enhance(Page);
