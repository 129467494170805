import React from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import cookie from "js-cookie";
import isEmpty from "lodash/isEmpty";
import { loadableReady } from "@loadable/component";

import endpoints from "src/common/config/endpoints";
import bing from "src/common/config/bing";
import featureFlags from "src/common/config/feature-flags";

import { guestTokenCookie } from "../client/js/store/thunks/cart";
import appFactory from "../client/js/view/app-factory";
import ErrorReporter from "../client/js/view/error-handlers/ErrorReporter";
import * as eagleCartFacade from "../client/js/facades/eagle/cart";
import * as subscriptionsFacade from "../client/js/facades/eagle/subscriptions";
import * as logFacade from "../client/js/facades/eagle/log";
import * as eagleValidation from "../client/js/facades/eagle/validation";
import * as eagleUser from "../client/js/facades/eagle/user";
import * as eagleCheckoutFacade from "../common/facades/eagle/checkout";
import eagleFacade from "../common/facades/eagle";

import "../client/css/index.scss";

const topology = window.model.topology;
const countryModel = window.model.countryModel;
const cookieOptions = { expires: 7, path: "/" };
cookie.set("country_code", countryModel.iso, cookieOptions);
cookie.set("currency", countryModel.currency, cookieOptions);
cookie.set("locale", countryModel.locale, cookieOptions);

eagleFacade.setBaseUrl(topology.eagle.api);
eagleUser.setBaseUrl(topology.eagle.api);
eagleCartFacade.setBaseUrl(topology.eagle.api);
eagleCheckoutFacade.setBaseUrl(topology.eagle.api);
subscriptionsFacade.setBaseUrl(topology.eagle.api);
logFacade.setBaseUrl(topology.eagle.api);
eagleValidation.setBaseUrl(topology.eagle.api);
endpoints.setMU2Endpoint(topology.preview.mu2);
endpoints.setLMNEndpoint(topology.preview.lmn);
bing.setMapEndpoint(window.model.bing.endpoint);
bing.setAccessKey(window.model.bing.key);

featureFlags.init(window.model.featureFlags);

/*
we have seen some cases of the guest token
being empty. this breaks adding to cart
so it's better to just remove it
 */
if (isEmpty(guestTokenCookie.get())) {
  guestTokenCookie.remove();
}

loadableReady(async () => {
  hydrate(
    <ErrorReporter>
      <BrowserRouter>{appFactory(window.model)}</BrowserRouter>
    </ErrorReporter>,
    document.getElementById("app")
  );
});
