import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";

import { request } from "src/common/rest/index";
import { getCookies } from "./helpers/cookies";

let baseUrl = "";
export const setBaseUrl = (value) => {
  baseUrl = value;
};

export const newsletter = (data, country) => {
  const { locale } = getCookies();

  const marketplace = country.prefix || "us";

  let dataToSend = {
    signup_locale: locale,
    signup_marketplace: marketplace,
    ...omitBy(data, (value) => isNil(value) || isEmpty(value)),
  };

  return request.post(
    `${baseUrl}/website/v2/subscriptions/sign_up_to_newsletter`,
    dataToSend,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};
