import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withModel } from "../data-providers/model";
import isEmpty from "lodash/isEmpty";
import logger from "src/logger";

const compareLocale = (a) => (b) => a.toLowerCase() === b.toLowerCase();
const filterCountries = (country, prismicCountryList) =>
  prismicCountryList.reduce((result, prismicCountry, index) => {
    if (result) {
      return result;
    }
    if (
      prismicCountry.iso === country.iso &&
      country.contentLocales.find(compareLocale(prismicCountry.localeCode))
    ) {
      return {
        _sortIndex: index,
        ...prismicCountry,
      };
    }
    return null;
  }, null);

export const getCountryList = (props) => {
  return props.model.countries
    .reduce((countries, country) => {
      try {
        const prismicCountry = filterCountries(
          country,
          props.model.prismicCountryListModel.countries
        );
        if (!isEmpty(prismicCountry)) {
          countries.push({
            ...country,
            ...prismicCountry,
          });
        }
      } catch (error) {
        logger.error(error);
      }
      return countries;
    }, [])
    .sort((a, b) => a._sortIndex - b._sortIndex);
};

const withCountries = compose(
  withModel,
  withProps((props) => ({
    countries: getCountryList(props),
  }))
);

export default withCountries;
