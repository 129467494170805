import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "src/client/js/view/components/Grid";
import FormatOption from "./FormatOption";

import "./Formats.scss";

const Formats = (props) => (
  <Row className="formats" colsToRowsOn="mobile">
    {props.formats.map((format) => (
      <Col key={format.formatId}>
        <FormatOption
          onFormikSubmit={props.onFormikSubmit}
          prismicFormat={format}
          formatIdentifier={props.values.format_identifier}
          variant={format.variant}
        />
      </Col>
    ))}
  </Row>
);

Formats.propTypes = {
  formats: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onFormikSubmit: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  productCustomisation: PropTypes.object.isRequired,
};

export default Formats;
