import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import pick from "lodash/pick";
import omit from "lodash/omit";
import has from "lodash/has";
import set from "lodash/set";
import isString from "lodash/isString";
import isObjectLike from "lodash/isObjectLike";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";

import { validate, formErrors } from "src/client/js/facades/eagle/validation";
import formatName from "src/common/utils/format-adventurer-name";
import { replaceTextWithValues } from "src/common/utils/text-replace";

import Adventurer from "./Adventurer";
import Avatars from "./Avatars";
import BearFam from "./BearFam";
import Birthday from "./Birthday";
import CardSelect from "./CardSelect";
import Characters from "./Characters";
import Companion from "./Companion";
import CustomExtraAdventurer from "./CustomExtraAdventurer";
import CustomSelect from "./CustomSelect";
import FamilyMembers from "./FamilyMembers";
import Formats from "./Formats";
import Input from "./Input";
import Inputs from "./Inputs";
import Inscription from "./Inscription";
import Interest from "./Interest";
import MultiAdventurer from "./MultiAdventurer";
import MultiAdventurerII from "./MultiAdventurerII";
import MultiBirthday from "./MultiBirthday";
import MultiInput from "./MultiInput";
import MultiLevelForm from "./MultiLevelForm";
import Qualities from "./Qualities";

import logger from "src/logger";

const nameFields = ["name", "surname"];
const fieldsWithPossibleWhiteSpace = ["inscription", "selection_value"];

/*
  This config describes what customisation keys are required for each drawer and whether the drawer is optional. It
  is primary used for display/ux purposes such as displaying something to the user that lets the user understand when
  the drawer has been completed and what drawer should be completed next. For example, for MGT, the companion drawer
  is optional, thus when all other drawers are completed the canvas button will change to an "add to cart" state
  and allow the user to continue the journey. However for DLY the companion drawer is not optional, and when all
  other drawers are completed the canvas button will be in a "continue" state and will force the user to
  add a companion to their book

  NOTE: This is very old and should actually be moved into Prismic, our CMS.
 */
const config = {
  inscription: {
    get component() {
      return Inscription;
    },
    fields: {
      required: ["inscription"],
    },
  },
  adventurer: {
    get component() {
      return Adventurer;
    },
    fields: {
      required: ["name", "gender"], // By default the name and gender are set to required for all books
      overrides: {
        // Below we override the exceptions for other books
        lmn: [
          {
            locale: "*",
            required: ["name", "gender", "locale"],
          },
          {
            locale: "en-GB",
            required: ["name", "gender", "locale", "phototype"],
          },
          {
            locale: "en-US",
            required: ["name", "gender", "locale", "phototype"],
          },
        ],
        "my-golden-ticket": [
          {
            locale: "*",
            required: ["name", "gender", "surname"],
          },
        ],
        "little-monsters": [
          {
            locale: "*",
            required: ["name"],
          },
        ],
        "kingdom-of-you": [
          {
            locale: "*",
            required: ["name", "gender", "phototype"],
          },
        ],
        "power-within": [
          {
            locale: "*",
            required: ["name", "gender", "phototype"],
          },
        ],
        "birthday-thief": [
          {
            locale: "*",
            required: ["name", "gender", "phototype"],
          },
        ],
        snowflake: [
          {
            locale: "*",
            required: ["locale"],
          },
        ],
        "what-kind-of-name": [
          {
            locale: "*",
            required: ["name"],
          },
        ],
        "happy-birthday-to-you": [
          {
            locale: "*",
            required: ["name", "gender"],
          },
        ],
      },
    },
  },
  book_formats: {
    get component() {
      return Formats;
    },
    fields: {
      required: ["format_identifier"],
    },
  },
  interest: {
    get component() {
      return Interest;
    },
    fieldsRequired: (drawer) => {
      // Temp for abc cover style as will need to update in product
      return { required: [drawer.primary.customisationKey] };
    },
    fields: {
      required: [],
      overrides: {
        "kingdom-of-you": [
          {
            locale: "*",
            type: "interest",
            required: ["interest"],
          },
          {
            locale: "*",
            type: "food",
            required: ["food"],
          },
        ],
        "power-within": [
          {
            locale: "*",
            type: "superhero",
            required: ["superhero"],
          },
        ],
        "what-kind-of-name": [
          {
            locale: "*",
            required: ["cover_style"], // manually set the required interest
          },
        ],
        "happy-birthday-to-you": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "little-monsters": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "i-love-you-this-much": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "you-love-daddy-this-much": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "you-love-mummy-this-much": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "you-love-them-this-much": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "y-is-for-you": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "who-can-you-can": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "best-dad-ever": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "best-mum-ever": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "best-ever": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "ten-little-yous": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "your-colors": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "new-sibling-for-you": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
        "remarkable-rebels": [
          {
            locale: "*",
            required: ["cover_style"],
          },
        ],
      },
    },
  },
  companion: {
    get component() {
      return Companion;
    },
    isOptional: true,
    fields: {
      required: ["gifter_name", "gifter_gender"],
      overrides: {
        "you-are-extraordinary": [
          {
            locale: "*",
            required: ["gifter_name"],
          },
        ],
        "you-love-daddy-this-much": [
          {
            locale: "*",
            required: ["gifter_name"],
            isOptional: false,
          },
        ],
        "you-love-mummy-this-much": [
          {
            locale: "*",
            required: ["gifter_name"],
            isOptional: false,
          },
        ],
        "you-love-them-this-much": [
          {
            locale: "*",
            required: ["gifter_name"],
            isOptional: false,
          },
        ],
      },
    },
  },
  birthday: {
    get component() {
      return Birthday;
    },
    fields: {
      required: ["day", "month", "year"],
    },
  },
  bears: {
    get component() {
      return BearFam;
    },
    fields: {
      required: ["bears"],
    },
  },
  family_members: {
    get component() {
      return FamilyMembers;
    },
    fields: {
      required: ["names"],
    },
  },
  qualities: {
    get component() {
      return Qualities;
    },
    fieldsRequired: (drawer) => {
      return { required: [drawer.primary.customisationKey] };
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
  },
  multi_adventurer: {
    get component() {
      return MultiAdventurer;
    },
    get trackingProps() {
      return {};
    },
    fields: {
      required: ["characters[].name", "characters[].gender"],
      minArrayLength: 2,
      overrides: {
        "thats-my-cake": [
          {
            locale: "*",
            required: [
              "characters[].name",
              "characters[].gender",
              "characters[].phototype",
            ],
          },
        ],
      },
    },
  },
  multi_adventurerii: {
    get component() {
      return MultiAdventurerII;
    },
  },
  multi_birthday: {
    get component() {
      return MultiBirthday;
    },
    isOptional: true,
    fields: {
      required: ["characters[].day", "characters[].month", "characters[].year"],
    },
  },
  custom_select: {
    get component() {
      return CustomSelect;
    },
    fields: {
      required: ["selection_state", "selection_value"],
    },
  },
  custom_extra_adventurer: {
    get component() {
      return CustomExtraAdventurer;
    },
    fieldsRequired: (drawer) => {
      return { required: [drawer.primary.drawerKey] };
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
  },
  card_select: {
    get component() {
      return CardSelect;
    },
    fieldsRequired: (drawer) => {
      return { required: [drawer.primary.customisationKey] };
    },
  },
  multi_input: {
    get component() {
      return MultiInput;
    },
    fieldsRequired: (drawer) => {
      return { required: [drawer.primary.drawerKey] };
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
  },
  input: {
    get component() {
      return Input;
    },
    fields: {
      required: [],
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
    fieldsRequired: (drawer) => {
      return { required: [drawer.primary.drawerKey] };
    },
  },
  inputs: {
    get component() {
      return Inputs;
    },
    fieldsRequired: (drawer) => {
      return {
        required: drawer.items
          .filter((item) => item.required)
          .map((item) => item.drawerKey),
      };
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
  },
  characters: {
    get component() {
      return Characters;
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
    fieldsRequired: (drawer) => ({
      required: [drawer.primary.customisationKey],
    }),
  },
  multi_level_form: {
    get component() {
      return MultiLevelForm;
    },
    fieldIsOptional: (drawer) => {
      return !drawer.primary.required;
    },
    fieldsRequired: (drawer) => {
      return {
        required: drawer.items.reduce((acc, { form }) => {
          form.body.forEach((field) => {
            if (field.sliceType !== "submit") {
              acc.push(field.name);
            }
          });
          return acc;
        }, []),
      };
    },
  },

  // avatars3 refers to prismic slice, do not change
  avatars3: {
    get component() {
      return Avatars;
    },
    fieldIsOptional: (drawer) => drawer.primary.required,
    fieldsRequired: (drawer) => ({
      required: [drawer.primary.customisationKey],
    }),
  },
};

export const getDrawer = (key) => cloneDeep(config[key] || {});

export const getComponent = (key) => getDrawer(key).component || null;
export const getTrackingProps = (key) => getDrawer(key).trackingProps || {};
export const getFields = (key) => getDrawer(key).fields || { required: [] };
export const getRequiredFieldsFromDrawer = (key, drawer) => {
  return getDrawer(key)?.fieldsRequired?.(drawer) || { required: [] };
};
export const getAllFieldsRequired = (key, drawer) => {
  let fields = getFields(key);
  const extraRequiredFields = getRequiredFieldsFromDrawer(key, drawer);
  fields.required.push(...extraRequiredFields.required);
  return fields;
};

/**
 * getOverride tries to find settings that overrides a drawers's original settings,
 * these setting/overrides can be found in the config object
 * within src/client/js/view/components/CreationForm/helpers.js
 *
 * @param {string} key a string that maps to a drawer in the config object
 * @param {Object} [options] an object used to find a specific config override
 * @param {string} [options.productId] used to reference a specific product, e.g. i-love-you
 * @param {string} [options.locale] a string used to find config for different countries
 * @param {string} [options.type] the type of drawer being used. Specifically used for KOY i.e. interest or food
 * @returns {Object}
 */
export const getOverride = (key, { productId, locale, type } = {}) => {
  if (!key || !productId) {
    return {};
  }

  const findOverrideByLocale = (overrides, locale) => {
    const overridesByLocale = overrides.find((o) => o.locale === locale) || {};

    if (isEmpty(overridesByLocale)) {
      return overrides.find((o) => o.locale === "*") || {};
    }

    return overridesByLocale;
  };

  const fields = getFields(key);
  const overrides = fields.overrides || [];
  const overridesByProduct = overrides[productId] || [];

  if (overridesByProduct.some((po) => !!po.type)) {
    if (!type) {
      logger.warn(
        `${productId}'s ${key} drawer expected to have a type to filter but "${type}" was passed instead.`
      );
      // If a falsey value for type was passed in then we want to filter the overridesByProduct array
      // to an array that doesnt have a type key or has * as it's type
      const overridesByDefaultType = overridesByProduct.filter(
        (po) => !po.type || po.type === "*"
      );
      return findOverrideByLocale(overridesByDefaultType, locale);
    }
    const overridesByType = overridesByProduct.filter(
      (override) => override.type === type
    );
    return findOverrideByLocale(overridesByType, locale);
  }

  return findOverrideByLocale(overridesByProduct, locale);
};
/**
 * isFieldOptional check if the field with the same key that was passed in was
 * optional or has matching overrides which change the default optional value
 * @param {string} key the drawer name or customisation key
 * @param {Object} [options] an object used to find a specific config override
 * @param {string} [options.productId] used to reference a specific product, e.g. i-love-you
 * @param {string} [options.locale] a string used to find config for different countries
 * @param {string} [options.type] the type of drawer being used. Specifically used for KOY i.e. interest or food
 * @returns {boolean}
 */
export const isFieldOptional = (key, options, drawer = undefined) => {
  const field = getDrawer(key);
  const defaultOptionalValue = field && !!field.isOptional;
  const override = getOverride(key, options);

  if (drawer && field.fieldIsOptional) {
    return field.fieldIsOptional(drawer);
  }

  return override.isOptional !== undefined
    ? override.isOptional
    : defaultOptionalValue;
};

export const buildErrors = (errors, raw) => {
  if (isEmpty(errors)) {
    return false;
  }

  return reduce(
    errors,
    (result, value, key) => {
      const message = raw ? value : value[0].message;

      switch (key) {
        case "gender":
        case "phototype":
          result.adventurer = {
            ...result.adventurer,
            [key]: message,
          };
          break;
        case "bears":
          result.bears = value;
          break;
        default:
          result[key] = message;
      }

      return result;
    },
    {}
  );
};
export const clearErrors = (props, name) => {
  if (!name) {
    props.setErrors({});
  }
  if (props.errors && (has(props.errors, name) || props.errors.base)) {
    props.setErrors(omit(props.errors, [name, "base"]));
  }
};

export const shouldFormatName = (key, value, country, customisation) => {
  /**
   * Japanese character sets often convert two consecutive letters to a single symbol.
   * As soon as one character is entered we format it, uppercase it and set it as the field value.
   * This results means that the user sees one capital latin character and a second incorrect character.
   * For japan we don't format the name so that we get the correct letters input. We may need to decided to
   * do something different for different books in the future for japan - maybe detect correct character set.
   * This should be part of the muse validation work.
   */
  const isNotJA = country.locale !== "ja" && customisation.locale !== "ja";
  return nameFields.includes(key) && !!value && isNotJA;
};

/**
 * updateFieldValues take the current form values and clean up/add defaults if needed
 * @param {Object} values
 * @param {CountryModel} country
 * @returns {Object}
 */
export const updateFieldValues = (values, country) => {
  return reduce(
    values,
    (result, value, key) => {
      // If the current form value is a phototype and it doesn't have a value the just default it to type-iii
      if (key === "phototype" && !value) {
        value = "type-iii";
      }

      if (shouldFormatName(key, value, country, values)) {
        value = formatName(value);
      }

      // Remove any white space from fields that match and that has a value
      if (fieldsWithPossibleWhiteSpace.includes(key) && value) {
        value = value.trim();
      }

      result[key] = value;
      return result;
    },
    {}
  );
};

export const validateCreationForm = (fields, props, config) => {
  config = {
    enableSetErrors: false,
    raw: false,
    ...config,
  };
  props.setSubmitting(true);
  if (!props.productId) {
    props.setSubmitting(false);
    const errors = {
      base: "ERROR: Missing Product ID",
    };
    if (config.enableSetErrors) {
      props.setErrors(errors);
      return;
    }
    // Formik 2.x requires the validate method to resolve to update the form state with errors
    return Promise.resolve(errors);
  }

  if (!props.country) {
    props.setSubmitting(false);
    const errors = {
      base: "ERROR: Missing country parameter",
    };
    if (config.enableSetErrors) {
      props.setErrors(errors);
      return;
    }
    // Formik 2.x requires the validate method to resolve to update the form state with errors
    return Promise.resolve(errors);
  }

  const values = updateFieldValues(
    {
      ...props.productCustomisation,
      ...fields,
    },
    props.country
  );

  const customisations = reduce(
    values,
    (result, value, key) => {
      switch (true) {
        case isString(value):
          result[key] = value;
          break;
        case isObjectLike(value):
          result[key] = JSON.stringify(value);
          break;
        case props.productId === "lmn" && key === "phototype" && !value:
          result[key] = "type-iii";
          break;
        case isNil(value):
          result[key] = "";
          break;
      }

      return result;
    },
    {}
  );

  if (
    customisations.name !== props.productCustomisation.name &&
    customisations.character_selection
  ) {
    props.updateProductCustomisation(
      omit(props.productCustomisation, ["character_selection"])
    );
    delete customisations.character_selection;
  }

  const productData = {
    item: {
      productId: props.productId,
      customisations,
    },
  };

  return validate(productData, props.country.locale).then((res) => {
    props.setSubmitting(false);

    const validationErrors = res.body.data.errors;
    const keys = Object.keys(fields);

    let errors;

    if (res.body.data.status === "invalid") {
      // Check if errors are muse or eagle. In the long term We want to switch to using just the formErrors method
      // which can cope with eagle and muse errors but doesn't have the extra logic around adventurer or 'bears'
      // ideally we migrate to a standard way of doing errors using dynamic keys in the drawers rather than having
      // product specific logic in the code.  For now we just want to do exisiting books the 'old' way
      if (res.body.data.validatedInEagle) {
        errors = buildErrors(pick(validationErrors, keys), config.raw);
      } else {
        const selectedTransformedErrors = formErrors(values, validationErrors);
        errors = props
          .joiErrorMessages(selectedTransformedErrors, props.drawer?.sliceType)
          .reduce((acc, error) => {
            // We cant be 100% sure that the drawer will have spread the primary object into props.drawer or not
            // so just incase we destructure props.drawer and pick out the key primary (set it as an empty object if nil)
            // and spread the rest of the keys into a variable called drawer.
            const { primary = {}, ...drawer } = props.drawer || {};
            const values = {
              ...primary,
              ...drawer,
              ...customisations,
            };

            const message = replaceTextWithValues(error.message, values);

            set(acc, error.name, message);

            // Similar functionality to what is in the buildErrors function
            if (error.name === "gender") {
              set(acc, "adventurer.gender", message);
            } else if (error.name === "phototype") {
              set(acc, "adventurer.phototype", message);
            }

            return acc;
          }, {});

        // if there are no errors we set errors as false not the empty object.
        // this logic is needed when we resolve the promise with errors
        // Be careful as this validateCreationForm method has multiple uses depending on the config being passed in
        // changing the contract isn't tested and any updates need checking across books.
        errors = isEmpty(errors) ? false : errors;
      }
    }

    if (errors) {
      if (config.enableSetErrors) {
        props.setErrors(errors);
        return true;
      }
      // Formik 2.x requires the validate method to resolve to update the form state with errors
      return Promise.resolve(errors);
    } else if (!isEmpty(props.errors)) {
      clearErrors(props);
      if (config.enableSetErrors) {
        return true;
      }
    }
  });
};

export const onFieldChange = (props) => (event) => {
  let name = event.target.name;

  if (name === "locale") {
    props.fetchPrismicProduct(props.productId, event.target.value);
  }

  if (name === "gender" || name === "phototype") {
    name = `adventurer[${name}]`;
  }

  clearErrors(props, name);

  props.handleChange(event);
};

/**
 * When used with the HOC withHandlers it might register to multiple events which can
 * lose scope, cause race conditions, and other bad things.
 * @returns {function(props): function(event): void}
 */
export const createOnFieldChangeHandler = () => {
  return (props) => (event) => {
    return onFieldChange(props)(event);
  };
};

/**
 * debounced validation for drawers that can happen onChange async
 */
export const debouncedValidation = debounce(
  (values, props, config) => validateCreationForm(values, props, config),
  500
);
