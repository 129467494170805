import compose from "recompose/compose";
import when from "recompose/branch";
import withProps from "recompose/withProps";
import withState from "recompose/withState";
import withProduct from "./withProduct";
import withCountry from "./withCountry";

import { useErrorMessages } from "../hooks";

const withValidationData = compose(
  when(
    (props) => !props.product || !props.productId,
    withProduct,
    (c) => c
  ),
  when(
    (props) => !props.country,
    withCountry,
    (c) => c
  ),
  withState("isSubmitting", "setSubmitting", false),
  withProps(() => {
    const { joiErrorMessages, getErrorMessage } = useErrorMessages();
    return {
      getErrorMessage,
      joiErrorMessages,
    };
  })
);

export default withValidationData;
