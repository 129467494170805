import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import PromoFeature from "wonderbly-components/lib/PromoFeature";
import Arrow from "wonderbly-components/lib/Arrow";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useModel } from "src/client/js/view/hooks";
import { replaceTextWithValues } from "src/common/utils/text-replace";
import { trackMarketingCardItem, trackScrollToImage } from "../tracking";

const SiteNavPromos = (props) => {
  const { countryModel } = useModel();
  const imageRef = useRef(null);

  const observerFunction = ([entry]) => {
    if (entry.isIntersecting) {
      trackScrollToImage(props.parentItemLabel, entry.target.id);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerFunction);

    if (imageRef && imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      // Remove the observer as soon as
      // the component is unmounted
      if (imageRef && imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [imageRef]);

  return (
    <ul
      className={cx("SiteNavLinksList", "SiteNavLinksList__promo-column", {
        [props.className]: props.className,
      })}
    >
      {props.items.map((item, idx) => (
        <li
          key={idx}
          className="SiteNavLinksList__link"
          id={item.navLinkLabel}
          ref={imageRef}
          onClick={() =>
            trackMarketingCardItem(props.parentItemLabel, item.navLinkLabel)
          }
        >
          <PromoFeature
            promoFeatureType="solo"
            image={item.navLinkedDocument.backgroundImage}
            title={replaceTextWithValues(item.navLinkedDocument.title, {
              code: `${item.navLinkedDocument.code}`,
            })}
            textPosition={item.navLinkedDocument.textPosition}
            code={item.navLinkedDocument.code}
            codeBackgroundColor={item.navLinkedDocument.codeBackgroundColor}
            codeTextColor={item.navLinkedDocument.codeTextColor}
            cta={item.navLinkedDocument.ctaText}
            ctaColor={item.navLinkedDocument.ctaColor}
            href={
              item.navLinkedDocument.href
                ? localiseUrlII(countryModel, item.navLinkedDocument.href)
                : null
            }
            invert={item.navLinkedDocument.invert}
          />
          <button className="SiteNavLinksList__button">
            <a
              className="SiteNavLinksList__promo-link"
              href={
                item.navLinkedDocument.href
                  ? localiseUrlII(countryModel, item.navLinkedDocument.href)
                  : null
              }
            >
              {/* Category titles aren't normally clickable.
              But one of the requirements for the promo column, is that the
              user should be able to click anywhere and it will lead
              them to the other page. Which is why, I decided to include,
              the category title in the <a> tag in this case. */}
              {props.categoryTitle && (
                <div
                  key={props.categoryTitle}
                  className="SiteNavLinksList__promo-title-item"
                >
                  <h5 className="SiteNavLinksList__promo-title">
                    {props.categoryTitle}
                  </h5>
                </div>
              )}
              <div className="SiteNavLinksList__promo-link-text">
                {item.navLinkLabel}
                <Arrow
                  inline
                  className="SiteNavLinksList__arrow"
                  direction="right"
                />
              </div>
            </a>
          </button>
        </li>
      ))}
    </ul>
  );
};

SiteNavPromos.propTypes = {
  className: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  parentItemLabel: PropTypes.string,
  categoryTitle: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      navLinkLabel: PropTypes.string,
      navLinkUrl: PropTypes.string,
    })
  ),
};

export default SiteNavPromos;
