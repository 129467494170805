import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Button from "wonderbly-components/lib/Button";
import snakeCase from "lodash/snakeCase";
import CardInput from "wonderbly-components/lib/CardInput";
import { replaceTextWithValues } from "src/common/utils/text-replace";
import { prismicImage } from "src/common/utils/prismic-image";

import "./CardSelect.scss";

const getHelperText = (cta, remaining, total) => {
  let str = cta.helperPlural;
  if (remaining === 1) {
    str = cta.helperSingular;
  } else if (remaining === 0) {
    str = cta.helperComplete;
  } else if (remaining < 0) {
    remaining = total;
    str = cta.helperInvalid;
  }
  return replaceTextWithValues(str, { number: remaining });
};

const CardSelect = (props) => (
  <form onSubmit={props.handleSubmit} className="CardSelect">
    <div className="CardSelect__wrap">
      <div className="CardSelect__items">
        {props.drawer.items?.map((item, idx) => {
          const value = item.id || snakeCase(item.title);
          const selected = props.values[props.customisationKey] || [];
          const id = `${props.customisationKey}_${value}`;

          return (
            <div className="CardSelect__item-wrap" key={idx}>
              <CardInput
                {...item}
                type="checkbox"
                id={id}
                name={props.customisationKey}
                image={prismicImage(item.image)}
                onChange={props.onHandleChange}
                value={value}
                checked={selected.includes(value)}
                altLayout
              />
            </div>
          );
        })}
      </div>
    </div>
    <div className="CardSelect__footer">
      <p
        className={cx("CardSelect__helper", {
          "CardSelect__helper--invalid": props.remaining < 0,
        })}
      >
        {getHelperText(
          props.drawer.cta,
          props.remaining,
          props.drawer.numberOfSelections
        )}
      </p>
      <Button
        fullWidth
        className="CardSelect__cta"
        type="submit"
        disabled={props.remaining !== 0}
      >
        {props.drawer.cta?.text}
      </Button>
    </div>
  </form>
);

CardSelect.propTypes = {
  customisationKey: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    numberOfSelections: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onHandleChange: PropTypes.func.isRequired,
  remaining: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
};

export default CardSelect;
