import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  replaceKeysServer,
  replaceKeysClient,
  hydrateReplacer,
} from "./helpers";

import htmlSanitizer from "../../utils/htmlSanitizer";

const InnerComponentReplacer = ({ children }) => (
  <span
    dangerouslySetInnerHTML={{
      __html: htmlSanitizer(children),
    }}
    suppressHydrationWarning
  />
);

InnerComponentReplacer.propTypes = {
  children: PropTypes.string.isRequired,
};

/**
 * ComponentReplacer
 *
 * Swap out a string formatter in children for a hydrated React component.
 *
 * @param {string} children A string that has all matching keys replaced with React components, i.e. `This is %{a_live_component}`. If this string contains markup, it will be sanitized and rendered.
 * @param {object} componentMapping Mapping of components to render, i.e. `{ a_live_component: MyComponent }`
 * @param {object} data Data to be passed to each component on render, i.e. `{ a_live_component: { name: 'Winston' } }`
 * @param {string} id unique identifier for SSR
 *
 * @returns {node} Rendered React node containing replaced components
 */
const ComponentReplacer = ({ children, componentMapping, data, id }) => {
  // Render server - have to do separately as get anerror when using
  // renderToString inside useState hook. We use suppressHydrationWarning
  // to stop react complaining about the mismatched strings.
  if (typeof window === "undefined") {
    const { modifiedChildren } = replaceKeysServer({
      children,
      componentMapping,
      data,
      id,
    });

    return <InnerComponentReplacer>{modifiedChildren}</InnerComponentReplacer>;
  }

  const [{ keys, classes, modifiedChildren }, updateClient] = useState(() =>
    replaceKeysClient({ children, data, id })
  );

  // If the children update, we want to make sure that any of
  // the keys, classes, and modifiedChildren are updated
  useEffect(() => {
    updateClient(replaceKeysClient({ children, data, id }));
  }, [children]);

  // Hydrate on client
  useEffect(
    () => hydrateReplacer({ classes, keys, componentMapping, data }),
    [classes, keys]
  );

  return <InnerComponentReplacer>{modifiedChildren}</InnerComponentReplacer>;
};

ComponentReplacer.propTypes = {
  children: PropTypes.node.isRequired,
  componentMapping: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default ComponentReplacer;
