import Gender from "./Gender";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import withHandlers from "recompose/withHandlers";

import withGlobalContent from "../../../../hocs/withGlobalContent";

const enhance = compose(
  withGlobalContent,
  mapProps((props) => ({
    tabletDown: props.tabletDown,
    onHandleChange: props.onHandleChange,
    gender: props.gender,
    error: props.error,
    labels: {
      boy: props.globalContent.canvas.boy,
      girl: props.globalContent.canvas.girl,
    },
  })),
  withHandlers({
    onHandleChange: (props) => (event) => {
      props.onHandleChange(event);
    },
  })
);

export default enhance(Gender);
