import NavCountryOption from "./NavCountryOption";
import withHandlers from "recompose/withHandlers";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import pick from "lodash/pick";
import { withModel } from "../../../../../data-providers/model";
import { getRedirectUrl } from "src/common/utils/get-redirect-url";
import { navigate } from "src/common/utils/navigate";
import withQueryParams from "../../../../../hocs/withQueryParams";

const enhance = compose(
  withModel,
  withQueryParams,
  withProps((props) => ({
    href: props.value.href,
  })),
  withHandlers({
    onClick: (props) => (e) => {
      // Check if the item has a href
      if (props.value.href) {
        return;
      }

      // Otherwise see if we can navigate to the same page in the new country
      e.preventDefault();

      // Get the metadata of the current page.
      const currentPageMetadata = props.model.prismicPageModel.metadata;

      const isSingleDocument = currentPageMetadata.isSingleDocument;

      // Get the available languages of the current page.
      const alternateLanguages = [
        pick(currentPageMetadata, ["id", "uid", "type", "lang"]),
        ...currentPageMetadata.alternateLanguages,
      ];

      // Get the Prismic UID of the current page.
      let currentUID = currentPageMetadata.uid;

      if (isSingleDocument) {
        currentUID = currentUID || currentPageMetadata.type;
      }

      // Get the route correct redirect route
      let route = getRedirectUrl(
        currentUID,
        props.value,
        alternateLanguages,
        props.queryParams
      );

      // Navigate to the correct route and track the event
      navigate(route, props.queryParams, {
        action: "Change country from country dropdown",
        from: props.model.countryModel.prefix,
        to: props.value.prefix,
      });
    },
  })
);

export default enhance(NavCountryOption);
