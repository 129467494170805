const url = require("url");
const omit = require("lodash/omit");
const mapValues = require("lodash/mapValues");
const isUndefined = require("lodash/isUndefined");
const isString = require("lodash/isString");

const kvParse = (kv) => {
  const i = kv.indexOf("=");
  if (i === -1) {
    return [kv];
  }
  return [kv.slice(0, i), kv.slice(i + 1)];
};

const kvStringify = (k, v, allowUndefined) => {
  if (isUndefined(v)) {
    if (allowUndefined) {
      return k + "=";
    }
    return k;
  }
  return `${k}=${v}`;
};
/**
 * This code is complex because it keeps the order of values when merging
 * new values with the same property name. The reason that this has to happen
 * is because I have low confidence in changing the behaviour not breaking
 * the site.
 *
 */
const appendQueryParams = (params, urlInput) => {
  params = mapValues(params || {}, (value) => {
    if (isString(value)) {
      return encodeURIComponent(value);
    }
    return value;
  });
  const urlObject = url.parse(urlInput, false);
  const search = urlObject.query || "";
  const arr = search
    .split("&") // split the query string
    .map((kv) => {
      let [k, v] = kvParse(kv);
      const hasProperty = params.hasOwnProperty(k);
      v = hasProperty ? params[k] : v; // merge new value if exists to maintain order - predictable!
      params = omit(params, k); // and omit from params
      return kvStringify(k, v, hasProperty);
    });

  Object.entries(params).forEach(([k, v]) => {
    arr.push(kvStringify(k, v, true));
  });

  urlObject.search = arr.filter((v) => !!v).join("&");
  return url.format(urlObject);
};

module.exports = appendQueryParams;
