import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import Icon from "wonderbly-components/lib/Icon";

const Item = (props) => (
  <div className="multi-input__field">
    <Button onClick={props.handleOnRemoveItem} type="button">
      <Icon icon="delete" />
    </Button>
    <input value={props.item} onChange={props.handleOnChangeItem} type="text" />
  </div>
);

Item.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.string.isRequired,
  handleOnRemoveItem: PropTypes.func.isRequired,
  handleOnChangeItem: PropTypes.func.isRequired,
};

export default Item;
