import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { prismicImage } from "src/common/utils/prismic-image";
import "./NavCountryOption.scss";

const NavCountryOption = (props) => {
  let prefix = props.value.prefix;

  if (prefix === "") {
    prefix = "en-us";
  }
  return (
    <a
      href={props.href ? props.href : ""}
      data-hook={`language-${
        props.value.locale ? `${prefix}` : "choose-country"
      }`}
      onClick={props.onClick}
      className={cx("NavCountryOption", {
        "NavCountryOption--active": props.isActive,
      })}
    >
      <img
        src={prismicImage(props.value.flag)}
        alt={props.value.iso}
        width="30"
        className="NavCountryOption__image"
      />
      <span className="NavCountryOption__text">{props.value.name}</span>
    </a>
  );
};

NavCountryOption.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  href: PropTypes.string,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
    iso: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    flag: PropTypes.object.isRequired,
    locale: PropTypes.string,
  }).isRequired,
};

export default NavCountryOption;
