import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import chunk from "lodash/chunk";
import cx from "classnames";

import Button from "wonderbly-components/lib/Button";
import Tooltip from "wonderbly-components/lib/Tooltip";
import { Row, Col } from "src/client/js/view/components/Grid";

import Item from "./Item";

import "./MultiInput.scss";

const MultiInput = (props) => {
  const items = props.values[props.drawerKey];
  const submitDisabled =
    items.length < props.drawer.minNumberOfInputs ||
    items.length > props.drawer.maxNumberOfInputs ||
    props.isSubmitting ||
    !props.isValid;
  const canAddItems = items.length < props.drawer.maxNumberOfInputs;
  return (
    <form className="multi-input" onSubmit={props.handleSubmit}>
      <Tooltip
        className="error-tooltip"
        visible={props.errors && !!props.errors.base}
        absolute={false}
        type="alert"
      >
        {props.errors && props.errors.base}
      </Tooltip>
      <Tooltip
        className="error-tooltip"
        visible={props.errors && !!props.errors[props.drawerKey]}
        absolute={false}
        type="alert"
      >
        {props.errors && props.errors[props.drawerKey]}
      </Tooltip>
      {!isEmpty(items) && (
        <Row colsToRowsOn="fablet-down">
          {chunk(items, 3).map((col, colIndex) => (
            <Col className="col-1-3" key={colIndex}>
              {col.map((item, rowIndex) => (
                <Item
                  key={colIndex * 3 + rowIndex}
                  index={colIndex * 3 + rowIndex}
                  item={item}
                  onRemoveItem={props.handleOnRemoveItem}
                  onChangeItem={props.handleOnChangeItem}
                />
              ))}
            </Col>
          ))}
        </Row>
      )}
      <Row className="multi-input-cta-row" colsToRowsOn="fablet-down">
        {canAddItems && (
          <Col className="col-2-3">
            <Row className="multi-input-additional-field-wrapper">
              <Col className="multi-input-additional-field">
                <input
                  autoFocus
                  key="multi-input-additional-field"
                  type="text"
                  onChange={props.handleOnChangeAdditionalItem}
                  value={props.additionalItem}
                />
              </Col>
              <Col className="multi-input-additional-cta">
                <Button
                  key="multi-input-additional-cta"
                  onClick={props.handleOnAddItem}
                  type="button"
                >
                  {props.drawer.addCta}
                </Button>
              </Col>
            </Row>
          </Col>
        )}
        <Col
          className={cx("multi-input-cta-button", { "col-1-3": canAddItems })}
        >
          <Button
            onClick={props.handleSubmit}
            type="submit"
            disabled={submitDisabled}
          >
            {props.drawer.cta}
          </Button>
        </Col>
      </Row>
    </form>
  );
};

MultiInput.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  additionalName: PropTypes.string,
  handleOnAddItem: PropTypes.func.isRequired,
  handleOnRemoveItem: PropTypes.func.isRequired,
  handleOnChangeItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  drawerKey: PropTypes.string,
  additionalItem: PropTypes.string,
  handleOnChangeAdditionalItem: PropTypes.func.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    addCta: PropTypes.string.isRequired,
    drawerKey: PropTypes.string,
    maxNumberMessage: PropTypes.string,
    maxNumberOfInputs: PropTypes.number,
    minNumberOfInputs: PropTypes.number,
  }),
  values: PropTypes.shape({
    names: PropTypes.array.isRequired,
  }),
  errors: PropTypes.shape({
    base: PropTypes.string,
    names: PropTypes.string,
  }),
};

export default MultiInput;
