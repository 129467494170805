import compose from "recompose/compose";
import withState from "recompose/withState";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { withFormik } from "formik";
import withValidationData from "../../../hocs/withValidationData";
import { validateCreationForm, clearErrors } from "../helpers";
import BearFam from "./BearFam";

const debouncedValidation = debounce(
  (values, props, config) => validateCreationForm(values, props, config),
  500
);

const enhance = compose(
  withValidationData,
  withState("bears", "setBears", (props) =>
    props.drawer.items.reduce(
      (items, item) => {
        const activeBear = props.productCustomisation.bears.find(
          (bear) => bear.type === item.type
        );
        !!activeBear
          ? (items.active = items.active.concat(activeBear))
          : (items.subs = items.subs.concat({ type: item.type }));
        return items;
      },
      { active: [], subs: [] }
    )
  ),
  withFormik({
    mapPropsToValues: (props) => ({ base: "", bears: props.bears.active }),
    handleSubmit: (values, formikBag) => formikBag.props.onFormikSubmit(values),
  }),
  withHandlers({
    handleBearChange: (props) => (action, index, bearType, bearName) => {
      let newBears;
      switch (action) {
        case "add": {
          const errors = props.errors.bears;
          const bearsBase = !!errors && errors.find((error) => !error.index);
          const baseIndex = !!errors && errors.indexOf(bearsBase);
          newBears = {
            active: props.bears.active.concat({
              type: bearType,
              name: bearName,
            }),
            subs: props.bears.subs.filter((bear) => bear.type !== bearType),
          };

          if (!isNil(baseIndex)) {
            debouncedValidation({ bears: newBears.active }, props, {
              enableSetErrors: true,
            });
          }
          break;
        }
        case "update": {
          newBears = {
            active: props.bears.active.map((bear, bearIndex) =>
              bearIndex === index ? { ...bear, name: bearName } : bear
            ),
            subs: props.bears.subs,
          };
          debouncedValidation({ bears: newBears.active }, props, {
            enableSetErrors: true,
          });
          break;
        }
        case "remove": {
          clearErrors(props, "bears");
          newBears = {
            active: props.bears.active.filter(
              (bear, bearIndex) => bearIndex !== index
            ),
            subs: props.bears.subs.concat({
              type: bearType,
            }),
          };
          debouncedValidation({ bears: newBears.active }, props, {
            enableSetErrors: true,
          });
        }
      }
      props.setBears(newBears);
      props.setFieldValue("bears", newBears.active);
    },
  }),
  withProps((props) => ({
    isInvalid:
      !isEmpty(props.errors) ||
      props.values.bears.filter((bear) => bear.name).length !==
        props.values.bears.length,
  }))
);

export default enhance(BearFam);
