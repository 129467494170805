import { track } from "src/client/js/analytics/facade";

export const trackParentNavItem = (menuLabel, linkUrl) =>
  track("Parent NavItem click", {
    label: `Parent NavItem: ${menuLabel || linkUrl}`,
    category: "Nav v3",
  });

export const trackParentNavItemHover = (menuLabel) =>
  track("Parent NavItem hover/sub menu container open", {
    label: `Parent NavItem: ${menuLabel}`,
    category: "Nav v3",
  });

export const trackChildNavItem = (parentLabel, linkLabel) =>
  track("Child Nav Item click", {
    label: `Nav link parent: ${parentLabel} / Nav Link Child: ${linkLabel}`,
    category: "Nav v3",
  });

export const trackMarketingCardItem = (parentLabel, linkLabel) =>
  track("Child Nav Marketing Card Item click", {
    label: `Nav link parent: ${parentLabel} / Nav Link Child: ${linkLabel}`,
    category: "Nav v3",
  });

export const trackAdditionalLinks = (linkLabel) =>
  track("Info Nav Item click", {
    label: `Nav Link Info: ${linkLabel}`,
    category: "Nav v3",
  });

export const trackHamburgerMenu = () =>
  track("Hamburger menu click", {
    label: `Open mobile menu`,
    category: "Nav v3",
  });

export const trackOpenCountryToggle = () =>
  track("Nav CountryToggle click", {
    label: `Open country toggle`,
    category: "Nav v3",
  });

export const trackMobileNavClosure = () =>
  track("Mobile nav overlay or close icon click", {
    label: `Close the mobile nav`,
    category: "Nav v3",
  });

export const trackScrollToImage = (parentLabel, imageId) =>
  track("Mobile nav scroll to image", {
    label: `Nav link parent: ${parentLabel} / Scroll to image: ${imageId}`,
    category: "Nav v3",
  });
