const ErrorBase = require("./ErrorBase");

class ProductRendererNotFoundError extends ErrorBase {
  constructor(err, productId) {
    super(err.details, err);
    this.status = 404;
    this.details = {
      errors: [
        {
          code: "product.notFound",
          message: "Product not found",
          context: {
            value: productId,
          },
        },
      ],
    };
  }
}

module.exports = ProductRendererNotFoundError;
