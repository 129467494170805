import logger from "src/logger";
import { request, jsonRequest } from "../../../common/rest/index";

/**
 * @deprecated
 * @param product
 */
export const canvas = (product) => {
  request
    .get(`/phoenix/api/v1/canvas/configure/${product}`)
    .then((response) => {
      logger.info(response);
    })
    .catch((error) => {
      logger.error(error);
    });
};

export const geoip = () => request.get(`/website-api/v1/geoip`);
/**
 *
 * @param [iso]
 * @param [locale]
 * @returns {Promise}
 */
export const switchCountry = async (iso, locale) => {
  try {
    const { body } = await request.get("/website-api/v1/switch-country", {
      country_iso: iso,
      locale,
    });
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * Hit the url that is passed in to see if it is available.
 * @param url {String}
 * @returns {Promise<boolean | boolean>}
 */
export const checkIfPageExists = (url) => {
  // Add a softLook query so that when hitting the page we dont actually log an error
  return request
    .get(url, { softLook: true })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const sendPersonalisedEmail = (template, user, customisations) => {
  return request.post(
    "/website-api/v1/personalised-email",
    {
      user,
      template,
      customisations,
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const sendSaveForLaterEmail = (email, saveForLaterUrl, product) => {
  return request.post(
    "/website-api/v1/save-for-later",
    {
      email,
      saveForLaterUrl,
      product,
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const sendGladlyForm = (data) => {
  return request.post(
    "/website-api/v1/send-contact-form",
    {
      data,
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const findAddresses = async (iso, locale, search, container) => {
  const { body } = await jsonRequest.post("/website-api/v1/address-lookup", {
    iso,
    locale,
    search,
    container,
  });

  return body;
};

export const getAddress = async (id) => {
  const { body } = await jsonRequest.get(
    `/website-api/v1/address-lookup/${id}`
  );
  return body;
};
