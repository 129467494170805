import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import { connect } from "react-redux";
import { withModel } from "../data-providers/model";
import withProductCustomisation from "./withProductCustomisation";
import {
  addToCart,
  editCartItem,
  removeCartItem,
  editAddOn,
} from "src/client/js/store/thunks/cart";
import { getStatus } from "src/client/js/store/selectors/cart";
import get from "lodash/get";
import isString from "lodash/isString";

export const transformCharacterSelection = (item) => {
  const characterSelection = get(item, "customisations.characterSelection");

  if (characterSelection && !isString(characterSelection)) {
    item.customisations.characterSelection = JSON.stringify(characterSelection);
  }

  return item;
};

const withCart = compose(
  withProductCustomisation,
  withModel,
  connect(
    (state) => ({
      status: getStatus(state),
    }),
    (dispatch, props) => ({
      add: (items = null, redirect = true) => {
        const itemFromStore = {
          productId: props.model.eagleProduct
            ? props.model.eagleProduct.id
            : props.productId,
          customisations: props.productCustomisation,
        };
        const newItems = items || [itemFromStore]; // needs to be an array
        // falls back to product customisations in the redux store if no item is passed in as an arg

        const payload = {
          items: newItems.map((item) => transformCharacterSelection(item)),
        };

        dispatch(
          addToCart(payload, props.model.countryModel, redirect, window)
        );
      },
      edit: (id) => {
        const item = { id, customisations: props.productCustomisation };
        return dispatch(editCartItem(item));
      },
      editCustomisations: (id, customisations) => {
        const item = { id, customisations: customisations };
        return dispatch(editCartItem(item));
      },
      remove: (id) => dispatch(removeCartItem(id, props.model.countryModel)),
      editAddOn: (item, countryModel, addOn) =>
        dispatch(editAddOn(item, countryModel, addOn)),
    })
  ),
  mapProps(
    ({
      status,
      add,
      edit,
      remove,
      editAddOn,
      editCustomisations,
      ...props
    }) => ({
      ...props,
      cart: {
        status,
        add,
        edit,
        remove,
        editAddOn,
        editCustomisations,
      },
    })
  )
);

export default withCart;
