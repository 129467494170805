// https://segment.com/docs/sources/website/analytics.js/
import once from "lodash/once";
import noop from "lodash/noop";
import logger from "src/logger";

// determine whether this is client or server
const isServerSide = typeof window === "undefined";

// locally assign window so we can work
const win = isServerSide ? {} : window;

// fake dataLayer in case GTM was blocked
const dataLayer = win.dataLayer || [];

// fake segment api in case segment was blocked or disabled
const mockSegment = {
  track(event, eventData, callback = noop) {
    logger.info(["track", event, eventData]);
    callback();
  },
};

const getSegmentClient = () => {
  return win.analytics || mockSegment;
};

const defaultEventData = {
  category: null,
  label: null,
  value: null,
  action: null,
  nonInteractive: true,
};

const eventTimeout = 1000;
const pushToDataLayer = (event, eventData) => {
  return new Promise((resolve) => {
    resolve = once(resolve);
    dataLayer.push({
      event,
      eventData,
      eventTimeout,
      eventCallback: resolve,
    });
    setTimeout(resolve, eventTimeout);
  });
};

const trackWithSegment = (event, eventData) => {
  return new Promise((resolve) => {
    resolve = once(resolve);
    try {
      getSegmentClient().track(event, eventData, resolve);
      setTimeout(resolve, eventTimeout);
    } catch (error) {
      logger.error(error);
      resolve();
    }
  });
};

/**
 * only use this method if you know what you are doing
 * it requires setting up of a trigger in GTM
 */
const trackCustom = (gtmEvent, event, data = {}) => {
  if (isServerSide) {
    throw new Error(`analytics.trackCustom was called server side: ${event}`);
  }
  data.category = data.category || "All";
  return Promise.all([
    trackWithSegment(event, data),
    pushToDataLayer(gtmEvent, {
      ...defaultEventData,
      ...data,
      action: event,
    }),
  ]);
};

/*
Each of these methods below has a matching trigger in GTM.
Never EVER be a lazy developer and wildcard the event names in GTM
It's impossible to manage and the next person coming after you
to work in this codebase will never know how it works.
 */
export const track = (event, data = {}) => trackCustom("track", event, data);
