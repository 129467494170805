import { useState, useEffect } from "react";

export default (ref, dependencies = []) => {
  const [bounds, setBounds] = useState(0);

  useEffect(() => {
    setBounds(ref.current.getBoundingClientRect());
  }, [ref, ...dependencies]);

  return bounds;
};
