/* eslint-disable react/jsx-no-bind */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import merge from "lodash/merge";
import Form from "wonderbly-components/lib/Form";
import { cleanAdventurers } from "../helpers";
import * as fieldHelpers from "src/common/models/prismic/slices/helpers/build-form-fields";

import "./CharacterForm.scss";

const getFormFields = (editedCharacter) => {
  const fields = editedCharacter.formFields.map((f) =>
    fieldHelpers.buildField(f)
  );

  for (const field of fields) {
    if (field.name) {
      const fieldName = field.name.split(".").pop();
      editedCharacter[fieldName]
        ? (field.value = editedCharacter[fieldName])
        : null;
    }
  }

  return fields;
};

const onHandleSubmit = async (
  submit,
  formValues,
  formMethods,
  characters,
  customisationKey,
  editedCharacter
) => {
  const cleanCharacters = cleanAdventurers(characters);
  const newCharacters = merge(
    { [customisationKey]: cleanCharacters },
    formValues
  );
  await submit(newCharacters, formMethods);
};

const CharacterForm = (props) => {
  const editedCharacter = props.characters.find(
    (char) => char.editIndex === props.editIndex
  );
  const fields = useMemo(() => getFormFields(editedCharacter), []);

  return (
    <div className="CharacterForm">
      <Form
        onSubmit={(values, formMethods) =>
          onHandleSubmit(
            props.onHandleSubmit,
            values,
            formMethods,
            props.characters,
            props.customisationKey,
            editedCharacter
          )
        }
        fields={fields}
      />
    </div>
  );
};

CharacterForm.propTypes = {
  characters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHandleSubmit: PropTypes.func.isRequired,
  editIndex: PropTypes.number.isRequired,
  customisationKey: PropTypes.string.isRequired,
};

export default CharacterForm;
