import { useState } from "react";
import { useLocation, useHistory } from "react-router";
import qs from "qs";
import omit from "lodash/omit";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

const getCurrentParams = (search) => {
  if (!search && typeof window === "undefined") {
    return {};
  }
  return qs.parse(search || window.location.search, {
    ignoreQueryPrefix: true,
  });
};

const mergeParams = (params) => {
  const currentParams = getCurrentParams();
  return {
    ...currentParams,
    ...params,
  };
};

export default () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const [params, setParams] = useState(getCurrentParams(search));

  const setSearch = (newParams, replaceHistory = false) => {
    setParams(newParams);

    const search = qs.stringify(newParams, { addQueryPrefix: true });
    replaceHistory
      ? history.replace(`${pathname}${search}`)
      : history.push(`${pathname}${search}`);
  };

  const removeParams = (paramKeys) => {
    const currentParams = getCurrentParams();
    const newParams = omit(currentParams, paramKeys);

    setSearch(newParams);
  };

  const buildQueryParams = (newParams, includePrevParams = true) => {
    let updatedParams = omitBy(newParams, isNil);
    if (includePrevParams) {
      updatedParams = { ...updatedParams, ...params };
    }

    return qs.stringify(updatedParams, { addQueryPrefix: true });
  };

  return {
    params,
    buildQueryParams,
    setParams: (params) => {
      const newParams = mergeParams(params);
      setSearch(newParams);
    },
    setParamsReplaceHistory: (params) => {
      const newParams = mergeParams(params);
      setSearch(newParams, true);
    },
    removeParams,
    removeParam: (key) => {
      removeParams([key]);
    },
  };
};
