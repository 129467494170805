import NavItem from "./NavItem.js";
import compose from "recompose/compose";
import withProps from "recompose/mapProps";
import withState from "recompose/withState";
import withMediaQueries from "../../../../hocs/withMediaQueries";
import { localiseUrl } from "src/common/utils/format-localised-url";

const enhance = compose(
  withMediaQueries,
  withProps((props) => {
    let rel = props.item.googleFollow ? "" : "nofollow";
    rel += props.item.openInNewTab ? "" : " noopener";
    rel = rel.trim() || null;
    return {
      ...props.item,
      url: localiseUrl(props.passedDown.country, props.item.url, false),
      target: props.item.openInNewTab ? "_blank" : null,
      fabletDown: props.media.fabletDown,
      rel,
    };
  }),
  withState("isActive", "handleOnActive", false)
);

export default enhance(NavItem);
