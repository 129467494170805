import React from "react";
import Tooltip from "wonderbly-components/lib/Tooltip";
import InlineFormField from "wonderbly-components/lib/InlineFormField";
import PropTypes from "prop-types";

const Age = (props) => (
  <div className="age">
    <Tooltip
      className="error-tooltip"
      visible={!!props.error}
      absolute={!props.tabletDown}
      type="alert"
    >
      {props.error}
    </Tooltip>
    <InlineFormField
      id={props.id}
      label={props.label}
      fieldType="select"
      options={props.options}
      value={props.age}
      onChange={props.onHandleChange}
    />
  </div>
);

Age.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  age: PropTypes.string.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  tabletDown: PropTypes.bool.isRequired,
};

export default Age;
