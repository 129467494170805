import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import Select from "wonderbly-components/lib/Select";

import "./QuickAdd.scss";
import InlineLabel from "wonderbly-components/lib/InlineLabel";

const QuickAdd = (props) => [
  <div key="or" className="quick-add-or">{` Or select from list:`}</div>,
  <form
    key="quickAddForm"
    onSubmit={props.handleSubmit}
    className="quick-add-form"
  >
    <InlineLabel htmlFor="select-mayday-item">Product</InlineLabel>
    <Select
      id="product"
      value={props.values.product}
      onChange={props.handleChange}
      options={props.productsOptions}
    />

    <InlineLabel htmlFor="select-mayday-format">Box</InlineLabel>
    <Select
      id="box"
      value={props.values.box}
      onChange={props.handleChange}
      options={props.boxes}
    />

    <InlineLabel htmlFor="select-mayday-cover">Cover</InlineLabel>
    <Select
      id="cover"
      value={props.values.cover}
      onChange={props.handleChange}
      options={props.covers}
    />

    <InlineLabel htmlFor="select-mayday-size">Size</InlineLabel>
    <Select
      id="size"
      value={props.values.size}
      onChange={props.handleChange}
      options={props.sizes}
    />

    <InlineLabel htmlFor="select-mayday-locale">Locales</InlineLabel>
    <Select
      id="locale"
      value={props.values.locale}
      onChange={props.handleChange}
      options={props.locales}
    />

    <Button className="quick-add-cta" key="quick-add-or" type="submit">
      Add
    </Button>
  </form>,
];

QuickAdd.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  productsOptions: PropTypes.array.isRequired,
  boxes: PropTypes.array.isRequired,
  covers: PropTypes.array.isRequired,
  sizes: PropTypes.array.isRequired,
  locales: PropTypes.array.isRequired,
};

export default QuickAdd;
