import cookie from "js-cookie";

/** @deprecated use /common/utils/user-session */
export const getCookies = () => ({
  guestToken: cookie.get("guestToken"),
  userToken: cookie.get("restricted_user_id"),
  locale: cookie.get("locale"),
  currency: cookie.get("currency"),
  countryIso: cookie.get("country_code"),
});

/** @deprecated use /common/utils/user-session */
export const isUserLoggedIn = () => !!cookie.get("restricted_user_id");
