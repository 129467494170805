import isEmpty from "lodash/isEmpty";
import omitBy from "lodash/omitBy";

import { request } from "src/common/rest/index";

// File used to send logging data to eagle for certain actions
let baseUrl = "";
export const setBaseUrl = (value) => {
  baseUrl = value;
};

export const logDigitalDownload = (data) => {
  let dataToSend = {
    ...omitBy(data, (value) => isEmpty(value)),
  };

  return request.post(`${baseUrl}/website/v2/digital_downloads`, dataToSend, {
    headers: {
      "content-type": "application/json",
    },
  });
};

// Data is of shape:
// {
//  "email":"mike+admin@wonderbly.com",
//  "slug":"ten-little-yous",
//  "save_for_later_url":"relative canvas url"",
//  "book_recipient_name":"name"  // if we have it send name of the person in the book
//  }
export const logSaveForLater = (data) => {
  let dataToSend = {
    ...omitBy(data, (value) => isEmpty(value)),
  };

  return request.post(`${baseUrl}/website/v2/save_for_later`, dataToSend, {
    headers: {
      "content-type": "application/json",
    },
  });
};
