import React from "react";
import PropTypes from "prop-types";

import loadable from "@loadable/component";

import { useModel } from "../../hooks";

import ProductContext from "./context";
import SliceErrorBoundary from "../SliceErrorBoundary";

import DynamicLayout from "../DynamicLayout/DynamicLayout";

const components = {
  ...DynamicLayout.components,
  ProductForm: loadable(() => import("./slices/ProductForm")),
};

const getComponent = (slice, index) => {
  const type = slice.type;
  const Component = components[type];

  return (
    <SliceErrorBoundary key={`${slice.type}_${index}`}>
      <Component {...slice} />
    </SliceErrorBoundary>
  );
};

const ProductLayout = ({ pageId }) => {
  const {
    prismicPageModel: { slices, product },
    eagleProduct,
  } = useModel();

  return (
    <ProductContext.Provider
      value={{
        ...eagleProduct,
        content: product,
      }}
    >
      <main id={pageId} className="wrapper" data-test={pageId}>
        {slices.map((slice, i) => getComponent(slice, i))}
      </main>
    </ProductContext.Provider>
  );
};

ProductLayout.propTypes = {
  pageId: PropTypes.string.isRequired,
};

export default ProductLayout;
