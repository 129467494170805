import get from "lodash/get";
import { remapLocale } from "src/common/l10n/country";

export const getPrismicProducts = (state) =>
  get(state, "session.prismic.products", {});

export const getPrismicProduct = (state, sku) =>
  get(getPrismicProducts(state), [sku], {});

export const getPrismicProductByLocale = (state, sku, locale) =>
  get(getPrismicProduct(state, sku), [remapLocale(locale)], {
    response: { pending: true },
  });

export const getPrismicProductDrawer = (state, sku, locale, drawer) => {
  const prismicProduct = getPrismicProductByLocale(state, sku, locale);
  const canvas = prismicProduct.canvas ? prismicProduct.canvas : { body: [] };
  return canvas.body.find((item) => item.sliceType === drawer);
};
