const isEmpty = require("lodash/isEmpty");
const omitBy = require("lodash/omitBy");
const isNil = require("lodash/isNil");

/**
 * arrayToSentence takes an array of strings and turns it into a sentence
 * @param {string[]} array
 * @param {Object} [options]
 * @param {boolean} [options.oxfordComma=false] define whether the sentence is with an oxford comma or not
 * @param {string | null} [options.conjunction="and"] used to connect clauses
 *
 * @example
 *  const array = ['ice cream', 'pizza', 'coffee'];
 *  arrayToSentence(array);
 *  // => 'ice cream, pizza and coffee'
 *  arrayToSentence(array, { oxfordComma: true });
 *  // => 'ice cream, pizza, and coffee'
 *  arrayToSentence(array, { conjunction: '&' });
 *  // => 'ice cream, pizza & coffee'
 * @returns {string}
 */
const arrayToSentence = (array, options) => {
  if (!array || isEmpty(array)) {
    return "";
  }
  if (array.length === 1) {
    return array[0];
  }

  const defaultOptions = { oxfordComma: false, conjunction: "&" };
  const omittedOptions = omitBy(options, isNil);
  const last = array.pop();
  let string = array.join(", ");

  options = { ...defaultOptions, ...omittedOptions };

  if (options.oxfordComma) {
    string = `${string}, ${options.conjunction} ${last}`;
  } else {
    string = `${string} ${options.conjunction} ${last}`;
  }
  return string;
};

module.exports = arrayToSentence;
