const logger = require("../../logger");

const getPathToAsset = (path, fallback) => {
  if (!path) {
    logger.error(new Error("Missing path string"));
    return "";
  }
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  if (typeof window === "undefined") {
    if (typeof fallback === "undefined") {
      const STATIC_SERVER = process.env.STATIC_SERVER
        ? `https://${process.env.STATIC_SERVER}`
        : "";
      const STATIC_PATH = process.env.STATIC_PATH || "/static-v2";
      fallback = `${STATIC_SERVER}${STATIC_PATH}`;
    }

    return `${fallback}${path}`;
  }
  return `${window.model.topology.cdn}${path}`;
};

module.exports = getPathToAsset;
