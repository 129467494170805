import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { Col, Row } from "src/client/js/view/components/Grid";
import InlineFormField from "wonderbly-components/lib/InlineFormField";
import "./BirthdayFormRow.scss";

const BirthdayFormRow = (props) => {
  const fieldOrder = props.fieldOrder;
  if (!fieldOrder || isEmpty(fieldOrder)) {
    return null;
  }
  return (
    <Row className="birthday-form-row" colsToRowsOn="fablet-down">
      {fieldOrder.map((field) => {
        // Create a unique id so that label link to the correct field
        const uniqueId = `${props.formId}-${field}`;
        return (
          <Col key={field} className={`birthday-form-col--${field}`}>
            <InlineFormField
              fieldType="select"
              name={field}
              id={uniqueId}
              options={props.options[`${field}s`]}
              label={(props.labels && props.labels[field]) || ""}
              value={props.values[field]}
              onChange={props.onHandleChange}
            />
          </Col>
        );
      })}
    </Row>
  );
};

BirthdayFormRow.propTypes = {
  fieldOrder: PropTypes.arrayOf(PropTypes.string),
  formId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  onHandleChange: PropTypes.func.isRequired,
};

export default BirthdayFormRow;
