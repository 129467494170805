import snakeCase from "lodash/snakeCase";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import Interest from "./Interest";
import splitPrismicKeyVals from "src/common/utils/split-prismic-key-vals";
import { matchDefaultCustomisation } from "src/client/js/view/utils/match-customisation";
import filter from "lodash/filter";
import withProduct from "../../../hocs/withProduct";

const withUpselledOptions = (fn = () => {}) =>
  compose(
    withProduct,
    withProps((props) => {
      const customisationKey = fn(props) || "";
      return {
        upselledOptions: filter(
          props.productVariant.components,
          ({ type, price }) => type === customisationKey && price.amount > 0
        ),
      };
    })
  );

const enhance = compose(
  withProps((props) => ({
    interestType: snakeCase(props.drawer.type),
    items: props.drawer.items.reduce((items, item) => {
      if (matchDefaultCustomisation(props.productCustomisation, item)) {
        const extraChoice = splitPrismicKeyVals(item.extraChoices);
        return items.concat({
          ...item,
          id: extraChoice ? extraChoice.value : item.id,
        });
      }
      return items;
    }, []),
  })),
  withUpselledOptions(({ interestType }) => interestType)
);

export default enhance(Interest);
