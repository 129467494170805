import { createContext, useReducer } from "react";
import Immutable from "seamless-immutable";

// Making an immutable object to create a key value pair of notifications
// where the key is a uniq epoch timestamp and the value is the content
// of the notification.

// NOTE: This used to be using Map from immutable.js but was replaced
// with the package seamless-immutable. However this can be replaced
// with a standard object, we just need to make sure we DON'T UPDATE
// IT BEFORE the store's lifecycle
// eslint-disable-next-line new-cap
const initialState = Immutable({});
const NotificationsContext = createContext(() => initialState);

const notificationsReducer = (state, action) => {
  switch (action.type) {
    case "add":
      return state.setIn([action.data.created], action.data);
    case "remove":
      return state.without(action.id);
    default:
      return state;
  }
};

export const useNotificationsContext = () => {
  const [notificationsObject, dispatch] = useReducer(
    notificationsReducer,
    initialState
  );

  const notifications = Object.values(notificationsObject);

  return [notifications, dispatch];
};

export default NotificationsContext;
