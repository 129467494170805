const preloadImage = (imageUrl) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageUrl;
    image.addEventListener("load", () => resolve());
    image.addEventListener("error", () =>
      reject(new Error(`preloadImage: failed to load ${imageUrl}`))
    );
  });

module.exports = preloadImage;
