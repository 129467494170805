import { withFormik } from "formik";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import withValidationData from "../../../hocs/withValidationData";
import { validateCreationForm, createOnFieldChangeHandler } from "../helpers";
import Companion from "./Companion";

const enhance = compose(
  withValidationData,
  withProps((props) => ({
    hasGender: props.drawer.genderLabelBoy && props.drawer.genderLabelGirl,
  })),
  withFormik({
    mapPropsToValues: (props) => {
      const values = {
        base: "",
        gifter_name: props.productCustomisation.gifter_name,
      };

      if (props.hasGender) {
        values.gifter_gender = props.productCustomisation.gifter_gender;
      }

      return values;
    },
    validate: (values, props) => validateCreationForm(values, props),
    validateOnChange: false,
    handleSubmit: (values, formikBag) => formikBag.props.onFormikSubmit(values),
  }),
  withHandlers(() => {
    const handleChange = createOnFieldChangeHandler();
    return {
      handleChange,
    };
  })
);

export default enhance(Companion);
