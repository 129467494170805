import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isEmpty from "lodash/isEmpty";
import CloseButton from "../../../../CloseButton";

const Item = ({
  type,
  label,
  name,
  placeholder,
  tabIndex,
  isDeleteDisabled,
  displayDeleteButton,
  image,
  error,
  handleOnChange,
  handleOnDelete,
}) => {
  return (
    <div className="Characters__List__Item__Wrapper">
      <div className="Characters__List__Item">
        {displayDeleteButton && (
          <CloseButton
            className={cx("Characters__List__Item__Delete", {
              "Characters__List__Item__Delete--disabled": isDeleteDisabled,
            })}
            handleOnClick={handleOnDelete}
          />
        )}
        {!isEmpty(image) && (
          <div
            className="Characters__List__Item__Image"
            style={{ backgroundImage: `url(${image.url} )` }}
          />
        )}
        <div className="Characters__List__Item__Content">
          {type && (
            <label htmlFor={type}>
              <h4 className="text-mega">{label}</h4>
            </label>
          )}
          <input
            type="text"
            placeholder={placeholder}
            tabIndex={tabIndex}
            id={type}
            value={name}
            onChange={handleOnChange}
          />
        </div>
      </div>
      {error && error.name && (
        <div className="Characters__List__Item__Error">{error.name}</div>
      )}
    </div>
  );
};

Item.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  error: PropTypes.shape({
    name: PropTypes.string,
  }),
  isDeleteDisabled: PropTypes.bool.isRequired,
  displayDeleteButton: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
};

export default Item;
