import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import cx from "classnames";
import CloseIcon from "wonderbly-components/lib/CloseIcon";
import NavCountryToggle from "../NavCountryToggle";
import ChooseYourCountryModal from "../../ChooseYourCountryModal";
import { useSiteNavContext } from "../SiteNavToggle/SiteNavContext";
import SitePrimaryNav from "../../SitePrimaryNav";
import SiteAdditionalLinks from "../../SiteAdditionalLinks";
import { MobileExpandedNav } from "../../SiteExpandedNav";
import { trackMobileNavClosure } from "../../tracking";
import "./SiteMobilePrimaryNav.scss";

const SiteMobilePrimaryNav = (props) => {
  const { subMenuIndex, setSubMenuIndex, isMenuOpen, setIsMenuOpen } =
    useSiteNavContext();
  const styles = useSpring({ left: isMenuOpen ? "0%" : "-100%" });

  useEffect(() => {
    isMenuOpen
      ? document.body.classList.add("preventScroll")
      : document.body.classList.remove("preventScroll");
  }, [isMenuOpen]);

  const handleOnClick = (e) => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
    setSubMenuIndex(false);
    trackMobileNavClosure();
  };

  return (
    <div className="SiteMobilePrimaryNav">
      <div
        role="button"
        aria-label="Close menu"
        onClick={handleOnClick}
        className={cx("SiteMobilePrimaryNav__overlay", {
          "SiteMobilePrimaryNav__overlay--is-visible": isMenuOpen,
        })}
      />
      {isMenuOpen && (
        <animated.div className="SiteMobilePrimaryNav__btn-animation">
          <button
            className="SiteMobilePrimaryNav__button"
            onClick={handleOnClick}
          >
            <div className="SiteMobilePrimaryNav__close-icon">
              <CloseIcon color="#FFF" />
            </div>
          </button>
        </animated.div>
      )}
      <animated.div style={styles} className="SiteMobilePrimaryNav__animation">
        <div className="SiteMobilePrimaryNav__main-menu">
          <SitePrimaryNav items={props.mobileNavigation.navMenuItems} />
          <div className="SiteMobilePrimaryNav__country-toggle">
            <NavCountryToggle />
          </div>
          <SiteAdditionalLinks items={props.mobileNavigation.secondaryNav} />
        </div>
      </animated.div>
      <div className="SiteMobilePrimaryNav__sub-menu">
        {props.mobileNavigation.navMenuItems.map((navMenuItem, index) => (
          <MobileExpandedNav
            key={index}
            backButtonText={navMenuItem.navMenuLabel}
            columns={navMenuItem.columns}
            showSubMenu={subMenuIndex === index}
            onHandleCloseMenu={() => setSubMenuIndex(false)}
          />
        ))}
      </div>
      <div className="SiteMobilePrimaryNav__choose-country-modal">
        <ChooseYourCountryModal />
      </div>
    </div>
  );
};

SiteMobilePrimaryNav.propTypes = {
  mobileNavigation: PropTypes.shape({
    changeCountryLabel: PropTypes.string,
    secondaryNav: PropTypes.array,
    navMenuItems: PropTypes.array.isRequired,
  }),
};

export default SiteMobilePrimaryNav;
