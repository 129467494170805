import React from "react";
import PropTypes from "prop-types";
import SiteNavLinksList from "../SiteNavLinksList";
import SiteNavPromos from "../SiteNavPromos";
import { trackParentNavItemHover } from "../tracking";
import "./DesktopExpandedNav.scss";

const DesktopExpandedNav = (props) => {
  if (!props.showSubMenu) {
    return null;
  }

  return (
    <div className="DesktopExpandedNav">
      <div
        className="DesktopExpandedNav__menu-container"
        onMouseEnter={() => trackParentNavItemHover(props.parentItemLabel)}
      >
        {props.columns?.linkColumns?.map((list, index) => (
          <SiteNavLinksList
            {...list}
            parentItemLabel={props.parentItemLabel}
            key={index}
          />
        ))}
        {props.columns?.marketingColumns?.map((list, index) => (
          <SiteNavPromos
            {...list}
            parentItemLabel={props.parentItemLabel}
            className={index === 0 && "DesktopExpandedNav__first-promo"}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

DesktopExpandedNav.propTypes = {
  columns: PropTypes.shape({
    linkColumns: PropTypes.array,
    marketingColumns: PropTypes.array,
  }),
  showSubMenu: PropTypes.bool,
  parentItemLabel: PropTypes.string,
};

export default DesktopExpandedNav;
