import { request } from "src/common/rest";
import { getSessionHeaders } from "src/common/facades/eagle/helpers/session-headers";
import { getCookies } from "./helpers/cookies";
import mapKeys from "lodash/mapKeys";
const {
  transformCartOrderData,
} = require("./helpers/transform-cart-order-data");

let baseUrl = "";
export const setBaseUrl = (value) => {
  baseUrl = value;
};

const sessionHeaders = getSessionHeaders(getCookies());

const jsonHeaders = {
  ...sessionHeaders,
  "content-type": "application/json",
};

/* eslint-disable camelcase */
export const logIn = (spree_user) => {
  return request.post(
    `${baseUrl}/website/v1/user/session`,
    JSON.stringify({ spree_user }),
    { headers: jsonHeaders }
  );
};

export const fetchUser = (query) => {
  return request.get(`${baseUrl}/website/v1/user`, query);
};

export const updateUser = (spree_user) => {
  return request.put(
    `${baseUrl}/website/v1/user`,
    JSON.stringify({ spree_user }),
    { headers: jsonHeaders }
  );
};

export const newUser = (spree_user) => {
  return request.post(
    `${baseUrl}/website/v1/user/registration`,
    JSON.stringify({ spree_user }),
    { headers: jsonHeaders }
  );
};

export const resendEmail = (spree_user) => {
  return request.post(
    `${baseUrl}/website/v1/user/send_confirmation_email`,
    JSON.stringify({ spree_user }),
    { headers: jsonHeaders }
  );
};

export const updatePassword = (spree_user) => {
  return request.put(
    `${baseUrl}/website/v1/user/password`,
    JSON.stringify({ spree_user }),
    { headers: jsonHeaders }
  );
};

export const fetchUserOrders = () => {
  return request.get(`${baseUrl}/website/v1/orders`);
};

export const fetchOrder = (orderId) => {
  return request
    .get(`${baseUrl}/website/v1/orders/${orderId}`, null, {
      headers: sessionHeaders,
    })
    .then((response) => {
      return {
        body: {
          data: transformCartOrderData(response.body.data),
        },
      };
    });
};

export const fetchOrderV2 = (orderId) => {
  return request
    .get(`${baseUrl}/website/v2/orders/${orderId}`, null, {
      headers: sessionHeaders,
    })
    .then((response) => {
      return {
        body: {
          data: transformCartOrderData(response.body.data),
        },
      };
    });
};

export const fetchRecentOrders = () => {
  return request
    .get(`${baseUrl}/website/v2/orders/recent`, null, {
      headers: jsonHeaders,
    })
    .then((response) => {
      return {
        body: {
          data: response.body.data.map((order) =>
            transformCartOrderData(order)
          ),
        },
      };
    });
};

export const fetchCompletedOrders = () => {
  return request
    .get(`${baseUrl}/website/v2/orders/completed`, null, {
      headers: jsonHeaders,
    })
    .then((response) => {
      return {
        body: {
          data: response.body.data.map((order) =>
            transformCartOrderData(order)
          ),
        },
      };
    });
};

export const expediteOrder = (orderId) => {
  return request.post(
    `${baseUrl}/website/v1/orders/${orderId}/expedite`,
    null,
    {
      headers: sessionHeaders,
    }
  );
};

export const updateOrderItem = (item) => {
  return request.put(`${baseUrl}/website/v1/orders/items`, item, {
    headers: jsonHeaders,
  });
};

export const updateOrderShipping = (shipping, orderNumber) => {
  const snakeCaseShipping = mapKeys(
    shipping,
    (v, k) =>
      ({
        stateAbbr: "state_abbr",
        firstName: "first_name",
        lastName: "last_name",
      }[k] || k)
  );

  return request.put(
    `${baseUrl}/website/v1/orders/shipping`,
    { order_number: orderNumber, shipping: snakeCaseShipping },
    { headers: jsonHeaders }
  );
};

export const updateShippingDetails = (orderNumber, address) =>
  request.put(
    `${baseUrl}/website/v2/orders/${orderNumber}/shipping`,
    { address },
    { headers: jsonHeaders }
  );
/* eslint-enable camelcase */
