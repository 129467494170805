import {
  ADD_TO_CART_PENDING,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  REMOVE_CART_ITEM_PENDING,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  EDIT_CART_ITEM_PENDING,
  EDIT_CART_ITEM_SUCCESS,
  EDIT_CART_ITEM_FAILURE,
  GET_CART_PENDING,
  GET_CART_SUCCESS,
  GET_CART_FAILURE,
  GET_CART_EMPTY,
  GET_ESTIMATED_CART_SUCCESS,
  ADD_PROMO_CODE_SUCCESS,
  ADD_PROMO_CODE_FAILURE,
  REMOVE_PROMO_CODE_SUCCESS,
  REMOVE_PROMO_CODE_FAILURE,
  GET_SHIPPING_METHODS_PENDING,
  GET_SHIPPING_METHODS_SUCCESS,
  GET_SHIPPING_METHODS_FAILURE,
  UPDATE_SHIPPING_METHODS_SUCCESS,
  UPDATE_SHIPPING_METHODS_FAILURE,
  GET_PAYMENT_METHODS_PENDING,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_FAILURE,
} from "../actions/cart";
import isEqual from "lodash/isEqual";

const helpers = {
  filter: (list, id, isQueue = false) =>
    list.filter((listItem) =>
      isQueue ? listItem.item.id !== id : listItem.id !== id
    ),
  find: (list, id, isQueue = false) =>
    list.find((listItem) =>
      isQueue ? listItem.item.id === id : listItem.id === id
    ),
  replace: (list, item) =>
    list.map((listItem) => (item.id === listItem.id ? item : listItem)),
  sort: (list) =>
    list
      .concat()
      .sort((a, b) => a.id.toString().localeCompare(b.id.toString())),
};

const initialState = {
  request: { queue: [] },
  response: { pending: true },
  items: [],
  orderDetails: { isLoading: true },
  shippingMethods: { items: [], isLoading: true },
  paymentMethods: {
    items: [],
    settings: {},
    isLoading: true,
  },
};

export const cart = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART_PENDING: {
      if (!action.payload.item && !action.payload.items) {
        return state;
      }

      const items = action.payload.items || [action.payload.item];
      const queueItems = items.map((item) => ({
        action: "add",
        item: item,
      }));

      return {
        ...state,
        request: {
          ...state.request,
          queue: state.request.queue.concat(queueItems),
        },
        response: {
          pending: true,
        },
      };
    }
    case ADD_TO_CART_SUCCESS: {
      const items = action.payload.items || [action.payload.item];
      const queueItems = items.map((item) => ({
        action: "add",
        item: item,
      }));

      return {
        ...state,
        request: {
          ...state.request,
          queue: state.request.queue.reduce((result, item) => {
            if (!queueItems.find((queueItem) => isEqual(queueItem, item))) {
              return result.concat(item);
            }
            return result;
          }, []),
        },
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...action.response.body.data.order,
      };
    }
    case ADD_TO_CART_FAILURE: {
      const items = action.payload.items || [action.payload.item];
      const queueItems = items.map((item) => ({
        action: "add",
        item: item,
      }));

      return {
        ...state,
        request: {
          ...state.request,
          queue: state.request.queue.reduce((result, item) => {
            if (queueItems.find((queueItem) => !isEqual(queueItem, item))) {
              return result.concat(item);
            }
            return result;
          }, []),
        },
        response: {
          ...action.response,
          // Because we use the cart store for multiple request that have some relation to cart
          // we are unsure whether or not the error returned in the response is specifically to do with cart.
          isCartError: true,
        },
      };
    }

    case REMOVE_CART_ITEM_PENDING: {
      const item = helpers.find(state.items, action.itemId);
      if (!item) {
        return state;
      }

      const queueItem = {
        action: "remove",
        item,
      };

      return {
        ...state,
        request: {
          ...state.request,
          queue: state.request.queue.concat(queueItem),
        },
        response: {
          pending: true,
        },
        items: helpers.filter(state.items, action.itemId),
      };
    }
    case REMOVE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        request: {
          ...state.request,
          queue: helpers.filter(state.request.queue, action.itemId, true),
        },
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...action.response.body.data.order,
      };
    }
    case REMOVE_CART_ITEM_FAILURE: {
      const queueItem = helpers.find(state.request.queue, action.itemId, true);
      if (!queueItem) {
        return state;
      }

      return {
        ...state,
        request: {
          ...state.request,
          queue: helpers.filter(state.request.queue, action.itemId, true),
        },
        response: {
          ...action.response,
          // Because we use the cart store for multiple request that have some relation to cart
          // we are unsure whether or not the error returned in the response is specifically to do with cart.
          isCartError: true,
        },
        items: helpers.sort(state.items.concat(queueItem.item)),
      };
    }

    case EDIT_CART_ITEM_PENDING: {
      const ogItem = helpers.find(state.items, action.item.id);
      if (!ogItem) {
        return state;
      }

      const queueItem = {
        action: "edit",
        item: {
          id: action.item.id,
          before: ogItem,
        },
      };

      return {
        ...state,
        request: {
          ...state.request,
          queue: state.request.queue.concat(queueItem),
        },
        response: {
          pending: true,
        },
      };
    }
    case EDIT_CART_ITEM_SUCCESS: {
      return {
        ...state,
        request: {
          ...state.request,
          queue: helpers.filter(state.request.queue, action.itemId, true),
        },
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...action.response.body.data.order,
      };
    }
    case EDIT_CART_ITEM_FAILURE: {
      const queueItem = helpers.find(state.request.queue, action.itemId, true);
      if (!queueItem) {
        return state;
      }

      return {
        ...state,
        request: {
          ...state.request,
          queue: helpers.filter(state.request.queue, action.itemId, true),
        },
        response: {
          ...action.response,
          // Because we use the cart store for multiple request that have some relation to cart
          // we are unsure whether or not the error returned in the response is specifically to do with cart.
          isCartError: true,
        },
        items: helpers.replace(state.items, queueItem.item.before),
      };
    }

    case GET_CART_PENDING: {
      return {
        ...state,
        request: {
          ...state.request,
          guestToken: action.guestToken,
        },
        response: {
          pending: true,
        },
      };
    }
    case GET_CART_SUCCESS: {
      let data = action.response.body.data.order;
      return {
        ...state,
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...data,
      };
    }
    case GET_CART_FAILURE: {
      return {
        ...state,
        response: {
          ...action.response,
          // Because we use the cart store for multiple request that have some relation to cart
          // we are unsure whether or not the error returned in the response is specifically to do with cart.
          isCartError: true,
        },
      };
    }
    case GET_CART_EMPTY: {
      return {
        ...state,
        response: {
          ok: true,
          pending: false,
        },
        request: {
          ...state.request,
          guestToken: null,
        },
      };
    }

    case GET_ESTIMATED_CART_SUCCESS: {
      const data = action.response.body.cart;

      return {
        ...state,
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...data,
      };
    }

    case ADD_PROMO_CODE_SUCCESS: {
      let data = action.response.body.data.order;
      return {
        ...state,
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...data,
      };
    }
    case ADD_PROMO_CODE_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }

    case REMOVE_PROMO_CODE_SUCCESS: {
      let data = action.response.body.data.order;
      data.promoCode = null; // This is because the new api compacts the object before sending it, thus removing all null values
      return {
        ...state,
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        ...data,
      };
    }
    case REMOVE_PROMO_CODE_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }

    case GET_SHIPPING_METHODS_PENDING: {
      return {
        ...state,
        orderDetails: {
          isLoading: true,
        },
        shippingMethods: {
          isLoading: true,
        },
      };
    }
    case GET_SHIPPING_METHODS_SUCCESS: {
      return {
        ...state,
        orderDetails: {
          ...action.response.checkout,
          shipment: {
            number: action.response.shipment.number,
          },
          isLoading: false,
        },
        shippingMethods: {
          items: action.response.shipment.shipping_rates,
          isLoading: false,
        },
      };
    }
    case GET_SHIPPING_METHODS_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }

    case UPDATE_SHIPPING_METHODS_SUCCESS: {
      return {
        ...state,
      };
    }
    case UPDATE_SHIPPING_METHODS_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }

    case GET_PAYMENT_METHODS_PENDING: {
      return {
        ...state,
        orderDetails: {
          isLoading: true,
        },
        paymentMethods: {
          items: [],
          settings: {},
          isLoading: true,
        },
      };
    }
    case GET_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        orderDetails: {
          ...action.response.checkout,
          isLoading: false,
        },
        paymentMethods: {
          items: action.response.checkoutMethods,
          settings: action.response.checkoutMethodsSettings,
          isLoading: false,
        },
      };
    }
    case GET_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }

    default:
      return state;
  }
};
