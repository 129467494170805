const PropTypes = require("prop-types");

class CountryModel {
  constructor(
    prefix,
    iso,
    locale,
    contentLocales,
    wordpressLocale,
    currency,
    name,
    isDefault
  ) {
    this.prefix = prefix;
    this.iso = iso;
    this.locale = locale;
    this.currency = currency;
    this.name = name;
    this.contentLocales = contentLocales;
    this.wordpressLocale = wordpressLocale;
    this.isDefault = isDefault;
  }
}

CountryModel.propTypes = PropTypes.shape({
  prefix: PropTypes.string.isRequired,
  iso: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  contentLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  wordpressLocale: PropTypes.string,
  isDefault: PropTypes.bool,
});

module.exports = CountryModel;
