import React from "react";
import PropTypes from "prop-types";

const Links = ({ items }) => {
  return (
    <ul>
      {items.map(({ url, title }, idx) => (
        <li key={idx}>
          <a href={url}>{title}</a>
        </li>
      ))}
    </ul>
  );
};

Links.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
};

export default Links;
