import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "wonderbly-components/lib/Icon";
import NavCountryOption from "./NavCountryOption";
import "./NavCountryOptions.scss";

const NavCountryOptions = (props) => (
  <div
    className={cx("NavCountryOptions", {
      "NavCountryOptions--open": props.open,
      [`NavCountryOptions--${props.for}`]: props.for,
    })}
  >
    <div className="NavCountryOptions__background" onClick={props.onClose} />
    <div className="NavCountryOptions__inner">
      <button
        type="button"
        className="NavCountryOptions__close"
        onClick={props.onClose}
      >
        <Icon icon="delete" />
      </button>
      <h3>{props.title}</h3>
      <div className="NavCountryOptions__wrap">
        {props.options
          ? [
              props.options.map((option) => (
                <NavCountryOption
                  key={`${option.iso}-${option.locale}`}
                  value={option}
                  isActive={
                    `${option.iso}-${option.locale}` ===
                    `${props.currentValue.iso}-${props.currentValue.locale}`
                  }
                />
              )),
              props.changeCountry.name && (
                <NavCountryOption
                  key="change-country"
                  value={props.changeCountry}
                />
              ),
            ]
          : "No options!"}
      </div>
    </div>
  </div>
);

NavCountryOptions.propTypes = {
  changeCountry: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentValue: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  for: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default NavCountryOptions;
