import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";

import "./CharacterCard.scss";

const CharacterCard = (props) => (
  <div className="CharacterCard">
    {props.character.image && (
      <img
        className="CharacterCard__avatar"
        src={props.character.image.url}
        alt={props.character.image.alt}
      />
    )}
    <h5 className="CharacterCard__name">{props.character.name}</h5>
    {props.cta?.handleEdit && (
      <Button
        fullWidth
        className="CharacterCard__cta"
        onClick={props.cta.handleEdit}
      >
        {props.cta.text}
      </Button>
    )}
  </div>
);

CharacterCard.propTypes = {
  character: PropTypes.shape({
    name: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
  cta: PropTypes.shape({
    handleEdit: PropTypes.func,
    text: PropTypes.string,
  }),
};

export default CharacterCard;
