import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import InterestOption from "./InterestOption";

const enhance = compose(
  withHandlers({
    onClick: (props) => () => {
      props.onFormikSubmit({
        [props.optionName]: props.id,
      });
    },
  })
);

export default enhance(InterestOption);
