import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import set from "lodash/set";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import reduce from "lodash/reduce";
import isObjectLike from "lodash/isObjectLike";
import CompleteTick from "wonderbly-components/lib/CompleteTick";
import ActiveItem from "./ActiveItem";
import Controls from "./Controls";

import { replaceTextWithValues } from "src/common/utils/text-replace";
import { validateCreationForm } from "../helpers";

import "./MultiLevelForm.scss";

const checkIfDrawerComplete = (items, queryParams) => {
  let isCompleted = true;

  for (const item of items) {
    if (!isCompleted) {
      break;
    }

    // check if the field name exists in the customisation, if so then set isComplete to true
    // do NOT check against field.value as this could be the default Prismic value
    isCompleted = item.form.body.every((field) => {
      return !!get(queryParams, field.name, false);
    });
  }

  return isCompleted;
};

const MultiLevelForm = ({
  activeDrawer,
  onToggleDrawer,
  onFormikSubmit,
  ...props
}) => {
  const drawer = props.drawer;
  const productCustomisation = cloneDeep(props.productCustomisation);

  const multiFormRef = useRef();
  const activeItemRef = useRef();

  const [activeItem, setActiveItem] = useState(null);
  const displayActiveItem = activeItem !== null;
  const activeItemIndex = drawer.items.findIndex((item) =>
    isEqual(item, activeItem)
  );

  const isDrawerCompleted = checkIfDrawerComplete(
    drawer.items,
    props.queryParams
  );

  const updateActiveItem = (itemIndex) => () => {
    const item = drawer.items[itemIndex];
    setActiveItem(item);
  };

  const nextActiveItem = () => {
    const maxItemsIndex = drawer.items.length - 1;

    if (activeItemIndex === maxItemsIndex) {
      onToggleDrawer();
      return;
    }

    const itemIndex = Math.min(activeItemIndex + 1, maxItemsIndex);
    updateActiveItem(itemIndex)();
  };

  const buildErrors = (errors) => {
    return reduce(
      errors,
      (acc, error, key) => {
        if (isObjectLike(error)) {
          set(acc, key, buildErrors(error));
          return acc;
        }

        set(acc, key, {
          type: "customisation-error",
          message: error,
        });

        return acc;
      },
      {}
    );
  };

  const submitValues = async (values) => {
    const omittedValues = omitBy(
      values,
      (value) => isNil(value) || !value || isEmpty(value)
    );
    // Formik requires the errors to be resolved.
    const errors = await validateCreationForm(omittedValues, props);
    if (errors) {
      throw buildErrors(errors);
    }
    await onFormikSubmit(omittedValues, activeDrawer);
  };

  const handleFormSubmit = useCallback(async (values, formMethods) => {
    try {
      await submitValues(values);
      nextActiveItem();
    } catch (err) {
      formMethods.setErrors(err);
      return;
    }
  });

  const handleSubmitClick = useCallback((e) => {
    activeItemRef.current.handleSubmit(handleFormSubmit)(e);
  });

  const handleBackClick = useCallback((e) => {
    activeItemRef.current.handleSubmit(async (values, formMethods) => {
      let hasEmptyValue = false;

      for (const field of activeItem.form.body) {
        if (hasEmptyValue) {
          break;
        }
        const value = get(values, field.name);
        hasEmptyValue = !value;
      }

      if (!hasEmptyValue) {
        try {
          await submitValues(values);
        } catch (err) {
          formMethods.setErrors(err);
          return;
        }
      }

      setActiveItem(null);
    })(e);
  });

  const handleCloseDrawerClick = useCallback(() => {
    onToggleDrawer();
  });

  useEffect(() => {
    // THIS IS NOT SOMETHING WE SHOULD BE DOING - IF WE NEED TO DO
    // THIS FOR MORE DRAWERS WE SHOULD REWRITE THE DRAWER COMPONENT!!!
    // THIS IS SHIT - Edwin Joseph, 11/12/2020
    if (multiFormRef.current) {
      const parent = multiFormRef.current.closest(".canvas-drawer-content");
      if (parent) {
        const title = parent.querySelector(".canvas-drawer-title");
        title.style.display = displayActiveItem ? "none" : "block";
      }
    }
  }, [displayActiveItem]);

  return (
    <div className="MultiLevelForm" ref={multiFormRef}>
      {displayActiveItem && (
        <ActiveItem
          content={activeItem.form.content}
          fields={activeItem.form.body}
          image={activeItem.image}
          customisations={productCustomisation}
          onHandleSubmit={handleFormSubmit}
          ref={activeItemRef}
        />
      )}
      {!displayActiveItem && (
        <div className="MultiLevelForm__list">
          {drawer.items.map((item, idx) => {
            let isComplete = true;
            let translatedCustomisations = productCustomisation;

            // check if the field name exists in the customisation, if so then set isComplete to true
            // do NOT check against field.value as the this could be the default Prismic value
            for (const field of item.form.body) {
              isComplete = !!get(props.queryParams, field.name, false);

              if (!isComplete) {
                break;
              }

              if (field.type.includes("radio")) {
                const option = field.options.find(
                  ({ value }) => value === field.value
                );
                set(
                  translatedCustomisations,
                  field.name,
                  option.label || option.title || field.value
                );
                continue;
              }

              set(translatedCustomisations, field.name, field.value);
            }

            const description = isComplete
              ? replaceTextWithValues(
                  item.completeDescription,
                  translatedCustomisations
                )
              : item.description;

            return (
              <div
                key={idx}
                onClick={updateActiveItem(idx)}
                className="MultiLevelForm__list__item"
              >
                <h4 className="MultiLevelForm__list__item__title text-mega">
                  {replaceTextWithValues(item.title, translatedCustomisations)}
                </h4>
                <div className="MultiLevelForm__list__item__content">
                  <CompleteTick ticked={isComplete} />
                  <p>{description}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <Controls
        previewCta={drawer.cta}
        submitOutlined={
          !isDrawerCompleted && activeItemIndex < drawer.items.length - 1
        }
        submitCta={
          isDrawerCompleted || !activeItem
            ? drawer.backToMainListCta
            : activeItem.form.submitCta
        }
        backLinkCta={drawer.backToMainListCta}
        displayBackLink={!isDrawerCompleted}
        displayPreviewButton={!displayActiveItem}
        onHandleLinkClick={handleBackClick}
        onHandleSubmitClick={
          isDrawerCompleted ? handleBackClick : handleSubmitClick
        }
        onHandlePreviewClick={handleCloseDrawerClick}
      />
    </div>
  );
};

MultiLevelForm.propTypes = {
  activeDrawer: PropTypes.string.isRequired,
  productCustomisation: PropTypes.object.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    backToMainListCta: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        completeDescription: PropTypes.string.isRequired,
        form: PropTypes.shape({
          body: PropTypes.array.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }).isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
  onFormikSubmit: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired,
};

export default MultiLevelForm;
