import { request } from "src/common/rest/index";
import logger from "src/logger";

const get = (sku, locale) => {
  if (!sku || !locale) {
    logger.error("Missing SKU or Locale when trying to fetch Prismic product.");
    // erroring here broke lots of things downstream - e.g. the whole cart.  By resolving
    // the promise you see some of the cart items - any that error will show fallback data
    return Promise.resolve({});
  }
  return request.get(
    `/website-api/v1/prismic/product/${sku}/${locale.toLowerCase()}`,
    null,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

const getByMultipleLocales = async (productSku, contentLocales) => {
  const productList = await Promise.all(
    contentLocales.map(async (locale) => {
      try {
        const { body } = await get(productSku, locale);
        return body;
      } catch (error) {
        return null;
      }
    })
  );

  return productList.find((product) => !!product);
};

export default {
  get,
  getByMultipleLocales,
};
