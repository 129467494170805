import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import NavItem from "./NavItem";
import "./MainNavigation.scss";

const MainNavigation = (props) => {
  return (
    <nav
      className={cx({
        "main-navigation--checkout": props.isCheckout,
        "main-navigation": !props.isCheckout,
      })}
    >
      <ul>
        {props.navigation.map((item) => (
          <NavItem key={item.linkText} item={item} passedDown={props} />
        ))}
      </ul>
    </nav>
  );
};

MainNavigation.propTypes = {
  country: PropTypes.object.isRequired,
  navigation: PropTypes.array.isRequired,
  onTrackLink: PropTypes.func.isRequired,
  isCheckout: PropTypes.bool,
};

export default MainNavigation;
