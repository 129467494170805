import compose from "recompose/compose";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";

export default compose(
  withState("phototypeVisible", "updatePhototypeVisibility", true),
  withHandlers({
    onHandleChange: (props) => (event) => {
      if (event.target.name === "gender") {
        if (props.gender === event.target.value) {
          props.updatePhototypeVisibility(!props.phototypeVisible);
        } else {
          props.updatePhototypeVisibility(true);
        }
      }
      props.onHandleChange(event);
    },
  })
);
