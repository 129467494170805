import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import withProps from "recompose/withProps";
import { withModel } from "../../data-providers/model";
import formatPrismicData from "../../hocs/formatPrismicData";
import { localiseUrl } from "src/common/utils/format-localised-url";
import Upsell from "./Upsell";

const enhance = compose(
  formatPrismicData,
  withModel,
  withProps((props) => {
    const navCart = props.model.prismicSiteNavigationModel.cart;
    return {
      navCart: {
        ...navCart,
        upsellCta: navCart.upsellCta,
        upsellCtaLink: navCart.upsellCtaLink || "/personalized-products",
      },
    };
  }),
  mapProps(({ model, ...props }) => {
    return {
      ...props,
      description: props.description || props.navCart.upsellDescription,
      cta: props.cta || props.navCart.upsellCta,
      ctaLink: localiseUrl(
        model.countryModel,
        props.ctaLink || props.navCart.upsellCtaLink
      ),
    };
  })
);

export default enhance(Upsell);
