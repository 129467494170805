const camelCase = require("lodash/camelCase");
const ErrorBase = require("./ErrorBase");

const getErrorType = (errorType) => {
  switch (true) {
    case errorType.includes("pattern"):
    case errorType.endsWith("only"):
      return "invalid";
    case errorType.endsWith("empty"):
      return "required";
    default:
      return errorType.split(".").pop();
  }
};

class InvalidCustomisationError extends ErrorBase {
  constructor(err) {
    super("Error: customisations failed validation");
    this.status = 400;
    this.details = {
      errors: err.details.map(({ context: { value, valids }, ...error }) => {
        const customisationKey = error.path.map(camelCase).join(".");
        const errorType = getErrorType(error.type);
        const key = `customisation.${customisationKey}`;
        return {
          code: `${key}.${errorType}`,
          message: error.message,
          context: {
            key,
            value,
            valids,
          },
        };
      }),
    };
  }
}

module.exports = InvalidCustomisationError;
