import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import Arrow from "wonderbly-components/lib/Arrow";

const Controls = ({
  previewCta,
  submitOutlined,
  submitCta,
  backLinkCta,
  onHandleSubmitClick,
  onHandleLinkClick,
  onHandlePreviewClick,
  displayBackLink,
  displayPreviewButton,
}) => {
  return (
    <div className="MultiLevelForm__controls">
      {!displayPreviewButton && (
        <Fragment>
          <Button onClick={onHandleSubmitClick}>{submitCta}</Button>
          {displayBackLink && (
            <Button
              className="MultiLevelForm__active-form__back"
              onClick={onHandleLinkClick}
              unstyled
            >
              <Arrow inline direction="left" />
              {backLinkCta}
            </Button>
          )}
        </Fragment>
      )}
      {displayPreviewButton && (
        <Button onClick={onHandlePreviewClick}>{previewCta}</Button>
      )}
    </div>
  );
};

Controls.propTypes = {
  previewCta: PropTypes.string.isRequired,
  submitOutlined: PropTypes.bool.isRequired,
  submitCta: PropTypes.string.isRequired,
  backLinkCta: PropTypes.string.isRequired,
  onHandleSubmitClick: PropTypes.func.isRequired,
  onHandleLinkClick: PropTypes.func.isRequired,
  onHandlePreviewClick: PropTypes.func.isRequired,
  displayBackLink: PropTypes.bool.isRequired,
  displayPreviewButton: PropTypes.bool.isRequired,
};

export default Controls;
