import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useLocation } from "react-router-dom";

import { unlocaliseUrlII } from "src/common/utils/format-localised-url";
import "./ActiveLink.scss";

const ActiveLink = ({
  url,
  className,
  target,
  rel,
  onActiveLink,
  children,
  activeClassName = "link--active",
}) => {
  const location = useLocation();

  const checkIfActive = (currentUrl, targetUrl) => {
    const currentPath = unlocaliseUrlII(currentUrl);
    const targetPath = unlocaliseUrlII(targetUrl);

    // Split the path by `/` to check if it is a
    // sub route, i.e. /account/orders or /personalised-product/lost-my-name-book will return
    // 3 items as there is a leading slash
    const isTargetSubRoute = targetPath.split("/").length > 2;

    if (isTargetSubRoute) {
      return currentPath.startsWith(targetUrl);
    }

    return currentPath === targetUrl;
  };

  const isActive = checkIfActive(location.pathname, url);

  useEffect(() => {
    if (isActive && onActiveLink) {
      onActiveLink();
    }
  }, [isActive]);

  return (
    <a
      href={url}
      className={cx("link", {
        [className]: className,
        [activeClassName]: isActive,
      })}
      target={target}
      rel={rel}
    >
      {children}
    </a>
  );
};

ActiveLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  target: PropTypes.string,
  rel: PropTypes.string,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  onActiveLink: PropTypes.func,
};

export default ActiveLink;
