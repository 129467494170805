import createBreakpoint from "react-use/lib/createBreakpoint";

const breakpoints = {
  mobile: 479,
  fablet: 777,
  tablet: 1039,
  desktop: 1199,
};

const useBreakpoint = createBreakpoint(breakpoints);

const useMediaQueries = (media) => {
  const breakpoint = useBreakpoint();

  if (media) {
    return media === breakpoint;
  }

  return breakpoint;
};

export default useMediaQueries;
