const find = require("lodash/find");
const isEmpty = require("lodash/isEmpty");
const get = require("lodash/get");
const { fallbackToEagleLocale } = require("../l10n/country");

const getAvailableProductLanguages = (product) => {
  if (!product) {
    return [];
  }

  return product.variants
    .map((variant) => variant.options.locale)
    .filter((value, index, array) => array.indexOf(value) === index);
};

const findProductById = (products, productId) => {
  return find(products, (product) => product.id === productId) || null;
};

/**
 * finds all matching variants based on the filter passed.
 * @param {Object[]} variants a list of product variants from Eagle
 * @param {Object} filter can include any of the keys in variants[].options, and the variants[].formatId field as well.
 * @param {number} [limit] only return a limited amount of filtered variants, Default: no limit
 * @returns {Array} filtered list of product variants from Eagle
 */
const findProductVariants = (variants, filter, countryModel, limit) => {
  if (!variants || isEmpty(variants) || !filter || isEmpty(filter)) {
    return [];
  }
  // Default limit to length of variants if argument was undefined
  limit = limit || variants.length;

  // Could come from product customisations where it is snake_case OR from eagle where it is camelCase
  const formatId = filter.format_identifier || filter.formatId;
  const filterKeys = Object.keys(filter);
  const filteredVariants = [];
  let currentCount = 0;

  for (let i = 0; i < variants.length; i++) {
    // If the limit that the caller has set has been reached then break out of loop
    if (currentCount >= limit) {
      break;
    }

    const variant = variants[i];
    // If the format that the caller is looking for is not matching the format of the variant, then
    // there is no point in going through the customisations, we can straight
    // away ignore this variant and skip to the next one.
    // We have added a variant.formatIdentifier in Eagle
    // It contains the size and cover (e.g. a4-landscape:softback)
    // But for backward compatibility, we want to check the formatId
    // (defined here by format_identifier)
    // against both Eagle variant.formatIdentifier && variant.formatId
    // TODO: When variant.formatId gets deprecated in Eagle, we will need to remoeve it here.
    if (
      formatId &&
      ![variant.formatIdentifier, variant.formatId].includes(formatId)
    ) {
      continue;
    }

    const isValid = filterKeys.every((filterKey) => {
      let filterValue = filter[filterKey];

      if (filterKey === "locale" && countryModel) {
        const filterLocale = filter[filterKey];
        filterValue = fallbackToEagleLocale(filterLocale, countryModel);
      }

      return (
        !variant.options[filterKey] ||
        variant.options[filterKey] === filterValue
      );
    });

    if (isValid) {
      filteredVariants.push(variant);
      currentCount++;
    }
  }

  return filteredVariants;
};

const findProductVariant = (variants, filters, countryModel) => {
  return findProductVariants(variants, filters, countryModel, 1)[0] || {};
};

const findLowestProductPrice = (products, productId) => {
  const product = findProductById(products, productId);
  return product && product.lowestPrice;
};
const findVariantPriceForProduct = (product, options, countryModel) => {
  const variant = findProductVariant(product.variants, options, countryModel);
  return (
    get(variant, "price.formatted", null) ||
    get(product.lowestPrice, "price.formatted", "")
  );
};

module.exports = {
  findProductById,
  findLowestProductPrice,
  findVariantPriceForProduct,
  findProductVariants,
  getAvailableProductLanguages,
  findProductVariant,
};
