import React from "react";
import PropTypes from "prop-types";
import InlineFormField from "wonderbly-components/lib/InlineFormField";
import Button from "wonderbly-components/lib/Button";
import ButtonRadio from "wonderbly-components/lib/ButtonRadio";
import { Row, Col } from "src/client/js/view/components/Grid";
import Tooltip from "wonderbly-components/lib/Tooltip";

import "./Companion.scss";

const Companion = (props) => (
  <form className="companion-form" onSubmit={props.handleSubmit}>
    <Row
      className="companion-form-row"
      colsToRowsOn="fablet-down"
      rowsSpacedOn="base"
    >
      <Col className="companion-form-field companion-form-field--name">
        <div className="companion-form-field-container">
          <Tooltip
            className="error-tooltip"
            visible={props.errors && !!props.errors.gifter_name}
            type="alert"
          >
            {props.errors && props.errors.gifter_name}
          </Tooltip>
          <InlineFormField
            id="gifterName"
            fieldType="input"
            name="gifter_name"
            label={props.drawer.nameLabel}
            onChange={props.handleChange}
            value={props.values.gifter_name}
            placeholder={props.drawer.defaultName}
          />
        </div>
      </Col>
      {props.hasGender && (
        <Col className="companion-form-field companion-form-field--gender">
          <div className="companion-form-field-container">
            <Tooltip
              className="error-tooltip companion-form-gender-tooltip"
              visible={props.errors && !!props.errors.gifter_gender}
              type="alert"
            >
              {props.errors && props.errors.gifter_gender}
            </Tooltip>
            <Row>
              <Col>
                <ButtonRadio
                  id="gifterGender-boy"
                  value="boy"
                  name="gifter_gender"
                  onChange={props.handleChange}
                  checked={props.values.gifter_gender === "boy"}
                >
                  {props.drawer.genderLabelBoy}
                </ButtonRadio>
              </Col>
              <Col>
                <ButtonRadio
                  id="gifterGender-girl"
                  value="girl"
                  name="gifter_gender"
                  onChange={props.handleChange}
                  checked={props.values.gifter_gender === "girl"}
                >
                  {props.drawer.genderLabelGirl}
                </ButtonRadio>
              </Col>
            </Row>
          </div>
        </Col>
      )}
      <Col className="companion-form-cta">
        <Button
          type="submit"
          disabled={
            !props.values.gifter_name ||
            (props.hasGender && !props.values.gifter_gender) ||
            props.isSubmitting
          }
          fullWidth
        >
          {props.drawer.cta}
        </Button>
      </Col>
    </Row>
  </form>
);

Companion.propTypes = {
  hasGender: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  drawer: PropTypes.shape({
    nameLabel: PropTypes.string.isRequired,
    genderLabelBoy: PropTypes.string.isRequired,
    genderLabelGirl: PropTypes.string.isRequired,
    defaultName: PropTypes.string,
    cta: PropTypes.string.isRequired,
  }),
  values: PropTypes.shape({
    gifter_name: PropTypes.string,
    gifter_gender: PropTypes.oneOf(["girl", "boy"]),
  }),
  errors: PropTypes.shape({
    gifter_name: PropTypes.string,
    gifter_gender: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Companion;
