import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import { withFormik } from "formik";
import Birthday from "./Birthday";
import withValidationData from "../../../hocs/withValidationData";
import { validateCreationForm, createOnFieldChangeHandler } from "../helpers";

const enhance = compose(
  withValidationData,
  withFormik({
    mapPropsToValues: (props) => ({
      base: "",
      day: props.queryParams.day || "",
      month: props.queryParams.month || "",
      year: props.queryParams.year || "",
    }),
    validate: (values, props) => validateCreationForm(values, props),
    validateOnChange: false,
    handleSubmit: (values, formikBag) => {
      const props = formikBag.props;
      props.onFormikSubmit(values);
    },
  }),
  withHandlers(() => {
    const handleChange = createOnFieldChangeHandler();
    return {
      handleChange,
    };
  })
);

export default enhance(Birthday);
