export const buildAgeOptions = (range, noAgeLabel) => {
  let [start, end] = range.split("-");
  start = parseInt(start, 10);
  // Adding 1 to the end value as we would lose the original value if we didn't
  // e.g. Without extra 1: 1-9 -> 1..8
  // e.g. With extra 1: 1-9+1 -> 1..9
  end = parseInt(end, 10) + 1;

  const length = end - start;

  const options = Array.from({ length }, (_, i) => {
    const option = start + i;
    return {
      label: option,
      value: option,
    };
  });

  options.unshift({
    label: noAgeLabel,
    value: "",
  });

  return options;
};
