import React from "react";
import PropTypes from "prop-types";
import Name from "../Name";
import Locale from "../Locale";
import Hero from "../Hero";
import { transformLocaleOptions } from "../helpers";
import Age from "../Age";
import CustomSelect from "../CustomSelect";

export const AdventurerForm = (props) => {
  switch (props.field.sliceType) {
    case "custom_select": {
      return (
        <CustomSelect
          field={props.field}
          setFieldValue={props.setFieldValue}
          onHandleChange={props.onHandleChange}
          error={props.errors && props.errors.selection_value}
        />
      );
    }
    case "name":
    case "surname": {
      return (
        <Name
          id={props.field.sliceType}
          label={props.field.primary.title}
          onHandleChange={props.onHandleChange}
          value={props.values[props.field.sliceType]}
          error={props.errors && props.errors[props.field.sliceType]}
          tabletDown={props.tabletDown}
        />
      );
    }
    case "age": {
      return (
        <Age
          id={props.field.sliceType}
          label={props.field.primary.title}
          onHandleChange={props.onHandleChange}
          age={props.values[props.field.sliceType]}
          error={props.errors && props.errors[props.field.sliceType]}
          tabletDown={props.tabletDown}
          noAge={props.field.primary.noAge}
          range={props.field.primary.range}
        />
      );
    }
    case "locale": {
      if (!!props.localeOverride) {
        return null;
      }
      return (
        <Locale
          id={props.field.sliceType}
          label={props.field.primary.title}
          options={transformLocaleOptions(props.field.items, props.product)}
          onHandleChange={props.onHandleChange}
          locale={props.values.locale}
          error={props.errors && props.errors[props.field.sliceType]}
          tabletDown={props.tabletDown}
        />
      );
    }
    case "adventurer": {
      const product = props.product.variants.find(
        (v) => v.options.locale === props.values.locale
      );
      const isPhototypeEnabled =
        !!product && product.options.phototypes.length > 1;
      return (
        <Hero
          id={props.field.sliceType}
          title={props.field.primary.title}
          items={props.field.items}
          onHandleChange={props.onHandleChange}
          gender={props.values.gender}
          phototype={props.values.phototype}
          isPhototypeEnabled={isPhototypeEnabled}
          errors={props.errors && props.errors[props.field.sliceType]}
          tabletDown={props.tabletDown}
          setFieldValue={props.setFieldValue}
          productId={props.product.id}
        />
      );
    }
  }
};

AdventurerForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired,
  product: PropTypes.object,
  localeOverride: PropTypes.bool,
  field: PropTypes.shape({
    sliceType: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    primary: PropTypes.shape({
      title: PropTypes.string.isRequired,
      noAge: PropTypes.string,
      range: PropTypes.string,
    }),
  }),
  values: PropTypes.shape({
    name: PropTypes.string,
    gender: PropTypes.string,
    phototype: PropTypes.string,
    age: PropTypes.string,
    locale: PropTypes.string,
  }).isRequired,
  tabletDown: PropTypes.bool,
  errors: PropTypes.object,
};

export default AdventurerForm;
