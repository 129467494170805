import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import Icon from "wonderbly-components/lib/Icon";

const Member = (props) => (
  <div className="family-members-field">
    <input
      id={`${props.name}-${props.index}`}
      value={props.name}
      onChange={props.onChange}
      type="text"
    />
    <Button onClick={props.onRemove} type="button">
      <Icon icon="delete" />
    </Button>
  </div>
);

Member.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Member;
