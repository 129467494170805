import useModel from "./use-model";
import { getCountryContent } from "../hocs/withCountry";

export default () => {
  const model = useModel();

  return {
    ...model.countryModel,
    ...getCountryContent({ model }),
  };
};
