import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import BearCard from "./BearCard";
import withProductCustomisation from "../../../../hocs/withProductCustomisation";
import { replaceCanvasText } from "../../../Canvas/helpers";

const enhance = compose(
  withProductCustomisation,
  withProps((props) =>
    props.bear.type === "littlest"
      ? { description: replaceCanvasText(props.bear.description, props) }
      : {}
  ),
  withHandlers({
    onAdd: (props) => () =>
      props.onBearChange("add", null, props.bear.type, ""),
    onChange: (props) => (e) =>
      props.onBearChange(
        "update",
        props.index,
        props.bear.type,
        e.target.value
      ),
    onRemove: (props) => () =>
      props.onBearChange("remove", props.index, props.bear.type),
  })
);

export default enhance(BearCard);
