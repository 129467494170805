import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./Loading.scss";

const Loading = (props) => (
  <div
    className={cx("loading", {
      "loading--dark": props.dark,
      "loading--relative": props.relative,
      "loading--absolute": props.absolute,
      "loading--fade": props.fade,
      "loading--xsmall": props.xsmall,
      "loading--small": props.small,
      [props.className]: props.className,
    })}
  >
    <span className="loading-indicator" />
  </div>
);

export const LoadComponent = ({
  isLoading,
  children,
  mounted = false,
  ...props
}) => {
  return (
    <Fragment>
      {isLoading && <Loading {...props} />}
      {(!isLoading || mounted) && (
        <div style={{ display: isLoading ? "none" : "block" }}>{children}</div>
      )}
    </Fragment>
  );
};

const sharedProps = {
  dark: PropTypes.bool,
  relative: PropTypes.bool,
  absolute: PropTypes.bool,
  xsmall: PropTypes.bool,
  small: PropTypes.bool,
  fade: PropTypes.bool,
  className: PropTypes.string,
};

Loading.propTypes = sharedProps;

LoadComponent.propTypes = {
  ...sharedProps,
  isLoading: PropTypes.bool.isRequired,
  mounted: PropTypes.bool,
};

export default Loading;
