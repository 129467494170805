import React from "react";
import useMediaQueries from "hooks/use-media-queries";
import Accordion, { AccordionItem } from "wonderbly-components/lib/Accordion";
import FooterLinks from "./FooterLinks";
import PaymentIcons from "../PaymentIcons";
import SocialNetworkSet from "./SocialNetworkSet";
import FooterSub from "./FooterSub";
import TrustpilotWidget from "../TrustpilotWidget";
import { useModel } from "src/client/js/view/hooks";
import logger from "src/logger";

import "./Footer.scss";

const Footer = () => {
  const mobile = useMediaQueries("mobile");
  const { prismicFooterModel } = useModel();

  if (!prismicFooterModel) {
    logger.error("No Prismic footer document was found.");
    return null;
  }

  return (
    <footer className="SiteFooter" data-cutout="top" data-cutout-style="wave">
      <div className="SiteFooter__container container-guttered">
        <div className="SiteFooter__links">
          {prismicFooterModel.columns.map((list, colIndex) => (
            <div key={colIndex} className="SiteFooter__column">
              {list.map((linkSet, linkSetIndex) => (
                <nav key={linkSetIndex} className="SiteFooter__list">
                  {mobile || linkSet.collapsedOnDesktop ? (
                    <Accordion invert closeOtherItems>
                      <AccordionItem
                        key={linkSet.title}
                        header={<h3>{linkSet.title}</h3>}
                      >
                        <FooterLinks links={linkSet} />
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    <>
                      <h3 className="SiteFooter__list-title">
                        {linkSet.title}
                      </h3>
                      <FooterLinks links={linkSet} />
                    </>
                  )}
                </nav>
              ))}
            </div>
          ))}
        </div>
        <div className="SiteFooter__widget-col">
          <TrustpilotWidget
            templateId="microstar"
            className="SiteFooter__trustpilot-micro-star"
            theme="dark"
            isComponent={false}
          />
          <PaymentIcons className="SiteFooter__payment-icons" />
          <SocialNetworkSet
            socialNetworks={prismicFooterModel.socialNetworks}
          />
        </div>
      </div>
      <FooterSub companyLinks={prismicFooterModel.companyLinks} />
    </footer>
  );
};

export default Footer;
