import qs from "qs";
import get from "lodash/get";
import logger from "../../../../logger";

import {
  addToCart as addToCartFacade,
  editCartItem as editCartItemFacade,
  removeCartItem as removeCartItemFacade,
  getCart as getEagleCart,
  applyPromoCode,
  removePromoCode,
} from "../../facades/eagle/cart";
import {
  getShippingMethods as getEagleShippingMethods,
  submitShippingMethods,
} from "src/common/facades/eagle/checkout";
import { getPaymentMethods as fetchPaymentMethods } from "src/common/facades/website/payments/methods";
import { getCookies } from "src/client/js/facades/eagle/helpers/cookies";
import {
  addToCartPending,
  addToCartSuccess,
  addToCartFailure,
  editCartItemPending,
  editCartItemSuccess,
  editCartItemFailure,
  removeCartItemPending,
  removeCartItemSuccess,
  removeCartItemFailure,
  getCartPending,
  getCartSuccess,
  getCartFailure,
  getCartEmpty,
  addPromoCodeSuccess,
  addPromoCodeFailure,
  removePromoCodeSuccess,
  removePromoCodeFailure,
  updateShippingMethodsSuccess,
  updateShippingMethodsFailure,
  getShippingMethodsSuccess,
  getShippingMethodsFailure,
  getShippingMethodsPending,
  getPaymentMethodsPending,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
} from "../actions/cart";
import { getEstimatedCountry } from "src/client/js/store/selectors/cart";
import { localiseUrl } from "src/common/utils/format-localised-url";
import cookieFactory from "../../cookieFactory";
import {
  addFlashMessageError,
  removeFlashMessage,
} from "../../view/components/FlashMessages/duck";
import { trackErrors } from "../../analytics/category/checkout";

export const guestTokenCookie = cookieFactory("guestToken", {
  path: "/",
  expires: 365,
});
export const itemAddedCookie = cookieFactory("item_added", { path: "/" }, true);
export const restrictedUserCookie = cookieFactory("restricted_user_id", {
  path: "/",
});

// Handle errors that aren't RestResponseErrors
const getError = (error) => {
  if (!error || !error.message || error.message.match("RestResponseError")) {
    return error;
  }
  return {
    ok: false,
    status: 500,
    reason: `${error.name} ${error.message}`,
  };
};

/**
 *
 * @param {function(*): *} fn
 */
const shippingMethodsFetch = (fn) => async (dispatch) => {
  await dispatch(getShippingMethodsPending());
  return await fn(dispatch);
};

/**
 *
 * @param {Error} error the original error that was cause by a resource
 * @param {function(*): *} retryFunction a function that gets called while retries is not 0
 * @param {Object} config
 * @param {function} config.sendErrorMessage the translations for all the errors provided by Prismic
 * @param {CountryModel} config.countryModel the current country model based on the website url's country prefix
 * @param {number} config.retries the number of retires to fetch the resource before actually throwing an error
 */
const handleShippingMethodsError =
  (
    error,
    retryFunction,
    { sendErrorMessage, countryModel, retries = 0 } = {}
  ) =>
  (dispatch) => {
    const errors = get(error, "details.errors") || get(error, "details") || [];
    const errorObject = errors[0] || {};
    let errorMessage = errorObject.message || errorObject.detail;

    switch (error.status) {
      case 400: {
        // returns 400 when there is no user data from shipping-details form (type: no_address)
        if (errorObject.code === "no_address") {
          errors.map((error) =>
            logger.warn(`Missing shipping details - ${error.message}`)
          );
          return (window.location = localiseUrl(
            countryModel,
            `/checkout-v2/address`
          ));
        }

        break;
      }
      case 404: {
        // returns 404 when there is no cart (type: no_cart)
        errors.map((error) => logger.warn(`No cart - ${error.message}`));
        return (window.location = localiseUrl(countryModel, `/cart`));
      }
    }

    // in case of server error, retry until unable to retry any more and show message to user
    if (retries > 0) {
      return dispatch(
        retryFunction({ countryModel, sendErrorMessage, retries: retries - 1 })
      );
    }

    logger.error("Request failed to fetch available shipping methods", error);

    sendErrorMessage((error) => error.http["500"] || errorMessage);

    return dispatch(getShippingMethodsFailure(error));
  };

export const getCart = (guestToken, currency) => (dispatch) => {
  dispatch(getCartPending(guestToken));
  return getEagleCart(guestToken, currency)
    .then((response) => dispatch(getCartSuccess(response)))
    .catch((error) => {
      switch (error.status) {
        case 400: // why would this happen?
          logger.error(error, "status 400 when fetching cart");
        case 404: // cart does not exist
          // TODO: to be removed when Eagle maintains all cookies.
          guestTokenCookie.remove();
          return dispatch(getCartEmpty());
      }
      return dispatch(getCartFailure(error));
    });
};

export const addToCart =
  (payload, countryModel, redirect = true, window = {}) =>
  (dispatch, getState) => {
    dispatch(addToCartPending(payload));
    const estimatedCountryIso = getEstimatedCountry(getState());
    return addToCartFacade(payload, {
      ...countryModel,
      iso: estimatedCountryIso || get(countryModel, "iso", ""),
    })
      .then((response) => {
        // this cookie is used by the data layer pixel to fire some really important tags and pixels. you should not
        // remove this unless you have a different solution for firing these tags.
        // the data-layer code, once run, will remove this cookie, so you should NOT depend on its existence for anything.
        itemAddedCookie.set();
        // TODO:
        //  when adding to cart for the first time, eagle creates a new cart in DB. for unknown reasons the semantics
        //  chosen for this new cart cookie is 'guestToken'. this token is actually attached to a cart, making it much less
        //  important from a security perspective - but because the semantics are so bad - the uninitiated might think that
        //  this is some kind of user token and attach PII to it.
        //  the big problem is that we send this token around quite a lot - for abandoned cart emails for instance - so we have
        //  to ensure that there's no PII attached and ideally change the semantics to `cart_token` and have Eagle set
        //  it instead of website.
        if (response.body.data.newGuestToken) {
          guestTokenCookie.set(response.body.data.newGuestToken);
        }
        if (redirect) {
          const itemIds = payload.items.map((item) => item.productId);
          const prodsAdded = `product-added=${itemIds.join(",")}`;
          window.location = localiseUrl(countryModel, `/cart?${prodsAdded}`);
        }
        return dispatch(addToCartSuccess(response, payload));
      })
      .catch((error) => {
        logger.error(error, "error when adding to cart");
        const errorMessage = `Error ${error.details?.errors?.[0].status}: ${error.details?.errors?.[0].code}`;
        dispatch(addFlashMessageError(errorMessage));
        return dispatch(addToCartFailure(getError(error), payload));
      });
  };

export const editCartItem =
  (item, countryModel, redirect = false, window = {}) =>
  (dispatch, getState) => {
    dispatch(editCartItemPending(item));
    const estimatedCountryIso = getEstimatedCountry(getState());
    return editCartItemFacade(item, {
      ...countryModel,
      iso: estimatedCountryIso || get(countryModel, "iso", ""),
    })
      .then((response) => {
        if (redirect) {
          window.location = localiseUrl(countryModel, "/cart");
        }
        return dispatch(editCartItemSuccess(response, item.id));
      })
      .catch((error) => {
        const errorMessage = `Error ${error.details?.errors?.[0].status}: ${error.details?.errors?.[0].code}`;
        dispatch(addFlashMessageError(errorMessage));
        return dispatch(editCartItemFailure(error, item.id));
      });
  };

export const removeCartItem =
  (itemId, countryModel) => (dispatch, getState) => {
    const estimatedCountryIso = getEstimatedCountry(getState());
    dispatch(removeCartItemPending(itemId));
    return removeCartItemFacade(itemId, {
      ...countryModel,
      iso: estimatedCountryIso || get(countryModel, "iso", ""),
    })
      .then((response) => dispatch(removeCartItemSuccess(response, itemId)))
      .catch((error) => {
        error = getError(error);
        return dispatch(removeCartItemFailure(error, itemId));
      });
  };

export const editAddOn =
  (item, countryModel, addOn) => (dispatch, getState) => {
    // there is a complexity in the eagle api where its PATCH '/website/v2/cart/items/{id}' endpoint
    // expects the request payload to have its components in the customisations blob. However, the
    // actual object model in eagle has the components outside of the customisations object, this
    // means when website queries the cart item the returned object has the components outside of
    // the customisations object. So, when an addon is edited we need to make sure all the components
    // are within the customisations blob.
    //
    // TODO: A more appropriate fix here is to change the Eagle endpoint to expect the components to
    // be outside of the customisations blob to match the line item model but because of its reach
    // and being in the midst of Q4 it's safer to make a quick change here and apply a thorough fix
    // in eagle and website post Q4

    // the latest instance of the item from eagle wont have components within the customisations, we
    // need to set this empty array here which will be the components used to patch the line item in
    // eagle
    item.customisations.components = [];

    // item.components are the most up to date components on the line item from eagle, we need to
    // determine whether the toggled addOn already exists on the line item and should be removed
    const idx = item.components.findIndex((c) => c.id === addOn.id);

    if (idx === -1) {
      // the component is being added to the line item and gets pushed into the components to patch
      item.customisations.components.push(addOn);
    } else {
      // the component is already included on the latest version of the line item from eagle, we need
      // to remove it from the components to patch
      item.components.splice(idx, 1);
    }

    // we include the components from the latest line item from eagle (omitting the addOn where toggled
    // off), these are the components which will be used to update the eagle line item components
    item.customisations.components.push(...item.components);

    dispatch(editCartItemPending(item));
    const estimatedCountryIso = getEstimatedCountry(getState());
    return editCartItemFacade(item, {
      ...countryModel,
      iso: estimatedCountryIso || get(countryModel, "iso", ""),
    })
      .then((response) => dispatch(editCartItemSuccess(response, item.id)))
      .catch((error) => dispatch(editCartItemFailure(error, item.id)));
  };

export const initializeCart = (options) => (dispatch) => {
  const query = qs.parse(options.query.replace(/^\?/, ""));
  const guestTokenFromQueryParam = query.guest_token || query.guestToken;
  if (guestTokenFromQueryParam) {
    // TODO:
    //  see cart.js#156 for more context about guestToken
    //  find a way to stop setting this client side. this is a pure Eagle concern and be should fully managed by eagle
    guestTokenCookie.set(guestTokenFromQueryParam);
  }
  const guestToken = guestTokenCookie.get();
  if (guestToken) {
    return dispatch(getCart(guestToken, options.currency));
  }
  const restrictedUserToken = restrictedUserCookie.get();
  if (restrictedUserToken) {
    return dispatch(getCart(null, options.currency));
  }
  return dispatch(getCartEmpty());
};

export const addPromoCode = (code, resetForm) => (dispatch) => {
  return applyPromoCode(code)
    .then((response) => {
      if (resetForm) {
        resetForm({ values: { code: "" } });
      }
      return dispatch(addPromoCodeSuccess(response));
    })
    .catch((error) => dispatch(addPromoCodeFailure(error)));
};

export const deletePromoCode = () => (dispatch) => {
  return removePromoCode()
    .then((response) => dispatch(removePromoCodeSuccess(response)))
    .catch((error) => dispatch(removePromoCodeFailure(error)));
};

export const updateShippingMethods =
  (shipmentNumber, selectedShippingRateId, currency, errorMessage) =>
  async (dispatch) => {
    try {
      await submitShippingMethods(
        { shipmentNumber, selectedShippingRateId },
        getCookies()
      );
      await dispatch(updateShippingMethodsSuccess());
      return await dispatch(getCart(null, currency));
    } catch (error) {
      trackErrors(
        `Update Shipping Methods - ${get(
          error,
          "details[0].message",
          error.description
        )}`
      );
      // Handling error when user tries to select shipping method, but the total price doesn't update, because of failed request.
      error.details.map((error) =>
        logger.error(`Unable to update shipping methods - ${error.message}`)
      );
      dispatch(addFlashMessageError(errorMessage || error.details[0].message));
      return dispatch(updateShippingMethodsFailure(error));
    }
  };

/**
 * getShippingMethods - get the current shipping details based on the current checkout information
 * @param {Object} config
 * @param {CountryModel} config.countryModel the current country model based on the website url's country prefix
 * @param {Object} config.sendErrorMessage the translations for all the errors provided by Prismic
 * @param {number} config.retries the number of retires to fetch the resource before actually throwing an error
 * @param {boolean} config.updateCart. But default this function updates the cart, this prop disable it.
 */
export const getShippingMethods = ({
  countryModel,
  sendErrorMessage,
  retries = 3,
  updateCart = true,
}) =>
  shippingMethodsFetch(async (dispatch) => {
    const cookies = getCookies();

    try {
      const { body: shippingMethods } = await getEagleShippingMethods(cookies);

      if (updateCart) {
        await dispatch(getCart(cookies.guestToken, countryModel.currency));
      }

      await dispatch(removeFlashMessage());
      return await dispatch(getShippingMethodsSuccess(shippingMethods));
    } catch (error) {
      return await dispatch(
        handleShippingMethodsError(error, getShippingMethods, {
          sendErrorMessage,
          countryModel,
          retries,
        })
      );
    }
  });

export const getPaymentMethods =
  (cookies, countryModel, errorTranslations = {}, retries = 3) =>
  (dispatch) => {
    dispatch(getPaymentMethodsPending());
    return fetchPaymentMethods(cookies)
      .then((response) => dispatch(getPaymentMethodsSuccess(response.body)))
      .catch((error) => {
        let errorMessage = get(error, "details[0]", {}).message;

        if (error.status === 404) {
          // returns 404 when there is no cart (type: no_cart)
          errorMessage = errorTranslations.noCart || errorMessage;
          dispatch(addFlashMessageError(errorMessage));
          error.details.map((error) =>
            logger.warn(`No cart - ${error.message}`)
          );
          return (window.location = localiseUrl(countryModel, `/cart`));
        }

        // in case of server error, retry until unable to retry any more and show message to user
        if (retries > 0) {
          return dispatch(
            getPaymentMethods(
              cookies,
              countryModel,
              errorTranslations,
              retries - 1
            )
          );
        }

        logger.error(
          "Request failed to fetch available payment methods",
          error
        );
        errorMessage = errorTranslations.serverError || errorMessage;
        dispatch(addFlashMessageError(errorMessage));
        return dispatch(getPaymentMethodsFailure(error));
      });
  };

export const addPromoCodeThenUpdatePayments =
  (code, resetForm, cookies, countryModel, sendErrorMessage) =>
  async (dispatch) => {
    const promoApplied = await dispatch(addPromoCode(code, resetForm));
    await dispatch(getPaymentMethods(cookies, countryModel, sendErrorMessage));
    return promoApplied;
  };

export const deletePromoCodeThenUpdatePayments =
  (cookies, countryModel, sendErrorMessage) => async (dispatch) => {
    const promoRemoved = await dispatch(deletePromoCode());
    await dispatch(getPaymentMethods(cookies, countryModel, sendErrorMessage));
    return promoRemoved;
  };
