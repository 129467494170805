import React from "react";
import PropTypes from "prop-types";

import Panel from "wonderbly-components/lib/Panel";
import Button from "wonderbly-components/lib/Button";

import Modal from "../../../Modal";
import RichText from "../../../Prismic/RichText";

import "./FixedOrderingModal.scss";

const FixedOrderingModal = ({
  title,
  description,
  cta,
  link,
  closeLink,
  image,
  isVisible,
  handleOnClick,
  hideFixedOrderingModal,
}) => {
  async function keepAllCharacters() {
    hideFixedOrderingModal();
  }

  return (
    <Modal isOpen={isVisible} updateIsOpen={hideFixedOrderingModal} default>
      <div className="Characters__Model">
        <a
          className="Characters__Model__Close"
          onClick={hideFixedOrderingModal}
        >
          <span className="icon" />
          {closeLink}
        </a>
        <Panel border="shadow">
          <img className="Characters__Model__Image" src={image?.url} alt="" />
          <h4 className="Characters__Model__Title">{title}</h4>
          <RichText fields={[{ type: "paragraph", text: description }]} />
          <Button
            className="Characters__Model__CTA"
            onClick={handleOnClick}
            fullWidth
          >
            {cta}
          </Button>
          <a onClick={keepAllCharacters} className="Characters__Model__Link">
            {link}
          </a>
        </Panel>
      </div>
    </Modal>
  );
};

FixedOrderingModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  closeLink: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  isVisible: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  hideFixedOrderingModal: PropTypes.string.isRequired,
};

export default FixedOrderingModal;
