import FamilyMembers from "./FamilyMembers";
import { withFormik } from "formik";
import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import withProps from "recompose/withProps";
import withState from "recompose/withState";
import withValidationData from "../../../hocs/withValidationData";
import has from "lodash/has";
import debounce from "lodash/debounce";
import reduce from "lodash/reduce";
import { validateCreationForm, onFieldChange } from "../helpers";

const handleValidationErrors =
  ({ names }, props) =>
  (errors) => {
    if (errors.names) {
      errors.names = errors.names.find(
        (item) => !item.error.includes("snowflake.names")
      );
    }

    if (!has(errors, "names.error")) {
      props.setErrors({});
      props.setFieldValue("names", names);
      props.setAdditionalName("");
    } else {
      errors = reduce(
        errors,
        (result, value, key) => {
          if (!result[key]) {
            result[key] = value.message;
          }
          return result;
        },
        {}
      );
      props.setErrors(errors);
    }
  };

const debouncedValidation = debounce(
  (values, props, config) =>
    validateCreationForm(values, props, config).catch(
      handleValidationErrors(values, props)
    ),
  500
);

const enhance = compose(
  withValidationData,
  withFormik({
    mapPropsToValues: (props) => ({
      base: "",
      names: props.productCustomisation.names,
    }),
    validate: (values, props) => validateCreationForm(values, props),
    validateOnChange: false,
    handleSubmit: (values, formikBag) => {
      formikBag.props.onFormikSubmit(values);
    },
  }),
  withState("additionalName", "setAdditionalName", ""),
  withHandlers({
    handleMemberChange: (props) => (namesIndex, value) => {
      const names = props.values.names.concat() || [];
      let newNames = [];
      if (typeof value === "undefined") {
        newNames = names.filter((_, index) => namesIndex !== index);
        props.setFieldValue("names", newNames);
        debouncedValidation({ names: newNames }, props, { raw: true });
      } else {
        newNames = names.map((name, index) =>
          namesIndex === index ? value : name
        );
        props.setFieldValue("names", newNames);
        debouncedValidation({ names: newNames }, props, { raw: true });
      }
    },
    onAddMember: (props) => () => {
      const names = props.values.names.concat() || [];
      const newNames = names.concat(props.additionalName);
      validateCreationForm({ names: newNames }, props, { raw: true })
        .then(() => {
          props.setFieldValue("names", newNames);
          props.setAdditionalName("");
        })
        .catch(handleValidationErrors({ names: newNames }, props));
    },
    onChangeAdditionalName: (props) => (e) =>
      props.setAdditionalName(e.target.value),
    handleChange: onFieldChange,
  }),
  withProps((props) => {
    const names = props.values.names;
    const hasEmptyNames =
      names.filter((name) => !!name).length !== names.length;
    const isAddButtonDisabled = names.length >= 9 || hasEmptyNames;
    const isCompleteButtonDisabled = names.length < 2 || hasEmptyNames;
    return {
      isAddButtonDisabled,
      isCompleteButtonDisabled,
    };
  })
);

export default enhance(FamilyMembers);
