import useModel from "./use-model";

export default (selectedTab = "") => {
  const { prismicGlobalContentModel } = useModel();

  if (selectedTab) {
    return prismicGlobalContentModel[selectedTab] || {};
  }

  return prismicGlobalContentModel;
};
