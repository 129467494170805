/**
 * @fileOverview
 *
 * Top level error handler & reporter.
 * If an exception made it to here the app is not
 * in a good state.
 *
 * CLIENT SIDE ONLY! You still need to handle
 * and report errors on the server as usual.
 */

import React from "react";
import PropTypes from "prop-types";
import logger from "src/logger";

export default class ErrorReporter extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    logger.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <h1>React Error - Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
