const mapKeys = require("lodash/mapKeys");
const snakeCase = require("lodash/snakeCase");
const isEmpty = require("lodash/isEmpty");

// Transforms the customisation keys from kebab case, which is what Chameleon/Muse
// store them as, to snake case.
//
// Due to this mismatch of strings Website, Squid v2 and Chameleon/Muse got different
// keys and caused a lot of issues to customers as well as developers when following the code.
// So because Eagle is is the one that transforms them what we do is transform back to
// the correct case them whenever we call any of those endpoints that transform
// customisation on return.

const transformCartOrderData = (order = {}) => {
  if (isEmpty(order) || isEmpty(order.items)) {
    return order;
  }

  return {
    ...order,
    items: order.items.map((item) => {
      return {
        ...item,
        customisations: mapKeys(item.customisations, (value, key) =>
          snakeCase(key)
        ),
      };
    }),
  };
};

module.exports = {
  transformCartOrderData,
};
