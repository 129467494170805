import React from "react";
import PropTypes from "prop-types";
import { getAdventurers } from "./helpers";
import Button from "wonderbly-components/lib/Button";
import CancelCta from "./CancelCta";
import CharacterForm from "./CharacterForm";
import CharacterCard from "./CharacterCard";

import "./MultiAdventurerII.scss";

const MultiAdventurerII = (props) => {
  const key = props.drawer.customisationKey;
  const adventurers = getAdventurers(
    props.productCustomisation[key],
    props.drawer.items
  );

  return (
    <div className="MultiAdventurerII">
      {props.isEditing !== false ? (
        <CharacterForm
          isSubmitting={props.isSubmitting}
          characters={adventurers}
          onHandleSubmit={props.handleFormSubmit}
          editIndex={props.isEditing}
          customisationKey={key}
        />
      ) : (
        <div className="MultiAdventurerII__form">
          {props.drawer.ctaCancelText && (
            <CancelCta href={props.drawer.ctaCancelHref}>
              {props.drawer.ctaCancelText}
            </CancelCta>
          )}
          <div className="MultiAdventurerII__cards">
            {adventurers
              .sort((a, b) => a.displayIndex - b.displayIndex)
              .map((adventurer, idx) => (
                <CharacterCard
                  key={idx}
                  character={adventurer}
                  cta={{
                    text: props.drawer.ctaEditText,
                    handleEdit: () => props.setIsEditing(adventurer.editIndex),
                  }}
                />
              ))}
          </div>
          {props.drawer.ctaText && (
            <div className="MultiAdventurerII__footer">
              <Button
                className="MultiAdventurerII__cta"
                onClick={props.handleCloseDrawer}
              >
                {props.drawer.ctaText}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

MultiAdventurerII.propTypes = {
  characters: PropTypes.array.isRequired,
  drawer: PropTypes.object.isRequired,
  productCustomisation: PropTypes.object.isRequired,
  isEditing: PropTypes.oneOf([false, PropTypes.number]).isRequired,
  isSubmitting: PropTypes.bool,
  onFormikSubmit: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  handleCloseDrawer: PropTypes.func.isRequired,
};

export default MultiAdventurerII;
