import Locale from "./Locale";
import withHandlers from "recompose/withHandlers";

const enhance = withHandlers({
  onHandleChange: (props) => (event) => {
    props.onHandleChange(event);
  },
});

export default enhance(Locale);
