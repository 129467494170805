import React from "react";
import PropTypes from "prop-types";
import BearCard from "./BearCard";
import Button from "wonderbly-components/lib/Button";
import get from "lodash/get";
import Tooltip from "wonderbly-components/lib/Tooltip";
import find from "lodash/find";
import { getBear } from "./helpers";

import "./BearFam.scss";

class BearFam extends React.Component {
  static propTypes = {
    isInvalid: PropTypes.bool.isRequired,
    lastEditedBear: PropTypes.number,
    drawer: PropTypes.shape({
      extraMemberLabel: PropTypes.string.isRequired,
      noMoreExtraMember: PropTypes.string.isRequired,
      extraMemberExplanation: PropTypes.string,
      image: PropTypes.object.isRequired,
      mainCharacter: PropTypes.array.isRequired,
    }),
    values: PropTypes.shape({
      bears: PropTypes.array.isRequired,
    }),
    bears: PropTypes.shape({
      subs: PropTypes.array,
    }),
    errors: PropTypes.shape({
      base: PropTypes.string,
    }),
    handleSubmit: PropTypes.func.isRequired,
    handleBearChange: PropTypes.func.isRequired,
    handleFocusOut: PropTypes.func.isRequired,
  };

  setRef = (c) => {
    this._container = c;
  };

  setRefInner = (c) => {
    this._inner = c;
  };

  componentDidUpdate(prevProps) {
    if (
      this._container &&
      prevProps.values.bears.length < this.props.values.bears.length
    ) {
      this._container.scrollLeft = this._inner.getBoundingClientRect().width;
    }
  }

  render() {
    const props = this.props;
    const errors = get(props, "errors.bears", false);
    const bearsBase =
      !!errors &&
      get(
        errors.find((error) => !error.index),
        "message",
        false
      );
    return [
      <Tooltip
        key="base-error"
        className="error-tooltip"
        visible={!!bearsBase || props.errors.base}
        absolute={false}
        type="alert"
      >
        {bearsBase || props.errors.base}
      </Tooltip>,
      <form key="form" className="bear-fam" onSubmit={props.handleSubmit}>
        <div className="bear-fam-active" ref={this.setRef}>
          <div className="bear-fam-active-inner" ref={this.setRefInner}>
            {props.values.bears.map((bear, index) => (
              <BearCard
                key={index}
                index={index}
                isActive
                label={props.drawer.extraMemberLabel}
                onBearChange={props.handleBearChange}
                bear={getBear(bear, props)}
                error={
                  !!errors &&
                  get(
                    find(errors, (error) => error.index === index),
                    "message",
                    false
                  )
                }
              />
            ))}
            <BearCard
              bear={{
                type: "littlest",
                image: props.drawer.image,
                description: props.drawer.mainCharacter,
              }}
            />
          </div>
        </div>
        {props.drawer.extraMemberExplanation && (
          <div className="bear-fam-instructions">
            {props.drawer.extraMemberExplanation}
          </div>
        )}
        <div className="bear-fam-cta-row">
          <div className="bear-fam-subs">
            {props.bears.subs.length > 0 ? (
              props.bears.subs.map((bear, index) => (
                <BearCard
                  key={index}
                  index={index}
                  onBearChange={props.handleBearChange}
                  bear={getBear(bear, props)}
                />
              ))
            ) : (
              <div>{props.drawer.noMoreExtraMember}</div>
            )}
          </div>
          <div className="bear-fam-cta-button">
            <Button type="submit" disabled={props.isInvalid}>
              Done
            </Button>
          </div>
        </div>
      </form>,
    ];
  }
}

export default BearFam;
