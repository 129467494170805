const { jsonRequest, request } = require("../../rest/index");
const CountriesModel = require("../../models/eagle/CountriesModel");
const CurrenciesModel = require("../../models/eagle/CurrenciesModel");

const formatCountryOptions = (countryModel) => ({
  user_locale: countryModel.locale,
  user_country_iso: countryModel.iso.toUpperCase(),
  user_currency: countryModel.currency.toUpperCase(),
});

let baseUrl = "";
const setBaseUrl = (value) => {
  baseUrl = value;
};

const health = () => {
  return request.get(`${baseUrl}/health`);
};

const countries = () => {
  return request
    .get(`${baseUrl}/website/v1/countries`)
    .then((response) =>
      new CountriesModel().deserialise(response.body.data).toJSON()
    );
};

const currencies = () => {
  return request
    .get(`${baseUrl}/website/v1/currencies`)
    .then((response) => new CurrenciesModel().deserialise(response.body.data));
};

const paymentMethods = (countryModel) => {
  return request
    .get(
      `${baseUrl}/website/v2/payment_methods`,
      formatCountryOptions(countryModel)
    )
    .then((response) => response.body.data);
};

const product = (countryModel, productId) => {
  return request
    .get(
      `${baseUrl}/website/v2/products/${productId}`,
      formatCountryOptions(countryModel),
      {
        timeout: 30000,
      }
    )
    .then((response) => {
      const product = response.body.data;

      // Sometimes a product is not available so it returns an empty hash.
      // we check if there's a property first before getting the canvas sizes.
      // TODO eagle should probably not return an empty hash if there's a missing
      // product.
      return {
        ...product,
        dimensions: {
          width:
            product.properties && product.properties["canvas-preview-width"],
          height:
            product.properties && product.properties["canvas-preview-height"],
        },
      };
    });
};

const variant = (countryModel, variantSku) => {
  return request
    .get(
      `${baseUrl}/website/v2/variants/${variantSku}`,
      formatCountryOptions(countryModel),
      {
        timeout: 30000,
      }
    )
    .then((response) => {
      const variant = response.body.data;
      return {
        ...variant,
      };
    });
};

/**
 * @param {CountryModel} countryModel
 * @returns {Promise}
 */
const products = (countryModel) => {
  return request
    .get(`${baseUrl}/website/v2/products`, formatCountryOptions(countryModel))
    .then((response) => response.body.data);
};

const shippingEstimates = (countryModel) => {
  return request
    .get(`${baseUrl}/website/v2/shipping/estimate`, {
      country_iso: countryModel.iso.toUpperCase(),
    })
    .then((response) => response.body.data);
};

const cart = async (headers) => {
  return request
    .get(`${baseUrl}/website/v2/cart`, null, { headers })
    .then((response) => response.body);
};

const updateItems = async (itemId, payload, headers) => {
  return jsonRequest
    .patch(`${baseUrl}/website/v2/cart/items/${itemId}`, payload, { headers })
    .then((response) => response.body);
};

const addItem = async (payload, headers) => {
  return jsonRequest
    .post(`${baseUrl}/website/v2/cart/items`, payload, { headers })
    .then((response) => response.body);
};

const deleteItem = async (itemId, payload, headers) => {
  return jsonRequest
    .del(`${baseUrl}/website/v2/cart/items/${itemId}`, payload, { headers })
    .then((response) => response.body);
};

const addPromoCode = async (payload, headers) => {
  return jsonRequest
    .post(`${baseUrl}/website/v2/cart/promotion`, payload, { headers })
    .then((response) => response.body);
};

const removePromoCode = async (payload, headers) => {
  return jsonRequest
    .del(`${baseUrl}/website/v2/cart/promotion`, payload, { headers })
    .then((response) => response.body);
};

module.exports = {
  setBaseUrl,
  health,
  countries,
  currencies,
  paymentMethods,
  products,
  product,
  variant,
  shippingEstimates,
  cart,
  updateItems,
  deleteItem,
  addItem,
  addPromoCode,
  removePromoCode,
};
