/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import omit from "lodash/omit";

const colPropTypes = {
  numofcols: PropTypes.number.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};

const colDefaultProps = {
  numofcols: 1,
};

export const Col = (props) => {
  if (!props.children) {
    return null;
  }

  const defaultClassName = `col-1-${props.numofcols}`;
  const regex = /(col-\d-\d)/g;
  const needsColClass = !props.className || !regex.exec(props.className);
  let className = classNames(
    { [defaultClassName]: needsColClass },
    props.className
  );
  if (props.numofcols === 1 && needsColClass) {
    className = classNames("col-1-1 widest", props.className);
  }
  return (
    <div {...omit(props, ["numofcols"])} className={className}>
      {props.children}
    </div>
  );
};

Col.propTypes = colPropTypes;
Col.defaultProps = colDefaultProps;

const mqs = [
  "base",
  "mobile",
  "mobile-and-fablet",
  "mobile-and-tablet",
  "fablet",
  "fablet-and-tablet",
  "fablet-up",
  "fablet-down",
  "tablet",
  "tablet-and-desktop",
  "tablet-up",
  "tablet-down",
  "desktop",
  "desktop-up",
  "desktop-down",
  "wide-up",
];

export const oneOfProps = {
  rowsSpacedOn: mqs,
  colsSpacedOn: mqs,
  colsReversedOn: mqs,
  colsToRowsOn: mqs,
};

export const componentPropTypes = {
  className: PropTypes.string,
  rowsSpacedOn: PropTypes.oneOf(oneOfProps.rowsSpacedOn),
  colsSpacedOn: PropTypes.oneOf(oneOfProps.colsSpacedOn),
  colsReversedOn: PropTypes.oneOf(oneOfProps.colsReversedOn),
  colsToRowsOn: PropTypes.oneOf(oneOfProps.colsToRowsOn),
  children: PropTypes.any,
};

const getSuffix = (value) => {
  if (value === "base") {
    return "";
  }
  return `-on-${value}`;
};

const getEmptyChildren = (props) => {
  if (props.children) {
    return React.Children.map(props.children, function (item) {
      if (!item) {
        return true;
      }
      if (!item.props) {
        return true;
      }
      return typeof item.props.children === "undefined" ||
        item.props.children === null
        ? true
        : null;
    });
  }

  return [];
};

const hasNoChildren = (props) => {
  if (props.children) {
    const childLength = React.Children.count(props.children) || 0;
    return getEmptyChildren(props).length === childLength;
  }

  return true;
};

const getNewChildren = (props) => {
  let newChildren = [];

  if (props.children) {
    const childLength =
      React.Children.count(props.children) - getEmptyChildren(props).length ||
      0;

    newChildren = React.Children.map(props.children, function (item, index) {
      if (!item) {
        return false;
      }
      return React.cloneElement(item, {
        key: index,
        numofcols: childLength,
      });
    });
  }

  return newChildren;
};

const getRow = (props) => {
  const className = classNames(
    "row",
    {
      [`cols-spaced${getSuffix(props.colsSpacedOn)}`]: props.colsSpacedOn,
    },
    {
      [`cols-reversed${getSuffix(props.colsReversedOn)}`]: props.colsReversedOn,
    },
    {
      [`cols-to-rows${getSuffix(props.colsToRowsOn)}`]: props.colsToRowsOn,
    },
    {
      [`rows-spaced${getSuffix(props.rowsSpacedOn)}`]: props.rowsSpacedOn,
    },
    props.className
  );
  const newChildren = getNewChildren(props);

  return (
    <div
      {...omit(props, [
        "rowsSpacedOn",
        "colsSpacedOn",
        "colsReversedOn",
        "colsToRowsOn",
      ])}
      className={className}
    >
      {newChildren}
    </div>
  );
};

export const Row = (props) => {
  if (hasNoChildren(props)) {
    return null;
  }

  if (props.rowsSpacedOn) {
    return (
      <div
        className={classNames("row", {
          [`rows-spaced${getSuffix(props.rowsSpacedOn)}`]: props.rowsSpacedOn,
        })}
      >
        {getRow(props)}
      </div>
    );
  }

  return getRow(props);
};

Row.propTypes = componentPropTypes;
Row.defaultProps = {
  colsSpacedOn: "base",
};
