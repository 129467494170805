const intl = require("intl");
const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
};
/**
 * @param {string | number | Date} input
 * @param {CountryModel} countryModel
 * @param {Object=} options
 * @returns {string}
 */
const date = (input, countryModel, options = dateOptions) => {
  const dateObject = new Date(input);
  try {
    try {
      return new intl.DateTimeFormat(countryModel.locale, options).format(
        dateObject
      );
    } catch (error) {
      return dateObject.toLocaleDateString(countryModel.locale, options);
    }
  } catch (error) {
    return dateObject.toDateString();
  }
};

module.exports = {
  date,
};
