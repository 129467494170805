import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import Arrow from "wonderbly-components/lib/Arrow";
import SiteNavLinksList from "../SiteNavLinksList";
import SiteNavPromos from "../SiteNavPromos";
import "./MobileExpandedNav.scss";

const MobileExpandedNav = (props) => {
  const mobileNavRef = useRef();

  const styles = useSpring({
    left: props.showSubMenu ? "0%" : "-100%",
  });

  // When the user closes the menu
  // either on the overlay or the cross,
  // we need to reset the menu so when they
  // re-open it, it shows them the initial
  // state of the menu.
  useEffect(() => {
    if (!props.showSubMenu) {
      mobileNavRef.current.scroll(0, 0);
    }
  }, [props.showSubMenu]);

  return (
    <animated.div
      className="MobileExpandedNav"
      ref={mobileNavRef}
      style={styles}
    >
      <button
        aria-label="Close sub-menu"
        onClick={(e) => {
          e.preventDefault();
          props.onHandleCloseMenu();
        }}
        type="button"
        className="MobileExpandedNav__button"
      >
        <Arrow inline className="MobileExpandedNav__arrow" direction="left" />
        <div className="MobileExpandedNav__button-text">
          {props.backButtonText}
        </div>
      </button>
      {props.columns?.linkColumns?.map((list, index) => (
        <SiteNavLinksList
          {...list}
          parentItemLabel={props.backButtonText}
          key={index}
        />
      ))}
      {props.columns?.marketingColumns?.map((list, index) => (
        <SiteNavPromos
          {...list}
          parentItemLabel={props.backButtonText}
          key={index}
        />
      ))}
    </animated.div>
  );
};

MobileExpandedNav.propTypes = {
  columns: PropTypes.shape({
    linkColumns: PropTypes.array,
    marketingColumns: PropTypes.array,
  }),
  backButtonText: PropTypes.string,
  showSubMenu: PropTypes.bool,
  onHandleCloseMenu: PropTypes.func,
  parentItemLabel: PropTypes.string,
};

export default MobileExpandedNav;
