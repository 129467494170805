import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Panel from "wonderbly-components/lib/Panel";
import Loading from "../Loading";
import "./SiteLoader.scss";

const SiteLoader = (props) => (
  <div
    className={cx("site-loader", {
      "site-loader--visible": props.isOpen,
    })}
  >
    <Panel className="site-loader-panel">
      <Loading dark relative small />
    </Panel>
  </div>
);

SiteLoader.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default SiteLoader;
