const qs = require("qs");

let mapEndpoint = null;
let accessKey = null;

const setMapEndpoint = (value) => {
  mapEndpoint = value;
  return module.exports;
};

const setAccessKey = (value) => {
  accessKey = value;
  return module.exports;
};

const buildBingLocationsUrl = (address) =>
  `${mapEndpoint}/${encodeURIComponent(address)}?${qs.stringify({
    maxResults: 10,
    key: accessKey,
  })}`;

const getBingAccessKey = () => accessKey;

module.exports = {
  setMapEndpoint,
  setAccessKey,
  buildBingLocationsUrl,
  getBingAccessKey,
};
