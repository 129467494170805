import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "src/client/js/view/components/Grid";
import Button from "wonderbly-components/lib/Button";
import Tooltip from "wonderbly-components/lib/Tooltip";
import AdventurerForm from "./AdventurerForm";
import cx from "classnames";

import "./Adventurer.scss";

const Adventurer = (props) => (
  <div className="hero-form">
    <Tooltip
      className="error-tooltip"
      visible={props.errors && !!props.errors.base}
      absolute={false}
      type="alert"
    >
      {props.errors && props.errors.base}
    </Tooltip>
    <Row
      colsToRowsOn="tablet-down"
      rowsSpacedOn="base"
      className={cx("hero-form-row", {
        "hero-form-row--one-subfield": props.form.body.length === 1,
      })}
    >
      {props.form.body.map((field, index) => (
        <Col
          className={cx("hero-form-field", {
            [field.sliceType]: field.sliceType,
          })}
          key={`${field.sliceType}-${index}`}
        >
          <AdventurerForm
            localeOverride={props.form.localeOverride}
            field={field}
            onHandleChange={props.handleChange}
            setFieldValue={props.setFieldValue}
            values={props.values}
            product={props.product}
            errors={props.errors}
            tabletDown={props.media.tabletDown}
          />
        </Col>
      ))}
      {props.form.cta && (
        <Col className="hero-form-cta-button">
          <Button
            type="submit"
            disabled={props.isDisabled}
            onClick={props.handleSubmit}
            fullWidth
          >
            {props.form.cta}
          </Button>
        </Col>
      )}
    </Row>
  </div>
);

Adventurer.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  form: PropTypes.shape({
    body: PropTypes.array.isRequired,
    cta: PropTypes.string.isRequired,
    localeOverride: PropTypes.string,
  }),
  media: PropTypes.shape({
    tabletDown: PropTypes.bool,
  }),
};

export default Adventurer;
