const { replaceTextWithValues } = require("../utils/text-replace");

let lmnEndpoint = null;
let mu2Endpoint = null;

const isChameleon = (id) => id === "lmn";
const isMu2 = (id) => !isChameleon(id);

const setMU2Endpoint = (value) => {
  mu2Endpoint = value;
  return module.exports;
};

const setLMNEndpoint = (value) => {
  lmnEndpoint = value;
  return module.exports;
};

const buildPreviewUrl = (id, params) => {
  // According to @robertwatts there will never be an english locale that isn't "en"
  const locale =
    params.locale && params.locale.startsWith("en") ? "en" : params.locale;

  // use chameleon for lost my name
  if (isChameleon(id)) {
    return lmnEndpoint;
  }

  // or use muse for all other books
  // handles skus that look like "[PRODUCT_ID]-%{locale}"
  const museId = replaceTextWithValues(id, { locale });
  return mu2Endpoint.replace("{sku}", museId);
};

module.exports = {
  isMu2,
  isChameleon,
  setLMNEndpoint,
  setMU2Endpoint,
  buildPreviewUrl,
};
