import logger from "src/logger";
import kebabCase from "lodash/kebabCase";
import snakeCase from "lodash/snakeCase";
import isNil from "lodash/isNil";
import get from "lodash/get";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import clamp from "lodash/clamp";

import PropTypes from "prop-types";
import qs from "qs";
import endpoints, { isMu2 } from "src/common/config/endpoints";
import appendQueryParams from "src/common/utils/append-query-params";
import getDevicePixelRatio from "src/common/utils/device-pixel-ratio";
import getWindowInnerWidth from "src/common/utils/window-inner-width";
import preloadImage from "src/common/utils/preloadImage";
import {
  matchDefaultCustomisation,
  matchExtraCustomisation,
} from "src/client/js/view/utils/match-customisation";
import { isTouchEnabled } from "src/common/utils/touch";
// These imports are shared between BookPreview.js and helpers/Canvas.js due to how webpack bundles code. They are
// causing a 132kb chunk of JS to be bundled in the global bundle.js, I've investigated it but have run out of time to
// fix. It will not work with lodable (not a component). One method was to use webpack's magic comments but that
// requires everything to be async. Tldr; needs better implementation. Another possible fix is to duplicate the nasty
// functions so they are not deemed 'shared' by webpack.
import {
  getFields,
  isFieldOptional,
  getOverride,
} from "../CreationForm/helpers";
import { hasRequiredField } from "../Canvas/helpers";

const getPropertiesToOmit = (sku) => {
  return isMu2(sku) ? ["q", "auto", "dpr", "w", "width", "fit"] : ["_width"];
};

const setImageUrlParams = (page, omitted = []) => {
  const maxWidth = 1280;
  const requestedWidth =
    getWindowInnerWidth(maxWidth) * 0.75 * getDevicePixelRatio();
  const width = Math.floor(Math.min(requestedWidth, maxWidth));
  return {
    ...page,
    url: appendQueryParams(
      omit(
        {
          // muse 1 required parameters
          width,
          // mu2 required parameters
          _width: clamp(width, 128, 1280), // clamped for to conform to API
          // imgix parameters
          w: width,
          q: 60,
          auto: "format",
          // dpr accounted for in width calculation
          dpr: 1,
        },
        omitted
      ),
      page.url
    ),
  };
};

const getCover = (options, pageType) => {
  if (!options.covers || !pageType || options.covers.length < 1) {
    return null;
  }

  const covers = options.covers.reduce(
    (results, cover) =>
      kebabCase(cover.pageType) === pageType
        ? results.concat({
            ...cover,
            gender: kebabCase(cover.gender),
            phototype: kebabCase(cover.phototype),
          })
        : results,
    []
  );

  if (covers.length === 0) {
    return null;
  }

  const isCustomised = covers.some(
    (cover) =>
      cover.gender !== "all" ||
      cover.phototype !== "all" ||
      !isNil(cover.extraChoices)
  );

  if (!isCustomised) {
    return covers[0].image.url;
  }

  const cover = covers
    .filter((cover) => matchDefaultCustomisation(options.customisation, cover))
    .find((cover) => matchExtraCustomisation(options.customisation, cover));
  return cover ? cover.image.url : null;
};

const addCovers = (options) => [
  getCover(options, "front-cover"),
  getCover(options, "front-inner"),
  ...options.pages,
  getCover(options, "back-inner"),
  getCover(options, "back-cover"),
];

const replaceCovers = (options) => {
  const omitted = getPropertiesToOmit(options.productId);
  return options.pages.map((page, index) => {
    switch (true) {
      case index === 0:
        return setImageUrlParams(
          {
            ...page,
            url: getCover(options, "front-cover") || page.url,
          },
          omitted
        );
      case index === 1:
        return setImageUrlParams(
          {
            ...page,
            url: getCover(options, "front-inner") || page.url,
          },
          omitted
        );
      case index === options.pages.length - 2:
        return setImageUrlParams(
          {
            ...page,
            url: getCover(options, "back-inner") || page.url,
          },
          omitted
        );
      case index === options.pages.length - 1:
        return setImageUrlParams(
          {
            ...page,
            url: getCover(options, "back-cover") || page.url,
          },
          omitted
        );
      default:
        return page;
    }
  });
};

const transformPlatformPages = (options) => {
  const pagesWithCovers = addCovers(options);
  const omitted = getPropertiesToOmit(options.productId);

  return pagesWithCovers.reduce((list, page) => {
    if (page) {
      list.push(
        setImageUrlParams(
          {
            id: list.length + 1,
            url: page.url || page,
          },
          omitted
        )
      );
    }
    return list;
  }, []);
};

export const transformPages = (options) => {
  if (options.pages.length < 1) {
    return options.pages;
  }

  const transformOptions = {
    pages: options.pages,
    customisation: options.customisation,
    productId: options.productId,
    isHeidelberg: options.isHeidelberg,
    covers: options.covers,
  };

  const pages = transformPlatformPages(transformOptions);

  // preload 2 images
  pages.forEach((image, index) =>
    index < 2
      ? preloadImage(image.url).catch((error) => logger.error(error))
      : null
  );

  return pages;
};

export const isNearActive = (pageId, activeSpread) => {
  return (
    activeSpread &&
    pageId >= activeSpread[0] - 3 &&
    pageId <= activeSpread[1] + 3
  );
};

export const pagesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  })
);

export const parseQuery = (query) =>
  qs.parse(query, { ignoreQueryPrefix: true });

export const isChameleonStillRendering = (pages = []) =>
  pages.some((page) => !page);

export const hasInsideCovers = (sku) => endpoints.isMu2(sku);

export const getBreadCrumbType = (sku, breadcrumbs = []) => {
  if (endpoints.isChameleon(sku)) {
    return "character-picker";
  }
  if (breadcrumbs.length > 0) {
    return "breadcrumb";
  }
  return null;
};

export const isMediaQueriesDifferent = (props, nextProps) =>
  !isEqual(props.media, nextProps.media);

export const getUpdatedPages = (props, nextProps) => {
  const isPrismicProductDifferent =
    nextProps.prismicProduct.response.ok &&
    nextProps.pages.length > 0 &&
    (props.prismicProduct.response.ok !==
      nextProps.prismicProduct.response.ok ||
      !isEqual(props.pages, nextProps.pages));

  if (isPrismicProductDifferent) {
    const options = {
      pages: nextProps.pages,
      customisation: nextProps.productCustomisation,
      productId: nextProps.productId,
      covers: nextProps.prismicProduct.canvas.bookPages,
      isHeidelberg: nextProps.isHeidelberg,
    };
    return replaceCovers(options);
  }
};

const getAllRequiredFieldsForPreview = (props) => {
  const allDrawers = props.drawers.other;
  const productCustomisation = props.productCustomisation;

  return allDrawers.reduce((requiredFields, drawer) => {
    let slice = drawer.sliceType;
    const fieldOptions = {
      ...productCustomisation,
      productId: props.productId,
      type: snakeCase(get(drawer, "primary.type", null)),
    };

    // This function used to get the preview images, if we say that formats is required to preview then
    // it will break the preview when first loading the preview page. It is for this reason we say that
    // optional fields and book formats are not required.
    if (
      isFieldOptional(slice, fieldOptions, drawer) ||
      slice === "book_formats"
    ) {
      return requiredFields;
    }

    const fields = getFields(slice);
    const override = getOverride(slice, fieldOptions);

    if (!isEmpty(override)) {
      fields.required = override.required;
    }

    return requiredFields.concat(fields.required);
  }, []);
};

const getCustomisation = (props, nextProps) => {
  const isNameSame = isEqual(
    props.productCustomisation.name,
    nextProps.productCustomisation.name
  );
  const isLocaleSame = isEqual(
    props.productCustomisation.locale,
    nextProps.productCustomisation.locale
  );
  const isEditedProduct = has(props, "match.params.itemId");
  if ((isNameSame && isLocaleSame) || isEditedProduct) {
    return nextProps.productCustomisation;
  }
  return omit(nextProps.productCustomisation, "characterSelection");
};

export const getUpdatedPreviewOptions = (props, nextProps) => {
  const isCustomisationDifferent = !isEqual(
    props.productCustomisation,
    nextProps.productCustomisation
  );

  if (!isCustomisationDifferent) {
    return;
  }

  const productCustomisation = nextProps.productCustomisation;
  const requiredFields = getAllRequiredFieldsForPreview(nextProps);

  const missingFields = requiredFields.filter(
    (field) => !hasRequiredField(field, productCustomisation)
  );
  const isFieldsValid = missingFields.length === 0;

  if (!isFieldsValid) {
    // TODO: Use the logger method instead and return
    throw new Error(
      `Preview cannot be loaded. Missing fields: ${missingFields.join(", ")}`
    );
  }

  return {
    productId: nextProps.productId,
    productMuseId:
      nextProps.model.eagleProduct.museSlug || nextProps.productMuseId, // either from eagle or prismic
    customisation: getCustomisation(props, nextProps),
    isHeidelberg: !isTouchEnabled() || !!nextProps.media.desktopUp,
    covers: nextProps.covers,
  };
};
