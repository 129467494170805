import React from "react";
import PropTypes from "prop-types";

const Messages = (props) =>
  props.messages.length > 0 && (
    <div
      className={`flash-messages-container flash-messages-container--${props.messageType}`}
    >
      <div className="container-guttered">
        {props.messages.map((notice, index) => (
          <div key={index}>{notice}</div>
        ))}
      </div>
    </div>
  );

Messages.propTypes = {
  messageType: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Messages;
