import React from "react";
import PropTypes from "prop-types";
import ImageModel from "src/common/models/prismic/components/ImageModel";
import { prismicDPRSrcSet } from "src/common/utils/prismic-image";

const PaymentIcon = (props) => {
  const img = prismicDPRSrcSet(props.image, { width: props.width });
  return (
    <img
      loading="lazy"
      width={props.width}
      {...img}
      alt={props.image.alt}
      className="payment-icon"
    />
  );
};

PaymentIcon.propTypes = {
  image: ImageModel.propTypes.isRequired,
  width: PropTypes.number.isRequired,
};

export default PaymentIcon;
