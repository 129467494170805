import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { prismicImage } from "src/common/utils/prismic-image";
import RichText from "../../Prismic/RichText";

import Button from "wonderbly-components/lib/Button";
import ButtonRadio from "wonderbly-components/lib/ButtonRadio";
import Tooltip from "wonderbly-components/lib/Tooltip";
import Panel from "wonderbly-components/lib/Panel";
import { Row, Col } from "src/client/js/view/components/Grid";

import "./CustomExtraAdventurer.scss";

const CustomExtraAdventurer = (props) => {
  const character = props.values.characters[0];

  return (
    <Row
      rowsSpacedOn="base"
      colsToRowsOn="fablet-down"
      colsSpacedOn="base"
      className="CustomAdventurer"
    >
      <Row
        colsToRowsOn="fablet-down"
        className="CustomAdventurer__gender-select"
      >
        <Col className="col-1-3">
          <RichText fields={props.drawer.genderDescription} />
        </Col>
        <Col className="col-1-3">
          <input
            type="radio"
            name="gender"
            value=""
            className="CustomAdventurer__input-hidden"
            defaultChecked={character.gender === ""}
          />
          <Row colsToRowsOn="fablet-down">
            {props.genderButtons.map((genderButton) => (
              <Col key={genderButton.gender}>
                <ButtonRadio
                  id={`gender-${genderButton.gender}`}
                  value={`${genderButton.gender}`}
                  name="characters[0].gender"
                  onClick={props.handleChange}
                  className="CustomAdventurer__gender-input"
                  checked={genderButton.gender === character.gender}
                >
                  {genderButton.buttonText}
                </ButtonRadio>
              </Col>
            ))}
          </Row>
        </Col>
        <Tooltip
          visible={character.gender}
          className={`tooltip-visible--${character.gender}`}
          huge
          noPadding
        >
          <Panel highlight="grass-ceiling">
            <div className="adventurer-phototypes-title text-milli">
              {props.drawer.phototypeTitle}
            </div>
            {props.genderButtons.map((genderButton) => (
              <Row
                key={`${genderButton.gender}`}
                className={cx(
                  "cols-spaced-small CustomAdventurer__phototypes",
                  {
                    "CustomAdventurer__phototypes--visible":
                      genderButton.gender === character.gender,
                  }
                )}
              >
                {genderButton.phototypes.map((phototype) => (
                  <Col
                    key={`${genderButton.gender}-${phototype.phototypeId}`}
                    className="col-1-3 CustomAdventurer__phototype-column"
                  >
                    <ButtonRadio
                      id={`${
                        genderButton.gender
                      }-${phototype.phototypeId.toLowerCase()}`}
                      value={phototype.phototypeId.toLowerCase()}
                      name="characters[0].phototype"
                      className="CustomAdventurer__phototype-input"
                      onChange={props.handleChange}
                      checked={
                        character.gender === genderButton.gender &&
                        character.phototype ===
                          phototype.phototypeId.toLowerCase()
                      }
                    >
                      <img
                        src={prismicImage(phototype.phototype)}
                        alt={`${genderButton.gender} ${phototype.phototypeId}`}
                      />
                    </ButtonRadio>
                  </Col>
                ))}
              </Row>
            ))}
          </Panel>
        </Tooltip>
        <Col className="col-1-3 hero-form-cta-button">
          <Button
            type="submit"
            disabled={props.isDisabled}
            onClick={props.handleSubmit}
            fullWidth
          >
            {props.drawer.cta}
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

CustomExtraAdventurer.propTypes = {
  genderButtons: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    phototypes: PropTypes.arrayOf(
      PropTypes.shape({
        phototype: PropTypes.string.isRequired,
        gender: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          dimensions: PropTypes.shape({
            width: PropTypes.number.isRequired,
          }),
        }),
      })
    ),
  }),
  values: PropTypes.shape({
    characters: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        gender: PropTypes.string.isRequired,
        phototype: PropTypes.string.isRequired,
      })
    ),
  }),
  drawer: PropTypes.shape({
    genderDescription: PropTypes.string.isRequired,
    phototypeTitle: PropTypes.string.isRequired,
    inputTitle: PropTypes.string.isRequired,
    inputDescription: PropTypes.string.isRequired,
    inputLabel: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
  }),
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default CustomExtraAdventurer;
