import { Children } from "react";
import Carousel from "./Carousel";
import withProps from "recompose/withProps";

const enhance = withProps(
  ({
    thumbnails,
    disablePagination,
    disableNavigation,
    disableDrag,
    ...props
  }) => {
    const childCount = Children.count(props.children);
    const hasOneSlide = childCount === 1;
    return {
      childCount,
      thumbnails: !hasOneSlide && thumbnails,
      disablePagination: disablePagination || hasOneSlide,
      disableNavigation: disableNavigation || hasOneSlide,
      disableDrag: disableDrag || hasOneSlide,
    };
  }
);

export default enhance(Carousel);
