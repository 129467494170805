const JSONSet = require("../../datastructures/JSONSet");
class CurrenciesModel extends JSONSet {
  constructor() {
    super();
  }
  deserialise(data) {
    data.forEach((value) => this.add(value));
    return this;
  }
}
module.exports = CurrenciesModel;
