import { useContext } from "react";
import getTime from "date-fns/getTime";

import NotificationsContext from "../components/Notifications/context";

export default () => {
  const dispatch = useContext(NotificationsContext);

  const removeNotification = (id) => {
    dispatch({
      type: "remove",
      id,
    });
  };

  const sendNotification = (notificationType, body) => {
    const created = getTime(new Date());

    dispatch({
      type: "add",
      data: {
        ...body,
        created,
        type: notificationType,
      },
    });

    setTimeout(() => {
      removeNotification(created);
    }, 5000);

    return created;
  };

  return {
    sendSuccessNotification: (body) => sendNotification("success", body),
  };
};
