import compose from "recompose/compose";
import { withFormik } from "formik";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";
import debounce from "lodash/debounce";
import Inscription from "./Inscription";
import withValidationData from "../../../hocs/withValidationData";
import {
  updateFieldValues,
  validateCreationForm,
  onFieldChange,
} from "../helpers";
import formatInscription from "src/common/utils/format-inscription";

const debouncedValidation = debounce(
  (values, props, config) => validateCreationForm(values, props, config),
  500
);

const enhance = compose(
  withValidationData,
  withFormik({
    mapPropsToValues: (props) => ({
      base: "",
      inscription: props.productCustomisation.inscription,
    }),
    handleSubmit: (fields, { props }) => {
      const values = updateFieldValues(fields, props.country);
      props.onFormikSubmit(values);
    },
  }),
  withState(
    "currentCount",
    "setCurrentCount",
    (props) => props.drawer.maxCharacters - props.values.inscription.length
  ),
  withHandlers({
    onChange: (props) => {
      const handleChange = onFieldChange(props);
      return (event) => {
        const value = formatInscription(event.target.value);

        event.target.value = value;

        handleChange(event);
        debouncedValidation({ inscription: value }, props, {
          enableSetErrors: true,
        });
        props.setCurrentCount(props.drawer.maxCharacters - value.length);
      };
    },
  })
);

export default enhance(Inscription);
