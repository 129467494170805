import React from "react";
import PropTypes from "prop-types";
import Icon from "wonderbly-components/lib/Icon";
import Button from "wonderbly-components/lib/Button";
import BasketItem from "./BasketItem";
import Upsell from "../../../../Upsell";
import QuickAdd from "../../../../Upsell/QuickAdd";
import "./Contents.scss";

const Contents = (props) => (
  <div className="basket-contents">
    <button
      type="button"
      className="basket-option-close"
      onClick={props.onClose}
    >
      <Icon icon="delete" />
    </button>
    {props.items.length > 0 ? (
      [
        props.items.map((item) => <BasketItem key={item.id} item={item} />),
        <div className="basket-total" key="basket-total">
          <span className="basket-total-heading">{props.totalLabel}</span>
          <span className="basket-total-price">{props.total.formatted}</span>
        </div>,
        <Button
          href={props.href}
          key="basket-checkout-button"
          className="basket-checkout-button"
          fullWidth
        >
          {props.checkoutCta}
        </Button>,
        <QuickAdd key="quick-add" />,
      ]
    ) : (
      <Upsell />
    )}
  </div>
);

Contents.propTypes = {
  href: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  totalLabel: PropTypes.string.isRequired,
  checkoutCta: PropTypes.string.isRequired,
  total: PropTypes.shape({
    formatted: PropTypes.string,
  }).isRequired,
};

export default Contents;
