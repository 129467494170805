const isEmpty = require("lodash/isEmpty");
const getUrlByUid = require("./get-url-by-uid");
const { localiseUrlII } = require("./format-localised-url");

/**
 * getRedirectUrl - Pass a Prismic UID, a country model and the available languages and get a localised URL.
 *
 * @param {String} uid - A Prismic document UID.
 * @param {Object} countryModel - The new country that you will be navigating to.
 * @param {Object[]} alternateLanguages - A list of languages from the Prismic Doc you will be navigation to/from.
 * @param {String} [searchQuery] - A string that attaches to the end of the path.
 * @returns {String} A localised url to either the Prismic document or the homepage.
 */
const getRedirectUrl = (
  uid,
  countryModel = {},
  alternateLanguages = [],
  queryParams = {}
) => {
  if (!uid || isEmpty(countryModel) || isEmpty(alternateLanguages)) {
    return localiseUrlII(countryModel, "/");
  }

  const doc = alternateLanguages.find((route) =>
    countryModel.contentLocales.find((locales) => locales === route.lang)
  );

  let route = `/`;

  // If the doc exists then set route to its url
  if (!!doc) {
    // The redirect doc may be linked to the current doc however the uid may be different - in this case
    // we set the redirect uid to be the same as the uid of the found doc.
    route = getUrlByUid(doc.uid || uid, { isUrlLocalised: false, queryParams });
  }

  return localiseUrlII(countryModel, route);
};

module.exports = {
  getRedirectUrl,
};
