import FlashMessages from "./FlashMessages";
import { getCartErrors } from "src/client/js/store/selectors/cart";
import { getFlashMessageErrors, getFlashMessageNotices } from "./duck";
import { connect } from "react-redux";

const enhance = connect((state) => ({
  notices: [...getFlashMessageNotices(state)],
  errors: [
    ...getFlashMessageErrors(state),
    ...getCartErrors(state).map((error) => error.detail),
  ],
}));

export default enhance(FlashMessages);
