import React from "react";
import PropTypes from "prop-types";
import Tooltip from "wonderbly-components/lib/Tooltip";
import Select from "wonderbly-components/lib/Select";
import InlineFormField from "wonderbly-components/lib/InlineFormField";

import "./CustomSelect.scss";

const CustomSelect = (props) => (
  <div className="custom-select">
    <div className="inline-label">
      <label className="inline-label__tag" htmlFor="selection_state">
        {props.title}
      </label>
    </div>
    <Select
      id="selection_state"
      name="selection_state"
      className="custom-select__select"
      options={props.field.items}
      value={props.selectionState}
      onChange={props.onChange}
      label={props.title}
    />
    {props.selectionState === props.otherValue && (
      <div className="custom-select__input">
        <Tooltip
          key="error"
          className="error-tooltip adventurer-gender-tooltip"
          visible={!!props.error}
          type="alert"
        >
          {props.error}
        </Tooltip>
        <InlineFormField
          id="selection_value"
          name="selection_value"
          fieldType="input"
          value={props.selectionValue}
          onChange={props.onChangeInput}
          placeholder={props.placeholder}
        />
      </div>
    )}
  </div>
);

CustomSelect.propTypes = {
  error: PropTypes.string,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectionState: PropTypes.string.isRequired,
  selectionValue: PropTypes.string.isRequired,
  otherValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  field: PropTypes.shape({
    items: PropTypes.array.isRequired,
  }),
};

export default CustomSelect;
