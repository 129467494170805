const url = require("url");
const path = require("path");
const memoize = require("lodash/memoize");
const country = require("../l10n/country");

const EAGLE_URLS = ["/admin", "/checkout", "/mayday"];

const EAGLE_MIGRATION_URLS = ["/checkout-v2"];

const pathnameStartsWith = (pathname, urls) => {
  return !!urls.find((url) => pathname.startsWith(url));
};

const isEagleUrl = memoize((pathname) => {
  return (
    pathnameStartsWith(pathname, EAGLE_URLS) &&
    !pathnameStartsWith(pathname, EAGLE_MIGRATION_URLS)
  );
});

const getPrefix = (countryModel, pathname) => {
  if (isEagleUrl(pathname)) {
    return country.isLegacyLocale(countryModel.locale)
      ? `/${countryModel.locale}`
      : `/en-US`;
  }
  return country.isDefault(countryModel) || !countryModel.prefix
    ? "/"
    : `/${countryModel.prefix}`;
};

const validateUrlObject = (urlObject) => {
  if (typeof urlObject !== "object") {
    throw new Error(
      `type of urlObject expected object, instead got "${typeof urlObject}"`
    );
  }

  if (
    !urlObject.protocol &&
    urlObject.pathname &&
    !urlObject.pathname.startsWith("/")
  ) {
    urlObject.pathname = `/${urlObject.pathname}`;
  }
};

/**
 * Dont want to break existing usages, so deprecating this in
 * favour of new method, which un-localises the url before
 * localising it with the country model that is passed in
 * @param countryModel
 * @param inputUrl
 * @param [removeTrailingSlash]
 * @returns {string}
 * @deprecated
 */
const localiseUrl = (countryModel, inputUrl, removeTrailingSlash = true) => {
  const urlObject = url.parse(inputUrl, true);
  if (urlObject.pathname && !urlObject.protocol) {
    validateUrlObject(urlObject);
    const pathname =
      getPrefix(countryModel, urlObject.pathname) + urlObject.pathname;
    urlObject.pathname = path.join(
      "/",
      removeTrailingSlash ? pathname.replace(/\/$/, "") : pathname
    );
  }
  return url.format(urlObject);
};

/**
 * Dont want to break existing usages, so deprecating this in
 * favour of new method, which un-localises the url by using
 * a regular expression to see if a prefix or locale code is
 * being used at the beginning of the string
 * @param countryModel
 * @param inputUrl
 * @returns {string}
 * @deprecated
 */
const unlocaliseUrl = (countryModel, inputUrl) => {
  const urlObject = url.parse(inputUrl, true);
  if (urlObject.pathname) {
    validateUrlObject(urlObject);
    urlObject.pathname = path.join(
      "/",
      urlObject.pathname.replace(
        getPrefix(countryModel, urlObject.pathname),
        ""
      )
    );
  }
  return url.format(urlObject);
};

/**
 * Remove a known prefix from a path
 * @param {string} inputUrl the url path that you want to remove the prefix from
 * @returns {string} the path that was originally passed in without the prefix
 */
const unlocaliseUrlII = (inputUrl) => {
  const urlObject = url.parse(inputUrl, true);
  validateUrlObject(urlObject);

  if (urlObject.pathname && !urlObject.protocol) {
    const pathname = urlObject.pathname;
    if (country.isPathLocalised(pathname)) {
      const prefix = country.getPrefixFromPath(pathname);
      if (prefix) {
        urlObject.pathname = path.join("/", pathname.replace(prefix, ""));
      }
    }
  }

  return url.format(urlObject);
};

/**
 *
 * @param {CountryModel} countryModel the country model you wish to use to prefix the pathname
 * @param {string} inputUrl
 * @param {boolean} [removeTrailingSlash]
 * @returns {string} the path that was originally passed in with the prefix from the country model
 */
const localiseUrlII = (countryModel, inputUrl, removeTrailingSlash = true) => {
  const urlObject = url.parse(inputUrl, true);
  validateUrlObject(urlObject);

  if (urlObject.pathname && !urlObject.protocol) {
    let pathname = urlObject.pathname;
    pathname = unlocaliseUrlII(pathname);
    pathname = `${getPrefix(countryModel, pathname)}${pathname}`;
    urlObject.pathname = path.join(
      "/",
      removeTrailingSlash ? pathname.replace(/\/$/, "") : pathname
    );
  }

  return url.format(urlObject);
};

module.exports = {
  localiseUrl,
  unlocaliseUrl,
  localiseUrlII,
  unlocaliseUrlII,
};
