import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import { Row, Col } from "src/client/js/view/components/Grid";
import Tooltip from "wonderbly-components/lib/Tooltip";
import isEmpty from "lodash/isEmpty";
import { prismicImage } from "src/common/utils/prismic-image";
import { replaceTextWithValues } from "src/common/utils/text-replace";
import "./Inscription.scss";

const Inscription = (props) => (
  <Row
    rowsSpacedOn="base"
    colsToRowsOn="tablet-down"
    colsSpacedOn="base"
    className="inscription"
  >
    {props.drawer.image && (
      <Col>
        <div className="inscription-image">
          <img
            src={prismicImage(props.drawer.image)}
            alt={props.drawer.image.alt}
          />
        </div>
      </Col>
    )}
    <Col>
      <form onSubmit={props.handleSubmit} className="inscription-form">
        <Tooltip
          className="error-tooltip inscription-tooltip"
          visible={props.errors && !!props.errors.inscription}
          absolute
          type="alert"
        >
          {props.errors && props.errors.inscription}
        </Tooltip>
        <textarea
          rows={9}
          value={props.values.inscription}
          name="inscription"
          className="inscription-textarea"
          onChange={props.onChange}
        />
        <Row colsToRowsOn="fablet-down">
          <Col className="col-2-3">
            {props.drawer.charactersLeftText &&
              replaceTextWithValues(props.drawer.charactersLeftText, {
                currentCount: props.currentCount,
              })}
          </Col>
          <Col className="col-1-3 inscription-cta-container">
            <Button
              type="submit"
              disabled={props.isSubmitting || !isEmpty(props.errors)}
            >
              {props.drawer.cta}
            </Button>
          </Col>
        </Row>
      </form>
    </Col>
  </Row>
);

Inscription.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  currentCount: PropTypes.number.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    charactersLeftText: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
  values: PropTypes.shape({
    inscription: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    inscription: PropTypes.string,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default Inscription;
