import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { useTransition, animated, config } from "react-spring";
import { useModel, useCountry } from "src/client/js/view/hooks";
import ScrollableCards from "wonderbly-components/lib/ScrollableCards";
import OfferCard from "wonderbly-components/lib/OfferCard";
import Button from "wonderbly-components/lib/Button";
import { applyPromoCodes, isScrolled } from "./helpers";

import "./OffersOverlay.scss";

const OffersOverlay = (props) => {
  const { prismicMyOffersModel } = useModel();
  const { pathname } = useLocation();

  if (
    !prismicMyOffersModel ||
    isEmpty(prismicMyOffersModel.items) ||
    prismicMyOffersModel.excludePaths.some((path) => pathname.includes(path))
  ) {
    return null;
  }

  const [isScrolledBottom, setIsScrolledBottom] = useState(false);
  const [lastCopiedIndex, setLastCopiedIndex] = useState(null);

  useEffect(() => {
    if (isScrolled()) {
      setIsScrolledBottom(true);
    }
    window.addEventListener(
      "scroll",
      debounce(() => setIsScrolledBottom(isScrolled()), 100)
    );
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [isOverlay, setIsOverlay] = useState(false);
  const country = useCountry();

  const offers = applyPromoCodes(
    prismicMyOffersModel.items,
    country,
    lastCopiedIndex,
    setLastCopiedIndex,
    prismicMyOffersModel.titlePromoCopied
  );

  const transitions = useTransition(isActive, null, {
    from: { transform: "translateY(100%)" },
    enter: { transform: "translateY(0%)" },
    leave: { transform: "translateY(100%)" },
    config: { ...config.default, duration: 150 },
    onRest: () => {
      if (!isActive) {
        setIsOverlay(false);
      }
    },
  });

  return (
    <div
      className={cx(
        "OffersOverlay",
        { "OffersOverlay--is-overlay": isOverlay },
        { "OffersOverlay--is-hidden": isScrolledBottom }
      )}
    >
      <div className="OffersOverlay__bg" onClick={() => setIsActive(false)} />
      <div className="OffersOverlay__cta">
        <Button
          className="OffersOverlay__btn"
          onClick={() => {
            setIsActive(true);
            setIsOverlay(true);
          }}
        >
          {prismicMyOffersModel.title}
        </Button>
      </div>
      {transitions.map(
        (spring) =>
          spring.item && (
            <animated.div key={spring.key} style={spring.props}>
              <div className="OffersOverlay__wrapper">
                <div className="OffersOverlay__cta">
                  <Button
                    className="OffersOverlay__btn"
                    onClick={() => setIsActive(false)}
                  >
                    {prismicMyOffersModel.titleClose}
                  </Button>
                </div>
                <ScrollableCards>
                  {offers.map((item, idx) => (
                    <div key={idx} className="OffersOverlay__offer">
                      <OfferCard
                        {...item}
                        title={item.promoTitle || item.title}
                      />
                    </div>
                  ))}
                </ScrollableCards>
              </div>
            </animated.div>
          )
      )}
    </div>
  );
};

export default OffersOverlay;
