const PropTypes = require("prop-types");
const BaseModel = require("../base/BaseModel");

class DefaultPromoBannerModel extends BaseModel {
  constructor() {
    super();
    this.text = null;
    this.promoCode = null;
    this.supportingText = null;
    this.background = null;
    this.textColour = null;
    this.promoCodeBackground = null;
    this.promoCodeTextColour = null;
    this.trustpilotTheme = null;
    this.trustpilotBackgroundColor = null;
    this.trustpilotWidgetVisible = true;
  }

  deserialise(document) {
    super.deserialise(document);
    this.text = document.data.text;
    this.promoCode = document.data.promo_code;
    this.supportingText = document.data.supporting_text;
    this.background = document.data.background;
    this.textColour = document.data.text_colour;
    this.promoCodeBackground = document.data.promo_code_background;
    this.promoCodeTextColour = document.data.promo_code_text_colour;
    this.trustpilotTheme = document.data.trustpilot_theme;
    this.trustpilotBackgroundColor = document.data.trustpilot_background_color;
    this.trustpilotWidgetVisible =
      document.data.trustpilot_widget_visible === "true";
    return this;
  }
}

DefaultPromoBannerModel.propTypes = PropTypes.shape({
  text: PropTypes.string.isRequired,
  promoCode: PropTypes.string,
  supportingText: PropTypes.string,
  background: PropTypes.string.isRequired,
  textColour: PropTypes.string.isRequired,
  promoCodeBackground: PropTypes.string,
  promoCodeTextColour: PropTypes.string,
  trustpilotWidgetVisible: PropTypes.bool,
  trustpilotTheme: PropTypes.string,
  trustpilotBackgroundColor: PropTypes.string,
});

module.exports = DefaultPromoBannerModel;
