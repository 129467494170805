import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import withProps from "recompose/withProps";
import { withFormik } from "formik";
import get from "lodash/get";

import withValidationData from "../../../hocs/withValidationData";
import { validateCreationForm } from "../helpers";

import { createValues } from "./helpers";
import Input from "./Input";

/*
 * Single input drawer with custom key so you can have multiple drawers in a form
 **/
const enhance = compose(
  withValidationData,
  withFormik({
    mapPropsToValues: (props) => {
      return createValues(
        props.drawer.drawerKey,
        props.productCustomisation,
        props.autocapitaliseInputs
      );
    },
    handleSubmit: (values, formikBag) => {
      return validateCreationForm(values, formikBag.props, {
        enableSetErrors: false,
      }).then((errors) => {
        if (errors) {
          formikBag.setErrors(errors);
        } else {
          formikBag.props.onFormikSubmit(values);
        }
      });
    },
  }),
  withHandlers({
    handleOnChangeItem: (props) => (e) => {
      props.setFieldValue(props.drawer.drawerKey, e.target.value);
    },
  }),
  withProps((props) => {
    return {
      errorMessage: get(props.errors, props.drawer.drawerKey),
    };
  })
);

export default enhance(Input);
