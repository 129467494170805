import React from "react";
import PropTypes from "prop-types";
import PromoBanner from "./PromoBanner";
import isEmpty from "lodash/isEmpty";

const PromoBanners = ({ banners, clientBanners }) => {
  const b = !isEmpty(clientBanners) ? clientBanners : banners;
  return b.map((banner) => (
    <PromoBanner key={banner.idx} promoBanner={banner} />
  ));
};

PromoBanners.propTypes = {
  banners: PropTypes.arrayOf(PropTypes.object),
  clientBanners: PropTypes.arrayOf(PropTypes.object),
};

export default PromoBanners;
