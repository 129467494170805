import useCountry from "./use-country";
import { useFetchEagle } from "./use-fetch";
import { fetchOrderV2 as fetchUserOrder } from "../../facades/eagle/user";
import { getAndAttachProduct } from "./helpers";

export default (id) => {
  const { locale } = useCountry();

  return useFetchEagle(fetchUserOrder, {
    id,
    map: async (response) =>
      await getAndAttachProduct(response.body.data, locale),
  });
};
