import FormatOption from "./FormatOption";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import get from "lodash/get";
import omit from "lodash/omit";

const enhance = compose(
  withHandlers({
    onChange:
      ({ variant: { formatId, options }, onFormikSubmit }) =>
      () => {
        onFormikSubmit({
          format_identifier: formatId,
          ...omit(options, ["phototypes", "stock_group"]),
        });
      },
  }),
  withProps((props) => {
    let variant;
    if (props.variant) {
      const [formatted, currency] = get(
        props.variant,
        "price.formatted",
        ""
      ).split(" ");
      variant = {
        ...props.variant,
        price: {
          formatted,
          currency,
        },
      };
    }
    return {
      isSelected: props.formatIdentifier === props.prismicFormat.formatId,
      variant,
    };
  })
);

export default enhance(FormatOption);
