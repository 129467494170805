import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "src/client/js/view/components/Grid";
import Button from "wonderbly-components/lib/Button";
import Tooltip from "wonderbly-components/lib/Tooltip";
import CompleteTick from "wonderbly-components/lib/CompleteTick";
import { isCTADisabled } from "./helpers";

import has from "lodash/has";
import snakeCase from "lodash/snakeCase";

import "./Qualities.scss";

const Qualities = (props) => (
  <Row colsToRowsOn="base">
    <Col>
      <form onSubmit={props.handleSubmit} className="qualities-form">
        <Tooltip
          className="error-tooltip values-tooltip"
          visible={props.errors && !!props.errors[props.customisationKey]}
          absolute={false}
          type="alert"
        >
          {props.errors && props.errors[props.customisationKey]}
        </Tooltip>
        <Row colsToRowsOn="base">
          <Col>
            <div className="qualities-wrapper">
              {props.drawer.items.map((item) => {
                const value = item.id || snakeCase(item.title);
                const checked =
                  props.values[props.customisationKey].includes(value);
                return (
                  <div key={value} className="qualities-item">
                    <input
                      type="checkbox"
                      id={value}
                      onChange={props.handleChange}
                      value={value}
                      checked={checked}
                    />
                    <label htmlFor={value}>
                      {has(item, "icon.url") && (
                        <img
                          src={item.icon.url}
                          alt={item.icon.alt || ""}
                          width="25"
                        />
                      )}
                      {item.title}
                      {checked && <CompleteTick ticked />}
                    </label>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
        <Row className="cols-to-rows">
          <Col className="qualities-cta-button">
            <Button type="submit" disabled={isCTADisabled(props)}>
              {props.drawer.cta}
            </Button>
          </Col>
        </Row>
      </form>
    </Col>
  </Row>
);

Qualities.propTypes = {
  customisationKey: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
      })
    ).isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Qualities;
