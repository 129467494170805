import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "src/client/js/view/components/Grid";
import ButtonRadio from "wonderbly-components/lib/ButtonRadio";
import Tooltip from "wonderbly-components/lib/Tooltip";

const Gender = (props) => [
  <Tooltip
    key="error"
    className="error-tooltip adventurer-gender-tooltip"
    visible={!!props.error}
    absolute={!props.tabletDown}
    type="alert"
  >
    {props.error}
  </Tooltip>,
  <Row key="main">
    {/* Need to add an empty field to be able to unset the selection of gender when the form resets */}
    <input
      type="radio"
      name="gender"
      value=""
      id="gender-empty"
      defaultChecked={props.gender === ""}
    />
    <Col>
      <ButtonRadio
        id="gender-boy"
        value="boy"
        name="gender"
        className="adventurer-gender-input adventurer-gender--boy"
        onClick={props.onHandleChange}
        checked={props.gender === "boy"}
      >
        {props.labels.boy}
      </ButtonRadio>
    </Col>
    <Col>
      <ButtonRadio
        id="gender-girl"
        value="girl"
        name="gender"
        className="adventurer-gender-input adventurer-gender--girl"
        onClick={props.onHandleChange}
        checked={props.gender === "girl"}
      >
        {props.labels.girl}
      </ButtonRadio>
    </Col>
  </Row>,
];

Gender.propTypes = {
  onHandleChange: PropTypes.func.isRequired,
  gender: PropTypes.oneOf(["", "girl", "boy"]),
  error: PropTypes.string,
  labels: PropTypes.shape({
    boy: PropTypes.string.isRequired,
    girl: PropTypes.string.isRequired,
  }).isRequired,
  tabletDown: PropTypes.bool.isRequired,
};

export default Gender;
