class ErrorBase extends Error {
  constructor(message, originalError) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else if (originalError) {
      this.stack = originalError.stack;
    }
  }
}

module.exports = ErrorBase;
