const isObject = require("lodash/isObject");
const isArray = require("lodash/isArray");
const truncate = require("lodash/truncate");
const get = require("lodash/get");
const arrayToSentence = require("./array-to-sentence");

const replaceTextWithValues = (string, values, truncateLength) => {
  if (!string || typeof string !== "string") {
    return "";
  }
  // https://regex101.com/r/Ua9hWR/1
  const value = string.replace(/%{([\w\s.|\[\]\-]+)}/g, (match) => {
    const field = match.replace(/(%{|})/g, "");
    const [path, defaultValue] = field.split(/[|]/g);

    const customisation = get(values, path);

    if (typeof customisation === "undefined" || customisation === null) {
      return defaultValue || match;
    }

    // avatars
    if (isArray(customisation)) {
      // avatars 3.0
      if (customisation[0].customisations) {
        return arrayToSentence(
          values.characters.reduce((acc, c) => {
            return acc.concat(
              c.customisations.map(
                (character) => character.firstname || character.name
              )
            );
          }, [])
        );
      }

      // avatars 2.0
      return (
        arrayToSentence(
          customisation.reduce((acc, item) => {
            let value = isObject(item) ? item.value || item.name || "" : item;

            if (value) {
              acc.push(value);
            }

            return acc;
          }, [])
        ) || match
      );
    }

    if (isObject(customisation) && customisation.label) {
      return customisation.label;
    }

    return customisation;
  });

  return truncateLength
    ? truncate(value, { length: truncateLength, separator: /[,\n]+/ })
    : value;
};

const replaceRichText = (richText = [], values, truncateLength) =>
  richText.map((element) => ({
    ...element,
    text: replaceTextWithValues(element.text, values, truncateLength),
  }));

module.exports = {
  replaceTextWithValues,
  replaceRichText,
};
