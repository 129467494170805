import React from "react";
import PropTypes from "prop-types";

import "./BasketItem.scss";

const BasketItem = (props) => (
  <div className="basket-item">
    <span className="basket-item-heading">
      {props.item.format.product.id}
      {props.item.customisations.name
        ? ` for ${props.item.customisations.name}`
        : ""}
    </span>
    <span className="basket-item-price">{props.item.price.formatted}</span>
  </div>
);

BasketItem.propTypes = {
  item: PropTypes.shape({
    format: PropTypes.shape({
      product: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
    customisations: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    price: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
    }),
  }),
};

export default BasketItem;
