import SiteLoader from "./SiteLoader";
import compose from "recompose/compose";
import lifecycle from "recompose/lifecycle";
import { connect } from "react-redux";
import {
  getAppLoadingState,
  getAppOverlayState,
} from "../../../store/selectors/app";
import { setOpenOverlay, setCloseOverlay } from "../../../store/actions/app";

const enhance = compose(
  connect(
    (state) => ({
      isOpen: getAppLoadingState(state),
      overlayActive: getAppOverlayState(state),
    }),
    (dispatch) => ({
      openOverlay: (scrollPos) => dispatch(setOpenOverlay(scrollPos)),
      closeOverlay: () => dispatch(setCloseOverlay()),
    })
  ),
  lifecycle({
    componentDidMount() {
      const props = this.props;
      if (props.isOpen && window) {
        props.openOverlay(window.scrollY);
      }
    },
    componentDidUpdate(prevProps) {
      const props = this.props;
      if (prevProps.isOpen !== props.isOpen) {
        if (props.isOpen) {
          props.openOverlay(window.scrollY);
        } else {
          props.closeOverlay();
        }
      }
    },
  })
);

export default enhance(SiteLoader);
