import set from "lodash/set";
import get from "lodash/get";
import merge from "lodash/merge";
import formatAdventurerName from "src/common/utils/format-adventurer-name";

export const createValues = (
  drawerKey,
  productCustomisation,
  autocapitaliseInputs
) => {
  const values = { base: "" };
  let value = get(productCustomisation, drawerKey) || "";

  value = autocapitaliseInputs ? formatAdventurerName(value) : value;

  set(values, drawerKey, value);

  const initialKey = drawerKey.match(/[^\]\[.]+/g)[0];
  const customisationValues = productCustomisation[initialKey];

  return merge({ [initialKey]: customisationValues }, values);
};
