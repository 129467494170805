import React from "react";
import PropTypes from "prop-types";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { trackAdditionalLinks } from "../tracking";
import { useModel } from "../../../hooks";
import "./SiteAdditionalLinks.scss";

const SiteAdditionalLinks = (props) => {
  const { countryModel } = useModel();

  return (
    <ul className="SiteAdditionalLinks">
      {props.items?.map((link, index) => (
        <li key={index} className="SiteAdditionalLinks__link">
          <a
            className="SiteAdditionalLinks__link-text"
            onClick={() => trackAdditionalLinks(link.linkText)}
            href={localiseUrlII(countryModel, link.linkUrl)}
          >
            {link.linkText}
          </a>
        </li>
      ))}
    </ul>
  );
};

SiteAdditionalLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      linkUrl: PropTypes.string,
      linkText: PropTypes.string,
    })
  ),
};

export default SiteAdditionalLinks;
