import reduce from "lodash/reduce";
import get from "lodash/get";
import kebabCase from "lodash/kebabCase";
import isEmpty from "lodash/isEmpty";
import {
  findProductVariants,
  findProductVariant,
} from "src/common/utils/product";

const joinAndSanitize = (commaSepratedString) => {
  return commaSepratedString
    ? commaSepratedString.split(",").map((s) => s.trim())
    : [];
};

export const getFormats = ({
  drawer: { items, defaultFormats = {} } = {},
  product: { variants },
  productCustomisation,
  model,
}) => {
  const { locale, gender, phototype } = productCustomisation;
  // Get the default translations for all formats
  defaultFormats =
    get(defaultFormats, "defaultFormats.bookFormats", null) || [];
  // Filter out any variants that are not from locale selected by the user
  const localisedVariants =
    findProductVariants(variants, { locale }, model.countryModel) || [];
  const localisedFormatIds = localisedVariants.map(
    (variant) => variant.formatId
  );

  // Filter down the Prismic formats to show only what Eagle has for the selected locale
  const availableFormats = items.filter((item) =>
    localisedFormatIds.includes(item.formatId)
  );

  const generalContent = get(model, "prismicGlobalContentModel.general");
  const formatsToHide = joinAndSanitize(generalContent.formatsToHide); // ['layflat'] //
  const hideFormatsOnTheseWebsites = joinAndSanitize(
    generalContent.hideFormatsOnTheseWebsites
  ); //['ie']

  const websitePrefix = model.countryModel.prefix;

  return availableFormats.reduce((formats, format) => {
    // This hides formats in specific locales.
    //
    // Note there is similar functionality in: src/client/js/view/components/FormatSelector/FormatSelector.js
    if (!(isEmpty(formatsToHide) || isEmpty(hideFormatsOnTheseWebsites))) {
      // check if the current website prefix is in the exclude list
      if (hideFormatsOnTheseWebsites.includes(websitePrefix)) {
        // check if the current format is excluded - we just to an includes to match,
        // say, the layflat part of the id
        const anyMatchingFormatsToHide = formatsToHide.find((formatMatch) =>
          format.formatId.includes(formatMatch)
        );

        if (anyMatchingFormatsToHide) {
          return formats;
        }
      }
    }

    const itemGender = kebabCase(format.gender);
    const itemPhototype = kebabCase(format.phototype);

    // Get the correct content from Prismic based on the options selected by the user,
    // as to show the correct format based on gender and phototype
    if (
      (itemGender === gender || itemGender === "all") &&
      (itemPhototype === phototype || itemPhototype === "all")
    ) {
      const defaultFormat =
        defaultFormats.find((f) => f.formatId === format.formatId) || {};

      format = reduce(
        format,
        (f, value, key) => {
          if (value) {
            f[key] = value;
          } else if (!isEmpty(defaultFormat) && defaultFormat[key]) {
            f[key] = defaultFormat[key];
          }

          return f;
        },
        {}
      );

      // Get the specific variant information
      format.variant = findProductVariant(localisedVariants, {
        gender,
        formatId: format.formatId,
      });

      return formats.concat(format);
    }

    return formats;
  }, []);
};
