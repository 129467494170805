import get from "lodash/get";

export const getCart = (state) => state.session.cart;

export const getEstimatedCountry = (state) =>
  get(state, "components.cartShipping.estimatedCountry", "");

export const getStatus = (state) => ({
  request: state.session.cart.request || {},
  response: state.session.cart.response || {},
});

export const getItems = (state) => {
  const cart = getCart(state);
  return cart && cart.items ? cart.items : [];
};

export const isCartEmpty = (state) => getItems(state).length === 0;

export const isCartPending = (state) => !!getStatus(state).response.pending;

export const hasCartFailed = (state) =>
  !isCartPending(state) && !!getStatus(state).response.isCartError;

export const getCount = (state) => getItems(state).length;

export const getTotalPrice = (state) =>
  get(getCart(state), "totals_v2.order") ||
  get(getCart(state), "totals.order") ||
  {};

export const getCartErrors = (state) =>
  get(getStatus(state), "response.details.errors", []);

export const getOrderDetails = (state) => getCart(state).orderDetails;

export const isShippingMethodsLoading = (state) =>
  get(getCart(state), "shippingMethods.isLoading", true);
