import pickBy from "lodash/pickBy";
import uniq from "lodash/uniq";
import get from "lodash/get";

import { remapLocale } from "src/common/l10n/country";

const inactiveOrderStates = ["canceled", "delivered"];

export const getProductsFromOrders = (orders, countryLocale) =>
  orders.reduce((products, { items }) => {
    const countryContentLocale = remapLocale(countryLocale);

    items.map(({ productSku, customisation: { locale } }) => {
      const customisationLocale = remapLocale(locale);
      const productInList = products.some(({ sku, contentLocales }) => {
        return sku === productSku && contentLocales[0] === customisationLocale;
      });

      if (productInList) {
        return products;
      }

      products.push({
        sku: productSku,
        contentLocales: uniq([customisationLocale, countryContentLocale]),
      });
    });

    return products;
  }, []);

export const attachPrismicProductsToOrders = (
  orders,
  prismicProducts,
  countryLocale,
  pickedKeys = []
) => {
  const countryContentLocale = remapLocale(countryLocale);
  return orders.reduce((result, order) => {
    const items = order.items.map((item) => {
      const {
        productSku,
        customisation: { locale },
      } = item;
      const customisationLocale = remapLocale(locale);
      const prismicProduct =
        prismicProducts.find((content) => {
          const productId = get(content, "main.productId");
          const lang = get(content, "metadata.lang");
          return (
            productId === productSku &&
            (lang === customisationLocale || lang === countryContentLocale)
          );
        }) || {};

      return {
        ...item,
        ...pickBy(prismicProduct, (value, key) => {
          if (pickedKeys.length === 0) {
            return true;
          }
          return pickedKeys.includes(key);
        }),
      };
    });

    result.push({
      ...order,
      isInactive: inactiveOrderStates.includes(order.state),
      isCancelled: order.state === "canceled",
      items,
    });
    return result;
  }, []);
};
