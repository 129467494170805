import React from "react";
import PropTypes from "prop-types";
import Messages from "./Messages";
import "./FlashMessages.scss";

const FlashMessages = ({ errors, notices }) => {
  const isServerSide = typeof window === "undefined";

  return (
    <div className="flash-messages">
      {!isServerSide && (
        <>
          <Messages messageType="notice" messages={notices} />
          <Messages messageType="error" messages={errors} />
        </>
      )}
    </div>
  );
};

FlashMessages.propTypes = {
  notices: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FlashMessages;
