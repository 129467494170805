import { ALLOW_COOKIES } from "../../actions/cookies/cookieConsent";

export default (state = { allowCookies: true }, action) => {
  switch (action.type) {
    case ALLOW_COOKIES: {
      return {
        ...state,
        allowCookies: action.allowCookies,
      };
    }
    default: {
      return state;
    }
  }
};
