import React from "react";
import { useSiteNavContext } from "../SiteNavHeader/SiteNavToggle/SiteNavContext";
import { useModel, useCountry, useCountries } from "../../../hooks";
import NavCountryOptions from "./NavCountryOptions";

export const ChooseYourCountryModal = () => {
  const { isChooseCountryModalOpen, setIsChooseCountryModalOpen } =
    useSiteNavContext();
  const country = useCountry();
  const countries = useCountries();
  const { prismicSiteNavigationModel } = useModel();

  if (!isChooseCountryModalOpen) {
    return null;
  }

  return (
    <NavCountryOptions
      for="country"
      options={countries}
      currentValue={country}
      title={prismicSiteNavigationModel.changeCountryLabel}
      open={() => setIsChooseCountryModalOpen(true)}
      onClose={() => setIsChooseCountryModalOpen(false)}
      changeCountryLink={prismicSiteNavigationModel.otherCountryLabel}
    />
  );
};

export default ChooseYourCountryModal;
