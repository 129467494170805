const url = require("url");
const get = require("lodash/get");
const isNumber = require("lodash/isNumber");

/*
 * legacy prismic host - this will be deprecated on 11/2019
 */
const PRISMIC_ASSET_HOST = "wonderbly.cdn.prismic.io";

/*
 * new prismic image host that goes via IMGIX
 * this will be the new default for all prismic images
 */
const PRISMIC_IMGIX_HOST = "images.prismic.io";

const maxImageWidth = 1920;

// If the url is a prismic url then add optimisation query params
// for IMGIX
const optimiseImage = (imageUrl, config = { auto: "format,compress" }) => {
  if (!imageUrl) {
    return imageUrl;
  }

  const imageUrlObject = {
    ...url.parse(imageUrl),
    // The search and query values are coming from Prismic/Imgix.
    // We do not need them, because we are the ones to supply the
    // desired configuration in the query params below.
    search: null,
    query: null,
  };

  const query = {
    auto: "format,compress",
    dpr: config.dpr,
    w: config.width || null,
    h: config.height || null,
    fit: config.fit || null,
    crop: config.crop || null,
    q: config.q || 35,
    "gif-q": 90, // gif compression - higher is more compression
  };

  // fail safe to avoid division by zero
  if (query.dpr === 0) {
    query.dpr = 1;
  }

  if (isNumber(query.w) && !isNumber(query.h)) {
    // cap width
    query.w = Math.min(maxImageWidth, query.w);
  } else if (!isNumber(query.w) && !isNumber(query.h)) {
    // no width specified - this is probably bad - but not sure what do here
    // going to leave this code block here until we make a decision.
    // yotam 2019-10-04
  }

  // legacy prismic image format - point this to the new prismic CDN
  // we're not going to use our IMGIX folder anymore
  if (imageUrlObject.host === PRISMIC_ASSET_HOST) {
    return url.format({
      protocol: "https:",
      host: PRISMIC_IMGIX_HOST,
      pathname: imageUrlObject.pathname,
      query,
    });
  }

  // new imgix/prismic image format
  if (imageUrlObject.host === PRISMIC_IMGIX_HOST) {
    return url.format({
      ...imageUrlObject,
      query,
    });
  }

  return imageUrl;
};

/**
 * @link https://blog.imgix.com/2016/03/30/dpr-quality.html
 * @link https://docs.imgix.com/tutorials/responsive-images-client-hints
 */
// Takes image object and returns optimized prismic image url with dpr injected
const prismicImage = (image, config = {}) => {
  config = {
    width: get(image, "dimensions.width"),
    ...config,
    // dpr should be injected, not derived
    // it doesn't make any sense when SSR.
    // dpr: config.dpr || getDevicePixelRatio(),
  };

  return optimiseImage(image.url, config);
};

const dprList = [{ dpr: 1 }, { dpr: 2 }];

// Takes image object and returns an object with src and srcSet parameters
const prismicDPRSrcSet = (image) => {
  const srcSet = dprList.map(
    (config) =>
      prismicImage(image, config) + (config.dpr > 1 ? ` ${config.dpr}x` : "")
  );

  return {
    src: srcSet[0],
    srcSet: srcSet.join(", "),
  };
};

module.exports = {
  prismicImage,
  optimiseImage,
  prismicDPRSrcSet,
};
