import React from "react";
import PropTypes from "prop-types";
import { WonderblyLogo, WonderblyIcon } from "../../SVGs";
import { localiseUrl } from "src/common/utils/format-localised-url";
import "./Logo.scss";

const Logo = (props) => (
  <a
    onClick={props.onClickLink}
    data-analytics-label="Logo"
    href={localiseUrl(props.country, "/")}
    className="header-logo"
  >
    <div className="header-logo-inner">
      <WonderblyLogo className="wonderbly-logo" />
      {!props.isCheckout && (
        <div className="wonderbly-icon">
          <WonderblyIcon />
        </div>
      )}
    </div>
  </a>
);

Logo.propTypes = {
  onClickLink: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  isCheckout: PropTypes.bool,
};

export default Logo;
