import differenceInYears from "date-fns/differenceInYears";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInWeeks from "date-fns/differenceInWeeks";
import differenceInDays from "date-fns/differenceInDays";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInSeconds from "date-fns/differenceInSeconds";
import addYears from "date-fns/addYears";
import addMonths from "date-fns/addMonths";
import addWeeks from "date-fns/addWeeks";
import addDays from "date-fns/addDays";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import addSeconds from "date-fns/addSeconds";
import isBefore from "date-fns/isBefore";

import { replaceTextWithValues } from "src/common/utils/text-replace";
import arrayToSentence from "src/common/utils/array-to-sentence";

const diffFns = {
  year: differenceInYears,
  month: differenceInMonths,
  week: differenceInWeeks,
  day: differenceInDays,
  hour: differenceInHours,
  minute: differenceInMinutes,
  second: differenceInSeconds,
};

const addFns = {
  year: addYears,
  month: addMonths,
  week: addWeeks,
  day: addDays,
  hour: addHours,
  minute: addMinutes,
  second: addSeconds,
};

const tickIntervals = {
  year: 31536000,
  month: 2618784,
  week: 604800,
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1,
};

const maxCountdownOptions = 3;

const defaultTimeStrings = {
  year: "%{val} year",
  years: "%{val} years",
  month: "%{val} month",
  months: "%{val} months",
  week: "%{val} week",
  weeks: "%{val} weeks",
  day: "%{val} day",
  days: "%{val} days",
  hour: "%{val} hour",
  hours: "%{val} hours",
  minute: "%{val} minute",
  minutes: "%{val} minutes",
  second: "%{val} second",
  seconds: "%{val} seconds",
};

// Safari seem to have a problem when trying to turn a string
// of this format "YYYY-MM-DDThh:mm:ssTZD" into a date, for that
// reason we need to format the string into "YYYY/MM/DD hh:mm:ssTZD"
export const parseDate = (date) => {
  const parsed = Date.parse(date);
  if (!isNaN(parsed)) {
    return parsed;
  }
  const hyphensBeforeTime = /(?!T.+)-/g;
  const anyAlphabeticalCharacter = /[a-z]+/gi;
  return Date.parse(
    date.replace(hyphensBeforeTime, "/").replace(anyAlphabeticalCharacter, " ")
  );
};

export const getHumanCountdown = (
  date,
  timeStrings,
  { dateNow = new Date(), conjunction } = {}
) => {
  let dateX = dateNow;
  let dateY = new Date(parseDate(date));
  let humanCountdownParts = [];
  let finalDiffKey = "";
  let finalDiff = 0;
  const diffKeys = Object.keys(diffFns);

  // Return 0 seconds if in past
  if (isBefore(dateY, dateX)) {
    return {
      humanCountdown: replaceTextWithValues(
        timeStrings.seconds || defaultTimeStrings.seconds,
        {
          val: 0,
        }
      ),
      remainderInSeconds: 0,
    };
  }

  const maxCountdownOptionsLocal = Math.min(
    maxCountdownOptions,
    diffKeys.length - 1
  );

  let counter = 0;

  for (let i = 0; i < diffKeys.length; i++) {
    const diffKey = diffKeys[i];
    const diffFn = diffFns[diffKey];
    const addFn = addFns[diffKey];

    const diff = diffFn(dateY, dateX);
    const replacementValue = { val: diff };
    let diffHuman = "";

    if (diff) {
      let timeString = timeStrings[diffKey] || defaultTimeStrings[diffKey];

      if (diff > 1) {
        timeString =
          timeStrings[`${diffKey}s`] || defaultTimeStrings[`${diffKey}s`];
      }

      diffHuman = replaceTextWithValues(timeString, replacementValue);
      humanCountdownParts.push(diffHuman);

      counter += 1;
    }

    if (counter === maxCountdownOptionsLocal) {
      finalDiffKey = diffKey;
      finalDiff = diff;
      break;
    }

    dateX = addFn(dateX, diff);
    finalDiffKey = diffKey;
    finalDiff = diff;
  }

  const remainderInSeconds = Math.round(
    (differenceInSeconds(dateY, dateX) / tickIntervals[finalDiffKey] -
      finalDiff) *
      tickIntervals[finalDiffKey]
  );

  const humanCountdown = arrayToSentence(humanCountdownParts, { conjunction });

  return {
    humanCountdown,
    remainderInSeconds,
  };
};
