import React from "react";

export const context = React.createContext();
export const { Consumer, Provider } = context;

export const withModel = (ComponentToWrap) => {
  return (props) => (
    <Consumer>
      {(model) => <ComponentToWrap {...props} model={model} />}
    </Consumer>
  );
};

export function useIsHeadless() {
  const value = React.useContext(context);
  return value.__headless;
}
