import prismicProductFacade from "../../facades/prismic/product";
import logger from "src/logger";

// TODO move these to this file
import {
  getProductsFromOrders,
  attachPrismicProductsToOrders,
} from "../components/RecentOrders/helpers";

export const getAndAttachProducts = async (
  orders,
  countryLocale,
  pickedKeys = []
) => {
  const prismicProducts = await Promise.all(
    getProductsFromOrders(orders, countryLocale).map(
      async ({ sku, contentLocales }) => {
        try {
          return await prismicProductFacade.getByMultipleLocales(
            sku,
            contentLocales
          );
        } catch (e) {
          logger.warn(
            `Unable to fetch Prismic product for "${sku}" in these locales: ${contentLocales.join(
              ","
            )}`
          );
          return {};
        }
      }
    )
  );

  return attachPrismicProductsToOrders(
    orders,
    prismicProducts,
    countryLocale,
    pickedKeys
  );
};

export const getAndAttachProduct = async (order, countryLocale, pickedKeys) =>
  (await getAndAttachProducts([order], countryLocale, pickedKeys))[0];
