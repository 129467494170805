import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import qs from "qs";
import Button from "wonderbly-components/lib/Button";
import AvatarCard from "wonderbly-components/lib/AvatarCard";
import AvatarCreationModal from "../../AvatarCreationModal";
import { useModel, useCountry } from "src/client/js/view/hooks";
import { replaceTextWithValues } from "src/common/utils/text-replace";
import { localiseUrlII } from "src/common/utils/format-localised-url";

import "./Avatars.scss";

const getCharacters = (characterTypes) => {
  const characters = [];
  for (const characterType of characterTypes) {
    for (const [idx, character] of characterType.customisations.entries()) {
      characters.push({
        type: characterType.type,
        character: idx,
        count: characterType.customisations.length,
        ...character,
      });
    }
  }
  return characters;
};

const Avatars = (props) => {
  const drawer = props.drawer;
  // needs model.prismicProduct here to get the main locale product of the site you are on,
  // not the localised product based on the url param of locale=fr
  // eg: when making an fr book in en-US locale.
  const {
    prismicProduct,
    topology,
    prismicGlobalContentModel,
    productAvatarCreation,
  } = useModel();
  const imageEndpoint = topology.preview.avatar.replaceAll(
    "{sku}",
    prismicProduct.main.productId
  );
  const characterTypes =
    get(props.productCustomisation, drawer.customisationKey) || [];
  const characters = getCharacters(characterTypes);
  const [avatarCreationVisible, setAvatarCreationVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const country = useCountry();

  const onEditCharacter = (char) => () => {
    setEditData({
      charType: char.type,
      charIndex: char.character,
      payload: { [drawer.customisationKey]: characterTypes },
    });
    setAvatarCreationVisible(true);
  };

  const onComplete = (data) => {
    if (editData) {
      props.onFormikSubmit(data, props.activeDrawer);
      setAvatarCreationVisible(false);
      setEditData(null);
    } else {
      setAvatarCreationVisible(false);
    }
  };

  const onCloseDrawer = () => {
    props.onToggleDrawer();
  };

  return (
    <div className="Avatars">
      <Button
        href={localiseUrlII(
          country,
          `/personalized-products/${prismicProduct.productInformation.productSlug}`
        )}
      >
        {drawer.startAgainCta}
      </Button>
      <div className="Avatars__list">
        {characters.map((character, idx) => {
          const avatarImage = `${imageEndpoint}&${qs.stringify(character)}`;
          return (
            <AvatarCard
              key={idx}
              image={avatarImage}
              backgroundColor="#EFEEF5"
              title={replaceTextWithValues(drawer.characterTitle, character)}
              cta={drawer.editCharacterCta}
              onClick={onEditCharacter(character)}
            />
          );
        })}
      </div>
      {drawer.saveCta && (
        <Button onClick={onCloseDrawer}>{drawer.saveCta}</Button>
      )}
      <AvatarCreationModal
        className="ProductHeroII__avatar-modal"
        isOpen={avatarCreationVisible}
        schemaEndpoint={topology.preview.schema}
        payloadBaseParams={{
          locale: props.productCustomisation.locale,
          product_slug: props.productCustomisation.product_slug,
        }}
        sku={prismicProduct.main.productId}
        onHandleClose={onComplete}
        onHandleAllComplete={onComplete}
        globalUiLabels={prismicGlobalContentModel.avatars}
        avatarUiLabels={productAvatarCreation}
        editData={editData}
      />
    </div>
  );
};

Avatars.propTypes = {
  activeDrawer: PropTypes.string,
  productCustomisation: PropTypes.object.isRequired,
  drawer: PropTypes.shape({
    customisationKey: PropTypes.string.isRequired,
    startAgainCta: PropTypes.string.isRequired,
    characterTitle: PropTypes.string.isRequired,
    editCharacterCta: PropTypes.string.isRequired,
    saveCta: PropTypes.string.isRequired,
  }).isRequired,
  onFormikSubmit: PropTypes.func.isRequired,
  onToggleDrawer: PropTypes.func.isRequired,
  productCustomisation: PropTypes.object,
  prismicProduct: PropTypes.object,
};

export default Avatars;
