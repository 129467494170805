import logger from "src/logger";
import React, { Component, Children, cloneElement } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import isNil from "lodash/isNil";
import Drag from "../Drag";
import Icon from "wonderbly-components/lib/Icon";
import "./Carousel.scss";

class Carousel extends Component {
  static propTypes = {
    group: PropTypes.string,
    children: PropTypes.any.isRequired,
    childCount: PropTypes.number.isRequired,
    thumbnails: PropTypes.any,
    disableDrag: PropTypes.bool,
    disableNavigation: PropTypes.bool,
    disablePagination: PropTypes.bool,
    externalNavigation: PropTypes.bool,
    currentIndex: PropTypes.number,
    updateSlideIndex: PropTypes.func,
  };

  state = {
    activeIndex: 0,
    carouselWidth: 0,
    isDragging: false,
    distance: 0,
  };

  componentDidMount() {
    this.goToPage(this.props.currentIndex || 0);
    this.setCarouselWidth();
    window.addEventListener("resize", this.setCarouselWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setCarouselWidth);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activeIndex !== this.state.activeIndex) {
      if (prevProps.updateSlideIndex) {
        prevProps.updateSlideIndex(this.state.activeIndex)();
      }
    }
    if (
      prevProps.currentIndex !== this.props.currentIndex &&
      this.props.currentIndex !== prevState.activeIndex
    ) {
      this.goToPage(this.props.currentIndex);
    }
  }

  // Styling
  onRef = (carousel) => {
    if (this._carousel !== carousel) {
      this._carousel = carousel;
    }
  };

  getCarouselWidth = () => {
    const width = this._carousel.getBoundingClientRect().width;
    let padding, border;
    try {
      const cs = getComputedStyle(this._carousel);
      padding = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
      border = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);
    } catch (e) {
      padding = 0;
      border = 0;
    }
    return width - (padding + border);
  };

  setCarouselWidth = () => {
    this.setState(() => ({
      carouselWidth: this.getCarouselWidth(),
    }));
  };

  getTransform = () => {
    const factor = this.state.carouselWidth;
    let draggedDistance = 0;
    if (this.state.isDragging) {
      draggedDistance = this.state.distance;
    }
    return `translateX(${
      this.state.activeIndex * factor * -1 + draggedDistance
    }px)`;
  };

  // Event Handlers

  handleDragStart = (state) => {
    this.setState(() => ({
      isDragging: state.isDragging,
    }));
  };

  handleDragMove = (state) => {
    this.setState(() => ({
      isDragging: state.isDragging,
      distance: state.distance,
    }));
  };

  handleDragEnd = (state) => {
    this.setState(
      () => ({
        isDragging: state.isDragging,
        distance: state.distance,
      }),
      () => {
        const factor = this.state.carouselWidth / 8;
        const direction = this.state.distance > 0 ? "previous" : "next";
        const total = Math.abs(this.state.distance);
        if (total > factor) {
          this.goToPage(
            this.getTarget(direction, {
              absolute: true,
            })
          );
        }
      }
    );
  };

  goToPage = (index) => {
    if (isNil(index)) {
      return;
    }
    this.setState(() => ({
      activeIndex: index,
    }));
  };

  handlePagination = (index) => (event) => {
    this.goToPage(index);
  };

  handleThumbnail = (index) => (event) => {
    this.goToPage(index);
  };

  getTarget = (which, options = {}) => {
    const currentIndex = this.state.activeIndex;
    const total = this.props.childCount;
    if (which === "previous") {
      const previous = currentIndex - 1;
      if (options.absolute) {
        return previous < 0 ? currentIndex : previous;
      }
      return previous < 0 ? total - 1 : previous;
    } else if (which === "next") {
      const next = currentIndex + 1;
      if (options.absolute) {
        return next >= total ? currentIndex : next;
      }
      return next >= total ? 0 : next;
    }
    logger.warn(
      "You must call this method with either `previous` or `next` as its argument."
    );
  };

  handlePrevious = () => {
    this.goToPage(this.getTarget("previous"));
  };

  handleNext = () => {
    this.goToPage(this.getTarget("next"));
  };

  render() {
    return (
      <div
        className={cx("carousel", {
          [`carousel-${this.props.group}`]: !!this.props.group,
          "carousel-is-dragging": this.state.isDragging,
          "carousel-disable-drag": this.props.disableDrag,
          "carousel--external-nav": this.props.externalNavigation,
        })}
      >
        <div
          key="carousel-items"
          className="carousel-item-wrap"
          ref={this.onRef}
        >
          <div
            className="carousel-items"
            style={{
              transform: this.getTransform(),
              width: `${100 * this.props.childCount}%`,
            }}
          >
            <Drag
              disableDrag={this.props.disableDrag}
              onDragStart={this.handleDragStart}
              onDragMove={this.handleDragMove}
              onDragEnd={this.handleDragEnd}
            >
              {Children.map(this.props.children, (child) => {
                return cloneElement(child, {
                  style: {
                    ...(child.props.style || {}),
                    display: "inline-block",
                    width: `${100 / this.props.childCount}%`,
                  },
                });
              })}
            </Drag>
          </div>
        </div>
        {!this.props.disablePagination && (
          <div key="carousel-pagination" className="carousel-pagination">
            {this.props.children.map((child, index) => (
              <button
                key={`pagination-${index}`}
                onClick={this.handlePagination(index)}
                className={cx(
                  "carousel-pagination-dot",
                  `carousel-pagination-dot-${index}`,
                  {
                    "carousel-pagination-dot-active":
                      this.state.activeIndex === index,
                  }
                )}
              >
                {index}
              </button>
            ))}
          </div>
        )}
        {this.props.thumbnails && (
          <div key="carousel-thumbnail" className="carousel-thumbnail">
            {this.props.thumbnails.map((thumbnail, index) => (
              <div
                className="carousel-thumbnail-item-wrapper"
                key={`thumbnail-${index}`}
              >
                <div
                  className={cx(
                    "carousel-thumbnail-item",
                    `carousel-thumbnail-item-${index}`,
                    {
                      "carousel-thumbnail-item-active":
                        this.state.activeIndex === index,
                    }
                  )}
                  onClick={this.handleThumbnail(index)}
                >
                  {thumbnail}
                </div>
              </div>
            ))}
          </div>
        )}
        {!this.props.disableNavigation && [
          <button
            key="previous"
            onClick={this.handlePrevious}
            className="carousel-navigation-previous"
          >
            <Icon icon="directleft" />
          </button>,
          <button
            key="next"
            onClick={this.handleNext}
            className="carousel-navigation-next"
          >
            <Icon icon="directright" />
          </button>,
        ]}
      </div>
    );
  }
}

export default Carousel;
