import {
  replaceTextWithValues,
  replaceRichText,
} from "src/common/utils/text-replace";
import splitKeyValues from "src/common/utils/split-prismic-key-vals";
import arrayToSentence from "src/common/utils/array-to-sentence";
import snakeCase from "lodash/snakeCase";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import isUndefined from "lodash/isUndefined";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import merge from "lodash/merge";
import isObjectLike from "lodash/isObjectLike";
// These imports are shared between BookPreview.js and helpers/Canvas.js due to how webpack bundles code. They are
// causing a 132kb chunk of JS to be bundled in the global bundle.js, I've investigated it but have run out of time to
// fix. It will not work with lodable (not a component). One method was to use webpack's magic comments but that
// requires everything to be async. Tldr; needs better implementation. Another possible fix is to duplicate the nasty
// functions so they are not deemed 'shared' by webpack.
import {
  getAllFieldsRequired,
  isFieldOptional,
  getOverride,
} from "../CreationForm/helpers";
import { addCharacter } from "../CreationForm/Characters/helpers";

/**
 * getGenderedValuesFromGlobal find pronouns in Prismic by key and gender
 * @param {Object} props the props from the component using this function
 * @param {string} key
 * @returns {string}
 */
const getGenderedValuesFromGlobal = (props, key) => {
  // This function is only used for KOY (for now) - Edwin, 13/06/19
  // Get gender value from the user, either from redux or the query params.
  const gender =
    get(props, "productCustomisation.gender") ||
    get(props, "queryParams.gender");

  if (!gender) {
    return key;
  }
  // Snake case the product ID as the prismic's slice types are snake cased
  const productId = snakeCase(props.productId);

  // Get global content from pre-fetched page model of from a fetch request
  const filteredProps =
    get(props, ["model", "prismicGlobalContentModel", "canvas", "body"]) ||
    get(props, ["globalContent", "canvas", "body"], []);

  // Pick only the slice that matched the product
  const slice = filteredProps.find((s) => s.sliceType === productId) || {};

  if (!slice || !slice.items) {
    return key;
  }
  // Return the object that matches the id
  return slice.items.find((o) => o.id === `${key}_${gender}`) || key;
};

/**
 * byFormatOptions filter down an array from Prismic by certain customisation keys
 * @param {String[]} customisationKeys
 * @param {Object} customisations
 * @returns {function(object): boolean}
 */
const byFormatOptions = (customisationKeys, customisations) => (item) =>
  customisationKeys.every((key) => {
    if (customisations[key] && item[key] && item[key] !== "All") {
      return item[key].toLowerCase() === customisations[key].toLowerCase();
    }
    return true;
  });

const blankablePaths = ["inscription"];
const allowedBlank = (path) => blankablePaths.includes(path);

/**
 * replaceCanvasText replaces any strings from Prismic that have templates in them with customisation values or other Prismic values.
 * @param {string} text the copy with template strings to replace
 * @param {Object} props the props from the component using this function
 * @param {number} [truncateLength] truncate the text by a character length
 * @returns {string}
 */
export const replaceCanvasText = (text, props, truncateLength) => {
  // Create an object that has all the user's customisations from the query params and redux store
  const values = merge({}, props.queryParams, props.productCustomisation);

  const prismicProduct = props.prismicProduct;

  // Check if we have finished fetching the product document from Prismic
  const prismicProductReady =
    get(prismicProduct, "response.pending") !== true &&
    get(prismicProduct, "response.ok", false) === true;

  // Get the fields for the adventurer form from the Prismic product document
  const formFields = get(prismicProduct, "form.body", []);

  if (values.inscription === "") {
    values.inscription = get(props, "drawer.primary.tabCompleteTitleBlank", "");
  }

  // Avatars 2.0?
  // pretty sure this is redundant...
  if (
    values.characters &&
    isArray(values.characters) &&
    values.characters[0].name
  ) {
    const options = {
      oxfordComma:
        get(
          props,
          "model.prismicGlobalContentModel.general.oxfordComma",
          false
        ) || false,
      conjunction:
        get(
          props,
          "model.prismicGlobalContentModel.general.coordinatingConjunctionAnd"
        ) || "&",
    };

    values.names = arrayToSentence(
      values.characters.map((character) => character.name),
      options
    );
  }

  if (props.productId === "kingdom-of-you") {
    values.pronoun = getGenderedValuesFromGlobal(props, "pronoun");
    values.royalty = getGenderedValuesFromGlobal(props, "royalty");
    values.kingdom = getGenderedValuesFromGlobal(props, "kingdom");
  }

  if (props.productId === "birthday-thief") {
    const hasBirthdayMonth =
      values.month &&
      get(props, "drawer.sliceType") === "birthday" &&
      get(props, "drawer.primary.months");

    if (hasBirthdayMonth) {
      const months = get(props, "drawer.primary.months", "")
        .split(",")
        .reduce((prev, next, index) => {
          prev[index + 1] = next;
          return prev;
        }, {});

      values.month = months[values.month].trim();
    }
  }

  if (props.productId === "thats-my-cake") {
    let currentCharacterLength = 0;

    if (values.characters) {
      currentCharacterLength = values.characters.length;
    }

    const multiAdventurerField =
      (prismicProductReady &&
        formFields.find((field) => field.sliceType === "multi-adventurer")) ||
      {};
    values.totalAdventurers = get(
      multiAdventurerField,
      "primary.maxCharacterLimit",
      currentCharacterLength
    );
    values.currentAdventurers = currentCharacterLength;
  }

  if (get(props, "drawer.sliceType") === "interest") {
    const type = snakeCase(get(props, "drawer.primary.type", ""));
    const interest = get(props, "drawer.items", []).find((item) => {
      const drawerType = item.extraChoices.split("::").pop();
      return drawerType === values[type];
    });

    if (interest) {
      values[type] = interest.title;
    }
  }

  if (get(props, "drawer.sliceType") === "qualities") {
    const drawer = props.drawer || {};
    const translatedValues = drawer.items || [];
    const customisationKey = get(drawer, "primary.customisationKey");

    const items = (get(values, customisationKey) || []).map((value) => {
      const translatedValue = translatedValues.find(
        (tv) => tv.id === value
      ) || { title: value };
      return translatedValue.title;
    });

    values.numberOfChoices = get(drawer, "primary.numberOfChoices");
    values.values = arrayToSentence(items);
  }

  if (get(props, "drawer.sliceType") === "card_select") {
    const drawer = props.drawer || {};
    values.number = drawer.primary.numberOfSelections;
  }

  if (get(props, "drawer.sliceType") === "book_formats") {
    const defaultBookFormats = get(
      props,
      "drawer.primary.defaultFormats.defaultFormats.bookFormats",
      []
    );
    const overriddenBookFormats = get(props, "drawer.items", []);
    const defaultFormat = defaultBookFormats.find(
      (f) => f.formatId === values.format_identifier
    );
    const overriddenFormat = overriddenBookFormats.find(
      (f) => f.formatId === values.format_identifier
    );

    if (overriddenFormat && !defaultFormat) {
      values.formatIdentifier = overriddenFormat.title;
    } else if (defaultFormat && !overriddenFormat) {
      values.formatIdentifier = defaultFormat.title;
    } else {
      const mergedFormat = reduce(
        defaultFormat,
        (result, val, key) => {
          result[key] = overriddenFormat[key] ? overriddenFormat[key] : val;
          return result;
        },
        {}
      );
      values.formatIdentifier = mergedFormat.title;
    }
  }

  if (get(props, "drawer.sliceType") === "adventurer") {
    let locale = null;
    if (prismicProductReady) {
      const formSlices = get(prismicProduct, "form.body", []);
      const localeSlice = formSlices.find(
        (slice) => slice.sliceType === "locale"
      );
      const languages = get(localeSlice, "items", []);
      const language =
        languages.find((item) => item.optionValue === values.locale) || {};
      locale = language.optionTitle || values.locale;
    }
    values.locale = locale;
  }

  if (get(props, "drawer.sliceType") === "characters") {
    const customisationKey = get(props, "drawer.primary.customisationKey");
    const names = (values[customisationKey] || []).map(({ name }) => name);
    values.characterNames = arrayToSentence(names);
  }

  // TODO: Prismic has a content template string like "${gifterName}". To make sure this
  //  doesn't break I am just setting that value here and once this is changed in
  //  Prismic we can remove this line of code.
  if (get(props, "drawer.sliceType") === "companion") {
    values.gifterName = values.gifter_name;
  }

  if (get(props, "drawer.sliceType") === "multi_level_form") {
    values.count = props.drawer.items.reduce((acc, { form }) => {
      // defaulting to true to break early from form
      // fields if a field is missing a value
      let isCompleted = true;

      for (const field of form.body) {
        if (!isCompleted) {
          break;
        }
        if (field.type !== "submit") {
          isCompleted = get(props.queryParams, field.name);
        }
      }

      if (isCompleted) {
        acc++;
      }

      return acc;
    }, 0);

    values.totalCount = props.drawer.items.length;
  }

  return isArray(text)
    ? replaceRichText(text, values, truncateLength)
    : replaceTextWithValues(text, values, truncateLength);
};

/**
 * getDefaultInscription find the inscription from Prismic depending on the gender that the user picked
 * @param {Object} props the props from the component using this function
 * @param {Object} localisedDrawer
 * @param {Object} localisedDrawer.primary
 * @param {string} localisedDrawer.primary.defaultInscriptionBoy
 * @param {string} localisedDrawer.primary.defaultInscriptionGirl
 *
 * @returns {string}
 */
export const getDefaultInscription = (props, localisedDrawer) => {
  if (localisedDrawer) {
    const values = {
      ...cloneDeep(props.queryParams),
      ...cloneDeep(props.productCustomisation),
    };

    // test for new avatars 2.0 characters array or fallback to gender param
    const gender = !isEmpty(values.characters)
      ? values.characters[0].gender
      : values.gender;

    const boyInscription =
      gender === "boy" && localisedDrawer.primary.defaultInscriptionBoy;
    const defaultInscription =
      boyInscription || localisedDrawer.primary.defaultInscriptionGirl;
    return replaceCanvasText(defaultInscription, props);
  }
};

/**
 * getDefaultCustomisations find the default customisations from Prismic
 * @param {Object} props the props from the component using this function
 * @returns {Object}
 */
export const getDefaultCustomisations = (props) => {
  // Loop though each drawer and get the default values from them
  return props.primary.drawers.other.reduce((defaults, drawer) => {
    const contentLocales = props.model.countryModel.contentLocales.slice();
    const contentLocale = contentLocales.pop();
    switch (drawer.sliceType) {
      case "adventurer": {
        defaults.name = get(drawer, "primary.defaultName");
        const form = get(props, "primary.drawers.form", {});

        const adventurerField = get(form, "body", []).find(
          (slice) => slice.sliceType === "adventurer"
        );
        const defaultAdventurer = (
          (adventurerField && adventurerField.items) ||
          []
        ).find((item) => item.isDefault === "true");
        defaults.gender = get(
          defaultAdventurer,
          "gender",
          "girl"
        ).toLowerCase();
        defaults.phototype = get(
          defaultAdventurer,
          "phototype",
          "type-ii"
        ).toLowerCase();
        defaults.locale = form.localeOverride || contentLocale;
        break;
      }
      case "inscription": {
        defaults.inscription = getDefaultInscription(props, drawer);
        break;
      }
      case "family_members": {
        defaults.names = drawer.primary.defaultMembers
          .split(",")
          .map((name) => name.trim());
        break;
      }
      case "address": {
        defaults.country = drawer.primary.defaultCountry;
        defaults.door = drawer.primary.defaultDoor;
        defaults.street = drawer.primary.defaultStreet;
        defaults.city = drawer.primary.defaultTown;
        defaults.address_type = "full-address";
        if (drawer.primary.defaultMapLocation) {
          defaults.lat = drawer.primary.defaultMapLocation.latitude;
          defaults.lng = drawer.primary.defaultMapLocation.longitude;
        }
        break;
      }
      case "birthday": {
        const birthday = new Date(
          drawer.primary.defaultBirthday ? drawer.primary.defaultBirthday : null
        );
        defaults.day = birthday.getDate().toString();
        defaults.month = (birthday.getMonth() + 1).toString();
        defaults.year = birthday.getFullYear().toString();
        break;
      }
      case "bears": {
        defaults.bears = drawer.items.reduce(
          (bears, bear) =>
            bear.defaultCharacterName
              ? bears.concat({
                  name: bear.defaultCharacterName,
                  type: bear.type,
                })
              : bears,
          []
        );
        break;
      }
      case "interest": {
        const defaultItem = drawer.items.find(
          (item) => item.isDefault === "true"
        );
        if (defaultItem && defaultItem.extraChoices) {
          const extraChoice = splitKeyValues(defaultItem.extraChoices);
          defaults[extraChoice.key] = extraChoice.value;
        }
        break;
      }
      case "card_select": {
        const customisationKey = drawer.primary.customisationKey;
        const numberOfChoices = drawer.primary.numberOfSelections;
        defaults[customisationKey] = drawer.items.reduce((result, item) => {
          if (item.isDefault === true && result.length < numberOfChoices) {
            result.push(item.id || snakeCase(item.title));
          }
          return result;
        }, []);
        break;
      }
      case "qualities": {
        const customisationKey = drawer.primary.customisationKey;
        const numberOfChoices = drawer.primary.numberOfChoices;
        defaults[customisationKey] = drawer.items.reduce((result, item) => {
          if (item.isDefault === "true" && result.length < numberOfChoices) {
            result.push(item.id);
          }
          return result;
        }, []);
        break;
      }
      case "multi_adventurer": {
        const form = get(props, "primary.drawers.form", {});
        defaults.locale = form.localeOverride || contentLocale;
        break;
      }
      case "companion": {
        defaults.gifter_name = drawer.primary.defaultName;
        break;
      }
      case "characters": {
        const customisationKey = drawer.primary.customisationKey;

        defaults[customisationKey] = drawer.items
          .filter((item) => item.isVisible)
          .reduce(
            (acc, { type, defaultValue, defaultGender }) =>
              addCharacter(acc, {
                type,
                gender: defaultGender,
                name: defaultValue,
              }),
            []
          );
        break;
      }
      case "custom_select": {
        const defaultItem = drawer.items.find((item) => item.isDefault);
        if (defaultItem) {
          defaults.selection_state = defaultItem.value;
          defaults.selection_value = defaultItem.label;
        }
        break;
      }
      case "input": {
        if (drawer.primary.defaultValue) {
          set(defaults, drawer.primary.drawerKey, drawer.primary.defaultValue);
        }
      }
      case "inputs": {
        drawer.items.forEach((item) => {
          if (item.required) {
            set(defaults, item.drawerKey, item.defaultValue);
          }
        });
      }
      case "multi_input": {
        if (drawer.primary.defaultValues) {
          const key = drawer.primary.drawerKey;
          set(defaults, key, drawer.primary.defaultValues.split(/ *, */g));
        }
        break;
      }
      case "multi_level_form": {
        drawer.items.forEach((item) => {
          item.form.body.forEach((field) => {
            if (field.type !== "submit" && field.value) {
              set(defaults, field.name, replaceCanvasText(field.value, props));
            }
          });
        });
        break;
      }
    }
    return defaults;
  }, {});
};

export const findMatchingDrawer = (drawerToMatch) => (drawer) => {
  const isSameDrawerType =
    drawer.sliceType === drawerToMatch.sliceType &&
    drawer.primary?.type === drawerToMatch.primary?.type;

  if (!isSameDrawerType) {
    return false;
  }

  // The field in prismic will either be defined as drawerKey or customisationKey. This is
  // unfortunately due to a miscommunication between engineers and moving forward should always
  // be customisationKey.
  const drawerCustomisationKey =
    drawer.primary?.drawerKey || drawer.primary?.customisationKey;
  const drawerToMatchCustomisationKey =
    drawerToMatch.primary?.drawerKey || drawerToMatch.primary?.customisationKey;

  if (drawerCustomisationKey || drawerToMatchCustomisationKey) {
    return drawerCustomisationKey === drawerToMatchCustomisationKey;
  }

  return true;
};

export const mergeLocalisedDrawers = (props) => {
  const clonedProps = cloneDeep(props);
  const bookLanguageDrawers = get(
    clonedProps,
    "prismicProduct.canvas.body",
    []
  );

  return bookLanguageDrawers.map((drawer) => {
    const localisedDrawer = get(clonedProps, "primary.drawers.other", []).find(
      findMatchingDrawer(drawer)
    );

    if (!localisedDrawer) {
      return drawer;
    }

    switch (drawer.sliceType) {
      case "inscription":
        localisedDrawer.primary.image = drawer.primary.image;
        break;
      case "book_formats":
        const customisation = clonedProps.productCustomisation;
        const siteLocaleFormatsSlice = get(
          clonedProps,
          "primary.body",
          []
        ).find((slice) => slice.sliceType === "book_formats");
        const siteLocaleDefaultFormats = get(
          siteLocaleFormatsSlice,
          "primary.defaultFormats.defaultFormats.bookFormats",
          []
        );
        const items = drawer.items
          .filter(byFormatOptions(["gender", "phototype"], customisation))
          .reduce((result, item) => {
            // Get a default format from site locale by current customisation
            // and by the same format id that is currently being iterated over
            const siteLocaleDefaultItem = siteLocaleDefaultFormats
              .filter(byFormatOptions(["gender", "phototype"], customisation))
              .find((defaultItem) => item.formatId === defaultItem.formatId);

            // Get a format from site locale by current customisation
            // and by the same format id that is currently being iterated over
            const siteLocaleItem = localisedDrawer.items
              .filter(byFormatOptions(["gender", "phototype"], customisation))
              .find(
                (localisedItem) => item.formatId === localisedItem.formatId
              );

            // Return a format with the copy of the site locale but
            // the image of the book locale
            result.push({
              ...omitBy(siteLocaleDefaultItem, isNull),
              ...omitBy(siteLocaleItem, isNull),
              image: item.image,
            });

            return result;
          }, []);
        localisedDrawer.items = items;
        break;
    }

    return localisedDrawer;
  });
};

/**
 * hasRequiredField
 * @param {string} path the path of the property in the customisations object
 * @param {Object} customisations
 * @param {Object} options
 * @param {number} [options.minArrayLength] minimum length for the first matching array in the fieldPath
 * used to apply a strict rule against the required field
 * @param {string} [options.originalPath] the original path passed in to the function when first called.
 * This will be added automatically if not provided, by using the path parameter.
 * @example
 *  const customisations = { name: 'Edwin' };
 *  hasRequiredField('name', customisations);
 *  // => true
 *  hasRequiredField('phototype', customisations);
 *  // => false
 *
 *  const customisationsWithArray = {
 *    characters: [{
 *        name: 'Edwin'
 *    }]
 *  };
 *  hasRequiredField('characters[].name', customisationsWithArray);
 *  // => true
 *  hasRequiredField('characters[].name', customisationsWithArray, { minArrayLength: 2 });
 *  // => false
 *
 *  const inscriptionCustomisation = {
 *    inscription: '',
 *  };
 *  hasRequiredField('inscription', inscriptionCustomisation)
 *  // => true
 * @returns {boolean}
 */
export const hasRequiredField = (path, customisations, options = {}) => {
  if (isNil(path) || isNil(customisations)) {
    return false;
  }
  if (!options.originalPath) {
    options.originalPath = path;
  }

  let value = isObjectLike(customisations)
    ? get(customisations, path)
    : customisations;

  // If the path includes a template array (characters[]), then loop through the array and
  // check the rest of the path to see if the field meets the minimum
  // length required to be valid
  // Regex: https://regex101.com/r/RVzE0R/5

  const pathObject = path.match(/(?:\[\]\.+)(.*)/);

  if (!!pathObject) {
    const nextPath = pathObject[1];
    const key = path.replace(pathObject[0], "");
    value = customisations[key];

    if (isArray(value)) {
      const minimumRequiredLength = options.minArrayLength || value.length;

      if (value.length < minimumRequiredLength) {
        return false;
      }

      let isValid = true;

      for (let i = 0; i < value.length; i++) {
        if (!isValid || i > minimumRequiredLength - 1) {
          break;
        }
        isValid = hasRequiredField(nextPath, value[i], options);
      }

      return isValid;
    }

    return hasRequiredField(nextPath, value, options);
  }

  if (value === "" && allowedBlank(options.originalPath)) {
    return true;
  }

  return !isNil(value) && value !== "";
};

/**
 * getCompletedDrawersMap get the current status of all the drawers by using the list of drawers coming from Prismic
 * and the required fields for each drawer from the creation form config
 * @param {Object} props
 * @return {{ required: string[], 'optional': string[], complete: string[], incomplete: string[] }}
 */
export const getCompletedDrawersMap = (props) => {
  const allDrawers = props.drawers.other;
  const queryParams = props.queryParams;

  const reduceDrawers = (drawersMap, drawer, index) => {
    let slice = drawer.sliceType;
    let isDrawerComplete = true;
    let fields = getAllFieldsRequired(slice, drawer);

    const type = snakeCase(get(drawer, "primary.type", null));
    const fieldOptions = {
      ...queryParams,
      type,
      productId: props.productId,
    };

    const override = getOverride(slice, fieldOptions);

    slice = type || slice;
    if (!isEmpty(override)) {
      fields = {
        ...fields,
        ...override,
      };
    }

    // Check if the required field is in the user's product customisation
    for (let i = 0; i < fields.required.length; i++) {
      if (!isDrawerComplete) {
        break;
      }

      const field = fields.required[i];
      const handledFields = ["lat", "lng", "age", "inscription"];
      const hasLatLngValue =
        (field === "lat" && queryParams.lat !== "empty") ||
        (field === "lng" && queryParams.lng !== "empty");
      const hasAgeValue = field === "age" && queryParams[field] !== undefined;
      const hasInscriptionValue =
        field === "inscription" && queryParams[field] !== undefined;

      const hasFieldValue =
        isDrawerComplete &&
        !handledFields.includes(field) &&
        hasRequiredField(field, queryParams, fields);

      switch (true) {
        case hasLatLngValue:
        case hasAgeValue:
        case hasInscriptionValue:
        case hasFieldValue:
          isDrawerComplete = true;
          break;
        default:
          isDrawerComplete = false;
          break;
      }
    }

    const isOptional = isFieldOptional(slice, fieldOptions, drawer);

    slice = `${slice}-${index}`;

    if (isOptional) {
      drawersMap = {
        ...drawersMap,
        optional: drawersMap.optional.concat(slice),
      };
    } else {
      drawersMap = {
        ...drawersMap,
        required: drawersMap.required.concat(slice),
      };
    }

    if (isDrawerComplete) {
      return {
        ...drawersMap,
        complete: drawersMap.complete.concat(slice),
      };
    }

    return {
      ...drawersMap,
      incomplete: drawersMap.incomplete.concat(slice),
    };
  };

  return allDrawers.reduce(reduceDrawers, {
    required: [],
    optional: [],
    complete: [],
    incomplete: [],
  });
};

/**
 * shouldInscriptionUpdate checks whether or not if inscription should be update
 * @param {Object} props the old props from the component using this function
 * @param {Object} nextProps the new props from the component using this function
 * @returns {boolean}
 */
export const shouldInscriptionUpdate = (props, nextProps) => {
  if (isUndefined(nextProps.queryParams.inscription)) {
    const inscriptionDrawerLanguageHasUpdated =
      !!nextProps.inscriptionDrawer &&
      !isEqual(props.inscriptionDrawer, nextProps.inscriptionDrawer);
    const queryParamsHasUpdated = !isEqual(
      props.queryParams,
      nextProps.queryParams
    );
    return inscriptionDrawerLanguageHasUpdated || queryParamsHasUpdated;
  }
  return false;
};

export const shouldFetchPrismicProduct = (props, nextProps) => {
  const nextBookLanguage = nextProps.productCustomisation.locale;
  const hasBookLocaleChanged =
    nextBookLanguage !== props.productCustomisation.locale;
  const isLocaleDifferentToBookLocale =
    nextBookLanguage !== nextProps.model.countryModel.locale;
  return hasBookLocaleChanged && isLocaleDifferentToBookLocale;
};
