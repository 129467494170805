import React from "react";
import PropTypes from "prop-types";
import SocialIcon from "wonderbly-components/lib/SocialIcon";

import "./SocialNetworkSet.scss";

const SocialNetworkSet = (props) => (
  <div className="SocialNetworkSet">
    {props.socialNetworks.map((network) => (
      <SocialIcon
        key={network.socialNetwork}
        network={network.socialNetwork}
        href={network.socialNetworkUrl}
        target={
          (network.socialNetworkUrl || "").startsWith("http") ? "_blank" : ""
        }
        rel={
          (network.socialNetworkUrl || "").startsWith("http")
            ? "nofollow noopener"
            : null
        }
        className="SocialNetworkSet__item"
      />
    ))}
  </div>
);

SocialNetworkSet.propTypes = {
  socialNetworks: PropTypes.arrayOf(
    PropTypes.shape({
      socialNetwork: PropTypes.string.isRequired,
      socialNetworkUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SocialNetworkSet;
