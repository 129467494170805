import Member from "./Member";
import withHandlers from "recompose/withHandlers";

const enhance = withHandlers({
  onChange: (props) => (e) => {
    props.onMemberChange(props.index, e.target.value);
  },
  onRemove: (props) => () => {
    props.onMemberChange(props.index);
  },
});

export default enhance(Member);
