import React from "react";
import PropTypes from "prop-types";
import kebabCase from "lodash/kebabCase";
import SliceErrorBoundary from "../SliceErrorBoundary";
import cx from "classnames";
import get from "lodash/get";
import loadable from "@loadable/component";

import "./DynamicLayout.scss";

// Components
const components = {
  AddOns: loadable(() => import("../AddOns")),
  AddToCart: loadable(() => import("../AddToCart")),
  BlogArticles: loadable(() => import("../BlogArticles")),
  Breadcrumbs: loadable(() => import("../Breadcrumbs")),
  CallToAction: loadable(() => import("../CallToAction")),
  Canvas: loadable(() => import("../Canvas")),
  CartItems: loadable(() => import("../CartItems")),
  CartItemsII: loadable(() => import("../CartItemsII")),
  CartShipping: loadable(() => import("../Shipping")),
  CartSummary: loadable(() => import("../CartSummary")),
  CheckoutOrderSummary: loadable(() =>
    import("../MainCheckout/CheckoutOrderSummary")
  ),
  ChooseYourCountry: loadable(() => import("../ChooseYourCountry")),
  Column: loadable(() => import("../Column")),
  ContactAndShipping: loadable(() => import("../ContactAndShipping")),
  CreditInfo: loadable(() => import("../Account/CreditInfo")),
  CrossSell: loadable(() => import("../CrossSell")),
  Description: loadable(() => import("../Description")),
  FAQs: loadable(() => import("../FAQs")),
  FeaturedItems: loadable(() => import("../FeaturedItems")),
  Features: loadable(() => import("../Features")),
  FullWidthImage: loadable(() => import("../FullWidthImage")),
  Group: loadable(() => import("../Group")),
  HelpContentModules: loadable(() => import("../HelpContentModules")),
  HeroII: loadable(() => import("../HeroII")),
  HeroIV: loadable(() => import("../HeroIV")),
  HomepageHero: loadable(() => import("../HomepageHero")),
  HomepageHeroII: loadable(() => import("../HomepageHeroII")),
  HorizontalCards: loadable(() => import("../HorizontalCards")),
  IntroPartial: loadable(() => import("../IntroPartial")),
  Navigation: loadable(() => import("../Navigation")),
  Newsletter: loadable(() => import("../Newsletter")),
  OffersCarousel: loadable(() => import("../OffersCarousel")),
  OffersList: loadable(() => import("../OffersList")),
  OrderConfirmationSummary: loadable(() =>
    import("../OrderConfirmationSummary")
  ),
  OrderDetails: loadable(() => import("../OrderDetails")),
  OrderLineItems: loadable(() => import("../OrderLineItems")),
  OrdersList: loadable(() => import("../OrdersList")),
  OrderStatusCard: loadable(() => import("../OrderStatusCard")),
  PaymentMethodsII: loadable(() => import("../PaymentMethodsII")),
  PrimaryHero: loadable(() => import("../PrimaryHero")),
  ProductBundles: loadable(() => import("../ProductBundles")),
  ProductForm: loadable(() => import("../ProductForm")),
  ProductGrid: loadable(() => import("../ProductGrid")),
  ProductGridII: loadable(() => import("../ProductGridII")),
  ProductHero: loadable(() => import("../ProductHero")),
  ProductHeroII: loadable(() => import("../ProductHeroII")),
  ProductInformation: loadable(() => import("../ProductInformation")),
  ProductListII: loadable(() => import("../ProductListII")),
  ProductReviewList: loadable(() => import("../ProductReviewList")),
  PromoBannerInline: loadable(() => import("./PromoBannerInline")),
  RangeHero: loadable(() => import("../RangeHero")),
  RecentOrders: loadable(() => import("../RecentOrders")),
  ReferAFriend: loadable(() => import("../ReferAFriend")),
  RelatedProducts: loadable(() => import("../RelatedProducts")),
  SearchLinks: loadable(() => import("../SearchLinks")),
  StepBannerImage: loadable(() => import("../StepBannerImage")),
  SwitchCountry: loadable(() => import("../SwitchCountry")),
  TextOverBackgroundImage: loadable(() => import("../TextOverBackgroundImage")),
  TrustpilotWidget: loadable(() => import("../TrustpilotWidget")),
  Upsell: loadable(() => import("../Upsell")),
  UserForm: loadable(() => import("../UserForm")),
  UserOrders: loadable(() => import("../UserOrders")),
  ValuePropsII: loadable(() => import("../ValuePropsII")),
  VerifyPayment: loadable(() => import("../VerifyPayment")),
  WelcomePartial: loadable(() => import("../Account/WelcomePartial")),
  CheckoutShipping: loadable(() => import("../CheckoutShipping")),
  PaypalCheckout: loadable(() => import("../PaypalCheckout")),
  FreeShippingBanner: loadable(() => import("../FreeShippingBanner")),
  GenericFormatSelector: loadable(() => import("../GenericFormatSelector")),
  ProductCards: loadable(() => import("../ProductCards")),
  FeatureCards: loadable(() => import("../FeatureCards")),
  CarouselNav: loadable(() => import("../CarouselNav")),
  SublimatorProductGrid: loadable(() => import("../SublimatorProductGrid")),
  BrandHero: loadable(() => import("../BrandHeroWrapper")),
  MainHero: loadable(() => import("../MainHero")),
  SliceError: loadable(() => import("../SliceError")),
  Testimonial: loadable(() => import("../Testimonial")),
  ProductTiles: loadable(() => import("../ProductTiles")),
  DynamicForm: loadable(() => import("../DynamicForm")),
  GladlyHelp: loadable(() => import("../GladlyHelp")),
  ShopNavigation: loadable(() => import("../ShopNavigation")),
  ShopNavigationTiles: loadable(() => import("../ShopNavigationTiles")),
  AgeFeatureCards: loadable(() => import("../AgeFeatureCards")),
};

const getComponent = (slice, i) => {
  let type = slice.type;
  let items = (slice.items || []).slice();

  const Component = components[type];
  if (!Component) {
    return;
  }

  if (["Group", "Column"].includes(type)) {
    items = slice.items.map(getComponent);
  }

  return (
    <SliceErrorBoundary key={`${slice.type}_${i}`}>
      <Component {...slice} items={items} />
    </SliceErrorBoundary>
  );
};

const getSlice = (sliceNames, slices) => {
  const index = slices.findIndex((slice) => sliceNames.includes(slice.type));
  if (index === -1) {
    return {
      index,
      slice: {},
    };
  }
  return {
    index,
    slice: slices.splice(index, 1)[0],
  };
};

const DynamicLayout = (props) => {
  const page = get(props, "model.prismicPageModel", {});

  // shallow cloning as to make changes to the array without effecting the original array
  const slices = (page.slices || []).slice();
  const layout = page.layout || "";

  const isTwoColumnLayout = !!page.isTwoColumnLayout;
  const isTwoColumnLayoutActive =
    (props.pageId === "cart" || props.isCheckout) && isTwoColumnLayout;

  // Setting initial values
  let cartSummaryIndex = -1;
  let cartSummarySlice = {};
  let helpContentModulesIndex = -1;
  let helpContentModulesSlice = {};

  if (isTwoColumnLayoutActive) {
    const cartSummary = getSlice(
      ["CartSummary", "CheckoutOrderSummary"],
      slices
    );
    cartSummarySlice = cartSummary.slice || cartSummarySlice;
    cartSummaryIndex = cartSummary.index || cartSummaryIndex;

    // Hack to expand help content modules full width without overlapping the content
    const helpContentModules = getSlice(["HelpContentModules"], slices);
    helpContentModulesIndex =
      helpContentModules.index || helpContentModulesIndex;
    helpContentModulesSlice =
      helpContentModules.slice || helpContentModulesSlice;
  }

  const banners = get(
    props,
    "model.prismicGlobalContentModel.general.banners",
    []
  );
  const isNewProductAdded =
    props.location.search && props.location.search.includes("product-added");
  const doesProductAddedBannerExist = banners.some(
    (banner) => banner.slug === "product-added-banner"
  );
  return (
    <main
      className={cx("wrapper", `${kebabCase(layout)}`, {
        "two-column-layout": isTwoColumnLayoutActive,
        "product-added": isNewProductAdded && doesProductAddedBannerExist,
        "wrapper--checkout": props.isCheckout,
      })}
      id={props.pageId}
      data-test={props.pageId}
      style={{
        backgroundColor: page.backgroundColor,
      }}
    >
      {props.hideReferrer && (
        // we generally want referrer and we use it in the checkout to only
        // send gtm/optimizely data once.  however for books with very long
        // params the url and the length of the referrer in header caused 431s.
        // As such we only show the domain and not the path and query string on some pages
        // These inlude pages with long params - e.g. canvas/formats/options
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Referrer-Policy
        <meta name="referrer" content="origin" />
      )}
      {!isTwoColumnLayoutActive &&
        slices.map((slice, i) => getComponent(slice, i))}
      {isTwoColumnLayoutActive && (
        <React.Fragment>
          <div className="left-column">
            {slices.map((slice, i) => getComponent(slice, i))}
          </div>
          <div className="right-column">
            {getComponent(cartSummarySlice, cartSummaryIndex)}
          </div>
          {helpContentModulesIndex !== -1 && (
            <div className="full-width-column">
              {getComponent(helpContentModulesSlice, helpContentModulesIndex)}
            </div>
          )}
        </React.Fragment>
      )}
    </main>
  );
};

DynamicLayout.propTypes = {
  pageId: PropTypes.string.isRequired,
  hideReferrer: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    prismicPageModel: PropTypes.shape({
      isTwoColumnLayout: PropTypes.bool,
      slices: PropTypes.array,
      layout: PropTypes.string,
    }),
    prismicGlobalContentModel: PropTypes.shape({
      general: PropTypes.shape({
        banners: PropTypes.array,
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  isCheckout: PropTypes.bool,
};

DynamicLayout.components = components;

export default DynamicLayout;
