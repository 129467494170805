const products = [
  {
    sku: "lmn",
    slug: "lost-my-name-book",
    dimensions: {
      width: 1280,
      height: 923,
    },
  },
  {
    sku: "kingdom-of-you",
    slug: "kingdom-of-you-book",
    dimensions: {
      width: 1280,
      height: 905,
    },
  },
  {
    sku: "birthday-thief",
    slug: "the-birthday-thief-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "my-golden-ticket",
    slug: "roald-dahl-my-golden-ticket-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "littlest-bear",
    slug: "the-littlest-bear-book",
    dimensions: {
      width: 1280,
      height: 768,
    },
  },
  {
    sku: "snowflake",
    slug: "the-christmas-snowflake-book",
    dimensions: {
      width: 1280,
      height: 768,
    },
  },
  {
    sku: "little-monsters",
    slug: "my-little-monster-name-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "wondrous-road",
    slug: "the-wondrous-road-book",
    dimensions: {
      width: 1280,
      height: 905.33,
    },
  },
  {
    sku: "power-within",
    slug: "the-power-within-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "where-are-you",
    slug: "where-are-you-book",
    dimensions: {
      width: 1227,
      height: 1575,
    },
  },
  {
    sku: "found-my-friends",
    slug: "found-my-friends-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "magboggle-mystery",
    slug: "the-curious-case-of-the-magboggle-mystery-kit",
    dimensions: {},
  },
  {
    sku: "my-voice",
    slug: "my-voice-book",
    dimensions: {},
  },
  {
    sku: "elf-who-saved-christmas",
    slug: "elf-who-saved-christmas-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "where-are-you-activity-book",
    slug: "where-are-you-activity-book",
    dimensions: {},
  },
  {
    sku: "bedtime-for-name",
    slug: "bedtime-for-name-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "what-kind-of-name",
    slug: "what-kind-of-name-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "happy-birthday-to-you",
    slug: "happy-birthday-to-you-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "thats-my-cake",
    slug: "thats-my-cake-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "dared-to-dream",
    slug: "dared-to-dream-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "you-are-extraordinary",
    slug: "you-are-extraordinary-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "where-are-you-2",
    slug: "where-are-you-2-book",
    dimensions: {
      width: 1227,
      height: 1575,
    },
  },
  {
    sku: "dream-town",
    slug: "dream-town-sticker-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "wonderbly-activity",
    slug: "the-world-of-wonderbly-activity-book",
    dimensions: {},
  },
  {
    sku: "christmas-wishes",
    slug: "christmas-wishes-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "i-love-you-this-much",
    slug: "i-love-you-this-much-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "i-love-you-this-much",
    slug: "i-love-you-this-much-book-v1",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "i-love-you-this-much-v2",
    slug: "i-love-you-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-daddy-this-much",
    slug: "you-love-daddy-this-much-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-daddy-this-much-v2",
    slug: "you-love-daddy-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-grandma-this-much-v2",
    slug: "you-love-grandma-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-grandad-this-much-v2",
    slug: "you-love-grandad-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-mummy-this-much",
    slug: "you-love-mummy-this-much-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-mummy-this-much-v2",
    slug: "you-love-mummy-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-them-this-much",
    slug: "you-love-them-this-much-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-them-this-much-v2",
    slug: "you-love-them-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "daycare-for-you",
    slug: "daycare-for-you-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "who-can-you-can",
    slug: "who-can-you-can-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "best-dad-ever",
    slug: "best-dad-ever-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "best-mum-ever",
    slug: "best-mum-ever-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "best-ever",
    slug: "best-ever-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "ten-little-yous",
    slug: "ten-little-yous-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-and-the-beanstalk",
    slug: "jack-and-the-beanstalk-book",
    dimensions: {
      width: 1280,
      height: 906,
    },
  },
  {
    sku: "activity-book",
    slug: "activity-book",
    dimensions: {},
  },
  {
    sku: "coloring-activity-book",
    slug: "coloring-activity-book",
    dimensions: {},
  },
  {
    sku: "coloring-book",
    slug: "coloring-book",
    dimensions: {},
  },
  {
    sku: "your-birthday-party",
    slug: "your-birthday-party-book",
    dimensions: {
      width: 1280,
      height: 905,
    },
  },
  {
    sku: "your-colors",
    slug: "your-colors-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "new-sibling-for-you",
    slug: "new-sibling-for-you-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "remarkable-rebels",
    slug: "remarkable-rebels-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "i-love-you-more-than-christmas",
    slug: "i-love-you-more-than-christmas-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-mummy-more-than-christmas",
    slug: "you-love-mummy-more-than-christmas-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-daddy-more-than-christmas",
    slug: "you-love-daddy-more-than-christmas-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-grandma-more-than-christmas",
    slug: "you-love-grandma-more-than-christmas-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-grandad-more-than-christmas",
    slug: "you-love-grandad-more-than-christmas-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "you-love-them-more-than-christmas",
    slug: "you-love-them-more-than-christmas-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
  {
    sku: "where-are-you-puzzle-adventure",
    slug: "where-are-you-epic-puzzle-quest-book",
    dimensions: {
      width: 1227,
      height: 1575,
    },
  },
  {
    sku: "best-wife-ever",
    slug: "best-wife-ever-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "best-husband-ever",
    slug: "best-husband-ever-book",
    dimensions: {
      width: 896,
      height: 1218,
    },
  },
  {
    sku: "ten-reasons",
    slug: "ten-reasons-book",
    dimensions: {
      width: 1280,
      height: 1280,
    },
  },
];

const getProductBySku = (sku) => products.find((p) => p.sku === sku);
const getProductBySlug = (slug) => products.find((p) => p.slug === slug);

module.exports = {
  getProductBySku,
  getProductBySlug,
};
