import Item from "./Item";
import withHandlers from "recompose/withHandlers";

const enhance = withHandlers({
  handleOnChangeItem: (props) => (e) => {
    props.onChangeItem(props.index, e.target.value);
  },
  handleOnRemoveItem: (props) => () => {
    props.onRemoveItem(props.index);
  },
});

export default enhance(Item);
