import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import "./SectionHeading.scss";

const stripMdBoldClasses = (md) => {
  const reg = new RegExp(/\*\*|{.*}/g);
  return md.replace(reg, "");
};

const replaceTag = (tag) => {
  return tag.indexOf("heading") > -1 ? `h${tag.substr(7)}` : tag;
};

/**
 * @props.richtext - a richtext nodelist
 * @props.tag - force the heading HTML tag
 * @props.children - nodes added inside the heading tag
 *
 * Use either props.children with props.tag for plaintext strings or use
 * props.richtext to render a styled section heading.
 */
const SectionHeading = (props) => {
  let Tag = props.tag || "h1";
  let title = props.children || "";

  if (props.richText && props.richText.length > 0) {
    Tag = replaceTag(props.richText[0].type);
    title = stripMdBoldClasses(props.richText[0].text);
  }

  if (title.length === 0) {
    return null;
  }

  return (
    <div className="SectionHeading">
      <div className="container-guttered">
        <Tag
          className={cx(
            "SectionHeading__heading",
            `SectionHeading__heading--${props.textAlign}`
          )}
        >
          {title}
        </Tag>
      </div>
    </div>
  );
};

SectionHeading.defaultProps = {
  textAlign: "center",
};

SectionHeading.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node,
  richText: PropTypes.array,
  textAlign: PropTypes.oneOf(["left", "center"]),
};

export default SectionHeading;
