import isEmpty from "lodash/isEmpty";

export const getMaxLength = (props) => props.drawer.numberOfChoices || 3;

export const isCTADisabled = ({ values, customisationKey, ...props }) => {
  const options = values[customisationKey] || [];
  return (
    props.isSubmitting ||
    !isEmpty(props.errors) ||
    options.length < getMaxLength(props)
  );
};
