import { getProductCustomisation } from "src/client/js/store/selectors/product";
import { useSelector, useDispatch } from "react-redux";

export default (productId) => {
  const dispatch = useDispatch();
  const productCustomisation = useSelector((state) =>
    getProductCustomisation(state)
  );
  const updateProductCustomisation = (customisation) =>
    dispatch(updateProductCustomisation(productId, customisation));
  const removeProductCustomisation = (keys) =>
    dispatch(removeProductCustomisation(keys));
  return {
    productCustomisation,
    updateProductCustomisation,
    removeProductCustomisation,
  };
};
