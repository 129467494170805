import MultiLevelForm from "./MultiLevelForm";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import withValidationData from "../../../hocs/withValidationData";
import { replaceTextWithValues } from "../../../../../../common/utils/text-replace";

const buildMultiLevelItems = (items, customisations) => {
  return cloneDeep(items).map((item) => {
    item.form.body = item.form.body.map((field) => ({
      ...field,
      // use value from customisations or fall back to prismic default value
      // also replace any custom text referenced by cusomisations.  e.g. %{name}
      value: replaceTextWithValues(
        get(customisations, field.name, field.prefillValue),
        customisations
      ),
      label: replaceTextWithValues(field.label, customisations),
    }));

    return item;
  });
};

export default compose(
  withValidationData,
  withProps(({ drawer, queryParams }) => ({
    drawer: {
      ...drawer,
      items: buildMultiLevelItems(drawer.items, queryParams),
    },
  }))
)(MultiLevelForm);
