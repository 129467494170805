import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { prismicImage } from "src/common/utils/prismic-image";

import "./PlaceholdImage.scss";

const PlaceholdImage = (props) => {
  return (
    <div
      style={{
        paddingTop: `${
          (props.image.dimensions.height / props.image.dimensions.width) * 100
        }%`,
      }}
      className="PlaceholdImage__image--wrapper"
    >
      <img
        className={cx("PlaceholdImage__image", {
          [props.className]: props.className,
        })}
        src={prismicImage(props.image)}
        alt={props.image.alt}
        width={props.width}
        loading={props.noLazyLoad ? "eager" : "lazy"}
      />
    </div>
  );
};

PlaceholdImage.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  noLazyLoad: PropTypes.bool,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default PlaceholdImage;
