import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import RichText from "../../../Prismic/RichText";

const MultiBirthdayFormEmpty = (props) => (
  <div className="multi-birthday__form-wrapper multi-birthday__form-wrapper--empty">
    <RichText fields={props.content} />
    <Button
      className="multi-birthday__add-characters-button"
      onClick={props.onOpenMultiAdventurersDrawer}
    >
      {props.cta}
    </Button>
  </div>
);

MultiBirthdayFormEmpty.propTypes = {
  content: PropTypes.array.isRequired,
  cta: PropTypes.string.isRequired,
  onOpenMultiAdventurersDrawer: PropTypes.func.isRequired,
};

export default MultiBirthdayFormEmpty;
