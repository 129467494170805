import React from "react";
import clipboardCopy from "clipboard-copy";
import RichText from "../Prismic/RichText";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { replaceTextWithValues } from "src/common/utils/text-replace";

export const applyPromoCodes = (
  offers,
  country,
  lastCopiedIndex,
  setLastCopiedIndex,
  titlePromoCopied
) => {
  for (const [idx, offer] of offers.entries()) {
    for (const cta of offer.ctas) {
      // default cta text
      cta.text = cta.cta;
      // prefix hrefs with current locale
      cta.href = cta.href ? localiseUrlII(country, cta.href) : undefined;
    }

    // insert promocode if one is used
    if (offer.title.includes("%{code}")) {
      // build promo code span styles
      let style = offer.codeBackgroundColor
        ? `background-color: ${offer.codeBackgroundColor};`
        : "";
      style += offer.codeTextColor ? `color: ${offer.codeTextColor};` : "";

      // enable promo code html
      const promoTitle = replaceTextWithValues(offer.title, {
        code: `<span class="OffersOverlay__code" style="${style}">${offer.code}</span>`,
      });
      offer.promoTitle = <RichText inline fields={[{ text: promoTitle }]} />;

      // remap the ctas required to enable click handler
      for (const cta of offer.ctas) {
        if (cta.copyPromoCode) {
          // bind promo code copy click
          cta.onHandleClick = () => {
            clipboardCopy(offer.code).then(() => {
              setLastCopiedIndex(idx);
              setTimeout(() => setLastCopiedIndex(null), 2000);
            });
          };

          // change cta text
          cta.text =
            titlePromoCopied && lastCopiedIndex === idx
              ? titlePromoCopied
              : cta.cta;
        }
      }
    }
  }
  return offers;
};

export const isScrolled = () =>
  window.innerHeight + window.scrollY + 200 >=
  document.documentElement.offsetHeight;
