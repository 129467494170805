import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import {
  crossDeviceEvent,
  crossDeviceEndEvent,
} from "src/common/utils/cross-device-event";
import "./Drag.scss";

class Drag extends Component {
  static propTypes = {
    disableDrag: PropTypes.bool,
    children: PropTypes.any.isRequired,
    onDragStart: PropTypes.func,
    onDragMove: PropTypes.func,
    onDragEnd: PropTypes.func,
    className: PropTypes.string,
  };
  state = {
    isPressed: false,
    isDragging: false,
    windowScrollY: 0,
    startX: 0,
    endX: 0,
    distance: 0,
  };
  handleStart = (evt) => {
    if (this.props.disableDrag) {
      return null;
    }

    const events = crossDeviceEvent(evt);
    const startX = events.pageX;
    const windowScrollY = window.scrollY;

    this.setState(
      () => ({
        isPressed: true,
        windowScrollY,
        startX,
        distance: 0,
        endX: 0,
      }),
      () => {
        if (this.props.onDragStart) {
          this.props.onDragStart(this.state);
        }
      }
    );
  };
  handleMove = (evt) => {
    if (this.props.disableDrag) {
      return null;
    }
    const events = crossDeviceEvent(evt);
    const windowScrollY = window.scrollY;

    // Do not capture mousemove events that are not preceeded by a mousedown (drag) or if scrolling window vertically
    if (
      (!this.state.isPressed && evt.type === "mousemove") ||
      (window && this.state.windowScrollY !== windowScrollY)
    ) {
      return;
    }

    const currentX = events.pageX;
    const distance = currentX - this.state.startX;
    this.setState(
      () => ({
        isDragging: true,
        windowScrollY,
        distance,
      }),
      () => {
        if (this.props.onDragMove) {
          this.props.onDragMove(this.state);
        }
      }
    );
  };
  handleEnd = (evt) => {
    if (this.props.disableDrag) {
      return null;
    }

    const events = crossDeviceEndEvent(evt);

    if (!this.state.isPressed && evt.type === "mouseleave") {
      return;
    }
    const endX = events.pageX;
    this.setState(
      () => ({
        isPressed: false,
        isDragging: false,
        startX: 0,
        endX,
      }),
      () => {
        if (this.props.onDragEnd) {
          this.props.onDragEnd(this.state);
        }
      }
    );
  };
  render() {
    return (
      <div
        className={cx("drag", {
          "is-dragging": this.state.isPressed,
          ...this.props.className,
        })}
        onTouchStart={this.handleStart}
        onMouseDown={this.handleStart}
        onTouchMove={this.handleMove}
        onMouseMove={this.handleMove}
        onTouchEnd={this.handleEnd}
        onMouseUp={this.handleEnd}
        onMouseLeave={this.handleEnd}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Drag;
