import sanitizeHtml from "sanitize-html";

/*
This is a very dangerous method. It will take HTML and sanitize it removing
all sorts of nastiness. It MUST be used anywhere that we use
dangerouslySetInnerHTML.

If you want to change any of the options of the library used you really have to know what you are doing.
If uncertain of what's allowed, you are NOT allowed to make a change without consulting a senior member of
engineering. Always ensure that any of the changes here are peer reviewed. Always!
 */
export default function (html) {
  return sanitizeHtml(html, {
    // explicitly specifying the allowed tag
    allowedTags: [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "blockquote",
      "p",
      "a",
      "ul",
      "ol",
      "nl",
      "li",
      "b",
      "i",
      "strong",
      "em",
      "strike",
      "code",
      "hr",
      "br",
      "div",
      "table",
      "thead",
      "caption",
      "tbody",
      "tr",
      "th",
      "td",
      "pre",
      "span",
    ],
    // for allowed tags allow only these attributes
    allowedAttributes: {
      "*": ["style", "class", "href", "target"],
    },
  });
}
