import React from "react";
import PropTypes from "prop-types";
import Member from "./Member";
import Button from "wonderbly-components/lib/Button";
import { Row, Col } from "src/client/js/view/components/Grid";
import chunk from "lodash/chunk";

import "./FamilyMembers.scss";
import Tooltip from "wonderbly-components/lib/Tooltip";

const FamilyMembers = (props) => (
  <form className="family-members" onSubmit={props.handleSubmit}>
    <Tooltip
      className="error-tooltip"
      visible={props.errors && !!props.errors.base}
      absolute={false}
      type="alert"
    >
      {props.errors && props.errors.base}
    </Tooltip>
    <Tooltip
      className="error-tooltip"
      visible={props.errors && !!props.errors.names}
      absolute={false}
      type="alert"
    >
      {props.errors && props.errors.names}
    </Tooltip>
    <Row colsToRowsOn="fablet-down">
      {chunk(props.values.names, 3).map((col, colIndex) => (
        <Col className="col-1-3" key={colIndex}>
          {col.map((name, rowIndex) => (
            <Member
              key={colIndex * 3 + rowIndex}
              index={colIndex * 3 + rowIndex}
              name={name}
              onMemberChange={props.handleMemberChange}
            />
          ))}
        </Col>
      ))}
    </Row>
    <Row className="family-members-cta-row" colsToRowsOn="fablet-down">
      <Col className="col-2-3">
        {!props.isAddButtonDisabled && (
          <Row className="family-members-additional-field-wrapper">
            <Col className="family-members-additional-field">
              <input
                key="family-members-additional-field"
                type="text"
                onChange={props.onChangeAdditionalName}
                value={props.additionalName}
              />
            </Col>
            <Col className="family-members-additional-cta">
              <Button
                key="family-members-additional-cta"
                onClick={props.onAddMember}
                disabled={props.isAddButtonDisabled}
                type="button"
              >
                {props.drawer.addCta}
              </Button>
            </Col>
          </Row>
        )}
      </Col>
      <Col className="family-members-cta-button col-1-3">
        <Button
          onClick={props.handleSubmit}
          type="submit"
          disabled={props.isCompleteButtonDisabled}
        >
          {props.drawer.cta}
        </Button>
      </Col>
    </Row>
  </form>
);

FamilyMembers.propTypes = {
  isCompleteButtonDisabled: PropTypes.bool.isRequired,
  isAddButtonDisabled: PropTypes.bool.isRequired,
  additionalName: PropTypes.string,
  onChangeAdditionalName: PropTypes.func.isRequired,
  onAddMember: PropTypes.func.isRequired,
  handleMemberChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    addCta: PropTypes.string.isRequired,
  }),
  values: PropTypes.shape({
    names: PropTypes.array.isRequired,
  }),
  errors: PropTypes.shape({
    base: PropTypes.string,
    names: PropTypes.string,
  }),
};

export default FamilyMembers;
