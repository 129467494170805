import {
  GET_PRISMIC_PRODUCT_PENDING,
  GET_PRISMIC_PRODUCT_SUCCESS,
  GET_PRISMIC_PRODUCT_FAILURE,
} from "../../actions/prismic/product";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PRISMIC_PRODUCT_PENDING:
      return {
        ...state,
        [action.sku]: {
          ...state[action.sku],
          [action.locale]: {
            response: {
              pending: true,
            },
          },
        },
      };
    case GET_PRISMIC_PRODUCT_SUCCESS:
      return {
        ...state,
        [action.sku]: {
          ...state[action.sku],
          [action.locale]: {
            response: {
              ok: true,
              status: 200,
            },
            ...action.response.body,
          },
        },
      };
    case GET_PRISMIC_PRODUCT_FAILURE:
      return {
        ...state,
        [action.sku]: {
          ...state[action.sku],
          [action.locale]: {
            response: action.response,
          },
        },
      };
    default:
      return state;
  }
};
