import compose from "recompose/compose";
import withCart from "../../../hocs/withCart";
import when from "recompose/branch";
import renderNothing from "recompose/renderNothing";
import { connect } from "react-redux";
import get from "lodash/get";

import { isCartEmpty, isCartPending } from "../../../../store/selectors/cart";
import { isQuickAddEnabled } from "src/common/config/feature-flags";
import QuickAdd from "./QuickAdd";
import { withProps } from "recompose";
import withProducts from "../../../hocs/withProducts";
import { buildCartItemFor } from "./helpers";
import { withFormik } from "formik";
import { withModel } from "../../../data-providers/model";
import withCountry from "../../../hocs/withCountry";

const enhance = compose(
  withProducts,
  withModel,
  withCountry,
  withProps((props) => {
    return {
      boxes: [
        { label: "No box", value: "no-box" },
        { label: "Keepsake", value: "keepsake-box" },
        { label: "Deluxe", value: "deluxe-box" },
      ],
      sizes: [
        "",
        { label: "Jumbo", value: "jumbo" },
        { label: "Journal", value: "journal" },
      ],
      covers: [
        { label: "Softback", value: "softback" },
        { label: "Hardback", value: "hardback" },
        { label: "Deluxe", value: "deluxe" },
      ],
      locales: (get(props, "country.contentLocales") || []).map(
        (contentLocale) => {
          return { label: contentLocale, value: contentLocale };
        }
      ),
      productsOptions: (get(props, "products") || []).map((prod) => {
        return { label: prod.id, value: prod.id, key: prod.id };
      }),
    };
  }),
  withCart,
  connect((state) => ({
    isCartEmpty: isCartEmpty(state),
    isCartPending: isCartPending(state),
  })),
  when(
    () => !isQuickAddEnabled(),
    renderNothing,
    (c) => c
  ),
  withFormik({
    mapPropsToValues: (props) => ({
      product: "lmn",
      size: "",
      locale: "en-GB",
      box: "no-box",
      cover: "softback",
    }),
    handleSubmit: async (values, formikBag) => {
      formikBag.props.cart.add(buildCartItemFor(values), false);
    },
  })
);

export default enhance(QuickAdd);
