const MetadataModel = require("./MetadataModel");

class BaseModel {
  constructor() {
    this.metadata = null;
  }
  deserialise(data) {
    this.metadata = new MetadataModel().deserialise(data);
    return this;
  }
}

module.exports = BaseModel;
