import React from "react";
import PropTypes from "prop-types";
import url from "url";
import MarkdownIt from "markdown-it";
import cx from "classnames";
import markdownItAttrs from "markdown-it-attrs";
import markdownItReplaceLink from "markdown-it-replace-link";

import CountdownReplacer from "../CountdownReplacer";

import { localiseUrl } from "src/common/utils/format-localised-url";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import { withModel } from "src/client/js/view/data-providers/model";
import logger from "src/logger";

import "./Prismic.scss";

const md = new MarkdownIt({
  breaks: true,
  html: true,
  replaceLink: (link, env) => {
    const urlObject = url.parse(link);
    if (urlObject.hostname) {
      return link;
    }
    return localiseUrl(env.countryModel, link);
  },
});

md.use(markdownItAttrs).use(markdownItReplaceLink);

export const objectToAttributes = (object) =>
  JSON.stringify(object).replace(/:/g, "=").replace(/,/g, " ");
export const attributesToObject = (text) =>
  reduce(
    text.split(" "),
    (result, string) => {
      const [key, value] = string.replace(/[{}"']/g, "").split("=");
      result[key] = value;
      return result;
    },
    {}
  );

export const hasAttr = (text) => {
  const regex = /{([\w\-]+\=[\w\s\-_#"'/;:]+)+}$/g;
  const attrs = text.match(regex);
  return attrs ? attrs[0] : false;
};

export const hasEmphasis = (text) => {
  const asterisksRegex = /\*{1,2}(.+)\*{1,2}$/;
  const underscoreRegex = /\_{1,2}(.+)\_{1,2}$/;
  const strikethroughRegex = /\~{2}(.+)\~{2}$/;

  return (
    asterisksRegex.test(text) ||
    underscoreRegex.test(text) ||
    strikethroughRegex.test(text)
  );
};

export const applyAttributesWithEmphasis = (text) => {
  let string = text;
  let attrs = hasAttr(string);

  if (!attrs) {
    return string;
  }
  string = string.replace(attrs, "");

  attrs = attributesToObject(attrs);
  attrs = objectToAttributes(attrs);

  return `${string}${attrs}`;
};

export const addMarkdownClass = (text, className) => {
  let attributes = {
    class: className,
  };
  let attrs = hasAttr(text);

  if (attrs) {
    text = text.replace(attrs, "");
    text = hasEmphasis(text) ? `${text} ` : text;

    attrs = attributesToObject(attrs);
    attributes = reduce(
      attrs,
      (result, value, key) => {
        result[key] = value;
        if (key === "class") {
          result[key] = `${attributes.class} ${value}`;
        }
        return result;
      },
      {}
    );
  }
  text = hasEmphasis(text) ? `${text} ` : text;
  attributes = objectToAttributes(attributes);
  return `${text}${attributes}`;
};

export const formatContent = (item) => {
  const type = item.type;
  let text = "";
  switch (type) {
    case "image":
      text = addMarkdownClass(`![${item.alt}](${item.url})`, "prismic-image");
      break;
    case "heading1":
      text = addMarkdownClass(`# ${item.text}`, "text-peta");
      break;
    case "heading2":
      text = addMarkdownClass(`## ${item.text}`, "text-tera");
      break;
    case "heading3":
      text = addMarkdownClass(`### ${item.text}`, "text-giga");
      break;
    case "heading4":
      text = addMarkdownClass(`#### ${item.text}`, "text-mega");
      break;
    case "heading5":
      text = addMarkdownClass(`##### ${item.text}`, "text-mega");
      break;
    case "heading6":
      text = addMarkdownClass(`###### ${item.text}`, "text-mega");
      break;
    default:
      text = applyAttributesWithEmphasis(item.text);
      break;
  }

  if (text === "") {
    text = "\n";
  }

  return text;
};

export const markdown = (fields, options = {}) => {
  const content = fields.map(formatContent).join("\n");

  if (options.inline) {
    try {
      return md.renderInline(content, { countryModel: options.countryModel });
    } catch (error) {
      logger.error(`Error rendering markdown: ${content}`, error);
      return "";
    }
  }

  try {
    return md.render(content, { countryModel: options.countryModel });
  } catch (error) {
    logger.error(`Error rendering markdown: ${content}`, error);
    return "";
  }
};

const RichText = (props) => {
  if (!props.fields) {
    logger.error(new Error("Missing fields in Rich Text component."));
    return null;
  }
  if (isEmpty(props.fields)) {
    return null;
  }

  const html = markdown(props.fields, {
    inline: props.inline,
    countryModel: props.model.countryModel,
  });

  if (props.inline) {
    return (
      <CountdownReplacer replacerId="richtext-countdown">
        {html}
      </CountdownReplacer>
    );
  }

  return (
    <div
      className={cx("rich-text", {
        [props.className]: props.className,
        [`color-${props.color}`]: props.color,
      })}
      data-animate-text={props.animateText}
      style={props.style}
    >
      <CountdownReplacer replacerId="richtext-countdown">
        {html}
      </CountdownReplacer>
    </div>
  );
};

RichText.propTypes = {
  model: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  className: PropTypes.string,
  animateText: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  inline: PropTypes.bool,
};

export default withModel(RichText);
