import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import useKeyPress from "react-use/lib/useKeyPress";

import cx from "classnames";
import { Transition } from "react-spring/renderprops.cjs";

import { useComponentVisible } from "../../hooks";

import {
  setOpenOverlay,
  setCloseOverlay,
} from "src/client/js/store/actions/app";

import { isDOMReady, modalRoot } from "./helpers";

import "./Modal.scss";

const AnimationWrapper = ({ show, children }) => (
  <Transition
    items={show}
    from={{ opacity: 0 }}
    enter={{ opacity: 1 }}
    leave={{ opacity: 0 }}
  >
    {(show) => show && ((styles) => <div style={styles}>{children}</div>)}
  </Transition>
);

const updateBodyOverflow = (isVisible, containScrollEnabled, dispatch) => {
  if (!isDOMReady() && !containScrollEnabled) {
    return;
  }

  if (isVisible) {
    dispatch(setOpenOverlay(window.scrollY));
  } else {
    dispatch(setCloseOverlay());
  }
};

const handleEscapePressed = (escEvent, cb) => {
  if (!isDOMReady() || !escEvent || !escEvent[0]) {
    return;
  }

  cb();
};

const Modal = (props) => {
  const { ref, isVisible, setIsVisible } = useComponentVisible(props.isOpen);
  const dispatch = useDispatch();
  const escEvent = useKeyPress("Escape");

  const modalVisible = isVisible && !props.bypass;

  useEffect(() => {
    if (props.isOpen !== isVisible) {
      setIsVisible(props.isOpen);
    }
  }, [props.isOpen]);

  useEffect(() => {
    updateBodyOverflow(modalVisible, props.containScroll, dispatch);
  }, [isVisible]);

  useEffect(() => {
    handleEscapePressed(escEvent, () => {
      if (modalVisible) {
        if (props.containScroll) {
          dispatch(setCloseOverlay());
        }

        if (props.updateIsOpen) {
          props.updateIsOpen(false);
        }
      }
    });
  }, [escEvent]);

  if (props.bypass) {
    return props.children;
  }

  if (!isDOMReady()) {
    return null;
  }

  const markup = (
    <AnimationWrapper show={props.isOpen}>
      <div
        className={cx("modal", {
          "modal--open": props.isOpen,
          "modal--default": props.default,
          "modal--transparent": props.transparent,
          "modal--fit": props.fit,
          [props.className]: !!props.className,
        })}
        style={props.style}
      >
        <div className="modal__container" ref={ref}>
          {props.children}
        </div>
      </div>
    </AnimationWrapper>
  );

  return ReactDOM.createPortal(markup, modalRoot);
};

AnimationWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  bypass: PropTypes.bool,
  default: PropTypes.bool,
  transparent: PropTypes.bool,
  fit: PropTypes.bool,
  containScroll: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Modal;
