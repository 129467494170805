import logger from "src/logger";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withRouter } from "react-router-dom";
import product from "src/common/config/product";
import { withModel } from "../data-providers/model";

const withProductId = compose(
  withRouter,
  withModel,
  withProps((props) => {
    const eagleProduct = props.product || props.model.eagleProduct;

    let productId = props.productId || (eagleProduct && eagleProduct.id);

    // using config/product.js is deprcated, this is only here as a last ditch effort
    try {
      if (!productId) {
        productId = product.getProductBySlug(
          props.match.params.productSlug
        ).sku;
      }
    } catch (error) {
      logger.warn(
        "Failed to get product from params.productSlug - ignore only if caused by quickAdd"
      );
    }

    return { productId };
  })
);

export default withProductId;
