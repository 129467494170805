import React from "react";
import get from "lodash/get";

export const { Provider, Consumer } = React.createContext({
  user: {},
  setUser: () => {},
});

export const withUser = (ComponentToWrap) => (props) =>
  (
    <Consumer>
      {({ user, setUser }) => (
        <ComponentToWrap
          {...props}
          user={user}
          setUser={setUser}
          isLoggedIn={!!get(user, "response.ok")}
        />
      )}
    </Consumer>
  );

export const buildUser = (response) => ({
  response: { ok: true },
  ...response.body.data,
});
