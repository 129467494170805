export const GET_PRISMIC_PRODUCT_PENDING = "GET_PRISMIC_PRODUCT_PENDING";
export const GET_PRISMIC_PRODUCT_SUCCESS = "GET_PRISMIC_PRODUCT_SUCCESS";
export const GET_PRISMIC_PRODUCT_FAILURE = "GET_PRISMIC_PRODUCT_FAILURE";

export const fetchPrismicProductPending = (sku, locale) => ({
  type: GET_PRISMIC_PRODUCT_PENDING,
  sku,
  locale,
});

export const fetchPrismicProductSuccess = (sku, locale, response) => ({
  type: GET_PRISMIC_PRODUCT_SUCCESS,
  sku,
  locale,
  response,
});

export const fetchPrismicProductFailure = (sku, locale, response) => ({
  type: GET_PRISMIC_PRODUCT_FAILURE,
  sku,
  locale,
  response,
});
