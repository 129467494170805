import Hero from "./Hero";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { hasPhototypes } from "../helpers";
import withPhototypeToggle from "./withPhototypeToggle";

const enhance = compose(
  withPhototypeToggle,
  withProps((props) => ({
    hasPhototypes: props.isPhototypeEnabled && hasPhototypes(props.items),
    availablePhototypes: {
      boy: props.items.filter(
        (hero) =>
          !!hero.phototype && hero.phototype !== "All" && hero.gender === "Boy"
      ),
      girl: props.items.filter(
        (hero) =>
          !!hero.phototype && hero.phototype !== "All" && hero.gender === "Girl"
      ),
    },
  }))
);

export default enhance(Hero);
