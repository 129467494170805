import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Adventurer from "../Adventurer";
import Button from "wonderbly-components/lib/Button";
import Characters from "./Characters";
import "./MultiAdventurer.scss";
import Tooltip from "wonderbly-components/lib/Tooltip";

const MultiAdventurer = (props) => {
  const currentIndex = props.currentFormIndex;
  return (
    <div className="multi-adventurer-form">
      {props.isAdventurerEditable && (
        <Fragment>
          {!props.isInCanvasPage && (
            <h5 className="text-Base multi-adventurer-form__title">
              {props.form.title}
            </h5>
          )}
          <Adventurer
            productId={props.productId}
            form={props.form}
            values={props.characters[currentIndex]}
            fetchPrismicProduct={props.fetchPrismicProduct}
            onValidateForm={props.handleAdventurerValidation}
            onFormikSubmit={props.handleAdventurerSubmission}
          />
        </Fragment>
      )}
      {!props.isAdventurerEditable && (
        <Fragment>
          <Tooltip
            className="error-tooltip"
            visible={props.errors && !!props.errors.base}
            absolute={false}
            type="alert"
          >
            {props.errors && props.errors.base}
          </Tooltip>
          <Characters
            maxCharacterLimit={props.maxCharacterLimit}
            characters={props.characters}
            heroes={props.heroes}
            addAnotherChild={props.drawer.adventurerAddButton}
            editChild={props.drawer.adventurerEditLink}
            onCharacterEdit={props.handleAdventurerEdit}
            onCharacterRemove={props.handleAdventurerDelete}
          />
          <Button
            type="submit"
            onClick={props.handleFormSubmission}
            disabled={props.isDisabled}
          >
            {props.drawer.cta}
          </Button>
        </Fragment>
      )}
    </div>
  );
};

MultiAdventurer.propTypes = {
  productId: PropTypes.string.isRequired,
  characters: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    adventurerAddButton: PropTypes.string.isRequired,
    adventurerEditLink: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    base: PropTypes.string,
  }),
  heroes: PropTypes.array.isRequired,
  currentFormIndex: PropTypes.number.isRequired,
  maxCharacterLimit: PropTypes.number.isRequired,
  isInCanvasPage: PropTypes.bool.isRequired,
  isAdventurerEditable: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  fetchPrismicProduct: PropTypes.func.isRequired,
  handleAdventurerSubmission: PropTypes.func.isRequired,
  handleAdventurerValidation: PropTypes.func.isRequired,
  handleAdventurerEdit: PropTypes.func.isRequired,
  handleAdventurerDelete: PropTypes.func.isRequired,
  handleFormSubmission: PropTypes.func.isRequired,
};

export default MultiAdventurer;
