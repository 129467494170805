import get from "lodash/get";
export const ADD_FLASH_MESSAGE_NOTICE = "ADD_FLASH_MESSAGE_NOTICE";
export const ADD_FLASH_MESSAGE_ERROR = "ADD_FLASH_MESSAGE_ERROR";
export const REMOVE_FLASH_MESSAGE = "REMOVE_FLASH_MESSAGE";

export const addFlashMessageNotice = (message) => ({
  type: ADD_FLASH_MESSAGE_NOTICE,
  message,
});

export const addFlashMessageError = (message) => ({
  type: ADD_FLASH_MESSAGE_ERROR,
  message,
});

export const removeFlashMessage = () => ({
  type: REMOVE_FLASH_MESSAGE,
});

export const getFlashMessageNotices = (state) =>
  get(state, "components.flashMessages.notices");
export const getFlashMessageErrors = (state) =>
  get(state, "components.flashMessages.errors");

export default (state = { notices: [], errors: [] }, action) => {
  switch (action.type) {
    case ADD_FLASH_MESSAGE_NOTICE: {
      if (state.notices.includes(action.message)) {
        return state;
      }
      return {
        ...state,
        notices: state.notices.concat(action.message),
      };
    }
    case ADD_FLASH_MESSAGE_ERROR: {
      if (state.errors.includes(action.message)) {
        return state;
      }
      return {
        ...state,
        errors: state.errors.concat(action.message),
      };
    }
    case REMOVE_FLASH_MESSAGE: {
      if (state.errors.length === 0 && state.notices.length === 0) {
        return state;
      }

      return {
        errors: [],
        notices: [],
      };
    }
    default: {
      return state;
    }
  }
};
