import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { matchesHero } from "../helpers.js";
import Panel from "wonderbly-components/lib/Panel";
import Icon from "wonderbly-components/lib/Icon";
import Button from "wonderbly-components/lib/Button";

const Characters = (props) => {
  const charactersLength = props.characters.length;
  const characterSpaceLeft = props.maxCharacterLimit - charactersLength;
  const showAddCharacterButton = characterSpaceLeft > 0;
  const addCharacterSpace = showAddCharacterButton ? 1 : 0;
  // Check if the total characters plus the button is an even number or not (used for styling)
  const isCharacterSlotsEven = (charactersLength + addCharacterSpace) % 2 === 0;
  return (
    <div
      className={cx("characters", {
        "characters--odd": !isCharacterSlotsEven,
        "characters--even": isCharacterSlotsEven,
        "characters--maxed": characterSpaceLeft === 0,
      })}
    >
      {props.characters.map((character, idx) => {
        const hero =
          props.heroes.find((hero) => matchesHero(hero, character)) || {};
        return (
          <Panel key={idx} className="character" border="shadow">
            <button
              onClick={props.onCharacterRemove(idx)}
              className="character__remove"
            >
              <Icon icon="delete" />
            </button>
            {hero.image && (
              <img
                src={hero.image.url}
                alt={hero.image.alt || ""}
                className="character__image"
              />
            )}
            <h5 className="text-base character__title">{character.name}</h5>
            <Button
              unstyled
              className="character__edit-link"
              onClick={props.onCharacterEdit(idx)}
            >
              {props.editChild}
            </Button>
          </Panel>
        );
      })}
      {showAddCharacterButton && (
        <button
          className="character character--add-new"
          onClick={props.onCharacterEdit(charactersLength)}
        >
          {props.addAnotherChild}
        </button>
      )}
    </div>
  );
};

Characters.propTypes = {
  addAnotherChild: PropTypes.string.isRequired,
  editChild: PropTypes.string.isRequired,
  maxCharacterLimit: PropTypes.number.isRequired,
  heroes: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
    })
  ).isRequired,
  characters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onCharacterEdit: PropTypes.func.isRequired,
  onCharacterRemove: PropTypes.func.isRequired,
};

export default Characters;
