import React, { Fragment } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Panel from "wonderbly-components/lib/Panel";
import Tooltip from "wonderbly-components/lib/Tooltip";
import BirthdayFormRow from "../../../BirthdayFormRow";

const MultiBirthdayFormFields = (props) => {
  const form = props.form;
  const multiAdventurerField =
    form.body.find((field) => field.sliceType === "multi-adventurer") || {};
  const maxCharacterLimit = multiAdventurerField.primary.maxCharacterLimit;
  const isCharacterLimitEven = maxCharacterLimit % 2 === 0;
  const birthdays = props.values.birthdays;
  const birthdayErrors = props.errors.birthdays;
  const fields = [];

  for (let i = 0; i < maxCharacterLimit; i++) {
    const values = birthdays[i];
    const character = props.characters[i];
    const displayForm = !!values && !!character;
    const error = birthdayErrors && birthdayErrors[i];
    fields.push(
      <Panel
        key={i}
        className={cx("multi-birthday__field", {
          "multi-birthday__field--empty": !displayForm,
        })}
        border={displayForm ? "shadow" : null}
      >
        {displayForm && (
          <Fragment>
            <h3 className="text-mega multi-birthday__field-name">
              {character.name}
            </h3>
            <h5 className="text-base multi-birthday__field-title">
              {props.drawer.dateOfBirthText}
            </h5>
            <Tooltip
              className="error-tooltip error-tooltip--multi-birthday-field"
              visible={!!error}
              absolute
              type="alert"
            >
              {error}
            </Tooltip>
            <BirthdayFormRow
              formId="birthday-form"
              drawer={props.drawer}
              values={values}
              onHandleChange={props.onFieldChange(i)}
            />
          </Fragment>
        )}
      </Panel>
    );
  }

  return (
    <div className="multi-birthday__form-wrapper">
      <Tooltip
        className="error-tooltip error-tooltip--multi-birthday-base"
        visible={props.errors && !!props.errors.base}
        absolute
        type="alert"
      >
        {props.errors && props.errors.base}
      </Tooltip>
      <form
        className={cx("multi-birthday__form", {
          "multi-birthday__form--error": props.errors && props.errors.base,
          "multi-birthday__form--even": isCharacterLimitEven,
          "multi-birthday__form--odd": !isCharacterLimitEven,
        })}
      >
        {fields}
      </form>
    </div>
  );
};

MultiBirthdayFormFields.propTypes = {
  characters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  form: PropTypes.shape({
    body: PropTypes.arrayOf(
      PropTypes.shape({
        sliceType: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  drawer: PropTypes.shape({
    dateOfBirthText: PropTypes.string.isRequired,
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
};

export default MultiBirthdayFormFields;
