import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import Button from "wonderbly-components/lib/Button";
import { Row, Col } from "src/client/js/view/components/Grid";
import Tooltip from "wonderbly-components/lib/Tooltip";

import "./Inputs.scss";

const Inputs = (props) => (
  <form className="drawer-inputs" onSubmit={props.handleSubmit}>
    <Tooltip
      className="error-tooltip"
      visible={props.errors && !!props.errors.base}
      absolute={false}
      type="alert"
    >
      {props.errors && props.errors.base}
    </Tooltip>
    <Row colsToRowsOn="fablet-down">
      <Col className="col-2-3">
        <Row colsToRowsOn="fablet-down">
          {props.drawer.items.map((item) => (
            <Col className="col-1-2" key={item.drawerKey}>
              <fieldset className="drawer-inputs__fieldset">
                <Tooltip
                  className="error-tooltip"
                  visible={!!get(props.errors, item.drawerKey)}
                  absolute
                  type="alert"
                >
                  {get(props.errors, item.drawerKey)}
                </Tooltip>
                <label>{item.label}</label>
                <input
                  name={item.drawerKey}
                  value={get(props.values, item.drawerKey)}
                  onChange={props.handleOnChangeItem}
                  placeholder={item.defaultValue}
                />
              </fieldset>
            </Col>
          ))}
        </Row>
      </Col>
      <Col className="col-1-3 drawer-inputs-cta-button">
        <Button
          onClick={props.handleSubmit}
          type="submit"
          disabled={props.isSubmitting || !isEmpty(props.errors)}
        >
          {props.drawer.cta}
        </Button>
      </Col>
    </Row>
  </form>
);

Inputs.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  handleOnChangeItem: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    addCta: PropTypes.string.isRequired,
    drawerKey: PropTypes.string,
    items: PropTypes.array.isRequired,
  }),
  values: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    base: PropTypes.string,
  }),
};

export default Inputs;
