import React from "react";
import { prismicImage } from "src/common/utils/prismic-image";
import { useSiteNavContext } from "../SiteNavToggle/SiteNavContext";
import { trackOpenCountryToggle } from "../../tracking";
import { useCountry } from "../../../../hooks";
import "./NavCountryToggle.scss";

export const NavCountryToggle = () => {
  const { setIsChooseCountryModalOpen } = useSiteNavContext();
  const country = useCountry();

  const handleOnClick = () => {
    setIsChooseCountryModalOpen(true);
    trackOpenCountryToggle();
  };

  return (
    <div className="NavCountryToggle">
      <button
        className="NavCountryToggle__pill"
        type="button"
        onClick={handleOnClick}
      >
        <img
          src={prismicImage(country.flag)}
          alt={country.iso}
          className="NavCountryToggle__image"
        />
        <span className="NavCountryToggle__text">{country.name}</span>
      </button>
    </div>
  );
};

export default NavCountryToggle;
