import { connect } from "react-redux";
import { getCount } from "src/client/js/store/selectors/cart";
import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import withProps from "recompose/withProps";
import get from "lodash/get";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import Basket from "./Basket";
import { updateActivePopup, getActivePopup } from "../../duck";
import { isHeaderBasketEnabled } from "src/common/config/feature-flags";

const enhance = compose(
  withProps((props) => {
    const cart = props.cart || [];
    return {
      icon: props.country.locale === "en-GB" ? "basket" : "emptycart",
      linkText:
        props.linkText ||
        get(
          cart.find((item) => item.linkType === "cart"),
          "linkText",
          "Cart"
        ),
      href: localiseUrlII(props.country, "/cart"),
    };
  }),
  connect(
    (state) => ({
      count: getCount(state),
      isOpen: getActivePopup(state) === "basket",
    }),
    (dispatch) => ({
      setActivePopup: () => dispatch(updateActivePopup("basket")),
    })
  ),
  withHandlers({
    handleClick: (props) => (e) => {
      if (isHeaderBasketEnabled()) {
        e.preventDefault();
        props.setActivePopup();
      }
    },
  })
);

export default enhance(Basket);
