import React, { useContext, useState, useCallback } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import { useCountry, useLock } from ".";

import reLogin from "../utils/re-login";

const fetchContext = React.createContext({
  fetchData: {},
  setFetchData: () => {},
  currentlyLoading: {},
  setCurrentlyLoading: () => {},
  errors: {},
  setErrors: () => {},
});

export const FetchDataProvider = ({ children }) => {
  const [fetchData, setFetchData] = useState({});
  const [currentlyLoading, setCurrentlyLoading] = useState({});
  const [errors, setErrors] = useState({});

  return (
    <fetchContext.Provider
      value={{
        fetchData,
        setFetchData,
        currentlyLoading,
        setCurrentlyLoading,
        errors,
        setErrors,
      }}
    >
      {children}
    </fetchContext.Provider>
  );
};

FetchDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const getFunctionIdFactory = () => {
  const fnIdMap = new WeakMap();
  let fnCount = 0;

  /**
   * Generate a unique id for a function with a given name
   * @param fn the function
   */
  return (fn) => {
    if (!fnIdMap.has(fn)) {
      fnIdMap.set(fn, ++fnCount);
    }
    return fnIdMap.get(fn);
  };
};

const getFunctionId = getFunctionIdFactory();

/**
 * Fetch data, or pull it out of context. The same data will
 * never be fetched twice, even if the hook is called twice
 * at the same time.
 *
 * @param {function} fn the fetching function
 * @param {object} [params] optional parameters
 * @param {string} [params.id] id for the data, passed to your fetching function
 * @param {string} [params.groupId] the key to store the data in context, and to lock groups of functions
 * @param {function} [params.map] map the data returned by the fetching function
 *
 * @return {*} the data returned by your fetching function or mapping function
 */
export const useFetch = (
  fn,
  { id = "ALL_DATA", map = (obj) => obj, ...params } = {}
) => {
  const {
    fetchData,
    setFetchData,
    currentlyLoading,
    setCurrentlyLoading,
    errors,
    setErrors,
  } = useContext(fetchContext);

  const groupId = params.groupId || getFunctionId(fn);

  const dotKey = `${groupId}.${id}`;
  const data = get(fetchData, dotKey, null);
  const error = get(errors, dotKey, null);
  const loading = get(currentlyLoading, dotKey, true);

  const deps = [groupId, id];
  const setForKey = useCallback(
    (val, setter, obj) =>
      setter({
        ...obj,
        [groupId]: { ...obj[groupId], [id]: val },
      }),
    deps
  );
  const setKeyLoading = useCallback(
    (val) => setForKey(val, setCurrentlyLoading, currentlyLoading),
    deps
  );
  const setKeyError = useCallback(
    (val) => setForKey(val, setErrors, errors),
    deps
  );
  const setKeyData = useCallback(
    (val) => setForKey(val, setFetchData, fetchData),
    deps
  );

  useLock(
    async () => {
      try {
        const data = await fn(id);
        setKeyData(await map(data));
        setKeyLoading(false);
      } catch (e) {
        setKeyError(e);
        setKeyLoading(false);
      }
    },
    id,
    groupId
  );

  return {
    data,
    loading,
    error,
  };
};

export const useFetchEagle = (
  fn,
  { groupId, id, map = (response) => response.body.data }
) => {
  const country = useCountry();

  return useFetch(
    async (...args) => {
      try {
        return await fn(...args);
      } catch (e) {
        if (e.status === 401) {
          reLogin(country);
        } else {
          throw e;
        }
      }
    },
    // Set id based on internal function ID, since above is created
    // with each call and hence would always receive a new ID
    { groupId: groupId || getFunctionId(fn), id, map }
  );
};
