import Inputs from "./Inputs";
import { withFormik } from "formik";
import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import withValidationData from "../../../hocs/withValidationData";
import { validateCreationForm } from "../helpers";
import formatAdventurerName from "src/common/utils/format-adventurer-name";
import set from "lodash/set";
import get from "lodash/get";

/*
 * Inputs drawer.  Prismic user sets a specific number of inputs and labels.
 * For examle 'Fist Name' and 'Last Name' but can include more or be used for
 * other set fields that can be grouped in one drawer.
 * */
const enhance = compose(
  withValidationData,
  withFormik({
    mapPropsToValues: (props) => {
      let values = { base: "" };
      props.drawer.items.map((item) =>
        set(
          values,
          item.drawerKey,
          get(props.queryParams, item.drawerKey) || ""
        )
      );
      return values;
    },
    validate: (values, props) => validateCreationForm(values, props),
    validateOnChange: false,
    handleSubmit: (values, formikBag) => {
      const drawer = formikBag.props.drawer;

      if (drawer.autocapitaliseInputs) {
        drawer.items.map((item) =>
          set(
            values,
            item.drawerKey,
            formatAdventurerName(get(values, item.drawerKey))
          )
        );
      }

      formikBag.props.onFormikSubmit(values);
    },
  }),
  withHandlers({
    handleOnChangeItem: (props) => (event) => {
      props.setFieldValue(event.target.name, event.target.value);
      props.setErrors({});
    },
  })
);

export default enhance(Inputs);
