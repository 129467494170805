import React from "react";
import PropTypes from "prop-types";
import InlineFormField from "wonderbly-components/lib/InlineFormField";
import Button from "wonderbly-components/lib/Button";
import Icon from "wonderbly-components/lib/Icon";
import RichText from "../../../Prismic/RichText";
import { prismicImage } from "src/common/utils/prismic-image";
import cx from "classnames";
import Tooltip from "wonderbly-components/lib/Tooltip";

import "./BearCard.scss";

const BearCard = (props) => (
  <div className="bear-card">
    <div
      className={cx("button button--unstyled", "bear-card-inner", {
        "bear-card-inner--disabled": props.disabled,
        "bear-card-inner--littlest": props.bear.type === "littlest",
        "bear-card-inner--active": props.isActive,
      })}
      onClick={!props.isActive ? props.onAdd : undefined}
    >
      <img src={prismicImage(props.bear.image)} alt={props.bear.image.alt} />
      {props.bear.type === "littlest" && (
        <RichText fields={props.description} />
      )}
      {props.isActive && [
        <Button
          unstyled
          onClick={props.onRemove}
          type="button"
          key="bear-card-remove"
          className="bear-card-remove"
        >
          <Icon icon="delete" />
        </Button>,
        <div key="bear-card-update" className="bear-card-input-wrapper">
          <Tooltip
            className="error-tooltip"
            visible={!!props.error}
            absolute
            type="alert"
          >
            {props.error}
          </Tooltip>
          <InlineFormField
            label={props.label}
            id={props.bear.type}
            fieldType="input"
            onChange={props.onChange}
            value={props.bear.name}
          />
        </div>,
      ]}
      {!props.isActive && props.bear.type !== "littlest" && (
        <div className="bear-card-add">+</div>
      )}
    </div>
  </div>
);

BearCard.propTypes = {
  isActive: PropTypes.bool,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  description: PropTypes.array,
  bear: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      dimensions: {
        width: PropTypes.number.isRequired,
      },
    }),
  }),
  error: PropTypes.string,
  onBearOut: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default BearCard;
