const omitBy = require("lodash/omitBy");
const isNil = require("lodash/isNil");

/** @deprecated use /common/utils/user-session.js instead */
const getSessionHeaders = (
  cookies,
  { locale, currency, guestToken, iso: countryIso } = {}
) => {
  const headers = {
    LMN_LOCALE: locale || cookies.locale,
    LMN_CURRENCY: currency || cookies.currency,
    LMN_GUEST_TOKEN: guestToken || cookies.guestToken,
    LMN_RESTRICTED_USER: cookies.userToken || cookies.restricted_user_id, // uses as backup in case raw cookies are passed in on the server
    LMN_COUNTRY_ISO: countryIso || cookies.countryIso || cookies.country_code,
  };

  return omitBy(headers, isNil);
};

/** @deprecated use /common/utils/user-session.js instead */
module.exports = {
  getSessionHeaders,
};
