import React from "react";
import PropTypes from "prop-types";
import Icon from "wonderbly-components/lib/Icon";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import "./AccountAction.scss";

const AccountAction = (props) => (
  <div>
    <a
      className="account-action"
      href={localiseUrlII(props.country, "/account")}
    >
      <Icon icon="user" title={props.linkText} />
    </a>
  </div>
);

AccountAction.propTypes = {
  linkText: PropTypes.string.isRequired,
  country: PropTypes.object.isRequired,
};

export default AccountAction;
