import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import { connect } from "react-redux";
import { getItems, getTotalPrice } from "src/client/js/store/selectors/cart";
import { withModel } from "../../../../../data-providers/model";
import Contents from "./Contents";
import { replaceTextWithValues } from "src/common/utils/text-replace";

const enhance = compose(
  connect((state) => ({
    items: getItems(state),
    total: getTotalPrice(state),
  })),
  withModel,
  mapProps(({ model, ...props }) => ({
    ...props,
    totalLabel: replaceTextWithValues(
      model.prismicSiteNavigationModel.cart.totalLabel,
      { quantity: props.items.length }
    ),
    checkoutCta: model.prismicSiteNavigationModel.cart.checkoutCta,
  }))
);

export default enhance(Contents);
