import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "qs";

import { useModel, useCart } from "../../hooks";
import { updateOrderItem } from "../../../facades/eagle/user";
import { localiseUrl } from "src/common/utils/format-localised-url";

import FlashMessages from "../FlashMessages";
import { addFlashMessageError } from "../FlashMessages/duck";

import Loading from "wonderbly-components/lib/Loading";

const CreateProduct = () => {
  const { prismicProduct, countryModel } = useModel();
  const { addItemToCart, editItemInCart } = useCart();
  const { search } = useLocation();
  const { productId } = prismicProduct.main;
  const customisations = qs.parse(search, { ignoreQueryPrefix: true });
  const dispatch = useDispatch();

  useEffect(async () => {
    const { cart_item_id: id, order_id: orderId } = customisations;
    delete customisations.cart_item_id;
    delete customisations.order_id;

    const payload = { item: { id, customisations } };

    if (id && !orderId) {
      // Editing a product in cart - update cart item and return to cart
      editItemInCart(
        {
          id,
          customisations,
        },
        true
      );
    } else if (id && orderId) {
      // Editing a post-purchase item - update order item and return to order
      updateOrderItem(payload)
        .then((res) => {
          window.location = res.headers.admin_redirect_url
            ? res.headers.admin_redirect_url
            : localiseUrl(countryModel, `/account/orders/${orderId}`);
        })
        .catch((err) => {
          const errors = err.details.errors;
          errors.forEach((error) => {
            dispatch(
              addFlashMessageError(`Error ${error.status}: ${error.code}`)
            );
          });
        });
    } else {
      // Add item directly to cart
      addItemToCart({
        productId,
        customisations,
      });
    }
  }, []);

  return (
    <div>
      <FlashMessages />
      <Loading dark />
    </div>
  );
};

export default CreateProduct;
