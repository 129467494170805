import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import QuickAdd from "./QuickAdd";
import "./Upsell.scss";

const Upsell = (props) => (
  <section className="upsell">
    <div className="container-guttered">
      <div className="upsell-description">{props.description}</div>
      {props.ctaLink && props.cta && (
        <Button href={props.ctaLink} variant="secondary">
          {props.cta}
        </Button>
      )}
      <QuickAdd />
    </div>
  </section>
);

Upsell.propTypes = {
  description: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
};

export default Upsell;
