import get from "lodash/get";
import throttle from "lodash/throttle";
import { fetchPreviewAssets } from "src/common/facades/platform";
import endpoints from "src/common/config/endpoints";
import { transformPages, isChameleonStillRendering } from "./helpers";
import { addFlashMessageError } from "../FlashMessages/duck";

// Actions
export const GET_BOOK_PREVIEW_URLS_PENDING = "GET_BOOK_PREVIEW_URLS_PENDING";
export const GET_BOOK_PREVIEW_URLS_SUCCESS = "GET_BOOK_PREVIEW_URLS_SUCCESS";
export const GET_BOOK_PREVIEW_URLS_FAILURE = "GET_BOOK_PREVIEW_URLS_FAILURE";
export const UPDATE_BOOK_PREVIEW_PAGES = "UPDATE_BOOK_PREVIEW_PAGES";
export const SET_CHARACTER_PICKER_NAV_ITEMS = "SET_CHARACTER_PICKER_NAV_ITEMS";
export const SET_BOOK_PREVIEW_IS_HEIDELBERG = "SET_BOOK_PREVIEW_IS_HEIDELBERG";
export const SET_BOOK_PREVIEW_IMAGE_WIDTH = "SET_BOOK_PREVIEW_IMAGE_WIDTH";
export const SET_BOOK_PREVIEW_ACTIVE_PAGE = "SET_BOOK_PREVIEW_ACTIVE_PAGE";
export const SET_BOOK_PREVIEW_SCROLL = "SET_BOOK_PREVIEW_SCROLL";

// Action creators
export const getBookPreviewUrlsPending = (options) => ({
  type: GET_BOOK_PREVIEW_URLS_PENDING,
  options,
});

export const getBookPreviewUrlsSuccess = (response, pages) => ({
  type: GET_BOOK_PREVIEW_URLS_SUCCESS,
  response,
  pages,
});

export const getBookPreviewUrlsFailure = (response) => ({
  type: GET_BOOK_PREVIEW_URLS_FAILURE,
  response,
});

export const updateBookPreviewPages = (pages) => ({
  type: UPDATE_BOOK_PREVIEW_PAGES,
  pages,
});

export const setCharacterPickerNavItems = (navItems) => ({
  type: SET_CHARACTER_PICKER_NAV_ITEMS,
  navItems,
});

export const setIsBookPreviewHeidelberg = (isHeidelberg) => ({
  type: SET_BOOK_PREVIEW_IS_HEIDELBERG,
  isHeidelberg,
});

export const setBookPreviewActivePage = (activePage) => ({
  type: SET_BOOK_PREVIEW_ACTIVE_PAGE,
  activePage,
});

export const setBookPreviewImageWidth = (imageWidth) => ({
  type: SET_BOOK_PREVIEW_IMAGE_WIDTH,
  imageWidth,
});

export const setBookPreviewScroll = (scrollAmount) => ({
  type: SET_BOOK_PREVIEW_SCROLL,
  scrollAmount,
});

// Selectors
export const getPages = (state) => state.components.bookPreview.pages;
export const isLoading = (state) => !state.components.bookPreview.response.ok;
export const getNavItems = (state) => state.components.bookPreview.navItems;
export const isHeidelberg = (state) =>
  state.components.bookPreview.isHeidelberg;
export const getImageWidth = (state) => state.components.bookPreview.imageWidth;
export const getActivePage = (state) => state.components.bookPreview.activePage;
export const getScrollAmount = (state) =>
  state.components.bookPreview.scrollAmount;

// Thunks
export const buildPreviewPages =
  ({ productId, productMuseId, customisation, isHeidelberg, covers }) =>
  (dispatch) => {
    dispatch(getBookPreviewUrlsPending(customisation));
    let fetchCount = 1;
    const fetchFromEndpoint = throttle(
      () =>
        fetchPreviewAssets(productMuseId || productId, customisation)
          .then((response) => {
            const body = response;

            /** character picker - if it's lmn _or_ it's got navtiems.  */
            if (body.navItems) {
              dispatch(setCharacterPickerNavItems(body.navItems)); // This is really dynamic breadcrumbs now
            }

            /** Poll old LMN until the 'name' spread has been fully rendered */
            const isChameleon = endpoints.isChameleon(productId);
            if (
              isChameleon &&
              isChameleonStillRendering(body.images) &&
              fetchCount < 10
            ) {
              dispatch(getBookPreviewUrlsPending(customisation));
              fetchFromEndpoint();
              fetchCount++;
            } else {
              fetchFromEndpoint.cancel();
            }

            /** Add or remove any covers and end pages with assets provided by Prismic */
            const transformedPages = transformPages({
              productId,
              customisation,
              isHeidelberg,
              covers,
              pages: body.images,
            });

            dispatch(
              getBookPreviewUrlsSuccess(
                { ok: true, status: 200 },
                transformedPages
              )
            );
          })
          .catch((error) => {
            dispatch(getBookPreviewUrlsFailure(error));
            const errorMessages = get(error, "details.errors", []);
            errorMessages.forEach((message) =>
              dispatch(addFlashMessageError(message))
            );
          }),
      2500
    );

    return fetchFromEndpoint();
  };

// Reducer
const reducer = (
  state = {
    response: {},
    pages: [],
    navItems: [],
    isHeidelberg: false,
    imageWidth: 0,
    activePage: 1,
    scrollAmount: 0,
  },
  action
) => {
  switch (action.type) {
    case SET_BOOK_PREVIEW_IS_HEIDELBERG: {
      return {
        ...state,
        isHeidelberg: action.isHeidelberg,
      };
    }
    case SET_BOOK_PREVIEW_IMAGE_WIDTH: {
      return {
        ...state,
        imageWidth: action.imageWidth,
      };
    }
    case SET_BOOK_PREVIEW_ACTIVE_PAGE: {
      return {
        ...state,
        activePage: action.activePage,
      };
    }
    case SET_BOOK_PREVIEW_SCROLL: {
      return {
        ...state,
        scrollAmount: action.scrollAmount,
      };
    }
    case GET_BOOK_PREVIEW_URLS_PENDING: {
      return {
        ...state,
        response: {
          ok: false,
          options: action.options,
        },
      };
    }
    case GET_BOOK_PREVIEW_URLS_SUCCESS: {
      return {
        ...state,
        response: {
          ok: true,
          status: 200,
        },
        pages: action.pages,
      };
    }
    case GET_BOOK_PREVIEW_URLS_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }
    case UPDATE_BOOK_PREVIEW_PAGES: {
      return {
        ...state,
        pages: action.pages,
      };
    }
    case SET_CHARACTER_PICKER_NAV_ITEMS: {
      return {
        ...state,
        navItems: action.navItems,
      };
    }
    default:
      return state;
  }
};

export default reducer;
