import React from "react";
import {
  fetchUserOrders,
  fetchOrder as fetchUserOrder,
} from "../../facades/eagle/user";

export const { Provider, Consumer } = React.createContext({
  userOrders: {},
  setUserOrders: () => {},
});

export const buildUserOrders = (response) => ({
  response: { ok: true },
  orders: response.body.data,
});

export const updateOrderDetails = (response, { orders }) => {
  const data = response.body.data;
  const orderExists = !!orders.find((order) => data.id === order.id);
  return {
    response: { ok: true },
    orders: orderExists
      ? orders.map((order) => (data.id === order.id ? data : order))
      : orders.concat(data),
  };
};

const fetchOrders = (callback) => async () => {
  const response = await fetchUserOrders();
  const orders = buildUserOrders(response);
  callback(orders);

  return orders;
};

const fetchOrder =
  (callback) =>
  async (orderNumber, currentUserOrders = { orders: [] }) => {
    const response = await fetchUserOrder(orderNumber);
    const orders = updateOrderDetails(response, currentUserOrders);
    callback(orders);
    return response.body.data;
  };

export const withUserOrders = (ComponentToWrap) => (props) =>
  (
    <Consumer>
      {({ userOrders, setUserOrders }) => {
        return (
          <ComponentToWrap
            {...props}
            userOrders={userOrders}
            setUserOrders={setUserOrders}
            fetchUserOrders={fetchOrders(setUserOrders)}
            fetchUserOrder={fetchOrder(setUserOrders)}
          />
        );
      }}
    </Consumer>
  );
