import { track } from "../facade";

let _context = "Misc";
const dispatch = (action, label = null, value = null) =>
  track(action, {
    category: _context,
    label,
    value,
  });

export const init = (context) => {
  _context = context || _context;
};

export const trackViewOrderClick = (orderStatus) =>
  dispatch("Click more order info", orderStatus);
export const trackEditOrderClick = (remainingTime) => {
  let timeLeft = `${remainingTime.hours} hours left`;
  if (!remainingTime.hours && remainingTime.minutes) {
    timeLeft = `${remainingTime.minutes} minutes left`;
  }
  return dispatch("Click edit item", timeLeft);
};
export const trackEditShippingClick = (remainingTime) => {
  let timeLeft = `${remainingTime.hours} hours left`;
  if (!remainingTime.hours && remainingTime.minutes) {
    timeLeft = `${remainingTime.minutes} minutes left`;
  }
  return dispatch("Click edit shipping", timeLeft);
};
