import React from "react";
import PropTypes from "prop-types";
import Logo from "./../Logo";
import FlashMessages from "../../FlashMessages";
import MainNavigation from "./../MainNavigation";
import "./CheckoutHeader.scss";

const noop = () => {};

class CheckoutHeader extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
    showNavigation: PropTypes.bool,
  };

  render() {
    const { showNavigation = true, ...props } = this.props;
    const siteNavigationModel = props.model.prismicSiteNavigationModel;
    const countryModel = props.model.countryModel;
    return (
      <div className="header-wrap header-wrap--checkout">
        <header className="header-checkout">
          <div className="container-guttered-on-desktop-up">
            <div className="header-navigation-inner-checkout">
              <div className="header-navigation-logo-checkout">
                <Logo country={countryModel} onClickLink={noop} isCheckout />
              </div>

              {showNavigation && (
                <MainNavigation
                  country={countryModel}
                  navigation={siteNavigationModel.checkoutNavigation}
                  onTrackLink={noop}
                  isCheckout
                />
              )}
            </div>
          </div>
          <FlashMessages />
        </header>
      </div>
    );
  }
}

export default CheckoutHeader;
