import cx from "classnames";
import get from "lodash/get";
import { replaceTextWithValues } from "src/common/utils/text-replace";
import { date } from "src/common/utils/date-time-formatter";
import shippingTypeToPrismicKey from "src/common/utils/shipping-type-to-prismic-key";

const getPromoMessage = (banner, props) => {
  let text = banner.text || "";
  if (text.includes("%{code}")) {
    const className = cx("highlight", {
      [`color-${banner.promoCodeTextColour}`]: banner.promoCodeTextColour,
    });
    const style = `background-color: ${banner.promoCodeBackground}`;
    text = replaceTextWithValues(text, {
      code: `<span class="${className}" style="${style}">${banner.promoCode}</span>`,
    });
  }

  if (text.includes("%{lod_")) {
    let shippingDates = props.model.eagleModel.shippingEstimates;
    // eagleModel.shippingEstimates are the estimated dates, ignoring the card content.
    // props.shippingDates - are the estimated dates based on the cart content.
    if (get(props, "shippingDates.length", 0) > 0) {
      shippingDates = props.shippingDates;
    }

    const shippingValues = shippingDates.reduce((values, shipping) => {
      const key = shippingTypeToPrismicKey(shipping.type);

      values[`lod_${key}`] = date(
        shipping.xmas_cutoff,
        props.model.countryModel,
        {
          weekday: "long",
          month: "short",
          day: "numeric",
        }
      );

      return values;
    }, {});

    text = replaceTextWithValues(text, shippingValues);
  }

  return text;
};

export const getPromoBanners = (defaultBanner, generalBanners = []) =>
  generalBanners.length > 0 ? generalBanners : [defaultBanner];

export const remapBanners = (banners, props) => {
  banners = banners.filter((banner) => banner !== undefined);
  return banners.map((banner, idx) => ({
    ...banner,
    idx: idx,
    text: getPromoMessage(banner, props),
  }));
};
