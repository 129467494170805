const ErrorBase = require("../errors/ErrorBase");

class RestResponseError extends ErrorBase {
  constructor(status, reason = "", details = "", url = "") {
    super(`RestResponseError: status (${status})`);
    this.ok = false;
    this.status = status;
    this.url = url;
    this.reason = reason;
    this.details = details;
    this.metaData = {
      status: status,
      url: url,
      reason: reason,
      details: details,
    };
  }
}

module.exports = RestResponseError;
