import React from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Button from "wonderbly-components/lib/Button";
import Icon from "wonderbly-components/lib/Icon";
import "./Footer.scss";

const Footer = ({
  submitLabel,
  displayThumbnails,
  thumbnails,
  submitDisabled,
  addCharacter,
  handleSubmit,
}) => {
  function onThumbnailClick(item) {
    return () => addCharacter(item);
  }
  return (
    <footer className="Characters__Footer">
      {displayThumbnails && !isEmpty(thumbnails) && (
        <div className="Characters__Footer__Thumbnails">
          {thumbnails.map((item, idx) => (
            <div
              key={idx}
              className="Characters__Footer__Thumbnails__Item"
              onClick={onThumbnailClick(item)}
              style={{ backgroundImage: `url(${item.image.url}` }}
            >
              <div className="icon">
                <Icon icon="plus" />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="Characters__Footer__Submit">
        <Button type="submit" onClick={handleSubmit} disabled={submitDisabled}>
          {submitLabel}
        </Button>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  submitLabel: PropTypes.string.isRequired,
  displayThumbnails: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  addCharacter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default Footer;
