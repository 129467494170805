import React, { useState } from "react";
import PropTypes from "prop-types";

import shippingTypeToPrismicKey from "src/common/utils/shipping-type-to-prismic-key";

import ComponentReplacer from "../ComponentReplacer";
import CountdownTimerHuman from "../CountdownTimerHuman";

import { useModel, useGlobalContent } from "../../hooks";
import logger from "src/logger";

const getCountdownsFromEndDates = (endDates, props) =>
  endDates.reduce((acc, { id, endDate }) => {
    if (!id || !endDate) {
      return acc;
    }
    return {
      ...acc,
      [`countdown_${id}`]: { date: endDate, ...props },
    };
  }, {});

const getCountdownsFromShippingEstimates = (estimates, props) => {
  if (typeof estimates === "undefined") {
    logger.warn(
      "No shipping estimates were provided by Eagle. Countdowns which use LODs will not render."
    );
    return {};
  }

  return estimates.reduce((values, shipping) => {
    const key = shippingTypeToPrismicKey(shipping.type);

    values[`countdown_lod_${key}`] = { date: shipping.xmas_cutoff, ...props };

    return values;
  }, {});
};

/**
 * CountdownReplacer
 *
 * Swap out all `%{countdown_*}` string formatters for `<CountdownTimerHuman />` components
 *
 * @param {string} children A string that contains `%{countdown_*}` keys to be replaced with
 *  `<CountdownTimerHuman />` components. i.e. `Last orders in %{countdown_lod_standard}`. If
 *  this string contains markup, it will be sanitized and rendered.
 *  - `countdown_` is the namespace for all countdowns
 *  - `_*` the suffix. i.e. `_lod_standard`. This tells us which date to use. This will:
 *    1. First be matched against Eagle shipping estimates, i.e. `lod_standard`
 *    2. Then be matched against the ids for prismic's `countdowns` in global content
 * @param {string} replacerId unique identifier for SSR, passed to ComponentReplacer
 * @param {...} props all remaining props are passed to each <CountdownTimerHuman />
 *
 * @returns {node} Rendered React node containing `<CountdownTimerHuman />` components
 */
const CountdownReplacer = ({ children, replacerId, ...props }) => {
  const { eagleModel } = useModel();
  const { endDates = [], timeStrings } = useGlobalContent("countdowns");
  const { coordinatingConjunctionAnd } = useGlobalContent("general");

  const countdownProps = {
    ...props,
    timeStrings,
    conjunction: coordinatingConjunctionAnd || "and",
  };

  const [countdowns] = useState(() => {
    const globalCountdowns = getCountdownsFromEndDates(
      endDates,
      countdownProps
    );
    const eagleCountdowns = getCountdownsFromShippingEstimates(
      eagleModel.shippingEstimates,
      countdownProps
    );

    return {
      ...globalCountdowns,
      ...eagleCountdowns,
    };
  });

  const [componentMapping] = useState(() =>
    Object.keys(countdowns).reduce(
      (acc, key) => ({ ...acc, [key]: CountdownTimerHuman }),
      {}
    )
  );

  return (
    <ComponentReplacer
      data={countdowns}
      componentMapping={componentMapping}
      id={replacerId}
    >
      {children}
    </ComponentReplacer>
  );
};

CountdownReplacer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  replacerId: PropTypes.string.isRequired,
};

export default CountdownReplacer;
