import compose from "recompose/compose";

import DynamicLayout from "./DynamicLayout";

import { withModel } from "../../data-providers/model";
import { withRouter } from "react-router-dom";
import withSetupTracking from "../../hocs/withSetupTracking";
import withProps from "recompose/withProps";

const enhance = compose(
  withModel,
  withRouter,
  withSetupTracking,
  withProps((props) => ({
    isCheckout: props.model.route.isCheckout,
  }))
);

export default enhance(DynamicLayout);
