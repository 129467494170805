import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";

import { replaceTextWithValues } from "src/common/utils/text-replace";
import { renderProduct } from "src/common/facades/platform";

export const findCharacterFromIdx = (characters, items, idx) => {
  if (characters.length === 0) {
    return {};
  }

  const character = characters[idx] || {};
  const item = items.find(({ type }) => type === character.type) || {};

  return {
    ...item,
    ...character,
  };
};

export const addCharacter = (characters, { type, name, gender }) => {
  // Depending on the book the customisation can be either an array of names or an
  // array of object with value and type as properties
  characters.push(!type ? name : omitBy({ name, type, gender }, isUndefined));
  return characters;
};

export const resetDefaultValues = (characters, items) =>
  characters.map((character) => {
    const item = items.find((item) => item.type === character.type) || {};
    const isDefaultValue = character.name === item.defaultValue;

    if (isDefaultValue) {
      return {
        ...character,
        name: "",
      };
    }

    return character;
  });

export const setCharacterName = (characters, idx, name) => {
  return characters.map((c, cIdx) => {
    if (cIdx === idx) {
      c.name = name;
    }
    return c;
  });
};

export const removeCharacter = (characters, idx) => {
  return characters.filter((c, cIdx) => cIdx !== idx);
};

export const getCharacterErrors = (error) => {
  const customisations = error.body;
  const characterErrors =
    customisations.find(({ sliceType }) => sliceType === "characters") || {};
  return characterErrors.primary || {};
};

export const submitForm =
  (productId, drawer, customisations, getErrorMessage, onFormikSubmit) =>
  async (values, formik) => {
    const { customisationKey, minRequired, maxRequired } = drawer;

    const characters = values[customisationKey];

    if (characters.length < minRequired) {
      const message = getErrorMessage((error) => {
        const characterErrors = getCharacterErrors(error);
        return replaceTextWithValues(characterErrors.minRequired, {
          minRequired,
        });
      });

      formik.setFieldError("base", message);
      return;
    }

    if (characters.length > maxRequired) {
      const message = getErrorMessage((error) => {
        const characterErrors = getCharacterErrors(error);
        return replaceTextWithValues(characterErrors.maxRequired, {
          maxRequired,
        });
      });

      formik.setFieldError("base", message);
      return;
    }

    try {
      // Using the TOC endpoint to validate the values, expecting to
      // get a new validation endpoint from the muse team at some point
      await renderProduct(productId, {
        ...customisations,
        ...values,
      });
    } catch (err) {
      const errors = get(err, "details.errors", []);
      const formErrors = errors.filter((e) =>
        e.code.includes(customisationKey)
      );

      // We only want to display the errors if the errors involve the form.
      if (!isEmpty(formErrors)) {
        formik.setErrors(
          formErrors.reduce((acc, error) => {
            // TODO: Make all customisation forms wrap their
            //  values around an object called customisation
            //  and avoid re-writing the key
            const path = error.context.key.replace("customisation.", "");
            const errorCode = error.code.split(".").pop();

            set(
              acc,
              path,
              getErrorMessage(
                (error) => error.general[errorCode] || error.general.invalid
              )
            );
            return acc;
          }, {})
        );
        return;
      }
    }

    onFormikSubmit(values);
  };
