import { useEffect } from "react";

const locked = {};

export default (fn, key, groupKey) => {
  // Only gets called when key hasn't already been called
  // Use effect doesn't get called on the server, so locks
  // only work on the client. This protects things like user
  // orders from being shared between users.
  useEffect(() => {
    const group = locked[groupKey] || {};

    if (!group[key]) {
      group[key] = true;
      locked[groupKey] = group;
      fn();
    }
  }, [key]);
};
