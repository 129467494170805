import get from "lodash/get";
import { getStates, getEstimatedDelivery } from "../../../facades/eagle/cart";

export const getRegionStates = (state) =>
  get(state, "components.cartShipping.regionStates", []);
export const getCurrentRegionStates = (state, countryIso) => {
  const regionStates = getRegionStates(state);
  return regionStates[countryIso] || [];
};
export const getShippingDates = (state) =>
  get(state, "components.cartShipping.dates", []);
export const isEstimatedDateLoading = (state) =>
  get(state, "components.cartShipping.response.pending", false);
export const isAvataxEnabledForCountry = (state, countryIso) =>
  get(state, "components.cartShipping.isAvataxEnabled", false);

export const GET_ESTIMATED_DELIVERY_PENDING = "GET_ESTIMATED_DELIVERY_PENDING";
export const GET_ESTIMATED_DELIVERY_SUCCESS = "GET_ESTIMATED_DELIVERY_SUCCESS";
export const GET_ESTIMATED_DELIVERY_FAILURE = "GET_ESTIMATED_DELIVERY_FAILURE";

export const fetchEstimatedDeliveryPending = (country, state) => ({
  type: GET_ESTIMATED_DELIVERY_PENDING,
  country,
  state,
});

export const fetchEstimatedDeliverySuccess = (country, response) => ({
  type: GET_ESTIMATED_DELIVERY_SUCCESS,
  country,
  response,
});

export const fetchEstimatedDeliveryFailure = (response) => ({
  type: GET_ESTIMATED_DELIVERY_FAILURE,
  response,
});

export const fetchEstimatedDeliveryDate = (params) => (dispatch) => {
  dispatch(
    fetchEstimatedDeliveryPending(params.country_iso, params.state_abbr)
  );

  return getEstimatedDelivery(params)
    .then((response) => {
      dispatch(fetchEstimatedDeliverySuccess(params.country_iso, response));
    })
    .catch((error) => dispatch(fetchEstimatedDeliveryFailure(error)));
};

export const GET_SHIPPING_STATES_PENDING = "GET_SHIPPING_STATES_PENDING";
export const GET_SHIPPING_STATES_SUCCESS = "GET_SHIPPING_STATES_SUCCESS";
export const GET_SHIPPING_STATES_FAILURE = "GET_SHIPPING_STATES_FAILURE";

export const fetchShippingStatesPending = (country) => ({
  type: GET_SHIPPING_STATES_PENDING,
  country,
});

export const fetchShippingStatesSuccess = (country, response) => ({
  type: GET_SHIPPING_STATES_SUCCESS,
  country,
  response,
});

export const fetchShippingStatesFailure = (response) => ({
  type: GET_SHIPPING_STATES_FAILURE,
  response,
});

export const fetchShippingStates = (country) => (dispatch) => {
  dispatch(fetchShippingStatesPending(country));

  return getStates(country)
    .then((response) => dispatch(fetchShippingStatesSuccess(country, response)))
    .catch((error) => dispatch(fetchShippingStatesFailure(error)));
};

export default (
  state = { regionStates: {}, dates: [], estimatedCountry: "" },
  action
) => {
  switch (action.type) {
    case GET_SHIPPING_STATES_PENDING: {
      return {
        ...state,
        regionStates: {
          ...state.regionStates,
          [action.country]: [],
        },
        response: {
          pending: true,
        },
      };
    }
    case GET_ESTIMATED_DELIVERY_PENDING: {
      return {
        ...state,
        response: {
          pending: true,
        },
      };
    }
    case GET_SHIPPING_STATES_SUCCESS: {
      return {
        ...state,
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        regionStates: {
          ...state.regionStates,
          [action.country]: action.response.body.data,
        },
        estimatedCountry: action.country,
        isAvataxEnabled: !!action.response.body.options?.is_avatax_enabled,
      };
    }
    case GET_ESTIMATED_DELIVERY_SUCCESS: {
      return {
        ...state,
        response: {
          ok: action.response.ok,
          status: action.response.status,
        },
        dates: action.response.body.data,
      };
    }
    case GET_SHIPPING_STATES_FAILURE:
    case GET_ESTIMATED_DELIVERY_FAILURE: {
      return {
        ...state,
        response: action.response,
      };
    }
    default: {
      return state;
    }
  }
};
