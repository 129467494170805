import CountryPrompt from "./CountryPrompt";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import { withModel } from "src/client/js/view/data-providers/model";

import { connect } from "react-redux";
import { getCookieConsent } from "../../../store/selectors/cookies";
import { getGeoLocation } from "../../../store/selectors/geoLocation";

const enhance = compose(
  withModel,
  connect((state) => ({
    allowCookies: getCookieConsent(state),
    geoLocation: getGeoLocation(state),
  })),
  mapProps((props) => ({
    disabled: props.disabled,
    allowCookies: props.allowCookies,
    geoLocation: props.geoLocation,
    updateGeoLocation: props.updateGeoLocation,
    countries: props.model.prismicCountryListModel.countries,
    countryModel: props.model.countryModel,
  }))
);

export default enhance(CountryPrompt);
