import noop from "lodash/noop";
import get from "lodash/get";
import reduce from "lodash/reduce";

import { useDispatch } from "react-redux";

import {
  addFlashMessageError,
  addFlashMessageNotice,
} from "../components/FlashMessages/duck";
import { useGlobalContent } from "./index";

import logger from "src/logger";

const getErrorType = (type, value) => {
  const matches = (string) => {
    return type === string;
  };

  switch (true) {
    case type.includes(".max"):
      return "tooLong";
    case type.includes(".min"):
      return "tooShort";
    case type.includes(".pattern"):
      return "weirdCharacters";
    case type.includes("invalid"):
      return "invalid";
    case matches("any.only"):
    case matches("any.required"):
    case matches("string.empty"):
    case matches("number.base") && !value:
      return "required";
    case matches("any.custom"):
      return "custom";
    default:
      logger.warn(`Unknown or unhandled JOI error type: "${type}"`);
      return "default";
  }
};

export default () => {
  const errors = reduce(
    useGlobalContent("error"),
    (acc, value, key) => {
      if (key === "body") {
        value.forEach(({ sliceType, primary }) => {
          acc[sliceType] = primary;
        });
        return acc;
      }
      acc[key] = value;
      return acc;
    },
    {}
  );

  const dispatch = useDispatch();

  const getErrorMessage = (codeOrFn = noop, defaultCode = null) => {
    let message;

    if (typeof codeOrFn === "string") {
      message = get(errors, codeOrFn);
    } else {
      message = codeOrFn(errors);
    }

    return (
      message || get(errors, defaultCode) || get(errors, "general.default")
    );
  };

  const joiErrorMessages = (errors, section) => {
    return errors.map(({ name, error }) => {
      const fieldName = error.path.slice(-1)[0];
      const type = getErrorType(error.type, error.value);
      const message = getErrorMessage((errors) => {
        const sectionPath = `${section}.${type}`;
        const fieldPath = `${fieldName}.${type}`;
        return get(errors, sectionPath) || get(errors, fieldPath);
      }, `general.${type}`);
      return {
        name,
        type,
        message,
      };
    });
  };

  const dispatchMessage = (dispatcher) => (codeOrFn, defaultValue) => {
    const message = getErrorMessage(codeOrFn, defaultValue);
    return dispatch(dispatcher(message));
  };

  return {
    errors,
    getErrorMessage,
    joiErrorMessages,
    sendErrorMessage: dispatchMessage(addFlashMessageError),
    sendWarningMessage: dispatchMessage(addFlashMessageNotice),
  };
};
