import CardSelect from "./CardSelect";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";
import { withFormik } from "formik";

const enhance = compose(
  withProps(({ drawer: { customisationKey = "values" } }) => ({
    customisationKey,
  })),

  withFormik({
    mapPropsToValues: ({ customisationKey, queryParams }) => ({
      base: "",
      [customisationKey]: queryParams[customisationKey] || [],
    }),
    handleSubmit: (values, { props }) => {
      props.onFormikSubmit(values);
    },
  }),
  withState(
    "remaining",
    "setRemaining",
    (props) =>
      props.drawer.numberOfSelections -
      props.values[props.customisationKey].length
  ),

  withHandlers({
    onHandleChange: (props) => (e) => {
      const values = props.values[props.customisationKey];
      const valueIndex = values.indexOf(e.target.value);

      valueIndex === -1
        ? values.push(e.target.value)
        : values.splice(valueIndex, 1);

      props.setRemaining(props.drawer.numberOfSelections - values.length);
      props.setFieldValue(props.customisationKey, values);
    },
  })
);

export default enhance(CardSelect);
