import {
  OPEN_OVERLAY,
  CLOSE_OVERLAY,
  ENABLE_LOADING,
  DISABLE_LOADING,
} from "../../actions/app/index";

export default (
  state = { loading: false, currentScrollPos: 0, overlayActive: false },
  action
) => {
  switch (action.type) {
    case OPEN_OVERLAY: {
      return {
        ...state,
        overlayActive: true,
        currentScrollPos: action.currentScrollPos,
      };
    }
    case CLOSE_OVERLAY: {
      return {
        ...state,
        overlayActive: false,
        currentScrollPos: 0,
      };
    }
    case ENABLE_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case DISABLE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};
