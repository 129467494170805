import CustomSelect from "./CustomSelect";

import compose from "recompose/compose";
import withHandlers from "recompose/withHandlers";
import withProps from "recompose/withProps";
import withState from "recompose/withState";

import {
  OTHER_VALUE,
  onChangeSelect,
  onChangeInput,
} from "../../CustomSelect/helpers";

const enhance = compose(
  withProps(({ field }) => ({
    otherValue: OTHER_VALUE,
    title: field.primary.title,
    placeholder: field.primary.customInputSuggestion,
  })),
  withState("selectionValue", "setSelectionValue", ""),
  withState("selectionState", "setSelectionState", ""),
  withState("tempValue", "setTempValue", ""),
  withHandlers({
    onChange: (props) => onChangeSelect(props, props.field.items),
    onChangeInput: (props) => {
      return (event) => {
        onChangeInput(props, event);
      };
    },
  })
);

export default enhance(CustomSelect);
