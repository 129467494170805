import useCountry from "./use-country";
import { useFetchEagle } from "./use-fetch";
import { fetchRecentOrders } from "../../facades/eagle/user";
import { getAndAttachProducts } from "./helpers";

export default () => {
  const { locale } = useCountry();

  return useFetchEagle(fetchRecentOrders, {
    map: async (response) =>
      await getAndAttachProducts(response.body.data, locale),
  });
};
