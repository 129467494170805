class LanguageModel {
  constructor() {
    this.id = null;
    this.uid = null;
    this.type = null;
    this.lang = null;
  }
  deserialise(data) {
    this.id = data.id;
    this.uid = data.uid;
    this.type = data.type;
    this.lang = data.lang;
    return this;
  }
}

module.exports = LanguageModel;
