export const UPDATE_PRODUCT_CUSTOMISATION = "UPDATE_PRODUCT_CUSTOMISATION";
export const REMOVE_PRODUCT_CUSTOMISATION = "REMOVE_PRODUCT_CUSTOMISATION";

export const updateProductCustomisation = (productId, customisation) => ({
  type: UPDATE_PRODUCT_CUSTOMISATION,
  productId,
  customisation,
});

export const removeProductCustomisation = (customisationKeys) => ({
  type: REMOVE_PRODUCT_CUSTOMISATION,
  customisationKeys,
});
