import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getHumanCountdown } from "./helpers";

const CountdownTimerHuman = ({ date, timeStrings, conjunction, ...props }) => {
  // You can't use hooks here since it gets used in a different context

  const [countdown, setCountdown] = useState(() => {
    const { humanCountdown } = getHumanCountdown(date, timeStrings, {
      conjunction,
    });

    return humanCountdown;
  });

  const tick = useCallback(() => {
    const { humanCountdown, remainderInSeconds } = getHumanCountdown(
      date,
      timeStrings,
      { conjunction }
    );

    setCountdown(humanCountdown);
    setTimeout(tick, (remainderInSeconds + 1) * 1000);
  });

  useEffect(tick, []);

  return (
    <span className="countdown-timer-human" {...props}>
      {countdown}
    </span>
  );
};

CountdownTimerHuman.propTypes = {
  conjunction: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  timeStrings: PropTypes.object.isRequired,
};

export default CountdownTimerHuman;
