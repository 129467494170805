import MultiBirthday from "./MultiBirthday";
import { withFormik } from "formik";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import withValidationData from "../../../hocs/withValidationData";
import { connect } from "react-redux";
import {
  getActiveCanvasDrawer,
  updateActiveCanvasDrawer,
} from "../../CanvasDrawers/duck";
import { validateBirthdays, getDrawerIdByName } from "./helpers";

const enhance = compose(
  withValidationData,
  connect(
    (state) => ({
      activeDrawers: getActiveCanvasDrawer(state),
    }),
    (dispatch) => ({
      handleToggleDrawer: (key) => dispatch(updateActiveCanvasDrawer(key)),
    })
  ),
  withProps((props) => ({
    characters: props.productCustomisation.characters || [],
  })),
  withFormik({
    mapPropsToValues: (props) => {
      const values = { base: "" };

      values.birthdays = props.characters.map((character) => ({
        day: character.day || "",
        month: character.month || "",
        year: character.year || "",
      }));

      return values;
    },
    validate: async (values, props) => {
      const characters = props.characters.map((character, index) => {
        const birthday = values.birthdays[index];
        return omitBy(
          {
            ...character,
            ...birthday,
          },
          (value) => isNil(value) || value === "n/a" || value === ""
        );
      });
      return await validateBirthdays(characters, props);
    },
    validateOnChange: false,
    handleSubmit: (values, formikBag) => {
      const props = formikBag.props;
      const characters = props.characters.map((character, index) => {
        const birthday = values.birthdays[index];
        return omitBy(
          {
            ...character,
            ...birthday,
          },
          (value) => isNil(value) || value === "n/a" || value === ""
        );
      });

      props.onFormikSubmit({ characters });
    },
  }),
  withProps((props) => ({
    isDisabled: props.isSubmitting || props.characters.length === 0,
  })),
  withHandlers({
    handleChange: (props) => (index) => (event) => {
      const { name, value } = event.target;
      props.setFieldValue(`birthdays[${index}][${name}]`, value, false);
    },
    handleFormSubmission: (props) => () => {
      props.handleSubmit(props.values, props);
    },
    onOpenMultiAdventurersDrawer: (props) => () => {
      const multiAdventurerDrawerId = getDrawerIdByName(
        "multi_adventurer",
        props
      );
      props.handleToggleDrawer(multiAdventurerDrawerId);
    },
  })
);

export default enhance(MultiBirthday);
