import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import "./Header.scss";

const Header = ({ label, isDisabled, handleOnClick }) => {
  return (
    <header className="Characters__Header">
      <Button type="button" onClick={handleOnClick} disabled={isDisabled}>
        {label}
      </Button>
    </header>
  );
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default Header;
