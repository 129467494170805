import MultiAdventurerII from "./MultiAdventurerII";
import compose from "recompose/compose";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";
import { connect } from "react-redux";
import {
  getActiveCanvasDrawer,
  updateActiveCanvasDrawer,
} from "../../CanvasDrawers/duck";
import { validateCreationForm } from "../helpers";
import { remapErrors, trimNameValue } from "./helpers";
import withValidationData from "../../../hocs/withValidationData";

const enhance = compose(
  withValidationData,
  withState("isEditing", "setIsEditing", false),
  connect(
    (state) => ({
      activeDrawer: getActiveCanvasDrawer(state),
    }),
    (dispatch) => ({
      handleToggleDrawer: (key) => dispatch(updateActiveCanvasDrawer(key)),
    })
  ),
  withHandlers({
    handleFormSubmit: (props) => async (payload, methods) => {
      payload = trimNameValue(payload, props.drawer.customisationKey);
      const errors = await validateCreationForm(
        payload,
        { ...props },
        { enableSetErrors: false }
      );
      if (errors) {
        methods.setErrors(remapErrors(errors, props.drawer.customisationKey));
      } else {
        props.onFormikSubmit(payload, props.activeDrawer);
        props.setIsEditing(false);
      }
    },
    handleCloseDrawer: (props) => () => {
      props.handleToggleDrawer();
    },
  })
);

export default enhance(MultiAdventurerII);
