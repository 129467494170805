const isTouchEvent = (evt) =>
  typeof evt !== "undefined" &&
  (typeof evt.touches !== "undefined" ||
    typeof evt.changedTouches !== "undefined");

const isTouchEnabled = () =>
  typeof window !== "undefined" &&
  ("ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0);

module.exports = {
  isTouchEnabled,
  isTouchEvent,
};
