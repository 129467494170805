import React from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import { replaceTextWithValues } from "src/common/utils/text-replace";
import { useGlobalContent } from "../../../../hooks";

import LabelledRadio from "wonderbly-components/lib/LabelledRadio";
import Badge from "wonderbly-components/lib/Badge";
import Icon from "wonderbly-components/lib/Icon";
import PlaceholdImage from "../../../PlaceholdImage";

import "./InterestOption.scss";

const InterestOption = (props) => {
  const { canvas } = useGlobalContent();
  const { upsellPrice } = canvas;
  const upselledOption = props.upselledOptions.find((item) =>
    item.id.endsWith(props.id)
  );
  const isUpselled = props.isUpsellEnabled && !!upselledOption;

  return (
    <LabelledRadio
      className="interest-option"
      id={props.id}
      value={props.id}
      name={props.optionName}
      checked={props.isSelected}
      onClick={props.onClick}
    >
      <div className="panel">
        {!isEmpty(props.image) && (
          <PlaceholdImage
            image={props.image}
            className="interest-option-image"
            width={props.image.dimensions.width / 2}
            noLazyload
          />
        )}
        <div className="interest-option__content">
          <span className="interest-option-name">{props.title}</span>
        </div>
      </div>
      {(!!props.badge || (isUpselled && upsellPrice)) && (
        <Badge highlight>
          <Icon icon="star" filled />
          {replaceTextWithValues(props.badge || upsellPrice, {
            price: upselledOption?.price?.formatted,
          })}
        </Badge>
      )}
    </LabelledRadio>
  );
};

InterestOption.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  badge: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  optionName: PropTypes.string.isRequired,
  upselledOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  isUpsellEnabled: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default InterestOption;
