import { connect } from "react-redux";
import compose from "recompose/compose";
import withProductId from "./withProductId";
import {
  updateProductCustomisation,
  removeProductCustomisation,
} from "src/client/js/store/actions/product";
import { getProductCustomisation } from "src/client/js/store/selectors/product";

const withProductCustomisation = compose(
  withProductId,
  connect(
    (state) => ({
      productCustomisation: getProductCustomisation(state),
    }),
    (dispatch, props) => ({
      updateProductCustomisation: (customisation) => {
        return dispatch(
          updateProductCustomisation(props.productId, customisation)
        );
      },
      removeProductCustomisation: (keys) =>
        dispatch(removeProductCustomisation(keys)),
    })
  )
);

export default withProductCustomisation;
