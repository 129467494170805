import logger from "src/logger";
import withProps from "recompose/withProps";
import * as accountTracking from "../../analytics/category/account";

export const withSetupTracking = withProps((props) => {
  try {
    accountTracking.init(props.analyticsContext);
  } catch (error) {
    logger.error(error);
  }
});

export default withSetupTracking;
