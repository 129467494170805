import logger from "src/logger";

/**
 * Get bounding rect from selector string
 * @param {string} selector
 * @returns {DOMRect}
 */
export const getRect = (selector) => {
  const target = document.querySelector(selector);
  return target.getBoundingClientRect();
};

/**
 * Get offset from selector string
 * @param {string} selector
 * @param {number} newTop
 * @returns {number}
 */
export const getOffset = (selector, newTop) => {
  if (selector) {
    const { top, height } = getRect(selector);

    if (top < newTop) {
      return height;
    }
  }

  return 0;
};

/**
 * get closest scrollable element
 * @param {Window|HTMLElement} node the element to check if it is scrollable
 * @param {number} [minScrollDist=100] the minimum distance required to consider the element scrollable
 * @returns {Window|HTMLElement}
 */
const getScrollable = (node, minScrollDist = 100) => {
  if (node === null || node === window) {
    return window;
  }

  const scrollableHeight = node.scrollHeight - node.clientHeight || 0;
  const scrollableWidth = node.scrollWidth - node.clientWidth || 0;
  const isScrollable =
    scrollableHeight > minScrollDist || scrollableWidth > minScrollDist;

  if (isScrollable) {
    return node;
  }

  return getScrollable(node.parentNode, minScrollDist);
};

/**
 * Get offset of wrapper
 * @param {Window|HTMLElement} wrapper
 * @param {'x'|'y'} position
 * @returns {number}
 */
const getWrapperOffset = (wrapper, position) => {
  if (position === "y") {
    return wrapper.pageYOffset ?? wrapper.scrollTop;
  }
  return wrapper.pageXOffset ?? wrapper.scrollLeft;
};

/**
 * getBoundingParentRect get the bounding rect of an element relative the the parent
 * @param {HTMLElement} element
 * @param {Window|HTMLElement} parent
 * @returns {DOMRect}
 */
const getBoundingParentRect = (element, parent) => {
  const elBounding = element.getBoundingClientRect();

  if (parent === window) {
    return elBounding;
  }

  const pBounding = parent.getBoundingClientRect();

  const width = elBounding.width;
  const height = elBounding.height;
  const top = elBounding.top - pBounding.top;
  const left = elBounding.left - pBounding.left;

  return {
    width,
    height,
    top,
    left,
    bottom: top + height,
    right: left + width,
    x: left,
    y: top,
  };
};

/**
 * isInViewport check if the element is in view of the scrollable element
 * @param {HTMLElement} element the element to check if visible
 * @param {Window|HTMLElement} scrollable the element that wraps around the element
 * @returns {boolean}
 */
const isInViewport = function (element, scrollable) {
  const bounding = getBoundingParentRect(element, scrollable);
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <= (scrollable.innerHeight ?? scrollable.clientHeight) &&
    bounding.right <= (scrollable.innerWidth ?? scrollable.clientWidth)
  );
};

/**
 * scrollToElement scroll to the position of an element
 * @param {Window|HTMLElement} wrapper the element that is being scrolled, e.g. the window or an element that is overflowed auto
 * @param {HTMLElement} element the element that we're scrolling to
 * @param {Object} [options]
 * @param {number} [options.xOffset=0] the scrollTo offset on the x axis
 * @param {number} [options.yOffset=0] the scrollTo offset on the y axis
 * @param {bool} [options.scrollIfInView=true] scroll window even if element is already in viewport
 * @returns {void}
 */
export const scrollToElement = (wrapper, element, options = {}) => {
  const { xOffset, yOffset, scrollIfInView } = {
    xOffset: 0,
    yOffset: 0,
    scrollIfInView: true,
    ...options,
  };

  const scrollable = getScrollable(wrapper);

  if (!scrollIfInView && isInViewport(element, scrollable)) {
    return;
  }

  const elYPosition = element.getBoundingClientRect().y;
  const elXPosition = element.getBoundingClientRect().x;
  const scrollableYOffset = getWrapperOffset(scrollable, "y");
  const scrollableXOffset = getWrapperOffset(scrollable, "x");

  scrollable.scrollTo({
    top: elYPosition + scrollableYOffset - yOffset,
    left: elXPosition + scrollableXOffset - xOffset,
    behavior: "smooth",
  });
};

/**
 * @typedef {Object} ScrollYToElementOptions
 * @property {number} offset the scrollTo offset on the y axis
 * @property {string} collapsibleSelector
 * @property {string} headerSelector the website header class or id
 */

/**
 * Scroll to an element, offsetting the website header and any collapsible element
 * @param element
 * @param {ScrollYToElementOptions} [options]
 */
const scrollYToElement = (element, options = {}) => {
  const { offset, collapsibleSelector, headerSelector } = {
    offset: 0,
    headerSelector: "header",
    ...options,
  };

  const { top } = element.getBoundingClientRect();
  const { height: headerOffset } = getRect(headerSelector);
  // Offset against any collapsing selectors to ensure scroll doesn't miss
  const collapsibleOffset = getOffset(collapsibleSelector, top);

  scrollToElement(window, element, {
    yOffset: offset + headerOffset + collapsibleOffset,
  });
};

/**
 * Scroll to a selector's parent node
 * @param {string} selector
 * @param {ScrollYToElementOptions} [options]
 */
export const scrollToParent = (selector, options) => {
  const target = document.querySelector(selector);
  if (!target) {
    logger.warn(`Unable to scroll to missing element: "${selector}"`);
    return;
  }
  scrollYToElement(target.parentNode, options);
};

const intersectionObserver = (callback) => {
  return new IntersectionObserver((entries) => {
    let [entry] = entries;
    if (entry.isIntersecting) {
      setTimeout(callback, 250);
    }
  });
};

export const scrollToFormField = (selector, options = {}) => {
  let target = document.querySelector(selector);
  if (!target) {
    logger.warn(`Unable to scroll to missing element: "${selector}"`);
    return;
  }

  if ("IntersectionObserver" in window) {
    const input = target.getElementsByTagName("input")[0];
    let iObserver = intersectionObserver(() => {
      input ? input.focus() : null;
      iObserver.disconnect();
      iObserver = undefined;
    });
    iObserver.observe(target);
  }

  scrollYToElement(target, options);
};

/**
 * Scroll to a selector
 * @param {string} selector
 * @param {ScrollYToElementOptions} [options]
 */
export default (selector, options) => {
  let target = document.querySelector(selector);
  if (!target) {
    logger.warn(`Unable to scroll to missing element: "${selector}"`);
    return;
  }
  scrollYToElement(target, options);
};
