import { useDispatch, useSelector } from "react-redux";
import { addToCart, editCartItem } from "src/client/js/store/thunks/cart";
import { useCountry } from "./index";
import { transformCharacterSelection } from "../hocs/withCart";
import { addFlashMessageNotice } from "src/client/js/view/components/FlashMessages/duck";

const useCart = () => {
  const dispatch = useDispatch();
  const countryModel = useCountry();
  const cartState = useSelector((state) => state.session.cart);

  return {
    addItemToCart: (item, redirect = true) => {
      const payload = { items: [transformCharacterSelection(item)] };
      dispatch(addToCart(payload, countryModel, redirect, window));
    },
    editItemInCart: (item, redirect = false) => {
      dispatch(editCartItem(item, countryModel, redirect, window));
    },
    cartStatus: {
      request: cartState.request || {},
      response: cartState.response || {},
    },
    setFlashMessage: (message) => {
      message && dispatch(addFlashMessageNotice(message));
    },
  };
};
export default useCart;
