import React from "react";
import PropTypes from "prop-types";

import isEmpty from "lodash/isEmpty";

import { prismicImage } from "src/common/utils/prismic-image";

import Select from "wonderbly-components/lib/Select";
import Button from "wonderbly-components/lib/Button";
import Tooltip from "wonderbly-components/lib/Tooltip";
import InlineFormField from "wonderbly-components/lib/InlineFormField";
import { Row, Col } from "src/client/js/view/components/Grid";

import "./CustomSelect.scss";

const CustomSelection = (props) => (
  <Row
    rowsSpacedOn="base"
    colsToRowsOn="tablet-down"
    colsSpacedOn="base"
    className="custom-selection"
  >
    <Col>
      <img
        src={prismicImage(props.drawer.customSelectImage)}
        alt={props.drawer.customSelectImage.alt || ""}
        draggable="false"
      />
    </Col>
    <Col>
      <form onSubmit={props.handleSubmit}>
        <Row colsSpacedOn="base">
          <div className="selection-option-wrapper">
            <Select
              id="selection_state"
              name="selection_state"
              key="index"
              options={props.drawer.items}
              value={props.selectionState}
              onChange={props.onChange}
            />
          </div>
          {props.selectionState === props.otherValue && (
            <div className="selection-option-wrapper">
              <InlineFormField
                id="selection_value"
                name="selection_value" // check elsewhere can add label
                fieldType="input"
                value={props.selectionValue}
                onChange={props.onChangeInput}
                placeholder={props.drawer.customInputSuggestion}
              />
            </div>
          )}
        </Row>
        <Row>
          <Col className="col-2-3">
            <div>
              {props.errors && !!props.errors.selection_value && (
                <Tooltip
                  className="error-tooltip inscription-tooltip"
                  visible="true"
                  type="alert"
                >
                  {props.errors && props.errors.selection_value}
                </Tooltip>
              )}
            </div>
          </Col>
          <Col className="col-1-3">
            <Button
              type="submit"
              disabled={props.isSubmitting || !isEmpty(props.errors)}
              fullWidth
            >
              {props.drawer.cta}
            </Button>
          </Col>
        </Row>
      </form>
    </Col>
  </Row>
);

CustomSelection.propTypes = {
  hasGender: PropTypes.bool.isRequired,
  selectionOptions: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool,
  drawer: PropTypes.shape({
    defaultName: PropTypes.string,
    cta: PropTypes.string.isRequired,
  }),
  errors: PropTypes.shape({
    gifter_name: PropTypes.string,
    gifter_gender: PropTypes.string,
  }),
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  selectionState: PropTypes.string.isRequired,
  selectionValue: PropTypes.string.isRequired,
  selection: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  otherValue: PropTypes.string.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    customInputSuggestion: PropTypes.string,
    items: PropTypes.array.isRequired,
    charactersLeftText: PropTypes.string.isRequired,
    customSelectImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      dimensions: PropTypes.shape({
        width: PropTypes.number.isRequired,
      }),
    }),
  }),
  errors: PropTypes.shape({
    selection_value: PropTypes.string,
  }),
};

export default CustomSelection;
