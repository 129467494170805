import { UPDATE_RESPONSIVE_QUERIES } from "../actions/responsive";
import { defaultQueries } from "../thunks/responsive";

const defaultState = Object.keys(defaultQueries).reduce((media, key) => {
  switch (key) {
    case "mobile":
    case "fabletDown":
    case "tabletDown":
    case "desktopDown":
      media[key] = true;
      break;
    default:
      media[key] = false;
  }
  return media;
}, {});

export const responsive = (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_RESPONSIVE_QUERIES:
      return action.queries;
    default:
      return state;
  }
};
