import splitKeyValues from "src/common/utils/split-prismic-key-vals";
import isArray from "lodash/isArray";

export const matchDefaultCustomisation = (customisations = {}, item) => {
  const gender = item.gender ? item.gender.toLowerCase() : "all";
  const phototype = item.phototype ? item.phototype.toLowerCase() : "all";

  return (
    (phototype !== "all" ? phototype === customisations.phototype : true) &&
    (gender !== "all" ? gender === customisations.gender : true)
  );
};

export const matchExtraCustomisation = (customisations, item) => {
  if (!item.extraChoices) {
    return true;
  }

  const extraChoice = splitKeyValues(item.extraChoices);
  const isExtraChoiceAnArray = isArray(customisations[extraChoice.key]);

  return isExtraChoiceAnArray
    ? customisations[extraChoice.key].includes(extraChoice.value)
    : extraChoice.value === customisations[extraChoice.key];
};
