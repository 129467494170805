import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Arrow from "wonderbly-components/lib/Arrow";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { trackParentNavItem } from "../tracking";
import { useModel } from "../../../hooks";
import { DesktopExpandedNav } from "../SiteExpandedNav";
import { useSiteNavContext } from "../SiteNavHeader/SiteNavToggle/SiteNavContext";
import "./SiteNavItem.scss";

const SiteNavItem = (props) => {
  const { subMenuIndex, setSubMenuIndex } = useSiteNavContext();
  const { countryModel } = useModel();

  return (
    <li
      className="SiteNavItem"
      key={props.idx}
      // We only want to trigger the onMouseEnter
      // if the item has columns
      onMouseEnter={() =>
        props.item.columns &&
        props.handleMouseEnter(
          props.idx,
          Object.entries(props.item.columns).length
        )
      }
      onMouseLeave={props.handleMouseLeave}
    >
      {props.item.columns ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            className={cx("SiteNavItem__button", {
              "SiteNavItem__button--is-active": subMenuIndex === props.idx,
            })}
            onClick={(e) => {
              e.preventDefault();
              setSubMenuIndex(props.idx);
              trackParentNavItem(props.item.navMenuLabel);
            }}
          >
            {props.item.navMenuLabel}
            <Arrow inline className="SiteNavItem__arrow" direction="right" />
          </button>
          <DesktopExpandedNav
            columns={props.item.columns}
            parentItemLabel={props.item.navMenuLabel}
            showSubMenu={subMenuIndex === props.idx}
          />
        </>
      ) : (
        <a
          className={cx("SiteNavItem__button", {
            "SiteNavItem__button--is-link": props.idx || props.idx === 0,
          })}
          onClick={() => trackParentNavItem(props.item.navMenuLinkUrl)}
          href={
            props.item.navMenuLinkUrl &&
            localiseUrlII(countryModel, props.item.navMenuLinkUrl)
          }
        >
          {props.item.navMenuLabel}
        </a>
      )}
    </li>
  );
};

SiteNavItem.propTypes = {
  item: PropTypes.shape({
    navMenuLabel: PropTypes.string,
    navMenuLinkUrl: PropTypes.string,
    columns: PropTypes.shape({
      linkColumns: PropTypes.array,
      marketingColumns: PropTypes.array,
    }),
  }),
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  idx: PropTypes.any,
};

export default SiteNavItem;
