class RestResponse {
  constructor(status, contentType, body, headers, url = null) {
    this.ok = true;
    this.status = status;
    this.contentType = contentType;
    this.body = body;
    this.url = url;
    this.headers = {
      admin_redirect_url: headers.get("admin_redirect_url"),
    };
  }
}

module.exports = RestResponse;
