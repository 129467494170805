import {
  UPDATE_PRODUCT_CUSTOMISATION,
  REMOVE_PRODUCT_CUSTOMISATION,
} from "../actions/product";
import omit from "lodash/omit";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import mergeObjects from "src/common/utils/merge-objects";

export const product = (state = { customisation: {} }, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_CUSTOMISATION:
      let customisation = mergeObjects(
        state.customisation || {},
        action.customisation
      );

      // This is necessary as the merge combines the components even when
      // we are removing one
      if ("components" in action.customisation) {
        customisation.components = action.customisation.components;
      }

      // This is necessary as the merge combines avatars 3.0 even when we
      // are removing character params.
      // eg: facialHairType from male to female gender change
      if (action.customisation?.characters?.[0].customisations) {
        customisation.characters = action.customisation.characters;
      }

      if (customisation.bears) {
        customisation.bears = customisation.bears.map((bear) => {
          try {
            return JSON.parse(bear);
          } catch (error) {
            return bear;
          }
        });
      }

      return {
        ...state,
        id: action.productId,
        customisation: omitBy(customisation, isNil),
      };
    case REMOVE_PRODUCT_CUSTOMISATION:
      return {
        ...state,
        customisation: omit(state.customisation, action.customisationKeys),
      };
    default:
      return state;
  }
};
