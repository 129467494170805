import { connect } from "react-redux";
import compose from "recompose/compose";
import lifecycle from "recompose/lifecycle";
import withState from "recompose/withState";
import mapProps from "recompose/mapProps";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { getShippingDates } from "../Shipping/duck";
import { withModel } from "../../data-providers/model";
import { getPromoBanners, remapBanners } from "./helpers";

import PromoBanners from "./PromoBanners";

const enhance = compose(
  connect((state) => ({
    shippingDates: getShippingDates(state),
  })),
  withModel,
  mapProps((props) => {
    const banners = getPromoBanners(
      props.model.prismicPromoBannerModel,
      get(props, "model.prismicGlobalContentModel.general.banners", [])
    );

    return {
      banners: remapBanners(banners, props),
      ...props,
    };
  }),
  withState("clientBanners", "addClientBanners", []),
  lifecycle({
    componentDidUpdate(prevProps) {
      let banners = getPromoBanners(
        this.props.model.prismicPromoBannerModel,
        get(this.props, "model.prismicGlobalContentModel.general.banners", [])
      );

      banners = remapBanners(banners, this.props);

      if (!isEqual(banners, prevProps.clientBanners)) {
        this.props.addClientBanners(banners);
      }
    },
  })
);

export default enhance(PromoBanners);
