export const UPDATE_ACTIVE_CANVAS_DRAWER = "UPDATE_ACTIVE_CANVAS_DRAWER";
export const SET_COMPLETED_DRAWER = "SET_COMPLETED_DRAWER";

let timer;

export const updateActiveCanvasDrawer = (drawer) => ({
  type: UPDATE_ACTIVE_CANVAS_DRAWER,
  drawer,
});

export const setCompletedDrawer = (drawer) => ({
  type: SET_COMPLETED_DRAWER,
  drawer,
});

export const setCompletedDrawerWithTimer =
  (dispatch) =>
  (drawer, timeout = 3000) => {
    if (timer) {
      clearTimeout(timer);
    }
    dispatch(setCompletedDrawer(drawer));
    timer = setTimeout(() => {
      dispatch(setCompletedDrawer(null));
    }, timeout);
  };

export const getActiveCanvasDrawer = (state) =>
  state.components.canvasDrawers.activeDrawer;

export const getCompletedDrawer = (state) =>
  state.components.canvasDrawers.completedDrawer;

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_CANVAS_DRAWER: {
      return {
        ...state,
        activeDrawer:
          state.activeDrawer === action.drawer ? null : action.drawer,
      };
    }
    case SET_COMPLETED_DRAWER: {
      return {
        ...state,
        completedDrawer: action.drawer,
      };
    }
    default: {
      return state;
    }
  }
};
