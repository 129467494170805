import debounce from "lodash/debounce";
import camelCase from "lodash/camelCase";
import get from "lodash/get";
import set from "lodash/set";
import cloneDeep from "lodash/cloneDeep";
import { validateCreationForm } from "../helpers";

const DEFAULT_DRAWER_KEY = "names";

export const drawerKey = (props) =>
  props.drawer.drawerKey || DEFAULT_DRAWER_KEY;

const handleSuccess = (key, value, props) => {
  props.setErrors({});
  props.setFieldValue(key, value);
};

const handleValidationErrors = (key, value, errors, props) => {
  // TODO workout correct validation errors once hooked up to muse
  if (!errors || !errors[key]) {
    handleSuccess(key, value, props);
    return;
  }

  const errorCode = errors[key][0].error.split(".").map(camelCase).join(".");

  if (errorCode.includes("notEnoughNames")) {
    handleSuccess(key, value, props);
  }

  if (errorCode.includes("tooManyNames")) {
    props.setAdditionalItem("");
  }

  props.setErrors(
    set({}, key, props.getErrorMessage(errorCode, "general.default"))
  );
};

const validate = async (values, props, config) => {
  const key = props.drawerKey;
  const items = get(values, key);

  try {
    const errors = await validateCreationForm(values, props, config);

    if (errors) {
      throw errors;
    }

    props.setFieldValue(key, items);
    return values;
  } catch (error) {
    handleValidationErrors(key, items, error, props);
  }
};

const debouncedValidation = debounce(validate, 500);

export const mapCustomistaionToValues = (props) => {
  const key = props.drawerKey;
  const values = get(props.productCustomisation, key) || [];
  return set({ base: "" }, key, values);
};

export const handleItemChange = (props) => async (itemsIndex, value) => {
  props.setErrors({});

  const key = props.drawerKey;
  const items = props.values[key].concat() || [];
  let newItems = [];

  if (value === undefined) {
    newItems = items.filter((_, index) => itemsIndex !== index);
    handleSuccess(key, newItems, props);
  } else {
    props.setSubmitting(true);
    newItems = items.map((item, index) =>
      itemsIndex === index ? value : item
    );
    handleSuccess(key, newItems, props);
    await debouncedValidation({ [key]: newItems }, props, { raw: true });
  }
};

export const addItem = (props) => async () => {
  const key = props.drawerKey;
  const values = cloneDeep(props.values);
  const items = (get(values, key) || []).concat(props.additionalItem);

  set(values, key, items);

  try {
    const errors = await validateCreationForm(values, props, { raw: true });

    if (errors) {
      throw errors;
    }

    props.setAdditionalItem("");
    props.setFieldValue(key, items);
    return values;
  } catch (error) {
    handleValidationErrors(key, items, error, props);
    return;
  }
};
