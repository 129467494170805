import { useState, useRef, useEffect } from "react";

export default (initiallyVisible) => {
  const [isVisible, setIsVisible] = useState(initiallyVisible);
  // Creating a reference to a DOM element to check if user interaction is outside the element's boundaries
  // see React Ref for more info: https://reactjs.org/docs/refs-and-the-dom.html
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isVisible, setIsVisible };
};
