import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import Icon from "wonderbly-components/lib/Icon";

const CloseButton = ({ handleOnClick, className }) => {
  return (
    <Button
      className={className}
      onClick={handleOnClick}
      type="button"
      unstyled
    >
      <Icon icon="delete" />
    </Button>
  );
};

CloseButton.propTypes = {
  className: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
};

export default CloseButton;
