import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "src/client/js/view/components/Grid";
import InterestOption from "./InterestOption";

const Interest = ({
  items,
  productVariant,
  productCustomisation,
  interestType,
  drawer,
  upselledOptions,
  onFormikSubmit,
}) => {
  const customisationValue = productCustomisation[interestType];
  const { isUpsellEnabled } = drawer;
  return (
    <Row className="cols-to-rows-on-mobile">
      {items.map((option) => (
        <Col
          className="col-1-4-on-desktop-up col-1-2-on-fablet-and-tablet interest-option-column"
          key={option.id}
        >
          <InterestOption
            {...option}
            upselledOptions={upselledOptions}
            isUpsellEnabled={isUpsellEnabled}
            optionName={interestType}
            productVariant={productVariant}
            isSelected={customisationValue === option.id}
            onFormikSubmit={onFormikSubmit}
          />
        </Col>
      ))}
    </Row>
  );
};

Interest.propTypes = {
  onFormikSubmit: PropTypes.func.isRequired,
  productVariant: PropTypes.object.isRequired,
  productCustomisation: PropTypes.object.isRequired,
  interestType: PropTypes.string.isRequired,
  drawer: PropTypes.shape({
    isUpsellEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
  upselledOptions: PropTypes.array.isRequired,
};

export default Interest;
