import React from "react";
import PropTypes from "prop-types";
import Category from "./Category";

const Categories = ({ items }) => {
  return (
    <div className="Sitemap__Categories">
      {items.map((item, idx) => (
        <Category key={idx} {...item} />
      ))}
    </div>
  );
};

Categories.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Categories;
