export const OPEN_OVERLAY = "OPEN_OVERLAY";
export const CLOSE_OVERLAY = "CLOSE_OVERLAY";
export const ENABLE_LOADING = "ENABLE_LOADING";
export const DISABLE_LOADING = "DISABLE_LOADING";

export const setOpenOverlay = (currentScrollPos) => ({
  type: OPEN_OVERLAY,
  currentScrollPos,
});

export const setCloseOverlay = () => ({
  type: CLOSE_OVERLAY,
});

export const setEnableLoading = () => ({
  type: ENABLE_LOADING,
});

export const setDisableLoading = () => ({
  type: DISABLE_LOADING,
});
