const splitKeyValues = (string) => {
  if (!string || typeof string !== "string") {
    return null;
  }
  const [key, value] = string.split("::");
  return {
    key,
    value,
  };
};

module.exports = splitKeyValues;
