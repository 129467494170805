import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import Tooltip from "wonderbly-components/lib/Tooltip";
import BirthdayFormRow from "../../BirthdayFormRow";
import cx from "classnames";

import "./Birthday.scss";

const Birthday = (props) => [
  <Tooltip
    key="error"
    className="error-tooltip birthday-form-tooltip"
    visible={props.errors && !!props.errors.base}
    absolute={false}
    type="alert"
  >
    {props.errors && props.errors.base}
  </Tooltip>,
  <form
    key="main"
    className={cx("birthday-form", {
      "birthday-form--error": props.errors && props.errors.base,
    })}
    onSubmit={props.handleSubmit}
  >
    <BirthdayFormRow
      formId="birthday-form"
      drawer={props.drawer}
      values={props.values}
      onHandleChange={props.handleChange}
    />
    <div className="birthday-form-button">
      <Button type="submit" disabled={props.isSubmitting}>
        {props.drawer.cta}
      </Button>
    </div>
  </form>,
];

Birthday.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    day: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
  }),
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
  }),
};

export default Birthday;
