import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import { withModel } from "../data-providers/model";

const withProducts = compose(
  withModel,
  mapProps(({ model, ...props }) => ({
    ...props,
    products: model.eagleModel.products,
  }))
);

export default withProducts;
