const omitBy = require("lodash/omitBy");
const isNil = require("lodash/isNil");

const replaceArrayTemplate = (string, idx = null) => {
  if (!string || !string.match(/\[\]/) || idx === null || idx === -1) {
    return string;
  }

  return string.replace(/\[\]/, `[${idx}]`);
};

const buildField = ({ sliceType, primary, items }, idx) => {
  let value = primary.value;
  let type, options;

  switch (sliceType) {
    case "radio_button":
    case "radio_image":
    case "filtered_radio_image":
    case "scrollable_card_radio":
    case "select_input":
      type = sliceType.split("_").reverse().join("_");

      if (sliceType === "filtered_radio_image") {
        type = "filter_image_radio";
        primary.filterKey = replaceArrayTemplate(primary.filterKey, idx);
      }

      if (sliceType === "scrollable_card_radio") {
        type = "scrollable_card_radio";
      }

      if (sliceType === "select_input") {
        type = "select";
      }

      options = items.map(({ isPreSelected, ...item }) => {
        const option = item;

        if (isPreSelected && !value) {
          value = option.value;
        }

        if (option.image) {
          option.image = option.image.url;
        }

        return option;
      });
      break;
    case "multi_text_field":
      type = sliceType;
      break;
    case "date_of_birth_select":
    case "date_of_birth_text":
      type = "date";

      const monthLabels = items.map(({ ...item }) => {
        if (item.monthLabel) {
          item = item.monthLabel;
        }

        return item;
      });

      primary.uiLabels.months = monthLabels;
      primary.uiLabels.title = primary.title;

      options = [
        {
          label: primary.optionsLabel.current,
          value: "current",
          defaultChecked: true,
        },
        {
          label: primary.optionsLabel.next,
          value: "next",
        },
      ];
      break;
    default:
      // e.g. text_field => text
      type = sliceType.split("_").shift();
  }

  primary.name = replaceArrayTemplate(primary.name, idx);

  return omitBy(
    {
      ...primary,
      value,
      options,
      type,
    },
    isNil
  );
};

module.exports = {
  buildField,
  replaceArrayTemplate,
};
