const { getWithContentLocale } = require("../../../l10n/country");
const { getProductBySku } = require("../../../config/product");

/**
 * The UID for a Prismic document not only defines what it is but also what route/page it is related to.
 * This class helps to figure that out.
 *
 * A typical UID may look like `homepage_en-us`, 'product_lost-my-name-book_en-us`, or `lmn_en-us`
 * **/

class DocumentUID {
  constructor(uid) {
    this._isNil = true;
    if (uid) {
      const parts = uid.split("_");
      if (parts.length > 1) {
        this._locale = parts.pop();
        this._country = getWithContentLocale(this._locale);
        this._slug = parts.length === 2 && parts.pop();
      }
      this._pageType = parts[0];
      this._isNil = false;
    }
  }
  isNil() {
    return this._isNil;
  }
  getPageType() {
    return this._pageType;
  }
  getCountry() {
    return this._country;
  }
  getSlug() {
    const product = getProductBySku(this._pageType);
    return product ? product.slug : this._slug;
  }
}

module.exports = DocumentUID;
