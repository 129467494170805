import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import get from "lodash/get";

import { withModel } from "../data-providers/model";
import routes from "../routes/routes";
import compose from "recompose/compose";
import withState from "recompose/withState";
import { connect } from "react-redux";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { initializeCart } from "../../store/thunks/cart";
import { updateResponsiveQueries } from "../../store/thunks/responsive";
import { setCookieConsent } from "../../store/thunks/cookies";
import { getCookieConsent } from "../../store/selectors/cookies";
import { getGeoLocation } from "../../store/selectors/geoLocation";
import { fetchGeoLocation } from "../../store/thunks/geoLocation";
import withOptimizelyEdit from "../hocs/withOptimizelyEdit";
import logger from "../../../../logger";
import { Provider as UserProvider, buildUser } from "../data-providers/user";
import { Provider as UserOrdersProvider } from "../data-providers/orders";
import { FetchDataProvider } from "../hooks";
import withQueryParams from "../hocs/withQueryParams";
import cookieFactory from "../../cookieFactory";
import { fetchUser } from "../../facades/eagle/user";
import { fetchEstimatedDeliveryDate } from "./Shipping/duck";
import { getItems as getCartItems } from "../../store/selectors/cart";
import { isLodFetchEnabled } from "../../../../common/config/feature-flags";

class App extends React.Component {
  static propTypes = {
    allowCookies: PropTypes.bool,
    cartCount: PropTypes.number,
    queryParams: PropTypes.object,
    geoLocation: PropTypes.object,
    user: PropTypes.object,
    filter: PropTypes.object,
    model: PropTypes.shape({
      countryModel: PropTypes.object.isRequired,
      topology: PropTypes.shape({
        google: PropTypes.shape({
          siteKey: PropTypes.string.isRequired,
        }),
      }).isRequired,
    }).isRequired,
    userOrders: PropTypes.shape({
      orders: PropTypes.array,
    }).isRequired,
    initializeCart: PropTypes.func.isRequired,
    updateResponsiveQueries: PropTypes.func.isRequired,
    initializeCookies: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    updateGeoLocation: PropTypes.func.isRequired,
    setFilter: PropTypes.func.isRequired,
    setUserOrders: PropTypes.func.isRequired,
    fetchEstimatedDeliveryDate: PropTypes.func,
  };

  initializeUser() {
    const props = this.props;
    if (!!cookieFactory("restricted_user_id").get()) {
      fetchUser(props.queryParams)
        .then((response) => buildUser(response))
        .then((user) => props.setUser(user))
        .catch();
    }
  }

  componentDidMount() {
    const props = this.props;

    if (isEmpty(props.geoLocation)) {
      props.updateGeoLocation();
    }

    const cookieConsentCookie = cookieFactory("allow_cookies");
    this.allowCookies = cookieConsentCookie.get() === "true";
    this.initializeUser();
    this.props.initializeCart();

    this.props.updateResponsiveQueries(window);
    this._debounce = debounce(
      () => this.props.updateResponsiveQueries(window),
      250
    );
    window.addEventListener("resize", this._debounce);

    // For Wonderbly Components that look for '.js'
    const appElement = document.getElementsByClassName("app")[0];
    if (appElement) {
      appElement.classList.add("js");
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props;

    let allowCookies = this.allowCookies;
    // getLocation.detected might return null. null is a value meaning lodash.get will not return the default value
    // that's why we return null as default and use || to default to an empty object
    const detected = get(props, "geoLocation.detected", null) || {};
    const deliveryDateParams = {
      country_iso: detected.country,
      state_abbr: detected.region,
    };

    if (!isEqual(prevProps.cartCount, props.cartCount)) {
      prevProps.fetchEstimatedDeliveryDate(deliveryDateParams);
    }

    if (
      !isEqual(prevProps.geoLocation, props.geoLocation) &&
      get(props, "geoLocation.response.ok", null)
    ) {
      prevProps.fetchEstimatedDeliveryDate(deliveryDateParams);

      if (!get(props, "geoLocation.detected.isEU", null)) {
        allowCookies = true;
      }

      if (props.allowCookies !== allowCookies) {
        prevProps.initializeCookies({
          allowCookies,
        });
      }
    }
  }

  render() {
    logger.type();
    const props = this.props;
    const model = props.model;
    const google = model.topology.google;
    const language = model.countryModel.contentLocales[0];
    const locale = language.split("-")[0];

    return (
      <GoogleReCaptchaProvider reCaptchaKey={google.siteKey} language={locale}>
        <UserProvider
          value={{
            user: props.user,
            setUser: props.setUser,
          }}
        >
          <UserOrdersProvider
            value={{
              userOrders: props.userOrders,
              setUserOrders: props.setUserOrders,
            }}
          >
            <FetchDataProvider>
              <div className="app">{routes}</div>
            </FetchDataProvider>
          </UserOrdersProvider>
        </UserProvider>
      </GoogleReCaptchaProvider>
    );
  }
}

export default compose(
  withModel,
  withRouter,
  withQueryParams,
  connect(
    (state) => ({
      allowCookies: getCookieConsent(state),
      geoLocation: getGeoLocation(state),
      cartCount: getCartItems(state).length,
    }),
    (dispatch, props) => ({
      updateResponsiveQueries: (window) =>
        dispatch(updateResponsiveQueries(window)),
      initializeCart: () =>
        dispatch(
          initializeCart({
            query: props.location.search,
            currency: props.model.countryModel.currency,
          })
        ),
      initializeCookies: ({ allowCookies }) => {
        dispatch(setCookieConsent(allowCookies));
      },
      updateGeoLocation: () => dispatch(fetchGeoLocation()),
      fetchEstimatedDeliveryDate: (params) =>
        isLodFetchEnabled() && dispatch(fetchEstimatedDeliveryDate(params)),
    })
  ),
  withOptimizelyEdit,
  withState("user", "setUser", { response: { ok: false } }),
  withState("userOrders", "setUserOrders", {
    response: { ok: false, pending: true },
    orders: [],
  }),
  withState("filter", "setFilter", {
    ages: "all",
    occasions: "all",
    order: "none",
    current: null,
  })
)(App);
