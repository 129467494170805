// This is legacy code from HeaderII and is used
// for the CountryToggle and Basket.
export const UPDATE_ACTIVE_POPUP = "UPDATE_ACTIVE_POPUP";
export const UPDATE_NAV_OPEN_STATE = "UPDATE_NAV_OPEN_STATE";

export const updateActivePopup = (name) => ({
  type: UPDATE_ACTIVE_POPUP,
  name,
});

export const updateNavOpenState = (open) => ({
  type: UPDATE_NAV_OPEN_STATE,
  open,
});

export const getActivePopup = (state) => state.components.header.activePopup;
export const isNavOpen = (state) => state.components.header.open;

export default (state = { open: false }, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_POPUP: {
      return {
        ...state,
        activePopup: state.activePopup === action.name ? null : action.name,
      };
    }
    case UPDATE_NAV_OPEN_STATE: {
      return {
        ...state,
        open: action.open,
      };
    }
    default: {
      return state;
    }
  }
  return state;
};
