import React from "react";
import ReactDOM from "react-dom";
import logger from "src/logger";
import { renderToString } from "react-dom/server";

/**
 * Find all matching keys, and run a custom replacing function on each one.
 *
 * @param {string} children string content containing keys.
 * @param {object} data data object to map later map to components. Keys of this object get replaced.
 * @param {object} id unique identifier for SSR.
 * @param {func} replacer replacing function that gets called on each matching key. Return value is replacement.
 *
 * @returns {string} string containing replaced values for each matched key.
 */
export const replaceKeys = ({ children, data, id, replacer }) => {
  const keys = [];
  const classes = [];
  const keyRegexGroup = Object.keys(data).join("|");

  // Matches all string formatters, i.e. for a data object with keys
  // `['countdown_lod_standard', 'my_component']`, this regex will
  // globally match `%{my_component}` and `%{countdown_lod_standard}`
  const pattern = new RegExp(`%{(${keyRegexGroup})}`, "g");

  const modifiedChildren = children.replace(pattern, (match) => {
    // Match the key inside the string formatter, i.e. `my_component`
    const key = match.replace(/(%{|})/g, "");
    const elClass = `component-replacer_${id}_${key}`;

    keys.push(key);
    classes.push(elClass);

    return replacer({ key, elClass });
  });

  return { keys, modifiedChildren, classes };
};

/**
 * Hydrate all spans previously rendered by renderKeysClient with their live React components version.
 *
 * @param {array} classes array of classes for each key found in the children.
 * @param {array} keys array of each key found in the children.
 * @param {object} componentMapping components to map to each key.
 * @param {object} data data object containing props to be passed to each key.
 *
 * @returns {string} string containing replaced values for each matched key.
 */
export const hydrateReplacer = ({ classes, keys, componentMapping, data }) => {
  for (let i = 0; i < classes.length; i++) {
    const key = keys[i];
    const elClass = classes[i];
    const els = document.querySelectorAll(`.${elClass}`);

    if (els) {
      const Component = componentMapping[key];

      if (!Component) {
        logger.warn(
          `Component for "${key}" was not defined in ComponentReplacer. Skipping...`
        );

        return;
      }

      for (let elIndex = 0; elIndex < els.length; elIndex++) {
        const el = els[elIndex];
        ReactDOM.hydrate(<Component {...data[key]} />, el);
      }
    } else {
      logger.warn(
        `No matching elements when rehydrating "${elClass}" in ComponentReplacer. Skipping...`
      );
    }
  }
};

/**
 * Replace all matching keys with rendered markup for their respective components using ReactDOM renderToString. This enables SSR for replaced components.
 *
 * @param {string} children string content containing keys.
 * @param {object} componentMapping components to map to each key.
 * @param {object} data data object to map later map to components.
 * @param {object} id unique identifier for SSR.
 *
 * @returns {string} string containing rendered markup for each matched key
 */
export const replaceKeysServer = ({ children, componentMapping, data, id }) =>
  replaceKeys({
    children,
    data,
    id,
    replacer: ({ elClass, key }) => {
      const Component = componentMapping[key];
      return renderToString(
        <span className={elClass}>
          <Component {...data[key]} />
        </span>
      );
    },
  });

/**
 * Replace all matching keys with a `<span />` that will be later rehydrated with the real component.
 *
 * @param {string} children string content containing keys.
 * @param {object} data data object to map later map to components. Keys of data object are those swapped out in children.
 * @param {object} id unique identifier for SSR.
 *
 * @returns {string} string containing `<span />` for each matched key
 */
export const replaceKeysClient = ({ children, data, id }) =>
  replaceKeys({
    children,
    data,
    id,
    replacer: ({ elClass }) => `<span class="${elClass}"></span>`,
  });
