import React from "react";
import when from "recompose/branch";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import withState from "recompose/withState";
import lifecycle from "recompose/lifecycle";
import renderComponent from "recompose/renderComponent";
import get from "lodash/get";
import { findProductVariant } from "src/common/utils/product";
import { product } from "src/common/facades/eagle";
import { withModel } from "../data-providers/model";
import withProductCustomisation from "./withProductCustomisation";
import withQueryParams from "./withQueryParams";
import Loading from "../components/Loading";
import logger from "src/logger";

const fetchProduct = async (productId, country, setProduct, setLoading) => {
  if (!productId) {
    logger.warn("Unable to fetch product data as product ID was missing");
    setLoading(false);
    return;
  }

  if (!country) {
    logger.warn("Unable to fetch product data as country model was missing");
    setLoading(false);
    return;
  }

  try {
    const data = await product(country, productId);
    setProduct(data);
  } catch (err) {
    logger.warn("Failed to fetch product data", err);
  }

  setLoading(false);
};

const withProduct = compose(
  withModel,
  withQueryParams,
  withProductCustomisation,
  withState("product", "setProduct", (props) => props.model.eagleProduct),
  withState("loading", "setLoading", true),
  lifecycle({
    componentDidMount() {
      const props = this.props;
      if (!props.product) {
        fetchProduct(
          props.productId,
          props.model.countryModel,
          props.setProduct,
          props.setLoading
        );
      }
    },
  }),
  when(
    (props) => props.loading && !props.product,
    renderComponent((props) => {
      if (props.loading) {
        return (
          <div style={{ margin: "16px 0" }}>
            <Loading dark relative small />
          </div>
        );
      }
    })
  ),
  mapProps(({ product, ...props }) => {
    const productVariant = findProductVariant(
      get(product, "variants"),
      props.productCustomisation,
      props.model.countryModel
    );

    return {
      ...props,
      product,
      productId: props.productId || (product && product.id), // some components are used not on a product page and use this hoc but don't have a product
      productVariant,
    };
  })
);

export default withProduct;
