import React, { useState } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { useFormik } from "formik";
import { replaceTextWithValues } from "src/common/utils/text-replace";

import { useOldProduct, useErrorMessages } from "../../../hooks";

import {
  findCharacterFromIdx,
  addCharacter,
  resetDefaultValues,
  setCharacterName,
  removeCharacter,
  submitForm,
} from "./helpers";

import Header from "./Header";
import List from "./List";
import Footer from "./Footer";
import FixedOrderingModal from "./FixedOrderingModal";

const Characters = ({
  drawer,
  productCustomisation,
  queryParams,
  onFormikSubmit,
}) => {
  const {
    customisationKey,
    placeholder,
    items,
    minRequired,
    maxRequired,
    additionalCharacterCta,
    fixedOrdering,
    cta,
  } = drawer;

  // Check if the form was previously submitted by checking if it exists in the query params.
  // If it was already submitted we want to disable the clear default values button
  const [isDefaultCleared, setIsDefaultCleared] = useState(
    !!queryParams[customisationKey]
  );
  const [isFixedOrderModalVisible, setIsFixedOrderingModalVisible] =
    useState(false);
  const [deletedCharacter, setDeletedCharacter] = useState({});

  const { productId } = useOldProduct();
  const { getErrorMessage } = useErrorMessages();

  // Need to ensure that the form values are cloned otherwise it updates
  // productCustomisation when changes happen to the form before saving
  const customisations = cloneDeep(productCustomisation);
  const defaultValues = customisations[customisationKey];

  const { values, errors, isSubmitting, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        [customisationKey]: defaultValues,
      },
      onSubmit: submitForm(
        productId,
        drawer,
        customisations,
        getErrorMessage,
        onFormikSubmit
      ),
    });

  const characters = values[customisationKey];
  const availableItems = items.reduce((acc, item) => {
    if (item.type) {
      const alreadyAdded = characters.find(
        (character) => character.type === item.type
      );
      if (!alreadyAdded) {
        acc.push(item);
      }
    }
    return acc;
  }, []);

  const hasMaxCharacters = characters.length === maxRequired;
  const lastCharacterIdx = characters.length - 1;
  const lastCharacter = findCharacterFromIdx(
    characters,
    items,
    lastCharacterIdx
  );

  const isOrderFixed = fixedOrdering.isActive;
  const showAddNextCharacterButton =
    isOrderFixed && availableItems.length !== 0 && !hasMaxCharacters;

  function setCharacters(characters) {
    setFieldValue(customisationKey, characters);
  }

  function removeDefaultValues() {
    setIsDefaultCleared(true);
    setCharacters(resetDefaultValues(characters, items));
  }

  async function addNewCharacter({ type, defaultValue, defaultGender }) {
    setCharacters(
      addCharacter(characters, {
        type,
        gender: defaultGender,
        name: isDefaultCleared || !defaultValue ? "" : defaultValue,
      })
    );
  }

  function addNextCharacter() {
    const nextCharacterIdx = characters.length;
    const nextCharacter = items[nextCharacterIdx];
    addNewCharacter(nextCharacter);
  }

  function updateCharacterValue(idx) {
    return (e) => {
      const value = e.target.value;
      setCharacters(setCharacterName(characters, idx, value));
    };
  }

  function deleteCharacter(idx) {
    return () => {
      setDeletedCharacter(findCharacterFromIdx(characters, items, idx));

      if (isOrderFixed) {
        if (lastCharacterIdx !== idx) {
          setIsFixedOrderingModalVisible(true);
          return;
        }
      }

      setCharacters(removeCharacter(characters, idx));
    };
  }

  async function deleteLastCharacter() {
    setIsFixedOrderingModalVisible(false);
    setCharacters(removeCharacter(characters, lastCharacterIdx));
  }

  function hideFixedOrderingModal() {
    setIsFixedOrderingModalVisible(false);
  }

  return (
    <form className="Characters">
      <Header
        label={"Remove default names"}
        isDisabled={isDefaultCleared}
        handleOnClick={removeDefaultValues}
      />
      <List
        nextCharacterButton={{
          isVisible: showAddNextCharacterButton,
          handleOnClick: addNextCharacter,
          label: additionalCharacterCta,
        }}
        characters={characters}
        baseError={errors && errors.base}
        characterErrors={errors && errors[customisationKey]}
        items={items}
        placeholder={placeholder}
        isOrderFixed={isOrderFixed}
        minRequired={minRequired}
        updateCharacterValue={updateCharacterValue}
        deleteCharacter={deleteCharacter}
      />
      <Footer
        submitLabel={cta}
        displayThumbnails={!isOrderFixed}
        thumbnails={availableItems}
        submitDisabled={isSubmitting}
        addCharacter={addNewCharacter}
        handleSubmit={handleSubmit}
      />
      <FixedOrderingModal
        {...fixedOrdering.modal}
        title={replaceTextWithValues(fixedOrdering.modal.title, {
          deletedCharacter,
        })}
        description={replaceTextWithValues(fixedOrdering.modal.description, {
          lastCharacter,
        })}
        cta={replaceTextWithValues(fixedOrdering.modal.cta, { lastCharacter })}
        image={deletedCharacter.image}
        isVisible={isFixedOrderModalVisible}
        hideFixedOrderingModal={hideFixedOrderingModal}
        handleOnClick={deleteLastCharacter}
      />
    </form>
  );
};

Characters.propTypes = {
  drawer: PropTypes.shape({
    cta: PropTypes.string,
    customisationKey: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        defaultValue: PropTypes.string,
      })
    ).isRequired,
    minRequired: PropTypes.number.isRequired,
    maxRequired: PropTypes.number.isRequired,
    additionalCharacterCta: PropTypes.string.isRequired,
    fixedOrdering: PropTypes.shape({
      isActive: PropTypes.bool.isRequired,
      modal: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        cta: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  productCustomisation: PropTypes.object.isRequired,
  queryParams: PropTypes.object.isRequired,
  onFormikSubmit: PropTypes.func.isRequired,
};

export default Characters;
