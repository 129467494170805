import React from "react";
import PropTypes from "prop-types";
import Carousel from "../../Carousel";
import { prismicImage } from "src/common/utils/prismic-image";
import MultiBirthdayForm from "./MultiBirthdayForm";
import "./MultiBirthday.scss";

const MultiBirthday = (props) => {
  return (
    <div className="multi-birthday">
      <div className="multi-birthday__carousel-container">
        <Carousel group="multi-birthday__carousel">
          {props.drawer.items.map((item, index) => (
            <img
              key={index}
              src={prismicImage(item.image)}
              alt={item.image.alt || ""}
              draggable="false"
            />
          ))}
        </Carousel>
      </div>
      <MultiBirthdayForm
        form={props.form}
        characters={props.characters}
        drawer={props.drawer}
        values={props.values}
        errors={props.errors}
        onFormSubmission={props.handleFormSubmission}
        onFieldChange={props.handleChange}
        onOpenMultiAdventurersDrawer={props.onOpenMultiAdventurersDrawer}
        isDisabled={props.isDisabled}
      />
    </div>
  );
};

MultiBirthday.propTypes = {
  characters: PropTypes.array,
  errors: PropTypes.object,
  values: PropTypes.object,
  form: PropTypes.object,
  drawer: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
          alt: PropTypes.string,
        }).isRequired,
      })
    ).isRequired,
  }),
  isDisabled: PropTypes.bool.isRequired,
  onOpenMultiAdventurersDrawer: PropTypes.func.isRequired,
  handleFormSubmission: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default MultiBirthday;
