import React from "react";
import PropTypes from "prop-types";
import Icon from "wonderbly-components/lib/Icon";
import Button from "wonderbly-components/lib/Button";
import cx from "classnames";
import PlaceholdImage from "../../../PlaceholdImage/PlaceholdImage";

import "./FormatOption.scss";

const PriceBadge = (props) => (
  <svg
    width="70px"
    height="70px"
    viewBox="0 0 70 70"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
  >
    <g
      id="Final-Creation-Flow-"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="canvas-preivew+format"
        transform="translate(-264.000000, -331.000000)"
        fill={props.alt ? "#FFCC37" : "#F27860"}
      >
        <g id="flash" transform="translate(264.000000, 331.000000)">
          <path
            d="M61.8314201,16.3136513 C59.6800716,13.4818272 59.4196452,8.74837189 56.4643718,6.68718601 C53.543067,4.64553743 48.7636764,5.85033803 45.2547138,4.76493208 C41.8578478,3.70231967 38.7565091,0 34.9961784,0 C31.2369799,0 28.134509,3.70123426 24.7489658,4.76493208 C21.2388709,5.85033803 16.4606127,4.64553743 13.5393079,6.68718601 C10.5840344,8.74837189 10.323608,13.4818272 8.1722595,16.3136513 C6.04355678,19.1259381 1.40117319,20.818086 0.268884508,24.1828444 C-0.840758399,27.4282081 1.84276578,31.4007939 1.84276578,35.005427 C1.84276578,38.6078894 -0.840758399,42.5707064 0.268884508,45.8160702 C1.40117319,49.1905973 6.04355678,50.884916 8.1722595,53.6852633 C10.323608,56.5181728 10.5840344,61.2603114 13.5393079,63.3236681 C15.6113961,64.767258 18.6006383,64.5827389 21.4200371,64.7336104 C22.5862944,64.7998201 23.7185831,64.9181294 24.7489658,65.2448366 C28.134509,66.2987657 31.2369799,70 34.9961784,70 C38.7565091,70 41.8578478,66.2987657 45.2547138,65.2448366 C46.2737736,64.9181294 47.4060623,64.7998201 48.5734519,64.7336104 C51.3928508,64.5827389 54.3934158,64.767258 56.4655041,63.3236681 C59.4207775,61.2613968 59.6812039,56.5181728 61.8314201,53.6852633 C63.9601228,50.884916 68.5923158,49.1905973 69.7347951,45.8160702 C70.8342474,42.5707064 68.1597816,38.6078894 68.1597816,35.005427 C68.1586493,31.4018793 70.8319828,27.4282081 69.7336628,24.1828444 C68.5900513,20.818086 63.9578583,19.1259381 61.8314201,16.3136513 Z"
            id="Fill-1"
          />
        </g>
      </g>
    </g>
  </svg>
);

const FormatOption = (props) => (
  <label
    htmlFor={`format-${props.prismicFormat.formatId}`}
    className={cx("format-option", "labelled-radio", {
      "format-option-selected": props.isSelected,
    })}
  >
    <input
      type="radio"
      name="format_identifier"
      id={`format-${props.prismicFormat.formatId}`}
      value={props.prismicFormat.formatId}
      onChange={props.onChange}
      checked={props.isSelected}
    />
    {props.prismicFormat.badge && (
      <div className="format-badge">
        <Icon icon="star" />
        {props.prismicFormat.badge}
      </div>
    )}
    <h2 className="format-option-name">{props.prismicFormat.title}</h2>
    <div className="format-option-content">
      <div className="format-option-image-wrap">
        {props.prismicFormat.image && (
          <PlaceholdImage
            image={props.prismicFormat.image}
            className="format-option-image"
            noLazyload
          />
        )}
        {props.variant && (
          <div
            className={cx("format-option-price", {
              "format-option-price--savings": props.variant.savings,
            })}
          >
            <PriceBadge
              className="format-option-price-bg"
              alt={!!props.variant.savings}
            />
            <span className="format-option-price-text">
              {props.variant.savings && (
                <span className="format-option-was-price-text">
                  {props.variant.savings.was_price.formatted}
                </span>
              )}
              {props.variant.price.formatted}
              {props.variant.price.currency && (
                <span className="format-option-currency-text">
                  {props.variant.price.currency}
                </span>
              )}
            </span>
          </div>
        )}
      </div>
      <div className="format-option-description">
        {props.prismicFormat.description}
      </div>
    </div>
    <div className="format-option-button-wrap">
      <Button type="button" textSize="small" onClick={props.onChange}>
        {props.prismicFormat.cta}
      </Button>
    </div>
  </label>
);

PriceBadge.propTypes = {
  className: PropTypes.string,
  alt: PropTypes.bool,
};

FormatOption.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  prismicFormat: PropTypes.shape({
    formatId: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    badge: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }),
  }).isRequired,
  variant: PropTypes.shape({
    price: PropTypes.shape({
      formatted: PropTypes.string.isRequired,
      currency: PropTypes.string,
    }),
    savings: PropTypes.shape({
      was_price: PropTypes.shape({
        formatted: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FormatOption;
