import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import SectionHeading from "src/client/js/view/components/SectionHeading";

import "./TrustpilotWidget.scss";

const TrustpilotComponent = (props) => {
  if (!props.isComponent) {
    return props.children;
  }
  return (
    <section
      className={cx("trustpilot", {
        "trustpilot-page-section": props.asPageSection,
      })}
      id={props.id}
    >
      <SectionHeading textAlign="left" richText={props.title} />
      <div className="container-guttered">{props.children}</div>
    </section>
  );
};

const TrustpilotWidget = (props) => (
  <TrustpilotComponent
    isComponent={props.isComponent}
    title={props.title}
    id={props.id}
    asPageSection={props.asPageSection}
  >
    <div
      ref={props.elmRef}
      className={cx("trustpilot-widget", {
        [props.className]: props.className,
        isFlush: props.isFlush,
        "trustpilot-widget-centered": props.asPageSection,
      })}
      {...props.template}
      data-businessunit-id="54495e1e00006400057b1b0d"
      style={props.style}
    >
      <a
        href={`https://${props.prefix}.trustpilot.com/review/wonderbly.com`}
        target="_blank"
      >
        Trustpilot
      </a>
    </div>
  </TrustpilotComponent>
);

TrustpilotWidget.propTypes = {
  title: PropTypes.array,
  className: PropTypes.string,
  template: PropTypes.object.isRequired,
  prefix: PropTypes.string.isRequired,
  style: PropTypes.object,
  isComponent: PropTypes.bool,
  id: PropTypes.string,
  isFlush: PropTypes.bool,
  elmRef: PropTypes.object,
  asPageSection: PropTypes.bool,
};

TrustpilotComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isComponent: PropTypes.bool.isRequired,
  title: PropTypes.array,
  id: PropTypes.string,
  asPageSection: PropTypes.bool,
};

export default TrustpilotWidget;
