import MultiAdventurer from "./MultiAdventurer";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withState from "recompose/withState";
import withHandlers from "recompose/withHandlers";
import get from "lodash/get";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import withQueryParams from "../../../hocs/withQueryParams";
import { getCustomisation } from "../Adventurer/helpers";
import withValidationData from "../../../hocs/withValidationData";
import {
  validateAdventurer,
  validateMultipleAdventurers,
  buildFormTitle,
  buildFormCta,
  mergeCharacters,
} from "./helpers";
import { connect } from "react-redux";
import {
  getActiveCanvasDrawer,
  updateActiveCanvasDrawer,
} from "../../CanvasDrawers/duck";

const enhance = compose(
  withQueryParams,
  withValidationData,
  connect(
    (state) => ({
      activeDrawer: getActiveCanvasDrawer(state),
    }),
    (dispatch) => ({
      handleToggleDrawer: (key) => dispatch(updateActiveCanvasDrawer(key)),
    })
  ),
  withProps((props) => {
    const fields = get(props, "form.body", []);
    const adventurerField =
      fields.find((field) => field.sliceType === "adventurer") || {};
    const multiAdventurerField =
      fields.find((field) => field.sliceType === "multi-adventurer") || {};
    const multiAdventurerLabels = multiAdventurerField.primary;
    return {
      ...multiAdventurerLabels,
      characterNumberLabels: multiAdventurerField.items,
      isInCanvasPage: !!props.drawer,
      heroes: adventurerField.items || [],
      maxCharacterLimit: parseInt(multiAdventurerLabels.maxCharacterLimit, 10),
      minCharacterLimit: parseInt(multiAdventurerLabels.minCharacterLimit, 10),
    };
  }),
  withState(
    "characters",
    "setCharacters",
    (props) => getCustomisation("characters", props) || []
  ),
  withState("currentFormIndex", "setCurrentFormIndex", (props) =>
    props.isInCanvasPage ? -1 : 0
  ),
  withState("isAdventurerEditable", "setAdventurerEditable", (props) =>
    props.isInCanvasPage ? props.currentFormIndex !== -1 : true
  ),
  withState("errors", "setErrors", null),
  withProps((props) => {
    const form = { ...props.form };
    const removableFields = ["locale", "multi-adventurer"];

    form.body = form.body.reduce((result, slice) => {
      if (!removableFields.includes(slice.sliceType)) {
        result.push(slice);
      }
      return result;
    }, new Array(0));

    form.title = buildFormTitle(props);
    form.cta = buildFormCta(props);

    return {
      form,
      isDisabled: !isEmpty(props.errors) || props.isSubmitting,
    };
  }),
  withHandlers({
    handleAdventurerEdit: (props) => (index) => () => {
      const isAddNewChild = index === props.characters.length;

      props.setCurrentFormIndex(index);
      props.setAdventurerEditable(true);

      if (isAddNewChild) {
        props.trackAddNewAdventurer();
      } else {
        props.trackCustomiseTab();
      }
    },
    handleAdventurerDelete: (props) => (index) => async () => {
      let characters = props.characters.slice();

      characters.splice(index, 1);

      props.setCharacters(characters);
      const errors = await validateMultipleAdventurers(characters, props);

      if (errors) {
        props.setErrors(errors);
        return;
      }

      // TODO: Move completed drawer map to redux instead of passing it down from the canvas component
      const drawersMap = props.completedDrawersMap;
      const hideDrawer = !!drawersMap.complete.find((drawerId) =>
        drawerId.includes("inscription")
      );
      props.onFormikSubmit({ characters }, !hideDrawer && props.activeDrawer);
    },
    handleAdventurerValidation: (props) => async (value, formProps) => {
      const characters = mergeCharacters(
        props.characters,
        value,
        props.currentFormIndex
      );
      return validateAdventurer(characters, { ...props, ...formProps });
    },
    handleAdventurerSubmission: (props) => (value, nextDrawer, resetForm) => {
      const characters = mergeCharacters(
        props.characters,
        value,
        props.currentFormIndex
      );
      const adventurerLimitIndex = props.maxCharacterLimit - 1;
      const currentFormIndex = props.currentFormIndex;
      const nextFormIndex = Math.min(
        currentFormIndex + 1,
        adventurerLimitIndex
      );

      props.setCharacters(characters, async () => {
        props.setCurrentFormIndex(nextFormIndex);
        if (props.isInCanvasPage) {
          props.setCurrentFormIndex(-1);
          props.setAdventurerEditable(false);

          const errors = await validateMultipleAdventurers(characters, props);
          if (errors) {
            props.setErrors(errors);
            return;
          }

          const drawersMap = props.completedDrawersMap;
          const someCharactersHaveDOBs = characters.some(
            (c) => c.day && c.month && c.year
          );
          const allCharactersHaveDOBs = characters.every(
            (c) => c.day && c.month && c.year
          );
          // If some characters but not all character have date of births then we will need to display
          // the birthday drawer to avoid the preview/add to cart from breaking
          const showDateOfBirthDrawer = someCharactersHaveDOBs
            ? !allCharactersHaveDOBs
            : false;
          const hideDrawer = !!drawersMap.complete.find((drawerId) =>
            drawerId.includes("inscription")
          );
          const nextDrawer = reduce(
            drawersMap,
            (result, value) => {
              // eslint-disable-next-line max-nested-callbacks
              const multiBirthdayDrawerId = value.find((drawerId) =>
                drawerId.includes("multi_birthday")
              );
              if (!showDateOfBirthDrawer) {
                return result;
              }
              return multiBirthdayDrawerId || result;
            },
            !hideDrawer && props.activeDrawer
          );

          props.onFormikSubmit({ characters }, nextDrawer);
        } else if (props.handleAdventurerSubmission) {
          props.handleAdventurerSubmission({ ...props, characters }, resetForm);
        }
      });
    },
    handleFormSubmission:
      ({ characters, ...props }) =>
      () => {
        props.handleToggleDrawer();
        props.trackSaveTab();
      },
  })
);

export default enhance(MultiAdventurer);
