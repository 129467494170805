import { replaceTextWithValues } from "../../../../../../common/utils/text-replace";
import { buildErrors, validateCreationForm } from "../helpers";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

/**
 * replaceMultiAdventurerValues replace template strings in text with relevant copy for multiple adventurers
 * @param {string} text
 * @param {Object} props
 * @returns {string}
 */
const replaceMultiAdventurerValues = (text, props) => {
  const values = {};
  const characterNumberLabels = props.characterNumberLabels.map((label) =>
    label.value.toLowerCase()
  );
  const currentIndex = props.currentFormIndex;
  const nextIndex = Math.min(currentIndex + 1, props.maxCharacterLimit - 1);

  values.current = characterNumberLabels[currentIndex];
  values.next = characterNumberLabels[nextIndex];

  return replaceTextWithValues(text, values);
};

/**
 * matchesGender check if the hero matches the current character's gender
 * @param {Object} hero content object from Prismic
 * @param {string} hero.gender
 * @param {Object} character a character from the user's customisation
 * @param {string} character.gender
 * @returns {boolean}
 */
const matchesGender = (hero, character) => {
  const gender = character.gender;
  const heroGender = hero.gender.toLowerCase();
  return gender === heroGender;
};

/**
 * matchesPhototype check if the hero matches the current character's phototype
 * @param {Object} hero content object from Prismic
 * @param {string} hero.phototype
 * @param {Object} character a character from the user's customisation
 * @param {string} character.phototype
 * @returns {boolean}
 */
const matchesPhototype = (hero, character) => {
  const phototype = character.phototype;
  const heroPhototype = hero.phototype.toLowerCase();
  return heroPhototype === "all" || phototype === heroPhototype;
};

/**
 * matchesHero check if the hero matches the current character
 * @param {Object} hero content object from Prismic
 * @param {Object} character a character from the user's customisation
 * @returns {boolean}
 */
export const matchesHero = (hero, character) =>
  matchesGender(hero, character) && matchesPhototype(hero, character);

/**
 * hasNotEnoughCharacters check if the errors include an error for when there isn't enough characters
 * @param {Object[]} errors a list of errors from validation the characters array from the customisations
 * @param {string} errors[].error a string that describes the error
 * @returns {{ base: Object }|{}}
 */
export const hasBaseErrors = (characterErrors) => {
  const errors = characterErrors.filter(
    (obj) => obj.index === undefined && obj.field !== "date_of_birth"
  );
  if (!errors || isEmpty(errors)) {
    return {};
  }
  return { base: errors.map((error) => error.message) };
};

/**
 * buildIndividualCharacterErrors build the characters error similar to how we get errors for a normal adventurer
 * @param {Object[]} characterErrors a list of errors from validation the characters array from the customisations
 * @returns {Object[]}
 */
export const buildIndividualCharacterErrors = (characterErrors) => {
  return characterErrors.reduce((result, { error, message, ...obj }) => {
    if (obj.index >= 0) {
      const index = obj.index;
      const fieldName = obj.field;
      const fieldError = { error, message };
      const item = result.find((resErr) => resErr.index === index);

      if (item) {
        const itemIndex = result.indexOf(item);
        const previousErrors = result[itemIndex][fieldName] || [];
        result[itemIndex] = {
          ...item,
          [fieldName]: [...previousErrors, fieldError],
        };
      } else {
        result.push({
          index,
          [fieldName]: [fieldError],
        });
      }
    }
    return result;
  }, []);
};

/**
 * buildFormTitle get the correct title label for the individual adventurer form
 * @param {Object} props the props from the component using this function
 * @returns {string}
 */
export const buildFormTitle = (props) => {
  const currentCharacterLength = props.characters.length;
  const currentFormIndex = props.currentFormIndex;
  const minCharacterLimit = props.minCharacterLimit - 1;
  let title = "";

  if (currentFormIndex < currentCharacterLength) {
    title = props.formEditTitle;
  } else {
    title = props.formAddTitle;
  }

  if (!props.isInCanvasPage && currentCharacterLength >= minCharacterLimit) {
    return replaceMultiAdventurerValues(title, {
      ...props,
      currentFormIndex: minCharacterLimit,
    });
  }
  return replaceMultiAdventurerValues(title, props);
};

/**
 * buildFormCta get the correct cta label for the individual adventurer form
 * @param {Object} props the props from the component using this function
 * @returns {string}
 */
export const buildFormCta = (props) => {
  const currentCharacterLength = props.characters.length;
  const minCharacterLimit = props.minCharacterLimit - 1;
  let cta = "";

  if (props.isInCanvasPage) {
    // If it is in the canvas page we can display the save current child button
    cta = props.formEditButton;
  } else if (currentCharacterLength >= minCharacterLimit) {
    // If it is in the product page and the amount of characters has reached the minimum limit then we
    // can display the go to preview page button
    cta = props.formPreviewButton;
  } else {
    // If it is in the product page and the amount of characters hasn't reached the minimum limit then we
    // can display the save and add next child button
    cta = props.formAddButton;
  }

  return replaceMultiAdventurerValues(cta, props);
};

/**
 * mergeCharacters take the newly edited or created character and merge it into the list of characters
 * @param {Object[]} currentCharacters
 * @param {Object} character
 * @param {number} index
 * @returns {Object[]} merged character list
 */
export const mergeCharacters = (currentCharacters, character, index) => {
  const characters = [...currentCharacters];
  characters[index] = character;

  return characters;
};

export const validateMultipleAdventurers = async (characters, props) => {
  const err = await validateCreationForm({ characters }, props, { raw: true });

  if (err) {
    const characterErrors = err.characters;

    let errors = {};

    if (characterErrors) {
      const baseErrors = hasBaseErrors(characterErrors);

      if (props.isInCanvasPage && baseErrors) {
        errors = {
          ...errors,
          ...baseErrors,
        };
      }
    }

    return errors;
  }
};

export const validateAdventurer = async (characters, props) => {
  const err = await validateCreationForm({ characters }, props, { raw: true });

  if (err) {
    const characterErrors = err.characters;

    let errors = {};

    if (characterErrors) {
      const individualCharacterErrors =
        buildIndividualCharacterErrors(characterErrors);
      if (individualCharacterErrors && !isEmpty(individualCharacterErrors)) {
        const characterError = individualCharacterErrors.find(
          (error) => error.index === props.currentFormIndex
        );
        const serializedError = buildErrors(
          omit(characterError, ["index", "date_of_birth"])
        );
        errors = {
          ...errors,
          ...serializedError,
        };
      }
    }

    return errors;
  }
};
