import compose from "recompose/compose";
import withProps from "recompose/withProps";
import mapProps from "recompose/mapProps";
import when from "recompose/branch";
import { withModel } from "../data-providers/model";

const withGlobalContent = compose(
  when(
    (props) => props.model,
    // If model exists in props then keep it and also add global content
    withProps(({ model }) => ({
      globalContent: model.prismicGlobalContentModel,
    })),
    // If model doesnt exist in props then add it but only keep global content
    compose(
      withModel,
      mapProps(({ model, ...props }) => ({
        ...props,
        globalContent: model.prismicGlobalContentModel,
      }))
    )
  )
);

export default withGlobalContent;
