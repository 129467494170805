import {
  localiseUrl,
  unlocaliseUrl,
} from "src/common/utils/format-localised-url";

const getCountryModel = (geoLocation, currentCountryModel) => {
  if (!geoLocation || !geoLocation.countryModel) {
    return {
      countryModel: null,
    };
  }

  if (geoLocation.countryModel.iso === currentCountryModel.iso) {
    return {
      countryModel: null,
    };
  }

  const baseUrl = document.location.href.replace(document.location.origin, "");
  const localisedUrl = localiseUrl(
    geoLocation.countryModel,
    unlocaliseUrl(currentCountryModel, baseUrl)
  );

  return {
    countryModel: geoLocation.countryModel,
    url: localisedUrl,
    alternateUrl: localiseUrl(geoLocation.countryModel, "/"),
  };
};

export default getCountryModel;
