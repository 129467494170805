import qs from "qs";
import { getAvailableProductLanguages } from "src/common/utils/product";
import { localiseUrl } from "src/common/utils/format-localised-url";

export const hasPhototypes = (list) =>
  list.filter((hero) => !!hero.phototype && hero.phototype !== "All").length >
  0;

export const isPhototypesVisible = (availablePhototypes, selectedGender) => {
  return availablePhototypes[selectedGender]
    ? availablePhototypes[selectedGender].length > 0
    : false;
};

export const transformLocaleOptions = (options, product) =>
  options
    .map((option) => ({
      label: option.optionTitle,
      value: option.optionValue,
    }))
    .filter((option) =>
      getAvailableProductLanguages(product).includes(option.value)
    );

export const getCustomisation = (field, props) =>
  props.productCustomisation[field] || props.queryParams[field] || "";

export const buildPreviewUrl = (slug, values, countryModel) => {
  const queryString = qs.stringify(values, { addQueryPrefix: true });
  const href = `/personalized-products/${slug}/preview${queryString}`;
  return localiseUrl(countryModel, href);
};

/**
 * getDefaultValue find the default value for a field
 * @param {string} fieldName
 * @param {Object} currentFormValues
 * @param {Object} props
 * @param {*} defaultValue
 * @returns {*}
 */
const getDefaultValue = (fieldName, currentFormValues, props, defaultValue) => {
  return (
    currentFormValues[fieldName] ||
    getCustomisation(fieldName, props) ||
    defaultValue
  );
};

/**
 * setValues set the values for a form
 * @param {Object[]} fields all the available fields set in Prismic
 * @param {Object} defaultValues default values for the form
 * @param {Object} props the props from the component using this function
 * @returns {Object}
 */
export const setValues = (fields, defaultValues, props) => {
  return fields.reduce((values, field) => {
    switch (field.sliceType) {
      case "locale":
        const defaultItem = field.items.find(
          (item) => item.isDefault === "true"
        );
        const defaultValue = defaultItem ? defaultItem.optionValue : "";

        values.locale = getDefaultValue("locale", values, props, defaultValue);

        break;
      case "adventurer":
        values.gender = getDefaultValue("gender", values, props, "");

        if (hasPhototypes(field.items)) {
          values.phototype = getDefaultValue("phototype", values, props, "");
        }
        if (
          typeof values.phototype === "undefined" &&
          props.productId === "lmn"
        ) {
          values.phototype = "type-iii";
        }
        break;
      case "custom_select":
        const firstItem = field.items[0];
        values.selection_value = getDefaultValue(
          "selection_value",
          values,
          props,
          firstItem.label
        );
        values.selection_state = getDefaultValue(
          "selection_state",
          values,
          props,
          firstItem.value
        );
        break;
      default:
        values[field.sliceType] = getDefaultValue(
          field.sliceType,
          values,
          props,
          ""
        );
        break;
    }

    // There always needs to be a locale, so this makes sure there is one even if locale is already a field.
    if (props.form.localeOverride) {
      values.locale = props.form.localeOverride;
    }

    values.base = ""; // needed for when form fails
    return values;
  }, defaultValues || {});
};
