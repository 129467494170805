import compose from "recompose/compose";
import lifecycle from "recompose/lifecycle";
import { connect } from "react-redux";
import { isNavOpen } from "../SiteNavigation/duck";
import {
  getAppScrollPos,
  getAppOverlayState,
} from "../../../store/selectors/app/index";
import Page from "./AppPage";

const enhance = compose(
  connect((state) => ({
    overlayActive: getAppOverlayState(state),
    currentScrollPos: getAppScrollPos(state),
    isNavOpen: isNavOpen(state),
  })),
  lifecycle({
    componentDidUpdate(oldProps) {
      if (typeof window !== "undefined") {
        if (
          oldProps.overlayActive &&
          oldProps.overlayActive !== this.props.overlayActive
        ) {
          window.scrollTo(0, oldProps.currentScrollPos);
        }
      }
    },
  })
);

export default enhance(Page);
