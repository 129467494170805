import React from "react";
import App from "./components/App";
import { Provider as ModelProvider } from "./data-providers/model";
import { Provider as ReduxProvider } from "react-redux";
import createStore from "../store/createStore";
import getPathToAsset from "../../../common/utils/get-path-to-asset";

const store = createStore();

const appFactory = (model) => {
  // HACK: Due to issues with the wonderbly-components bundling technique
  // context providers do not work.
  // we need a method to make the icon component aware of the correct asset path
  // ... and on a tight deadline
  // It needs to work on server and client side.
  // So an awful global constant is the answer for today. Sorry.
  global.ICON_ASSET_PATH = model.__headless
    ? "/assets/icons/iconsax"
    : getPathToAsset("/icons/iconsax");
  return (
    <ModelProvider value={model}>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </ModelProvider>
  );
};

export default appFactory;
