import React from "react";
import PropTypes from "prop-types";
import Links from "./Links";

const Category = ({ title, links }) => {
  return (
    <div className="Sitemap__Category">
      <h3 className="text-giga Sitemap__Category__title">{title}</h3>
      <Links items={links} />
    </div>
  );
};

Category.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
};

export default Category;
