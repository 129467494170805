import React from "react";
import PropTypes from "prop-types";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useCountry } from "src/client/js/view/hooks";
import "./FooterLinks.scss";

const FooterLinks = (props) => {
  const countryModel = useCountry();

  return (
    <nav className="LinksNav">
      {props.links.items.map((item, idx) => (
        <li key={idx}>
          <a
            // logout needs to proxy to eagle to end the user's session, this link isn't proxied when a locale is
            // provided which isn't legacy. We override the footer logout link to avoid localising the url
            // Also checks for localiseUrls prismic toggle so the countries links aren't localised
            href={
              item.linkUrl === "/logout" || item.unlocaliseUrl
                ? item.linkUrl
                : localiseUrlII(countryModel, item.linkUrl)
            }
            className="LinksNav__link"
          >
            <span className="LinksNav__link-text">{item.linkText}</span>
          </a>
        </li>
      ))}
    </nav>
  );
};

FooterLinks.propTypes = {
  links: PropTypes.object.isRequired,
};

export default FooterLinks;
