export const GET_GEO_LOCATION_PENDING = "GET_GEO_LOCATION_PENDING";
export const GET_GEO_LOCATION_SUCCESS = "GET_GEO_LOCATION_SUCCESS";
export const GET_GEO_LOCATION_FAILURE = "GET_GEO_LOCATION_FAILURE";

export const fetchGeoLocationPending = () => ({
  type: GET_GEO_LOCATION_PENDING,
});

export const fetchGeoLocationSuccess = (response) => ({
  type: GET_GEO_LOCATION_SUCCESS,
  response,
});

export const fetchGeoLocationFailure = (response) => ({
  type: GET_GEO_LOCATION_FAILURE,
  response,
});
