import compose from "recompose/compose";
import withProps from "recompose/withProps";
import { withFormik } from "formik";

import withValidationData from "../../../hocs/withValidationData";
import { updateFieldValues } from "../helpers";
import CustomExtraAdventurer from "./CustomExtraAdventurer";

// This component is used in sibling for you.  It provides the ability to add an optional name and a gender and phototype
const enhance = compose(
  withValidationData,
  withProps((props) => {
    return {
      genderButtons: [
        {
          gender: "boy",
          buttonText: props.drawer.boyButtonText,
          phototypes: props.drawer.items.filter((item) => item.group === "boy"),
        },
        {
          gender: "girl",
          buttonText: props.drawer.girlButtonText,
          phototypes: props.drawer.items.filter(
            (item) => item.group === "girl"
          ),
        },
        {
          gender: "neutral",
          buttonText: props.drawer.otherButtonText,
          phototypes: props.drawer.items.filter(
            (item) => item.group === "other"
          ),
        },
      ],
    };
  }),
  withFormik({
    mapPropsToValues: ({ productCustomisation }) => {
      const character =
        (productCustomisation.characters &&
          productCustomisation.characters[0]) ||
        {};

      return {
        characters: [character],
      };
    },
    handleSubmit: (fields, { props }) => {
      const values = updateFieldValues(fields, props.country);
      props.onFormikSubmit(values);
    },
  })
);

export default enhance(CustomExtraAdventurer);
