import React from "react";
import { useModel } from "../../hooks";

import Categories from "./Categories";

import "./SitemapPage.scss";

const SitemapPage = () => {
  const {
    prismicPageModel: { categories },
  } = useModel();
  return (
    <main className="wrapper" id="sitemap">
      <section className="Sitemap">
        <div className="container-guttered">
          <Categories items={categories} />
        </div>
      </section>
    </main>
  );
};

export default SitemapPage;
