const { request } = require("../../../rest/index");
const { getSessionHeaders } = require("../../eagle/helpers/session-headers");

const jsonHeaders = {
  "content-type": "application/json",
};

const jsonSessionHeaders = (cookies) => ({
  ...jsonHeaders,
  ...getSessionHeaders(cookies),
});

const getPaymentMethods = (cookies) =>
  request.get("/website-api/v1/payments/methods", null, {
    headers: jsonSessionHeaders(cookies),
  });

module.exports = {
  getPaymentMethods,
};
