/* eslint-disable no-console */
import bugsnag from "@bugsnag/js";
import get from "lodash/get";

import math from "src/common/math";

const bugsnagApiKey = get(window, "model.head.bugsnagClientApiKey");

const bugsnagClient = bugsnagApiKey ? bugsnag(bugsnagApiKey) : null;

const performance = window.performance || {
  now() {
    return Date.now();
  },
};

// The export style is a CommonJS one
// Do not change this, because this module
// might be used by both common code and
// client code.
// This type of export supports both CommonJS
// imports and ES6 Modules imports.
module.exports = {
  type: () => {}, //console.info('logger type: client'),
  info: (...args) => console.info(...args),
  warn: (...args) => console.warn(...args),
  error: (error, info) => {
    if (bugsnagClient) {
      bugsnagClient.notify(error, { metaData: info });
    }
    console.error(error.stack || error);
  },
  time: () => {
    const time = performance.now();
    return {
      done(options = {}) {
        console.log({
          level: "info",
          ...options,
          duration: math.toFixed(performance.now() - time, 2),
        });
      },
    };
  },
  init: () => "",
};
