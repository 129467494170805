import Adventurer from "./Adventurer";
import { connect } from "react-redux";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import withValidationData from "../../../hocs/withValidationData";
import { withFormik } from "formik";
import { setValues, buildPreviewUrl } from "./helpers";
import { withRouter } from "react-router-dom";
import {
  updateFieldValues,
  validateCreationForm,
  createOnFieldChangeHandler,
} from "../helpers";
import withMediaQueries from "../../../hocs/withMediaQueries";
import { withModel } from "../../../data-providers/model";
import product from "src/common/config/product";
import { getPrismicProductByLocale } from "../../../../store/selectors/prismic/product";
import withQueryParams from "../../../hocs/withQueryParams";
import logger from "src/logger";

const enhance = compose(
  withQueryParams,
  withMediaQueries,
  withValidationData,
  withRouter,
  withModel,
  withFormik({
    mapPropsToValues: (props) =>
      omitBy(setValues(props.form.body, props.values, props), isUndefined),
    validate: (values, props) => {
      if (props.onValidateForm) {
        return props.onValidateForm(values, props);
      }
      return validateCreationForm(values, props);
    },
    validateOnChange: false,
    handleSubmit: (fields, { props, resetForm, setFieldError }) => {
      const values = updateFieldValues(fields, props.country);

      props.setSubmitting(true);

      if (!!props.onFormikSubmit) {
        // If this is not undefined or not null then this comes from 1 of two places depending on what is rendered
        // 1. If the multi adventurer is rendered then this function is actually coming from
        //    src/client/js/view/components/CreationForm/MultiAdventurer/index.js#handleAdventurerSubmission
        // 2. if the adventurer is rendered then this function is actually coming from
        //    src/client/js/view/components/CreationForm/index.js#handleFormikSubmit
        props.onFormikSubmit(
          values,
          null,
          () =>
            resetForm({
              values: omitBy(
                setValues(props.form.body, props.values, props),
                isUndefined
              ),
            }),
          setFieldError
        );
      } else {
        const eagleProduct =
          props.model.eagleProduct ||
          props.model.eagleModel?.products?.find(
            ({ id }) => id === props.productId
          );
        const slug =
          eagleProduct?.slug || product.getProductBySku(props.productId).slug;

        if (!slug) {
          logger.error(
            "Failed to redirect user to canvas page as slug was missing"
          );
          setFieldError("base", props.getErrorMessage());
          return;
        }

        window.top.location.href = buildPreviewUrl(
          slug,
          values,
          props.model.countryModel
        );
      }
    },
  }),
  connect((state, props) => ({
    prismicProduct: getPrismicProductByLocale(
      state,
      props.productId,
      props.values.locale
    ),
  })),
  withProps((props) => {
    if (!props.prismicProduct.form) {
      return {};
    }
    return {
      form: {
        ...props.form,
        body: props.form.body
          .filter((item) => item.sliceType !== "custom_select")
          .map((form) => {
            if (form.sliceType === "adventurer") {
              const adventurerSlice = props.prismicProduct.form.body.find(
                (item) => item.sliceType === "adventurer"
              );
              form.items = adventurerSlice ? adventurerSlice.items : form.items;
            }
            return form;
          }),
      },
    };
  }),
  withHandlers(() => {
    const handleChange = createOnFieldChangeHandler();
    return {
      handleChange,
    };
  })
);

export default enhance(Adventurer);
