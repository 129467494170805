import BirthdayFormRow from "./BirthdayFormRow";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import withHandlers from "recompose/withHandlers";
import { getRange } from "../CreationForm/Birthday/helpers";

const enhance = compose(
  withHandlers({
    onHandleChange: (props) => (event) => {
      props.onHandleChange(event);
    },
  }),
  mapProps(
    ({
      formId,
      fieldOrder,
      labels,
      options,
      values,
      drawer,
      onHandleChange,
    }) => {
      const hasRequiredProps = fieldOrder && labels && options;
      // If the required props aren't passed and there is a drawer prop, then start creating the
      // the required props using the data in the drawer
      if (drawer && !hasRequiredProps) {
        const months = drawer.months.split(",").map((month, index) => ({
          value: index + 1,
          label: month.trim(),
        }));

        fieldOrder = drawer.fieldOrder.split("-");
        labels = fieldOrder.reduce((result, field) => {
          result[field] = drawer[`${field}Label`];
          return result;
        }, {});

        options = {
          days: [
            {
              value: "n/a",
              label: drawer.selectDayText,
            },
            ...getRange(drawer.days),
          ],
          months: [
            {
              value: "n/a",
              label: drawer.selectMonthText,
            },
            ...months,
          ],
          years: [
            {
              value: "n/a",
              label: drawer.selectYearText,
            },
            ...getRange(drawer.years),
          ],
        };
      }

      return {
        formId,
        fieldOrder,
        labels,
        values,
        options,
        onHandleChange,
      };
    }
  )
);

export default enhance(BirthdayFormRow);
