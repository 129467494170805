// CountryPrompt is currently not used and it's been disabled by default and on purpose

import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import getCountryModel from "./helpers";
import { checkIfPageExists } from "../../../facades/website";
import { optimiseImage } from "src/common/utils/prismic-image";
import Loading from "../Loading";
import RichText from "../Prismic/RichText";
import cookieFactory from "../../../cookieFactory";
import { replaceTextWithValues } from "src/common/utils/text-replace";
import * as trackCountryPrompt from "../../../analytics/category/countryPrompt";

import "./CountryPrompt.scss";

const isContentInvalid = (prismicContent) => {
  return (
    !prismicContent ||
    !isArray(prismicContent.changeCountryPromptMessage) ||
    isEmpty(prismicContent.changeCountryPromptMessage.filter((item) => !!item))
  );
};

class CountryPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryModel: null,
      url: null,
      alternateUrl: null,
      isNavigating: false,
    };
    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.geoIpCookie = cookieFactory("geo_ip");
  }

  wasSelectedInSession() {
    return !this.props.allowCookies || this.geoIpCookie.get() === "true";
  }

  setSelectedInSession() {
    this.geoIpCookie.set(true);
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (
      prevProps.geoLocation !== props.geoLocation &&
      props.geoLocation.response.ok
    ) {
      const state = getCountryModel(props.geoLocation, prevProps.countryModel);
      this.setState(() => state); // eslint-disable-line react/no-did-update-set-state
    }
  }

  handleClose(event) {
    event.stopPropagation();
    event.preventDefault();

    trackCountryPrompt.hide(this.props.countryModel, this.state.countryModel);

    this.setSelectedInSession();
    this.setState(() => ({
      countryModel: null,
    }));
  }

  handleNavigate(event) {
    event.preventDefault();
    this.setState(() => ({
      isNavigating: true,
    }));

    trackCountryPrompt
      .navigate(this.props.countryModel, this.state.countryModel)
      .then(() =>
        checkIfPageExists(this.state.url).then((pageExists) => {
          this.setSelectedInSession();
          if (pageExists) {
            window.top.location = this.state.url;
          } else {
            window.top.location = this.state.alternateUrl;
          }
        })
      );
  }

  render() {
    if (
      this.props.disabled ||
      !this.state.countryModel ||
      this.wasSelectedInSession()
    ) {
      return null;
    }

    let prismicContent =
      this.props.countries.find(
        (country) =>
          country.iso === this.state.countryModel.iso &&
          this.state.countryModel.contentLocales.includes(
            country.localeCode.toLowerCase()
          )
      ) ||
      this.props.countries.find(
        (country) => country.iso === "US" && country.locale === "en-US"
      ) ||
      null;

    if (isContentInvalid(prismicContent)) {
      return null;
    }

    const width = 22;
    const imageOptions = {
      w: width,
      dpr: window.devicePixelRatio || 1,
    };

    const description = prismicContent.changeCountryPromptMessage.map(
      (item) => {
        const flagUrl = get(prismicContent, "flag.url", null);
        let flag = "";
        if (flagUrl) {
          flag = replaceTextWithValues(
            '<img class="%{className}" src="%{src}" width="%{width}" alt="%{alt}" />',
            {
              className: "country-prompt-flag",
              src: optimiseImage(flagUrl, imageOptions),
              width,
              alt: this.state.countryModel.iso,
            }
          );
        }

        const text = replaceTextWithValues(item.text, {
          flag,
          name: prismicContent.name,
        });

        return {
          ...item,
          text,
        };
      }
    );

    trackCountryPrompt.show(this.props.countryModel, this.state.countryModel);

    return (
      <div className="country-prompt">
        {this.state.isNavigating ? (
          <Loading relative small className="country-prompt-loading" />
        ) : (
          <React.Fragment>
            <a
              href={this.state.url}
              onClick={this.handleNavigate}
              className="country-prompt-link"
            >
              <RichText className="country-prompt-text" fields={description} />
            </a>
            <span className="country-prompt-spacer">|</span>
            <button className="country-prompt-close" onClick={this.handleClose}>
              {prismicContent.closeCountryPromptMessage}
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}

CountryPrompt.propTypes = {
  countryModel: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  allowCookies: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  geoLocation: PropTypes.shape({
    response: PropTypes.shape({
      ok: PropTypes.bool,
      status: PropTypes.number,
    }),
  }),
};

export default CountryPrompt;
