import cookie from "js-cookie";
import logger from "src/logger";

const defaultOptions = {
  path: "/",
};

const create = (cookieName, options = defaultOptions, defaultValue) => {
  return {
    get: () => cookie.get(cookieName),
    set: (value = defaultValue) => {
      try {
        cookie.set(cookieName, value, options);
      } catch (error) {
        logger.warn(`cannot set ${cookieName}`);
      }
    },
    remove: () => {
      try {
        cookie.remove(cookieName, options);
      } catch (error) {
        logger.warn(`cannot remove ${cookieName}`);
      }
    },
  };
};

export default create;
