// This component allows a user to select an option from a drop down.  If the value is OTHER_VALUE in the dropdown
// then show an input box where the user can input their custom entry.  Having two different input values means that
// it adds complexity to the component and we've had to add a temporary value to save what is entered in the input box
// so if the user types somehting, switches out and then back we still have it.  In addition having two inputs means we
// also need to force update the fields used by formik as you can't just rely on the input being updated.
//
// We need to send two values to the server - selection_state (non localised value of the dropdown) and the selection_value
// (the value is the localised label or the custom input by the user.)  In daycare for you only selection_value is used but
// other books the template might change dependings on what's in the value. (e.g. X loves Y books).
//
// These helpers use the same functionality between the adventurer drawer and the preview canvas page
export const OTHER_VALUE = "other";

export const onChangeSelect = (props, items) => {
  return (event) => {
    const value = event.target.value;
    const eventValue = items.find((option) => option.value === value).label;
    props.setSelectionState(value);
    props.setFieldValue("selection_state", value);

    if (value === OTHER_VALUE) {
      props.setSelectionValue(props.tempValue);
      props.setFieldValue("selection_value", props.tempValue);
    } else {
      props.setSelectionValue(eventValue);
      props.setFieldValue("selection_value", eventValue);
    }
  };
};

export const onChangeInput = (props, event) => {
  const value = event.target.value;
  props.setTempValue(value);
  props.setSelectionValue(value);
  props.setFieldValue("selection_value", value);
  props.setSelectionState(OTHER_VALUE);
  props.setFieldValue("selection_state", OTHER_VALUE);
};
