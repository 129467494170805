import React from "react";
import PropTypes from "prop-types";
import Button from "wonderbly-components/lib/Button";
import MultiBirthdayFormEmpty from "./MultiBirthdayFormEmpty";
import MultiBirthdayFormFields from "./MultiBirthdayFormFields";

const MultiBirthdayForm = (props) => {
  const isCharactersEmpty = props.characters.length === 0;
  const drawer = props.drawer;
  return (
    <div className="multi-birthday__form-container">
      {isCharactersEmpty ? (
        <MultiBirthdayFormEmpty
          content={drawer.emptyAdventurersContent}
          cta={drawer.emptyAdventurersCta}
          onOpenMultiAdventurersDrawer={props.onOpenMultiAdventurersDrawer}
        />
      ) : (
        <MultiBirthdayFormFields
          form={props.form}
          drawer={drawer}
          characters={props.characters}
          errors={props.errors}
          values={props.values}
          onFieldChange={props.onFieldChange}
        />
      )}
      <div className="multi-birthday__form-footer">
        <Button
          type="submit"
          className="multi-birthday__form-submit"
          onClick={props.onFormSubmission}
          disabled={props.isDisabled}
        >
          {drawer.cta}
        </Button>
        <p>{drawer.ctaHelperMessage}</p>
      </div>
    </div>
  );
};

MultiBirthdayForm.propTypes = {
  characters: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object,
  form: PropTypes.object.isRequired,
  drawer: PropTypes.shape({
    cta: PropTypes.string.isRequired,
    ctaHelperMessage: PropTypes.string.isRequired,
    emptyAdventurersCta: PropTypes.string.isRequired,
    emptyAdventurersContent: PropTypes.array.isRequired,
  }).isRequired,
  onFormSubmission: PropTypes.func.isRequired,
  onOpenMultiAdventurersDrawer: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default MultiBirthdayForm;
