import React from "react";
import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import withProps from "recompose/withProps";
import lifecycle from "recompose/lifecycle";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import withCountry from "../../hocs/withCountry";
import TrustpilotWidget from "./TrustpilotWidget";
import formatPrismicData from "../../hocs/formatPrismicData";

const getTemplate = (key, productId = null) => {
  key = key.trim().toLowerCase();
  const templates = {
    mini: {
      "data-template-id": "53aa8807dec7e10d38f59f32",
      "data-style-height": "115px",
      "data-theme": "light",
    },
    micro: {
      "data-template-id": "5419b637fa0340045cd0c936",
      "data-style-width": "250px",
      "data-style-height": "24px",
    },
    microstar: {
      "data-template-id": "5419b732fbfb950b10de65e5",
      "data-style-height": "24px",
    },
    carousel: {
      "data-template-id": "53aa8912dec7e10d38f59f36",
      "data-style-height": "130px",
      "data-stars": "5,4",
    },
    slider: {
      "data-template-id": "54ad5defc6454f065c28af8b",
      "data-style-height": "215px",
      "data-stars": "5,4",
    },
    productmini: {
      "data-template-id": "577258fb31f02306e4e3aaf9",
      "data-style-width": "250px",
      "data-style-height": "24px",
      "data-scroll-to-list": false,
      "data-sku": productId,
    },
    productreviews: {
      "data-template-id": "5763bccae0a06d08e809ecbb",
      "data-style-width": "100%",
      "data-style-height": "700px",
      "data-sku": productId,
    },
    nonseoproductreviews: {
      "data-template-id": "57177697fdb1180308e3815f",
      "data-style-width": "100%",
      "data-style-height": "700px",
      "data-sku": productId,
    },
  };

  return templates[key] || { "data-template-id": key };
};

const buildExtras = (extras) => {
  if (isNil(extras) || isEmpty(extras)) {
    return {};
  }

  return extras.reduce((result, item) => {
    const { key, value } = item;
    result[`data-${key}`] = value;

    return result;
  }, {});
};

const enhance = compose(
  withCountry,
  formatPrismicData,
  defaultProps({
    template: {
      "data-theme": "light",
      "data-style-width": "100%",
      "data-text-color": "#00294C",
    },
    isComponent: true,
  }),
  withProps((props) => {
    const localeRegex = /-[a-z]{2}$/;
    const locale =
      props.country.contentLocales[1] || props.country.contentLocales[0];
    const localeMatch = locale.match(localeRegex)[0] || "";
    const prefix = !!props.country.prefix ? props.country.prefix : "www";
    const productId = props.productId || "";
    const template = {
      ...props.template,
      ...getTemplate(props.templateId, productId),
    };
    return {
      template: {
        ...template,
        ...buildExtras(props.items),
        "data-theme": props.theme || template["data-theme"],
        ...(props.theme === "dark" ? { "data-text-color": "#FFFFFF" } : {}),
        "data-style-width": props.width || template["data-style-width"],
        "data-style-height": props.height || template["data-style-height"],
        "data-locale": locale.replace(localeMatch, localeMatch.toUpperCase()),
      },
      prefix,
      elmRef: React.createRef(),
    };
  }),
  lifecycle({
    componentDidMount() {
      // I'm so sorry.
      // Titles must be unique for accessibility rating
      // this is because TP doesn't create unique titles
      // nor do they allow setting via a data-title attr.
      // Yotam told me to.
      const elm = this.props.elmRef.current;
      const widgets = document.querySelectorAll(".trustpilot-widget");
      const num = Array.from(widgets).findIndex((n) => n === elm);
      // poll for iframe creation
      const interval = setInterval(() => {
        const iframe = elm.querySelector("iframe");
        if (iframe) {
          iframe.setAttribute(
            "title",
            `${iframe.getAttribute("title")} ${num + 1}`
          );
          // cancel the polling
          clearInterval(interval);
        }
      }, 200);

      const scriptId = "trustpilot-widget-script";
      let script = document.getElementById(scriptId);
      if (!script) {
        script = document.createElement("script");
        script.id = scriptId;
        script.src =
          "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
        script.async = true;
        document.head.appendChild(script);
      }
    },
  })
);

export default enhance(TrustpilotWidget);
