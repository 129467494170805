import { localiseUrl } from "src/common/utils/format-localised-url";
import logger from "src/logger";
import cookieFactory from "../../cookieFactory";

export default (country) => {
  const restrictedUserId = cookieFactory("restricted_user_id").get();
  const eagleSession = cookieFactory("_eagle_session").get();

  const missingRestrictedUserId = typeof restrictedUserId === "undefined";
  const missingEagleSession = typeof eagleSession === "undefined";
  const missingSomething =
    missingRestrictedUserId || missingRestrictedUserId || missingEagleSession;

  // Remove cookies
  cookieFactory("restricted_user_id").remove();
  cookieFactory("restricted_user_id").remove();

  // Redirect to login page
  const localisedLogin = localiseUrl(country, "/login");
  const currentPath = window.location.pathname;
  const currentSearch = window.location.search;
  const currentUrl = `${currentPath}${currentSearch}`;
  logger.info(
    `Auth redirect: ${
      missingSomething
        ? `${
            (missingRestrictedUserId && "restricted_user_id") ||
            (missingEagleSession && "_eagle_session")
          } cookie missing`
        : "restricted_user_id cookie invalid"
    }`,
    { path: currentPath }
  );
  window.location = `${localisedLogin}?return_to=${currentUrl}`;
};
