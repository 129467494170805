import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { useMediaQueries } from "../../../../hooks";
import { scrollToElement } from "../../../../utils/scrollTo";

import Tooltip from "wonderbly-components/lib/Tooltip";
import Item from "./Item";

import "./List.scss";

const List = ({
  placeholder,
  nextCharacterButton,
  characters,
  items,
  baseError,
  characterErrors,
  isOrderFixed,
  minRequired,
  updateCharacterValue,
  deleteCharacter,
}) => {
  const mobile = useMediaQueries("mobile");
  const ref = useRef();

  useEffect(() => {
    if (!isEmpty(characterErrors) && ref.current) {
      const firstErrorIdx = characterErrors.findIndex((error) => !!error);
      const firstErrorItem = document.getElementsByClassName(
        "Characters__List__Item"
      )[firstErrorIdx];
      scrollToElement(ref.current, firstErrorItem, {
        yOffset: 16,
        xOffset: 16,
        scrollIfInView: false,
      });
    }
  }, [characterErrors]);

  return (
    <Fragment>
      <Tooltip
        className="Characters__List__Error"
        visible={baseError}
        absolute={false}
        type="alert"
      >
        {baseError}
      </Tooltip>
      <main className="Characters__List" ref={ref}>
        <div className="Characters__List__Wrapper">
          {characters.map((character, idx) => {
            const item = items.find((item) => item.type === character.type);
            const displayDeleteButton = isOrderFixed
              ? idx >= minRequired
              : true;
            const isLastItem = idx === characters.length - 1;
            const tabIdx = mobile ? 0 : characters.length - idx;
            const error = get(characterErrors, idx);
            return (
              <Item
                key={idx}
                {...item}
                {...character}
                error={error}
                placeholder={placeholder}
                tabIndex={tabIdx}
                displayDeleteButton={displayDeleteButton}
                isDeleteDisabled={isOrderFixed && !isLastItem}
                handleOnDelete={deleteCharacter(idx)}
                handleOnChange={updateCharacterValue(idx)}
              />
            );
          })}
          {nextCharacterButton.isVisible && (
            <div className="Characters__List__Item__Wrapper">
              <div
                className="Characters__List__Item Characters__List__Item--add-new"
                onClick={nextCharacterButton.handleOnClick}
              >
                <div className="icon" />
                {nextCharacterButton.label}
              </div>
            </div>
          )}
        </div>
      </main>
    </Fragment>
  );
};

List.propTypes = {
  placeholder: PropTypes.string.isRequired,
  nextCharacterButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleOnClick: PropTypes.func.isRequired,
  }).isRequired,
  characters: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  baseError: PropTypes.string,
  characterErrors: PropTypes.arrayOf(PropTypes.object),
  isOrderFixed: PropTypes.bool.isRequired,
  minRequired: PropTypes.number.isRequired,
  updateCharacterValue: PropTypes.func.isRequired,
  deleteCharacter: PropTypes.func.isRequired,
};

export default List;
