import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";

const useReduxFetch = (fn, { args = [], selector = (state) => state }) => {
  const dispatch = useDispatch();
  const callbackFn = useCallback(
    (...args) => fn(...args)(dispatch),
    [dispatch]
  );

  useEffect(() => {
    callbackFn(...args);
  }, []);

  return useSelector(selector);
};

export default useReduxFetch;
