import {
  GET_GEO_LOCATION_PENDING,
  GET_GEO_LOCATION_SUCCESS,
  GET_GEO_LOCATION_FAILURE,
} from "../../actions/geoLocation";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_GEO_LOCATION_PENDING:
      return {
        ...state,
        response: {
          pending: true,
        },
      };
    case GET_GEO_LOCATION_SUCCESS:
      return {
        ...state,
        response: {
          ok: true,
          status: 200,
        },
        ...action.response.body,
      };
    case GET_GEO_LOCATION_FAILURE:
      return {
        ...state,
        response: action.response,
      };
    default:
      return state;
  }
};
