import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useQueryParams } from "../../../../hooks";
import { useCountry } from "src/client/js/view/hooks";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import Button from "wonderbly-components/lib/Button";

const CancelCta = (props) => {
  const country = useCountry();
  const { buildQueryParams } = useQueryParams();
  const { itemId, orderNumber } = useParams();
  const search = buildQueryParams({
    itemId,
    orderNumber,
    openCreationFlow: true,
  });

  return (
    <div className="MultiAdventurerII__header">
      <Button
        className="MultiAdventurerII__cta"
        href={localiseUrlII(country, `${props.href}${search}`)}
      >
        {props.children}
      </Button>
    </div>
  );
};

CancelCta.propTypes = {
  href: PropTypes.string,
  children: PropTypes.any,
};

export default CancelCta;
