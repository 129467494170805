import React from "react";
import PropTypes from "prop-types";
import { localiseUrlII } from "src/common/utils/format-localised-url";
import { useCountry } from "src/client/js/view/hooks";

import "./FooterSub.scss";

const FooterSubItem = ({ companyLinkUrl, companyLinkText }) => (
  <li>
    <FooterSubItem.Tag companyLinkUrl={companyLinkUrl}>
      {companyLinkText}
    </FooterSubItem.Tag>
  </li>
);

FooterSubItem.Tag = ({ companyLinkUrl, children }) => {
  const countryModel = useCountry();
  if (companyLinkUrl) {
    return <a href={localiseUrlII(countryModel, companyLinkUrl)}>{children}</a>;
  }
  return children;
};

const FooterSub = (props) => (
  <div className="FooterSub">
    <div className="container-guttered">
      <nav className="FooterSub__sub-links">
        <ul>
          {props.companyLinks.map((link, idx) => (
            <FooterSubItem key={idx} {...link} />
          ))}
        </ul>
      </nav>
    </div>
  </div>
);

FooterSubItem.Tag.propTypes = {
  companyLinkUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FooterSubItem.propTypes = {
  companyLinkUrl: PropTypes.string,
  companyLinkText: PropTypes.string.isRequired,
};

FooterSub.propTypes = {
  companyLinks: PropTypes.arrayOf(PropTypes.shape(FooterSubItem.propTypes))
    .isRequired,
};

export default FooterSub;
