import Age from "./Age";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";

import { buildAgeOptions } from "./helpers";

const enhance = compose(
  withProps((props) => ({
    options: buildAgeOptions(props.range, props.noAge),
  })),
  withHandlers({
    onHandleChange: (props) => (event) => {
      props.onHandleChange(event);
    },
  })
);

export default enhance(Age);
