import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

export const SiteNavContext = React.createContext();
export const useSiteNavContext = () => useContext(SiteNavContext);

export const SiteNavContextProvider = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [subMenuIndex, setSubMenuIndex] = useState(false);
  const [isChooseCountryModalOpen, setIsChooseCountryModalOpen] =
    useState(false);

  return (
    <SiteNavContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen,
        subMenuIndex,
        setSubMenuIndex,
        isChooseCountryModalOpen,
        setIsChooseCountryModalOpen,
      }}
    >
      {props.children}
    </SiteNavContext.Provider>
  );
};

SiteNavContextProvider.propTypes = {
  children: PropTypes.any,
};
