const qs = require("qs");
const isEmpty = require("lodash/isEmpty");

const DocumentUID = require("../models/prismic/base/DocumentUID");
const { localiseUrlII } = require("./format-localised-url");
const { replaceTextWithValues } = require("./text-replace");

/**
 * getUrlByUid - Find the correct path by Prismic UID
 * @param {String} uid - A Prismic UID
 * @param {Object} [options]
 * @param {Boolean} [options.isUrlLocalised] - Localise the return url
 * @param {Object} [options.queryParams] - an object of query params
 * @param {Object} [options.urlParams] - an object of url params
 * @param {CountryModel} [options.country] - the current country model
 * @returns {String} Return url either localised or not.
 */
const getUrlByUid = (uid = "", options) => {
  const document = new DocumentUID(uid);
  const pageType = document.getPageType();
  const slug = document.getSlug();

  const defaultOptions = {
    isUrlLocalised: true,
    queryParams: {},
    urlParams: {},
    country: document.getCountry(),
  };

  const { queryParams, urlParams, isUrlLocalised, country } = {
    ...defaultOptions,
    ...options,
  };

  let path = "/";

  switch (pageType) {
    case "shipping-details-variant":
    case "shipping-details":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/checkout-v2/address";
      break;
    case "shipping-methods-variant":
    case "shipping-methods":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/checkout-v2/delivery";
      break;
    case "payment-methods-variant":
    case "payment-methods":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/checkout-v2/payment";
      break;
    case "product-variant":
      path = `/landing-pages/personalized-products/product/${slug}`;
      break;
    case "range-variant":
      path = `/landing-pages/personalized-products/${slug}`;
      break;
    case "homepage-variant":
      path = `/landing-pages/${slug}`;
      break;
    case "options":
      path = `/personalized-products/${slug}/options`;
      break;
    case "canvas":
      path = `/personalized-products/${slug}/preview`;
      break;
    case "product":
      path = `/personalized-products/${slug}`;
      break;
    case "product-download":
      path = `/personalized-downloads/${slug}`;
      break;
    case "product-range":
      path = `/personalized-products`;
      break;
    case "choose-your-country":
      path = `/choose-your-country`;
      break;
    case "login":
      path = "/login";
      break;
    case "search-links":
      path = "/search-links";
      break;
    case "signup-existing":
      path = "/signup/existing";
      break;
    case "signup":
      path = "/signup";
      break;
    case "account-profile":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/account/profile";
      break;
    case "account-offers":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/account/offers";
      break;
    case "account-credit":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/account/credit";
      break;
    case "account-dashboard":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/account";
      break;
    case "orders":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/account/orders";
      break;
    case "order-details":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = "/account/orders/%{orderNumber}";
      break;
    case "pages":
      path = `/pages/${slug}`;
      break;
    case "offers-variant":
      path = `/offers/${slug}`;
      break;
    case "offers":
      path = "/offers";
      break;
    case "cart":
    case "cart-variant":
      if (slug) {
        queryParams.contentVariant = slug;
      }
      path = `/cart`;
      break;
    case "forgot-password":
      path = `/forgot-password`;
      break;
    case "confirm":
      path = `/confirm`;
      break;
    case "sitemap":
      path = "/sitemap";
      break;
    default:
      if (slug) {
        path = `/personalized-products/${slug}`;
      }
  }

  if (!isEmpty(urlParams)) {
    path = replaceTextWithValues(path, urlParams);
  }

  if (!isEmpty(queryParams)) {
    const query = qs.stringify(queryParams, { addQueryPrefix: true });
    path = `${path}${query}`;
  }

  // Return the correct path but localised to the locale set in the UID
  if (isUrlLocalised && country) {
    return localiseUrlII(country, path);
  }

  return path;
};

module.exports = getUrlByUid;
