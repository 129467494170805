import lifecycle from "recompose/lifecycle";

const runOptimizelyHook = () => {
  if (window.optimizelyHook && typeof window.optimizelyHook === "function") {
    window.optimizelyHook();
  }
};

const withOptimizelyEdit = lifecycle({
  componentDidMount() {
    runOptimizelyHook();
  },
  componentDidUpdate() {
    runOptimizelyHook();
  },
});

export default withOptimizelyEdit;
