import React from "react";
import PropTypes from "prop-types";
import FlashMessages from "../FlashMessages";
import SiteLoader from "../SiteLoader";
import CheckoutHeader from "../MainCheckout/CheckoutHeader";
import "./CheckoutPage.scss";

const CheckoutPage = ({ showNavigation, ...props }) => (
  <div className="app-checkout">
    <CheckoutHeader showNavigation={showNavigation} />
    <FlashMessages {...props} />
    {props.children}
    <SiteLoader />
    <div id="modal-container" />
  </div>
);

CheckoutPage.propTypes = {
  children: PropTypes.node,
  showNavigation: PropTypes.bool,
};

export default CheckoutPage;
