/**
 * @fileOverview country dictionary
 * @link https://en.wikipedia.org/wiki/ISO_3166-2
 */

/**
 * A country model
 * @typedef {Object} CountryModel
 * @property {string} prefix
 * @property {string} iso
 * @property {string} locale is the Eagle locale
 * @property {string} wordpressLocale
 * @property {Array.<string>} contentLocales
 * @property {string} currency
 * @property {string} name
 * @property {boolean} isDefault when the country code is used for multiple locales in the same country
 */

const CountryModel = require("../models/l10n/CountryModel");
const logger = require("../../logger");

// NEVER CHANGE THIS LIST!
const LEGACY_LOCALES = [
  "en-GB",
  "en-US",
  "de",
  "es",
  "fr",
  "nl",
  "it",
  "zh-CN",
  "ja",
];

// These are really locales with a different mapping - i.e. not ko-ko but ko-kr.
// this mapping is used in src/client/js/store/selectors/prismic/product.js within the
// remapLocale function.
const NON_STANDARD_LOCALES_REMAP = {
  da: "da-dk",
  ja: "ja-jp",
  sv: "sv-se",
  ko: "ko-kr",
};

const prefixDict = {};
const countryCodeDict = {};
const knownCountries = [];
let localeList = [];

/**
 * Adds a new country to both the dictionary and knownCountries and then returns the country
 * @param {string} prefix
 * @param {string} iso
 * @param {string} eagleLocale
 * @param {string} wordpressLocale
 * @param {Array.<string>} contentLocales
 * @param {string} currency
 * @param {string} name
 * @param {boolean} isDefault when the country code is used for multiple locales in the same country
 * @returns {CountryModel}
 */
const add = (
  prefix,
  iso,
  eagleLocale,
  contentLocales,
  wordpressLocale,
  currency,
  name,
  isDefault = false
) => {
  const country = new CountryModel(
    prefix,
    iso,
    eagleLocale,
    contentLocales,
    wordpressLocale,
    currency,
    name,
    isDefault
  );

  prefixDict[country.prefix] = country;
  countryCodeDict[country.iso] =
    !countryCodeDict[country.iso] || isDefault
      ? country
      : countryCodeDict[country.iso];

  localeList = contentLocales.reduce((result, locale) => {
    locale = locale.replace(/..$/g, (match) => match.toUpperCase());
    if (!result.includes(locale)) {
      result.push(locale);
    }
    return result;
  }, localeList);

  knownCountries.push(country);
  return country;
};

// To add a country you need to run the add command.
// See the add function for the mappings - they currently are in this order:
// const add = (prefix, iso, eagleLocale, contentLocales, wordpressLocale, currency, name, isDefault=false) => {
// NORTH AMERICA
add("au", "AU", "en-GB", ["en-au", "en-gb"], "", "AUD", "Australia");
add("ca", "CA", "en-US", ["en-ca", "en-us"], "us", "CAD", "Canada (English)");
add("fr-ca", "CA", "fr", ["fr-ca", "fr-fr"], "fr", "CAD", "Canada (French)");
add("", "US", "en-US", ["en-us"], "us", "USD", "United States (English)", true);
add(
  "es-us",
  "US",
  "es",
  ["es-us", "es-es"],
  "es",
  "USD",
  "United States (Spanish)"
);

// ASIA
add("hk", "HK", "en-GB", ["en-hk", "en-ro", "en-gb"], "", "HKD", "Hong Kong");
add("in", "IN", "en-GB", ["en-in", "en-ro", "en-gb"], "", "INR", "India");
add("jp", "JP", "ja", ["ja-jp"], "us", "JPY", "Japan", true);
add("sg", "SG", "en-GB", ["en-sg", "en-gb"], "", "SGD", "Singapore");
add("kr", "KR", "ko", ["ko-kr"], "", "KRW", "South Korea");

// EUROPE
add("dk", "DK", "da", ["da-dk"], "us", "DKK", "Denmark");
add("nz", "NZ", "en-GB", ["en-nz", "en-au", "en-gb"], "", "NZD", "New Zealand");
add("se", "SE", "sv", ["sv-se"], "us", "SEK", "Sweden");
add("uk", "GB", "en-GB", ["en-gb"], "", "GBP", "United Kingdom", true);

// EUROPE - EUROS
add("at", "AT", "de", ["de-at", "de-de"], "de", "EUR", "Austria");
add(
  "nl-be",
  "BE",
  "nl",
  ["nl-be", "nl-nl"],
  "nl",
  "EUR",
  "Belgium (Dutch)",
  true
);
add("fr-be", "BE", "fr", ["fr-be", "fr-fr"], "fr", "EUR", "Belgium (French)");
//add('cy',     'CY', 'en-US',  ['en-us'],          'us',   'EUR', 'Cyprus');
add("ee", "EE", "en-GB", ["et-ee", "en-gb"], "", "EUR", "Estonia");
add("fi", "FI", "en-GB", ["fi-fi", "en-gb"], "", "EUR", "Finland");
add("fr", "FR", "fr", ["fr-fr"], "fr", "EUR", "France", true);
add("de", "DE", "de", ["de-de"], "de", "EUR", "Germany", true);
//add('gr',     'GR', 'en-US',  ['en-us'],          'us',   'EUR', 'Greece');
add("ie", "IE", "en-GB", ["en-ie", "en-gb"], "", "EUR", "Ireland");
add("it", "IT", "it", ["it-it"], "it", "EUR", "Italy", true);
//add('lv',     'LV', 'en-US',  ['en-us'],          'us',   'EUR', 'Latvia');
//add('lt',     'LT', 'en-US',  ['en-us'],          'us',   'EUR', 'Lithuania');
//add('lu',     'LU', 'en-US',  ['en-us'],          'us',   'EUR', 'Luxembourg');
add("mt", "MT", "en-GB", ["en-gb"], "", "EUR", "Malta");
add("nl", "NL", "nl", ["nl-nl"], "nl", "EUR", "Netherlands", true);
add("pt", "PT", "en-GB", ["pt-pt", "en-gb"], "", "EUR", "Portugal");
//add('sk',     'SK', 'en-US',  ['en-us'],          'us',   'EUR', 'Slovakia');
//add('si',     'SI', 'en-US',  ['en-us'],          'us',   'EUR', 'Slovenia');
add("es", "ES", "es", ["es-es"], "es", "EUR", "Spain", true);

// NON NATIVE CURRENCY
add("ar", "AR", "es", ["es-es"], "es", "USD", "Argentina"); // Actual currency is ARS
add("mx", "MX", "es", ["es-mx", "es-es"], "es", "USD", "Mexico"); // Actual currency is MXN
add(
  "fr-ch",
  "CH",
  "fr",
  ["fr-ch", "fr-fr"],
  "fr",
  "EUR",
  "Switzerland (French)"
); // Actual currency is CHF
add(
  "de-ch",
  "CH",
  "de",
  ["de-ch", "de-de"],
  "de",
  "EUR",
  "Switzerland (German)"
); // Actual currency is CHF
add("pe", "PE", "es", ["es-es"], "es", "USD", "Peru"); // Actual currency is PEN
add("ph", "PH", "en-US", ["en-ro", "en-us"], "us", "USD", "Philippines"); // Actual currency is PHP
add("za", "ZA", "en-US", ["en-ro", "en-us"], "us", "USD", "South Africa"); // Actual currency is ZAR
add(
  "ae",
  "AE",
  "en-US",
  ["en-ro", "en-us"],
  "us",
  "USD",
  "United Arab Emirates"
); // Actual currency is AED
add("br", "BR", "en-US", ["en-ro", "en-us"], "us", "USD", "Brazil"); // Actual currency is BRL
add("cl", "CL", "es", ["es-cl", "es-es"], "es", "USD", "Chile"); // Actual currency is CLP
add("co", "CO", "es", ["es-es"], "es", "USD", "Colombia"); // Actual currency is COP
add("id", "ID", "en-US", ["en-ro", "en-us"], "us", "USD", "Indonesia"); // Actual currency is IDR
add("my", "MY", "en-US", ["en-ro", "en-us"], "us", "USD", "Malaysia"); // Actual currency is MYR

const prefixLocaleRegex = /^\/([a-z]{2}-[a-zA-Z]{2}|[a-z]{2})\/?\b/;

const defaultCountry = countryCodeDict.US;

/**
 * @param locale
 * @returns {boolean}
 */
const isLegacyLocale = (locale) =>
  !!locale &&
  LEGACY_LOCALES.map((l) => l.toLowerCase()).includes(locale.toLowerCase());

/**
 * Returns the country with the same prefix that is passed through or
 * the default country if the prefix doesn't exist.
 *
 * @param {string} value
 * @returns {CountryModel}
 */
const getCountryWithPathConvention = (value) =>
  prefixDict[value] || defaultCountry;

/**
 * Returns the country with the same country code that is passed through or null
 *
 * @param {string} value
 * @returns {CountryModel}
 */
const getCountryWithCountryCode = (value) => countryCodeDict[value] || null;

/**
 * Returns the country with the same country code and locale that is passed through or null
 *
 * @param {string} iso
 * @param {string} [locale]
 * @returns {CountryModel}
 */
const getCountryWithIsoAndEagleLocale = (iso, locale) =>
  knownCountries
    .filter((country) => country.iso === iso)
    .find((country, idx, array) => {
      if (!locale && (country.isDefault || array.length === 1)) {
        return true;
      }

      return locale && country.locale.toLowerCase() === locale.toLowerCase();
    });

/**
 * Returns the country with the same country code and locale that is passed through or null
 *
 * @param {string} iso
 * @param {string} [locale]
 * @returns {CountryModel}
 */
const getCountryWithIsoAndContentLocale = (iso, locale) =>
  knownCountries
    .filter((country) => country.iso === iso)
    .find((country, idx, array) => {
      if (!locale && (country.isDefault || array.length === 1)) {
        return true;
      }

      return country.contentLocales.includes(locale && locale.toLowerCase());
    });

/**
 * Returns the default country with the same locale that is passed through.
 *
 * @param {string} locale
 * @returns {CountryModel}
 */
const getDefaultCountryWithEagleLocale = (locale) =>
  knownCountries.find(
    (country) =>
      country.locale.toLowerCase() === locale.toLowerCase() && country.isDefault
  );

/**
 * Returns the country with the same content locale that is passed through.
 * Some countries will have the same `contentLocale` and the primary one will be set to
 * explicity by the `isDefault` property. Scan the entire knownCountries, if you encounter the default
 * country return, otherwise finish iterating to see that no other country is the default and
 * return the result.
 *
 * @param {string} locale
 * @returns {CountryModel}
 */
const getWithContentLocale = (locale) => {
  let result = defaultCountry;
  for (const country of knownCountries) {
    const resultIsDefault =
      result.isDefault && locale === result.contentLocales[0];
    if (!resultIsDefault && locale === country.contentLocales[0]) {
      result = country;
    }
  }
  return result;
};

/**
 * Checks if the country prefix or iso passed through is in the list of known countries.
 *
 * @param {string} value can be either a prefix or iso code
 * @returns {boolean}
 */
const isCountry = (value) => !!prefixDict[value] || !!countryCodeDict[value];

/**
 * Checks if the country prefix passed through is not in the knownCountries of known countries.
 *
 * @param {string} value
 * @returns {boolean}
 */
const isNotCountry = (value) => !!value && !isCountry(value);

/**
 * Returns all known countries on the site.
 *
 * @returns {Array.<CountryModel>}
 */
const getAll = () => knownCountries;

/**
 * Returns all supported content locales on the site.
 *
 * @returns {Array.<string>}
 */
const getSupportedLocales = () => localeList;

/**
 * Checks if the country object passed in is the default country.
 *
 * @param {CountryModel} countryModel
 * @returns {boolean}
 */
const isDefault = (countryModel) => countryModel.prefix === "";

/**
 * @param locale
 * @returns {boolean}
 */
const isNotLegacyLocale = (locale) => !!locale && !isLegacyLocale(locale);

/**
 * Gets the default country
 *
 * @returns {CountryModel}
 */
const getDefault = () => defaultCountry;

/**
 * With the addition of TBOE it's possible to have an option locale which is not a valid eagle locale
 * used as a product variant option. The prime example of this is 'en-AU' where TBOE can be rendered in
 * Australian English but Eagle has no variant locale to support this. In this case we need to fallback
 * to the country model locale which for Australia is 'en-GB' so we can pull out the 'en-GB' variant. This
 * avoids having to configure a bunch of variants in Eagle for each new language we render a book in
 *
 * @param {string} locale
 * @param {CountryModel} country
 * @returns {string}
 */
const fallbackToEagleLocale = (locale, country) => {
  const isSupportedEagleLocale = knownCountries.some(
    (country) => country.locale.toLowerCase() === locale?.toLowerCase()
  );
  return isSupportedEagleLocale ? locale : country.locale;
};

/**
 * Remaps a locale that is passed in if it exists in the object or returns it lowercased
 *
 * @param locale
 * @returns {string}
 */
const remapLocale = (locale) => {
  if (!locale) {
    logger.warn("Tried to remap an undefined or null locale");
    return "";
  }
  let mappedLocale = NON_STANDARD_LOCALES_REMAP[locale];
  if (!mappedLocale && locale.length === 2) {
    mappedLocale = `${locale}-${locale}`;
  }
  return mappedLocale || locale.toLowerCase();
};

const getPrefixFromPath = (path) => {
  // If path has prefix it will return an array like so:
  // ['/uk', 'uk'] otherwise it will return null
  const found = path.match(prefixLocaleRegex);
  if (!found) {
    return null;
  }
  return found[1];
};

const getCountryFromPath = (path) => {
  const prefix = getPrefixFromPath(path) || "";
  return getCountryWithPathConvention(prefix);
};

const isPathLocalised = (path) => {
  const prefix = getPrefixFromPath(path);
  if (!prefix) {
    return false;
  }

  const prefixes = Object.keys(prefixDict);
  return prefixes.includes(prefix) || isLegacyLocale(prefix);
};

const getCountriesByIso = (iso) =>
  knownCountries.filter((country) => country.iso === iso);

module.exports = {
  prefixLocaleRegex,
  getCountryWithPathConvention,
  getCountryWithCountryCode,
  getCountryWithIsoAndEagleLocale,
  getCountryWithIsoAndContentLocale,
  getDefaultCountryWithEagleLocale,
  getWithContentLocale,
  getCountriesByIso,
  isCountry,
  isNotCountry,
  isDefault,
  getAll,
  getSupportedLocales,
  getDefault,
  isLegacyLocale,
  isNotLegacyLocale,
  fallbackToEagleLocale,
  remapLocale,
  getPrefixFromPath,
  getCountryFromPath,
  isPathLocalised,
};
