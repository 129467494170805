import Qualities from "./Qualities";
import compose from "recompose/compose";
import withProps from "recompose/withProps";
import withHandlers from "recompose/withHandlers";
import debounce from "lodash/debounce";
import { withFormik } from "formik";
import withValidationData from "../../../hocs/withValidationData";
import { validateCreationForm, clearErrors } from "../helpers";
import { getMaxLength } from "./helpers";

const debouncedValidation = debounce(
  (values, props, config) => validateCreationForm(values, props, config),
  700
);

const enhance = compose(
  withValidationData,
  withProps(({ drawer: { customisationKey = "values" } }) => ({
    customisationKey,
  })),
  withFormik({
    mapPropsToValues: ({
      customisationKey,
      queryParams,
      productCustomisation,
    }) => ({
      base: "",
      [customisationKey]:
        queryParams[customisationKey] ||
        productCustomisation[customisationKey] ||
        [],
    }),
    handleSubmit: (values, { props }) => {
      props.onFormikSubmit(values);
    },
  }),
  withHandlers({
    handleChange:
      ({ customisationKey, values, ...props }) =>
      (e) => {
        let options = values[customisationKey].concat();

        const value = e.target.value;
        const valueIndex = options.indexOf(value);

        if (valueIndex !== -1) {
          options.splice(valueIndex, 1);
        } else {
          options.push(value);
        }

        if (options.length >= getMaxLength(props)) {
          clearErrors(props, customisationKey);
          props.setSubmitting(true);
          debouncedValidation({ [customisationKey]: options }, props, {
            enableSetErrors: true,
          });
        }

        props.setFieldValue(customisationKey, options);
      },
  })
);

export default enhance(Qualities);
