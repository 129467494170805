// This object has ALL available customisations, across all products.
const customisations = {
  locale: "en-GB",
  address_type: "full-address",
  door: "10",
  street: "Stonehenge Road",
  city: "Amesbury",
  country: "gb",
  lat: "51.171223",
  lng: "-1.789289",
  food: "chicken",
  interest: "dinosaurs",
  superhero: "overdrive",
  box: "no-box",
  size: "journal",
  day: "1",
  month: "1",
  year: "2000",
  cover_style: "cover-i",
  cover: "softback",
  phototype: "type-iii",
  age: "1",
  name: "Mike",
  surname: "Wazowski",
  gender: "girl",
  bears: [
    {
      name: "Asi",
      type: "baking_bear",
    },
    {
      name: "Pedro",
      type: "balloon_bear",
    },
    {
      name: "David",
      type: "wrapping_bear",
    },
    {
      name: "Tal",
      type: "drinks_bear",
    },
  ],
  names: ["Daddy", "Daddy"],
  values: ["determination", "bravery", "curiosity"],
  characters: [
    {
      name: "Mister",
      gender: "boy",
      phototype: "type-iii",
    },
    {
      name: "Bean",
      gender: "girl",
      phototype: "type-iii",
    },
  ],
  wishes: ["strength", "adventure", "compassion", "wisdom"],
};

const inscription = `Wow what a sophisticated inscription, you must be a writer`;

export const buildCartItemFor = (productParams) => {
  // When submitting the item to eagle, eagle doesn't care about extra fields.
  // It will only fail a request for missing/incorrect fields.
  // Hence, we add ALL customisations for every book.
  //
  // Eagle WILL break if one product supports values {a,b} for customisation "cover-type",
  // and another product supports only {c,d} for the same customisation "cover-type".
  // Handling these specific scenarios is possible here, but it would be better to use same keys in eagle, if possible.
  return [
    {
      productId: productParams.product,
      customisations: {
        ...customisations,
        inscription,
        ...productParams,
      },
    },
  ];
};
